import React, { useEffect, useState } from 'react'

import moment from 'moment'

import styled from '@emotion/styled'

import { CloseOutlined } from '@ant-design/icons'
import { Button, Drawer, Form } from 'antd'

import { Box } from 'common/components/boxes'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { OrderMaterial } from 'common/server/orders'

import { IssueSelectForm } from './issue_select_form'
import { ItemHeader } from './item_header'
import { SwitchControls } from './switch_controls'

const BorderedBox = styled(Box)`
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 16px;
`

type ItemProps = {
  orderMaterial: OrderMaterial
  handleFinish: (
    values: { quantity_delivered: number; back_ordered_date?: string; isNewIssue?: boolean },
    id: string,
  ) => void
  showResolve?: boolean
  isNewIssue?: boolean
  disabled?: boolean
}

export const Item = (props: ItemProps) => {
  const { orderMaterial, handleFinish, showResolve, isNewIssue, disabled } = props
  const [isLocked, setIsLocked] = useState(showResolve)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [hasOpenIssue, setHasOpenIssue] = useState(false)
  const isMobile = useMediaQuery('md')
  const [form] = Form.useForm()
  const deliveryIssues = Form.useWatch(`delivery_issues_${orderMaterial.id}`, form)
  const isResolved = !!Form.useWatch(`resolved_${orderMaterial.id}`, form) || !!orderMaterial.resolved_at

  const handleEditClick = () => {
    if (isMobile) {
      setDrawerVisible(true)
    } else {
      setIsLocked(false)
    }
  }

  const handleFormClose = () => {
    if (isMobile) {
      setDrawerVisible(false)
    } else {
      setIsLocked(!isNewIssue)
    }
  }

  const handleSetIssue = () => {
    setHasOpenIssue(true)
    if (isMobile) setDrawerVisible(true)
  }

  const handleFormFinish = (
    values: { quantity_delivered: number; back_ordered_date?: string; isNewIssue?: boolean },
    id: string,
  ) => {
    handleFinish(values, id)
    handleFormClose()
  }

  const setDefaultValues = () => {
    setHasOpenIssue(orderMaterial?.has_open_issue)
    form.setFieldsValue({
      [`quantity_delivered_${orderMaterial.id}`]: orderMaterial.quantity_delivered,
      [`back_ordered_date_${orderMaterial.id}`]: orderMaterial.expected_back_ordered_date
        ? moment.utc(orderMaterial.expected_back_ordered_date).local()
        : null,
      [`delivery_issues_${orderMaterial.id}`]: orderMaterial.delivery_issues,
      [`resolved_${orderMaterial.id}`]: !!orderMaterial.resolved_at,
      [`resolved_at_${orderMaterial.id}`]: orderMaterial.resolved_at,
    })
  }

  useEffect(() => {
    setDefaultValues()
  }, [orderMaterial, form])

  const handleCancel = () => {
    setDefaultValues()
    handleFormClose()
  }

  return (
    <Form
      form={form}
      disabled={disabled}
      layout="vertical"
      onFinish={(values) => handleFormFinish(values, orderMaterial.id)}
      style={{ width: '100%' }}
    >
      <BorderedBox key={orderMaterial.id}>
        <Box display="flex" mb={8} justifyContent="space-between">
          <ItemHeader isResolved={isResolved} orderMaterial={orderMaterial} isLocked={isLocked} form={form} />
          <SwitchControls
            hasOpenIssue={hasOpenIssue}
            onRemoveIssue={() => setHasOpenIssue(false)}
            onSetIssue={handleSetIssue}
            isLocked={isLocked}
            onEditClick={handleEditClick}
            showResolve={showResolve && !isLocked}
            isMobile={isMobile}
            orderMaterial={orderMaterial}
            isResolved={isResolved}
          />
        </Box>

        {!isMobile && hasOpenIssue && (
          <IssueSelectForm
            deliveryIssues={deliveryIssues}
            onCancelEdit={handleCancel}
            isNewIssue={isNewIssue}
            isLocked={isLocked}
            setLocked={setIsLocked}
            orderMaterial={orderMaterial}
            handleFinish={handleFormFinish}
            form={form}
          />
        )}

        {isMobile && hasOpenIssue && (
          <Drawer
            title="Edit Item"
            placement="bottom"
            closable={false}
            extra={<Button onClick={handleCancel} icon={<CloseOutlined />} />}
            onClose={handleCancel}
            open={drawerVisible}
            height="auto"
            bodyStyle={{ paddingBottom: 60 }}
            footer={
              <Box style={{ display: 'flex', padding: '10px 8px', gap: '16px' }}>
                <Button style={{ flex: 1 }} onClick={handleCancel} type="ghost">
                  Cancel
                </Button>
                <Button
                  style={{ flex: 1 }}
                  type="primary"
                  onClick={() => handleFormFinish(form.getFieldsValue(), orderMaterial.id)}
                >
                  Confirm
                </Button>
              </Box>
            }
            footerStyle={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
          >
            {hasOpenIssue && (
              <IssueSelectForm
                form={form}
                deliveryIssues={deliveryIssues}
                onCancelEdit={handleCancel}
                showResolve={showResolve}
                isLocked={false}
                setLocked={setIsLocked}
                orderMaterial={orderMaterial}
                handleFinish={handleFormFinish}
              />
            )}
          </Drawer>
        )}
      </BorderedBox>
    </Form>
  )
}
