import React from 'react'

import { InputCurrency } from 'common/components/InputCurrency'

import { useInvoice } from '../../context'
import { TaxInputDrawer } from './tax_input_drawer'
import { TaxInputProps } from './types'

export const TaxInput = (props: TaxInputProps) => {
  const { taxLineItemsEnabled } = useInvoice()

  if (taxLineItemsEnabled) {
    return <TaxInputDrawer {...props} />
  }

  return <InputCurrency {...props} />
}
