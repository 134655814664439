import React, { useState, useRef, useEffect } from 'react'

import { Alert, Form, message, Checkbox, Divider, Button, Input } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import Tags from 'common/components/tags'
import { CostCode } from 'common/server/cost_codes/cost_codes'

import { SelectCostCodeClass } from 'contractor/components/SelectCostCodeClass'
import { SelectCostCodeNumber } from 'contractor/components/SelectCostCodeNumber'
import { SelectCostCodePhase } from 'contractor/components/SelectCostCodePhase'
import { useStores } from 'contractor/hooks/use-stores'

import { SelectProjects } from './select_projects'
import { UpsertCostCodeClass } from './upsert_cost_code_class'
import { UpsertCostCodeNumber } from './upsert_cost_code_number'
import { UpsertCostCodePhase } from './upsert_cost_code_phase'

type UpsertCostCodeProps = {
  onCancel: () => void
  onSubmit?: (data: CostCode) => void
}

const makeOption = (costCode) =>
  costCode
    ? {
        value: costCode?.id,
        label: costCode?.code,
      }
    : undefined

const makeUpdateOption = (option) => ({
  id: option?.id,
  code: option?.code,
  description: option?.description,
})

export const UpsertCostCode = observer<UpsertCostCodeProps, DrawerRef>(
  ({ onCancel, onSubmit }, ref) => {
    const { costCodeStore, companySettingStore } = useStores()

    const { costCodeListStore } = costCodeStore

    const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings
    const currentCostCode = costCodeStore.selectedCostCode

    const [alertMessage, setAlertMessage] = useState('')
    const [isSubmitting, setSubmitting] = useState(false)

    const [costCodeClassUpdate, setCostCodeClassUpdate] = useState(null)
    const [costCodeNumberUpdate, setCostCodeNumberUpdate] = useState(null)
    const [costCodePhaseUpdate, setCostCodePhaseUpdate] = useState(null)

    const upsertCostCodeClassRef = useRef<DrawerRef>()
    const upsertCostCodeNumberRef = useRef<DrawerRef>()
    const upsertCostCodePhaseRef = useRef<DrawerRef>()

    const [form] = Form.useForm()
    const costCodePhaseField = Form.useWatch('costCodePhase', form)
    const costCodeNumberField = Form.useWatch('costCodeNumber', form)
    const costCodeClassField = Form.useWatch('costCodeClass', form)

    const showPhase = costCodeSettings.phase_code_enabled && !costCodeSettings.independent_phase_codes_enabled

    const handleCancel = () => {
      form.resetFields()
      setAlertMessage('')
      costCodeStore.selectFromList(null)
      onCancel()
    }

    const handleSubmit = async (formValues) => {
      setSubmitting(true)
      setAlertMessage('')

      try {
        const [costCode] = await costCodeStore.upsert({
          id: currentCostCode?.id,
          active: formValues?.active,
          cost_code_number: formValues?.code,
          cost_code_number_description: formValues?.description,
          cost_code_phase_id: formValues?.costCodePhase?.value || formValues?.costCodePhase,
          cost_code_class_id: formValues?.costCodeClass?.value || formValues?.costCodeClass,
          cost_code_number_id: formValues?.costCodeNumber?.value || formValues?.costCodeNumber,
          project_ids_relation: formValues?.projectIdsRelation,
          tags: formValues?.tags,
        })
        costCodeListStore.fetchRecords()
        costCodeListStore.fetchFacets()
        costCodeStore.getCostCodeTags()
        message.success('Successfully added cost code')
        onSubmit?.(costCode)
        handleCancel()
      } catch (error) {
        if (error.response.data.error.includes('duplicate key value violates')) {
          setAlertMessage('Unable to save due to duplicate cost code.')
        } else {
          setAlertMessage(error?.response?.data?.error || 'Unable to add cost code')
        }
      } finally {
        setSubmitting(false)
      }
    }

    const fetchCostCodeClass = (id) => {
      costCodeStore.getCostCodeClass(id).then((costCodeClass) => {
        setCostCodeClassUpdate(makeUpdateOption(costCodeClass))
        upsertCostCodeClassRef.current?.show()
      })
    }

    const fetchCostCodePhase = (id) => {
      costCodeStore.getCostCodePhase(id).then((costCodePhase) => {
        setCostCodePhaseUpdate(makeUpdateOption(costCodePhase))
        upsertCostCodePhaseRef.current?.show()
      })
    }

    const fetchCostCodeNumber = (id) => {
      costCodeStore.getCostCodeNumber(id).then((costCodeNumber) => {
        setCostCodeNumberUpdate(makeUpdateOption(costCodeNumber))
        upsertCostCodeNumberRef.current?.show()
      })
    }

    useEffect(() => {
      if (currentCostCode?.id) {
        form.setFieldsValue({
          code: currentCostCode?.code,
          description: currentCostCode?.description,
          costCodePhase: makeOption(currentCostCode?.cost_code_phase),
          costCodeClass: makeOption(currentCostCode?.cost_code_class),
          costCodeNumber: makeOption(currentCostCode?.cost_code_number),
          projectIdsRelation: currentCostCode?.project_ids || [],
          tags: currentCostCode?.tags || [],
          active: !currentCostCode?.discarded_at,
        })
      }
    }, [currentCostCode?.id])

    const isPhaseCodeEnabled =
      costCodeSettings?.phase_code_enabled && !costCodeSettings?.independent_phase_codes_enabled

    return (
      <Drawer
        bgGray
        ref={ref}
        title={currentCostCode?.id ? 'Update Cost Code' : 'Add New Cost Code'}
        onClose={handleCancel}
        width={378}
      >
        <UpsertCostCodeClass
          ref={upsertCostCodeClassRef}
          onCancel={() => upsertCostCodeClassRef.current?.close()}
          onSubmit={(costCodeClass) => form.setFieldsValue({ costCodeClass: makeOption(costCodeClass) })}
          initialValues={costCodeClassUpdate}
        />
        <UpsertCostCodeNumber
          ref={upsertCostCodeNumberRef}
          onCancel={() => upsertCostCodeNumberRef.current?.close()}
          onSubmit={(costCodeNumber) => form.setFieldsValue({ costCodeNumber: makeOption(costCodeNumber) })}
          initialValues={costCodeNumberUpdate}
        />
        {showPhase && (
          <UpsertCostCodePhase
            ref={upsertCostCodePhaseRef}
            onCancel={() => upsertCostCodePhaseRef.current?.close()}
            onSubmit={(costCodePhase) => form.setFieldsValue({ costCodePhase: makeOption(costCodePhase) })}
            initialValues={costCodePhaseUpdate}
          />
        )}

        <Box width="100%" height="100%" p={16} overflow="auto">
          {alertMessage && <Alert type="error" message={alertMessage} style={{ marginBottom: '20px' }} />}

          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            initialValues={{ active: true }}
            style={{ width: '100%' }}
          >
            {costCodeSettings?.class_enabled || isPhaseCodeEnabled ? (
              <>
                {showPhase && (
                  <Form.Item
                    label="Phase"
                    name="costCodePhase"
                    rules={[{ required: true, message: 'Phase is required!' }]}
                  >
                    <SelectCostCodePhase
                      data-cy="select-upsert-cost-code-phase"
                      onAdd={() => {
                        setCostCodePhaseUpdate(null)
                        upsertCostCodePhaseRef.current?.show()
                      }}
                      onEdit={() => fetchCostCodePhase(costCodePhaseField?.value || costCodePhaseField)}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="costCodeNumber"
                  label="Code"
                  rules={[{ required: true, message: 'Code is required!' }]}
                >
                  <SelectCostCodeNumber
                    data-cy="select-upsert-cost-code-number"
                    onAdd={() => {
                      setCostCodeNumberUpdate(null)
                      upsertCostCodeNumberRef.current?.show()
                    }}
                    onEdit={() => fetchCostCodeNumber(costCodeNumberField?.value || costCodeNumberField)}
                  />
                </Form.Item>

                {costCodeSettings.class_enabled && (
                  <Form.Item
                    name="costCodeClass"
                    label="Class"
                    rules={[{ required: true, message: 'Class is required!' }]}
                  >
                    <SelectCostCodeClass
                      data-cy="select-upsert-cost-code-class"
                      onAdd={() => {
                        setCostCodeClassUpdate(null)
                        upsertCostCodeClassRef.current?.show()
                      }}
                      onEdit={() => fetchCostCodeClass(costCodeClassField?.value || costCodeClassField)}
                    />
                  </Form.Item>
                )}
              </>
            ) : (
              <>
                <Form.Item name="code" label="Code" rules={[{ required: true, message: 'Code is required!' }]}>
                  <Input data-cy="upsert-cost-code-code-input" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="description" label="Description">
                  <Input data-cy="upsert-cost-code-description-input" style={{ width: '100%' }} />
                </Form.Item>
              </>
            )}

            {costCodeSettings?.project_filtering_enabled && (
              <Form.Item name="projectIdsRelation" label="Projects">
                <SelectProjects data-cy="select-projects-upsert-cost-code" />
              </Form.Item>
            )}

            <Form.Item name="tags" label="Tags">
              <Tags tags={costCodeStore.costCodeTags} data-cy="tags-upsert-cost-code" />
            </Form.Item>

            <Form.Item name="active" label="Active" valuePropName="checked">
              <Checkbox data-cy="active-checkbox" />
            </Form.Item>

            <Divider />

            <Box display="flex" justifyContent="space-between">
              <Button
                data-cy="cancel-button-upsert-cost-code"
                onClick={onCancel}
                disabled={isSubmitting}
                style={{ width: 100 }}
              >
                Cancel
              </Button>

              <Button
                data-cy="save-button-upsert-cost-code"
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
                style={{ width: 100 }}
              >
                {currentCostCode?.id ? 'Update' : 'Create'}
              </Button>
            </Box>
          </Form>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
