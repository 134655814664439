import React from 'react'

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Menu, Switch, Typography } from 'antd'

import { FlexBoxX } from 'common/components/boxes'

import { ColumnsProps } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/LogView/columns_definition'

type ColumnsSettingsProps = {
  columnSettings: ColumnsProps[]
  toggleColumnVisibility: (dataIndex: string | string[]) => void
}

export const ColumnsView = React.memo((props: ColumnsSettingsProps) => {
  const { columnSettings, toggleColumnVisibility } = props
  return (
    <Menu>
      {columnSettings.map((col) => {
        const keyStr = Array.isArray(col.dataIndex) ? col.dataIndex.join('.') : col.dataIndex
        return (
          <Menu.Item style={{ width: '100%' }} key={keyStr}>
            <FlexBoxX justifyContent="space-between" style={{ minWidth: 250 }}>
              <FlexBoxX justifyContent="start">
                <Typography.Text>{col.title}</Typography.Text>
              </FlexBoxX>

              <Switch
                checked={col.visible}
                onChange={() => toggleColumnVisibility(col.dataIndex)}
                checkedChildren={<EyeOutlined />}
                unCheckedChildren={<EyeInvisibleOutlined />}
              />
            </FlexBoxX>
          </Menu.Item>
        )
      })}
    </Menu>
  )
})
