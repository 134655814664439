import './style.css'

import React from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { Button, notification } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { usePushToOrder } from 'common/hooks/use-push-to-order'
import { OrderStates, OrderSubStates } from 'common/server/server_types'
import theme from 'common/styles/theme'

import { Box } from '../../components/boxes'

type Operation = 'create' | 'update'

type Index = {
  orderId?: string
  operation?: Operation
  basePath?: string
}

type NotifyOrderParams = {
  message: string
  state?: OrderStates
  subState?: OrderSubStates
  orderId?: string
  orderPackageId?: string
  orderType?: OrderType
  description?: string
  isNewTab?: boolean
  fileUrl?: string
}

export const useNotifyOrder = ({ orderId: orderIdProp, operation = 'create', basePath }: Index = {}) => {
  const { push } = usePushToOrder()
  const key = `order-notify-${new Date().getTime()}`

  const handleOpenClick = (params) => {
    notification.close(key)
    push({
      ...params,
      basePath,
      orderId: params?.orderId || orderIdProp,
    })
  }

  const notifyOrder = ({
    message,
    orderId = '',
    orderPackageId,
    orderType,
    state,
    subState,
    description,
    isNewTab,
  }: // fileUrl,
  NotifyOrderParams) => {
    const closeIcon = (
      <Box display="flex" gridGap={1}>
        <Button
          data-cy={`view-${operation === 'create' ? orderType : 'order'}-notification`}
          type="primary"
          size="small"
          onClick={() => {
            trackEvent(Events.ORDER_NOTIFICATION_CLICKED_VIEW_ORDER, window.location.pathname, {
              state,
              subState,
              isNewTab,
              orderType,
              operation,
            })

            handleOpenClick({ orderId, orderPackageId, state, subState, isNewTab })
          }}
        >
          View {operation === 'create' ? orderType : 'Order'}
        </Button>
        <Button
          data-cy={`close-${operation === 'create' ? orderType : 'order'}-notification`}
          type="text"
          icon={<CloseOutlined style={{ color: theme.colors['gray-7'] }} />}
          size="small"
          onClick={() => {
            notification.close(key)
          }}
        />
      </Box>
    )

    notification.success({
      message,
      description,
      duration: 10,
      placement: 'bottomLeft',
      key,
      className: 'use-notify-order',
      closeIcon,
    })
  }

  return { notifyOrder }
}
