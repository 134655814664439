import React from 'react'

import { v4 as uuidV4 } from 'uuid'

import { Button, Checkbox, Form } from 'antd'
import { Card } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { InputCurrency } from 'common/components/InputCurrency'
import { SearchInput } from 'common/components/SearchInput'

import { useStores } from 'contractor/hooks/use-stores'

import { AccountingDetails } from '../components/AccountingDetails'
import { CreateGroupItem } from '../components/CreateGroupItem'
import { DeleteInvoiceItems } from '../components/DeleteInvoiceMaterials'
import { TabReconcile } from '../components/TabReconcile'
import { TaxInput } from '../components/TaxInput'
import { useInvoice } from '../context'
import { extractQtyAndUnitsOfOrderMaterials } from '../helpers'
import { InvoiceMaterials } from '../InvoiceMaterials'

export const ExtractedData = observer(() => {
  const { invoiceStore } = useStores()

  const {
    form,
    setSelectedInvoiceDirty,
    lockInvoice,
    handleSearch,
    toggleAllInvoiceMaterialsChecked,
    allInvoiceMaterialsIsChecked,
    invoiceMaterialsFiltered,
    searchTerm,
    currentReconcileStatus,
  } = useInvoice()

  const invoice = invoiceStore.invoice

  const handleAddItem = () => {
    invoiceStore.updateSelectedInvoice('invoice_materials', [
      ...invoice?.invoice_materials,
      {
        id: `new-item-${uuidV4()}`,
        description: '',
        quantity_shipped: 0,
        uom: '',
        unit_price: 0,
        extended_price: 0,
        key: uuidV4(),
        invoice_id: invoice?.id,
        accepts_tax_split: true,
      },
    ])
    setSelectedInvoiceDirty(true)
  }

  React.useEffect(() => {
    form.setFieldsValue({
      taxAmount: invoice?.tax_amount,
      discountAmount: invoice?.discount_amount,
      shippingCost: invoice?.shipping_cost,
      otherCosts: invoice?.other_costs,
    })
  }, [invoice?.tax_amount, invoice?.discount_amount, invoice?.shipping_cost, invoice?.other_costs])

  const handleFilterInvoiceMaterialsTable = (invoiceMaterial) => {
    if (currentReconcileStatus === 'all') {
      return true
    }

    const extractedQuantity = Number(invoiceMaterial?.quantity_shipped || 0)
    const invoiceUnit = invoiceMaterial?.uom?.trim() || ''

    const [orderMaterialsQuantities, orderMaterialsUnits] = extractQtyAndUnitsOfOrderMaterials(
      invoiceMaterial?.order_materials,
    )

    const hasOrderMaterials = !!invoiceMaterial?.order_materials?.length

    if (currentReconcileStatus === 'matches') {
      return (
        hasOrderMaterials &&
        orderMaterialsQuantities?.length <= 1 &&
        orderMaterialsQuantities[0] === extractedQuantity &&
        orderMaterialsUnits?.length <= 1 &&
        invoiceUnit === orderMaterialsUnits[0]
      )
    }

    if (currentReconcileStatus === 'partial-matches') {
      return (
        hasOrderMaterials &&
        (orderMaterialsQuantities?.length > 1 ||
          orderMaterialsUnits?.length > 1 ||
          orderMaterialsQuantities[0] !== extractedQuantity ||
          invoiceUnit !== orderMaterialsUnits[0])
      )
    }

    return !invoiceMaterial?.order_materials?.length
  }

  const accountDetailsButtonIsDisabled = invoiceMaterialsFiltered.materialsFilteredBySearch.every(
    (material) => !material.checked,
  )
  const groupButtonIsDisabled = invoiceMaterialsFiltered.groups.some((group) => group.checked)

  return (
    <FlexBoxY flex={1} maxWidth="100%" style={{ gap: 16 }}>
      <Box display="flex" flexDirection="column" width="100%">
        <TabReconcile />

        <Card
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          bodyStyle={{ padding: 10, flexGrow: 1, display: 'flex', alignItems: 'center' }}
        >
          <Checkbox onChange={() => toggleAllInvoiceMaterialsChecked()} checked={allInvoiceMaterialsIsChecked} />

          <FlexBoxX ml={8} style={{ gap: 8 }}>
            <CreateGroupItem disabled={groupButtonIsDisabled} />

            <AccountingDetails disabled={accountDetailsButtonIsDisabled && !groupButtonIsDisabled} />

            <DeleteInvoiceItems />

            <SearchInput onSearch={handleSearch} onChange={(e) => handleSearch(e.target.value)} value={searchTerm} />
          </FlexBoxX>
        </Card>
      </Box>

      <Form
        key={invoice?.id}
        disabled={!lockInvoice.canEditInvoice}
        form={form}
        onChange={() => setSelectedInvoiceDirty(true)}
        layout="vertical"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flexGrow: 1,
          width: '100%',
        }}
      >
        <Box flexGrow={1} display="flex" flexDirection="column">
          <Box overflow="auto" height={0} flexGrow={1}>
            <InvoiceMaterials onFilterTable={handleFilterInvoiceMaterialsTable} />
            <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end" mt={12}>
              <Button disabled={true /*!lockInvoice.canEditInvoice*/} onClick={() => handleAddItem()} type="primary">
                Add Item
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{ gap: 16 }}
          mt={12}
          backgroundColor="white"
          p={8}
        >
          <Form.Item label="Tax" name="taxAmount" style={{ flexGrow: 1, margin: 0 }}>
            <TaxInput tabIndex={51} disabled={!lockInvoice.canEditInvoice} />
          </Form.Item>
          <Form.Item label="Shipping" name="shippingCost" style={{ flexGrow: 1, margin: 0 }}>
            <InputCurrency tabIndex={52} disabled={!lockInvoice.canEditInvoice} />
          </Form.Item>
          <Form.Item label="Other costs" name="otherCosts" style={{ flexGrow: 1, margin: 0 }}>
            <InputCurrency tabIndex={53} disabled={!lockInvoice.canEditInvoice} />
          </Form.Item>
          <Form.Item label="Discount" name="discountAmount" style={{ flexGrow: 1, margin: 0 }}>
            <InputCurrency tabIndex={54} disabled={!lockInvoice.canEditInvoice} />
          </Form.Item>
        </Box>
      </Form>
    </FlexBoxY>
  )
})
