import React from 'react'

import styled from '@emotion/styled'

import { Collapse, Typography } from 'antd'

import { OrderMaterial } from 'common/server/orders'

import { Item } from 'contractor/components/DeliveryDetail/IncompleteItems/item'

type IncompleteItemsProps = {
  onChange?: (orderMaterials: OrderMaterial[]) => void
  orderMaterials: OrderMaterial[]
  disabled?: boolean
}

const StyledPanel = styled(Collapse.Panel)`
  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 16px !important;
  }

  .ant-collapse-content-box {
    padding: 0 16px !important;
  }

  .ant-collapse-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
    padding: 0 16px 16px 16px !important;
  }
`

export const IncompleteItems = (props: IncompleteItemsProps) => {
  const { onChange, orderMaterials, disabled } = props
  const incompleteItems = orderMaterials?.filter((item) => item.has_open_issue)
  const completeItems = orderMaterials?.filter((item) => !item.has_open_issue)

  if (orderMaterials?.length === 0) {
    return null
  }

  const handleFinishForm = (
    values: { quantity_delivered: number; back_ordered_date?: string; isNewIssue?: boolean },
    id: string,
  ) => {
    const updatedOrderMaterials = orderMaterials.map((orderMaterial) => {
      if (orderMaterial.id === id) {
        return {
          ...orderMaterial,
          quantity_delivered: values[`quantity_delivered_${id}`],
          expected_back_ordered_date: values[`back_ordered_date_${id}`],
          has_open_issue: !values[`has_open_issue_${id}`],
          delivery_issues: values[`delivery_issues_${id}`],
          resolved: values[`resolved_${id}`],
          resolved_at: values[`resolved_${id}`] ? new Date().toISOString() : null,
        }
      }

      return orderMaterial
    })

    onChange(updatedOrderMaterials)
  }

  return (
    <Collapse expandIconPosition="end" style={{ paddingTop: 0, paddingBottom: 0 }} ghost defaultActiveKey={['1', '2']}>
      {incompleteItems?.length > 0 && (
        <StyledPanel
          style={{ paddingTop: 0, paddingBottom: 0 }}
          header={<Typography.Text>Problems</Typography.Text>}
          key="1"
        >
          {incompleteItems.map((orderMaterial) => (
            <Item
              key={orderMaterial.id}
              handleFinish={handleFinishForm}
              orderMaterial={orderMaterial}
              showResolve
              disabled={disabled}
            />
          ))}
        </StyledPanel>
      )}
      {completeItems?.length > 0 && (
        <StyledPanel
          style={{ paddingTop: 0, paddingBottom: 0 }}
          header={<Typography.Text>Items list</Typography.Text>}
          key="2"
        >
          {completeItems?.map((orderMaterial) => (
            <Item
              key={orderMaterial.id}
              handleFinish={handleFinishForm}
              orderMaterial={orderMaterial}
              isNewIssue
              disabled={disabled}
            />
          ))}
        </StyledPanel>
      )}
    </Collapse>
  )
}
