import React from 'react'

import { Form, TimePicker } from 'antd'

import { BaseInputProps } from '../../hooks/editable-hooks'

export const TimeInput: React.FC<BaseInputProps> = ({ name, title, rules, onSave, ref }) => {
  return (
    <Form.Item
      style={{ margin: 0 }}
      name={name}
      rules={rules || [{ required: false, message: `Please input ${title}!` }]}
    >
      <TimePicker ref={ref} format="HH:mm" onChange={onSave} onBlur={onSave} />
    </Form.Item>
  )
}
