import React, { useContext, useEffect, useRef } from 'react'

import moment from 'moment'

import { ConcreteCommitmentMaterial } from 'contractor/server/commitments'

import { EditableContext } from '../hooks/editable_context'
import { useEditableState } from '../hooks/editable-hooks'
import { useNestedData } from '../hooks/use-nested-data'
import { DateInput, NumberInput, TextInput, TimeInput } from './EditableInputs'

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: string | string[]
  record: ConcreteCommitmentMaterial
  handleSave: (record: ConcreteCommitmentMaterial) => void
  inputType?: 'text' | 'number' | 'date' | 'time'
}

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  inputType = 'text',
  ...restProps
}) => {
  const form = useContext(EditableContext)
  const [editing, toggleEdit, setEditing] = useEditableState(false)
  const flattenedDataIndex = Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex
  const { getValue, setValue } = useNestedData(record, dataIndex)
  const wrapperInputRef = useRef(null)

  const handleEdit = () => {
    let initialValue = getValue()
    if (inputType === 'date' && initialValue) {
      initialValue = moment(initialValue)
    } else if (inputType === 'time' && initialValue) {
      initialValue = moment(initialValue, 'HH:mm')
    }
    form?.setFieldsValue({ [flattenedDataIndex]: initialValue })
  }

  useEffect(() => {
    if (editing) {
      handleEdit()
    }
  }, [editing, dataIndex, form, inputType])

  const save = async () => {
    const values = await form?.validateFields()
    setEditing(false)

    if (values) {
      let val = values[flattenedDataIndex]

      if (['date', 'time'].includes(inputType) && val) {
        val = moment(val).toISOString()
      }

      const updatedRecord = setValue(val) as ConcreteCommitmentMaterial
      handleSave(updatedRecord)
    }
  }

  const renderInput = () => {
    const baseProps = { name: flattenedDataIndex, title, onSave: save, ref: wrapperInputRef }
    switch (inputType) {
      case 'date':
        return <DateInput {...baseProps} />
      case 'time':
        return <TimeInput {...baseProps} />
      case 'number':
        return <NumberInput {...baseProps} />
      case 'text':
      default:
        return <TextInput {...baseProps} />
    }
  }

  const renderContent = () => {
    if (!editable) return children

    if (editing) {
      return renderInput()
    }

    return (
      <div
        className="editable-cell-value-wrap"
        style={{ position: 'relative', width: '100%' }}
        onMouseDown={(e) => {
          e.stopPropagation()
        }}
        onClick={toggleEdit}
      >
        <span>{children}</span>
      </div>
    )
  }

  return <td {...restProps}>{renderContent()}</td>
}
