import React, { useEffect, useState } from 'react'

import { Button, Skeleton } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { SearchInput } from 'common/components/SearchInput'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { UnreconciledInvoiceResponse } from 'common/server/invoice'

import { InvoiceOptions } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/Invoices/invoice_options'

type SelectInvoiceStepProps = {
  unreconciledInvoiceList: UnreconciledInvoiceResponse[]
  isLoadingUnreconciledInvoiceList: boolean
  handleSelectInvoice: (invoice: UnreconciledInvoiceResponse) => void
  isLoading: boolean
  onBack: () => void
  onAttachInvoices: (invoiceIds: string[]) => void
}

export const SelectInvoiceStep = observer((props: SelectInvoiceStepProps) => {
  const {
    unreconciledInvoiceList,
    isLoadingUnreconciledInvoiceList,
    handleSelectInvoice,
    isLoading,
    onBack,
    onAttachInvoices,
  } = props

  const isMobile = useMediaQuery('md')
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredUnreconciledInvoiceList, setFilteredUnreconciledInvoiceList] = useState<UnreconciledInvoiceResponse[]>(
    [],
  )
  const [selectedInvoices, setSelectedInvoices] = useState<UnreconciledInvoiceResponse[]>([])

  const updateFilteredUnreconciledInvoiceList = () => {
    const newFilteredList = unreconciledInvoiceList?.filter((invoice) =>
      searchTerm ? invoice.searchable.includes(searchTerm) : true,
    )
    setFilteredUnreconciledInvoiceList(newFilteredList)
  }

  useEffect(() => {
    updateFilteredUnreconciledInvoiceList()
  }, [unreconciledInvoiceList, searchTerm])

  const handleAttachInvoices = () => {
    const invoiceIds = selectedInvoices.map((invoice) => invoice.id)
    onAttachInvoices(invoiceIds)
    setSelectedInvoices([])
  }

  return (
    <Box display="flex" flexDirection="column" maxWidth={isMobile ? '100%' : 800} pt="16" height="100%">
      <SearchInput
        onChange={(event) => setSearchTerm(event.target.value)}
        style={{ paddingLeft: 16, paddingRight: 16 }}
      />
      <Box flexGrow={1} overflow="auto" style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 16 }}>
        {isLoadingUnreconciledInvoiceList ? (
          <Skeleton />
        ) : (
          <InvoiceOptions
            setSelectedInvoices={setSelectedInvoices}
            dataSource={filteredUnreconciledInvoiceList}
            onSelect={handleSelectInvoice}
          />
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        style={{
          padding: '16px',
          gap: '16px',
          backgroundColor: 'white',
          borderTopStyle: 'solid',
          borderTopWidth: '1px',
          borderTopColor: '#f0f0f0',
          position: 'sticky',
          bottom: 0,
          zIndex: 1,
        }}
      >
        <Button disabled={isLoading} onClick={onBack} style={{ flexGrow: 1 }}>
          Close
        </Button>

        <Button
          disabled={selectedInvoices.length === 0}
          loading={isLoading}
          data-cy="save_delivery"
          type="primary"
          onClick={handleAttachInvoices}
          style={{ flexGrow: 1 }}
        >
          Attach invoices
        </Button>
      </Box>
    </Box>
  )
})
