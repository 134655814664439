import { useCallback } from 'react'

type DataIndex = string | string[]

export function useNestedData<T extends object>(record: T, dataIndex: DataIndex) {
  const getValue = useCallback(() => {
    if (typeof dataIndex === 'string') {
      return record[dataIndex]
    }
    return dataIndex.reduce((acc, key) => (acc ? acc[key] : undefined), record)
  }, [record, dataIndex])

  const setValue = useCallback(
    (value: DataIndex) => {
      if (typeof dataIndex === 'string') {
        return { ...record, [dataIndex]: value }
      }

      const newRecord = { ...record }
      let current = newRecord

      for (let i = 0; i < dataIndex.length - 1; i++) {
        const key = dataIndex[i]
        current[key] = { ...(current[key] || {}) }
        current = current[key]
      }

      current[dataIndex[dataIndex.length - 1]] = value
      return newRecord
    },
    [record, dataIndex],
  )

  return { getValue, setValue }
}
