import React, { useCallback, useEffect } from 'react'

import { Skeleton } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DebounceSelect, DebounceSelectProps } from 'common/components/DebounceSelect'

import { useStores } from 'contractor/hooks/use-stores'

const makeCostCodePhaseOption = (costCodePhase) => {
  if (!costCodePhase) {
    return undefined
  }

  return {
    value: costCodePhase.id,
    label: `${costCodePhase.code}: ${costCodePhase.description}`,
  }
}

export const SelectDefaultPhase = observer<Partial<DebounceSelectProps>>(({ ...props }) => {
  const { costCodeStore } = useStores()
  const { costCodePhaseListStore } = costCodeStore

  const [isLoading, setIsLoading] = React.useState(true)
  const [data, setData] = React.useState([])

  const setDefault = () => {
    costCodePhaseListStore.setFilter('project_id', 'all', true, true)
    costCodePhaseListStore.setFilter('active', true, true, true)
  }

  const fetchOptions = useCallback((search: string) => {
    setDefault()
    return costCodePhaseListStore.setSearch(search).then((data) => data.map(makeCostCodePhaseOption))
  }, [])

  useEffect(() => {
    costCodePhaseListStore.resetState()
    setIsLoading(true)
    setDefault()
    costCodePhaseListStore
      .fetchRecords()
      .then(() => {
        setData(costCodePhaseListStore.records)
      })
      .finally(() => setIsLoading(false))
    return () => {
      costCodePhaseListStore.resetState()
      setDefault()
    }
  }, [])

  if (isLoading) {
    return <Skeleton.Input block active />
  }

  return (
    <Box width="100%" display="inline-flex" style={{ gap: 10 }}>
      <DebounceSelect
        allowClear
        placeholder="Select Phase"
        loading={isLoading}
        initialOptions={data?.map(makeCostCodePhaseOption)}
        fetchOptions={fetchOptions}
        {...props}
      />
    </Box>
  )
})
