import React from 'react'

import { Col, Row, Typography } from 'antd'

import { FlexBoxY } from 'common/components/boxes'
import { Unit } from 'common/server/units'

import { CreateDetailedMaterial } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/Search/create_detailed_material'
import { ShowCompanyMaterialResponse } from 'contractor/server/company_materials'

export type FooterProps = {
  materialDescription: string
  publicOrderFormUrlExtension?: string
  companyAttributes?: string[]
  isCommitment?: boolean
  units?: Unit[]
  setIsOpen?: (isOpen: boolean) => void
  userId?: string
  onSelect?: (material: ShowCompanyMaterialResponse) => void
}

export const Footer = (props: FooterProps) => {
  return (
    <FlexBoxY px="8px" py="16px" backgroundColor="gray-2">
      <Typography.Text type="secondary" strong style={{ fontSize: 16, marginBottom: 4 }}>
        Don&apos;t see what you are looking for?
      </Typography.Text>

      <Row gutter={12} justify="center">
        <Col style={{ margin: '4px 0' }}>
          <CreateDetailedMaterial {...props} />
        </Col>
      </Row>
    </FlexBoxY>
  )
}
