import React from 'react'

import { Button, message } from 'antd'

import { Drawer, DrawerBody, DrawerFooter, DrawerProps } from 'common/components/Drawer'
import { PdfFile } from 'common/server/invoice'

import { ReportIssue } from 'contractor/components/InvoiceInboxDetailsDrawer'

import { Content } from './content'

export type ReportIssueHistoryProps = {
  invoiceInboxId?: string
  filters?: { invoice_inbox_attachment_id: string }
  onSendIssue: (invoiceInboxId: string, issueMessage: string, invoiceInboxAttachmentIds: string[]) => Promise<void>
  pdfFiles?: PdfFile[]
} & Pick<DrawerProps, 'onClose' | 'open'>

export const ReportIssueHistory = (props: ReportIssueHistoryProps) => {
  const { invoiceInboxId, filters, onSendIssue, pdfFiles, ...rest } = props

  const [openIssueModal, setOpenIssueModal] = React.useState(false)

  const handleSendIssue = async (...params: Parameters<typeof onSendIssue>) => {
    try {
      await onSendIssue(...params)
    } catch (error) {
      message.error('Failed to report issue')
    } finally {
      setOpenIssueModal(false)
    }
  }

  return (
    <Drawer title="Issue History" width={500} bodyStyle={{ flexDirection: 'column' }} {...rest}>
      <DrawerBody>
        <Content invoiceInboxId={invoiceInboxId} filters={filters} />
      </DrawerBody>

      <DrawerFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" size="small" onClick={() => setOpenIssueModal(true)}>
          Report another issue
        </Button>
      </DrawerFooter>
      <>
        <ReportIssue
          invoiceInboxId={invoiceInboxId}
          open={openIssueModal}
          onSendIssue={handleSendIssue}
          onClose={() => setOpenIssueModal(false)}
          pdfFiles={pdfFiles}
        />
      </>
    </Drawer>
  )
}
