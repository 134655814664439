import React from 'react'

import { Button, Divider, Select } from 'antd'
import { SelectProps, RefSelectProps } from 'antd/lib/select'

import { observer } from 'mobx-react-lite'

import { BoxProps, Box } from 'common/components/boxes'
import { AddressTo } from 'common/server/addressesTo'

import { SelectorOption } from './selector_option'

export type AddressSelectorProps = {
  addressToOptions: AddressTo[]
  onShowAddressToCreate?: () => void
  onEditAddressTo?: (addressTo: AddressTo) => void
  wrapperProps?: BoxProps
} & SelectProps<string>

const AddressToSelector = observer<AddressSelectorProps, RefSelectProps>(
  (props, ref) => {
    const { onShowAddressToCreate, onEditAddressTo, addressToOptions, wrapperProps, ...rest } = props

    return (
      <Box maxWidth="375px" justifyContent="flex-start" {...wrapperProps}>
        <Select
          ref={ref}
          aria-label="address-to-select"
          style={{ width: '100%' }}
          placeholder="Optional"
          allowClear
          aria-autocomplete="none"
          dropdownRender={(dropdown) => (
            <>
              {dropdown}
              {onShowAddressToCreate && (
                <React.Fragment>
                  <Divider style={{ margin: '4px 0' }} />
                  <Button type="link" onClick={onShowAddressToCreate} data-cy={'add-attention-to-button'}>
                    + Add new attention to
                  </Button>
                </React.Fragment>
              )}
            </>
          )}
          showSearch
          filterOption={(input, option) => {
            const inputValue = input.toLowerCase()
            return option?.searchable?.toLowerCase().includes(inputValue)
          }}
          {...rest}
        >
          {addressToOptions.map((addressTo) => {
            const label = addressTo.name ? addressTo.name : addressTo.email
            const phoneNumber = addressTo.phone_number ? addressTo.phone_number : 'Missing Phone number'
            return (
              <Select.Option
                data-cy={`address-to-option-${addressTo.id}`}
                key={addressTo.id}
                value={addressTo.id}
                searchable={`${addressTo?.email}:${addressTo?.name}:${addressTo?.phone_number}`}
              >
                <SelectorOption
                  title={`${label} - ${phoneNumber}`}
                  label={label}
                  disabled={!!rest?.disabled}
                  onEditAddressTo={onEditAddressTo ? () => onEditAddressTo(addressTo) : undefined}
                />
              </Select.Option>
            )
          })}
        </Select>
      </Box>
    )
  },
  { forwardRef: true },
)

export default AddressToSelector
