import React, { useRef, useMemo } from 'react'

import pluralize from 'pluralize'

import { Badge, Typography } from 'antd'

import { FlexBoxY, Box, BoxProps } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { useTheme } from 'common/hooks/use-theme'

import { WatchersSelect, WatchersSelectProps } from 'contractor/components/WatchersSelect'

type WatchersDrawerProps = WatchersSelectProps & {
  boxProps?: BoxProps
  children?: React.ReactNode
  style?: React.CSSProperties
}

export const WatchersDrawer = ({ value = [], boxProps, children, style, ...props }: WatchersDrawerProps) => {
  const drawerRef = useRef<DrawerRef>()

  const theme = useTheme()

  const items = value || []
  const count = items.length

  const content = useMemo(() => {
    if (children) {
      return children
    }

    return (
      <>
        <Badge
          data-cy="watcher-badge"
          count={count}
          showZero
          style={{
            marginRight: '10px',
            backgroundColor: theme.colors['gray-6'],
            color: theme.colors.primary,
            border: 'none',
            boxShadow: 'none',
          }}
        />
        <Typography.Text>{pluralize('Watcher', count, false)}</Typography.Text>
      </>
    )
  }, [children, count])

  return (
    <>
      <Box
        display="inline-flex"
        alignItems="center"
        onClick={() => drawerRef.current?.show()}
        border="1px solid"
        borderColor="gray-4"
        borderRadius="md"
        px={8}
        py={2}
        width="100%"
        height="30px"
        justifyContent="center"
        style={{ cursor: 'pointer', ...style }}
        {...boxProps}
      >
        {content}
      </Box>

      <Drawer data-cy="watchers-drawer" title="Watchers" ref={drawerRef} forceRender width={378}>
        <FlexBoxY alignItems="flex-start" justifyContent="flex-start" p={16}>
          <WatchersSelect value={value} {...props} />
        </FlexBoxY>
      </Drawer>
    </>
  )
}
