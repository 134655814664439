import React, { useEffect, useRef, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { Button, Divider, Select } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { OptionDescriptor } from 'common/components/Select/Option'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { CreateProjectModal } from 'contractor/pages/Projects/create_project_modal'
import { ShowProjectResponse } from 'contractor/server/projects'

export type ProjectOption = {
  id: string
  name: string
  project_id: string
  inventory: boolean
}

type ProjectDropdownProps = {
  showSettings?: boolean
  showAddProject?: boolean
  projectOptions?: ProjectOption[]
  onChange?: (project: ShowProjectResponse) => void
  style?: React.CSSProperties
}

const allProjectsOption = {
  id: 'all',
  name: 'All Projects',
  project_id: null,
  inventory: false,
}

const ProjectDropdown = observer<ProjectDropdownProps>((props) => {
  const { showSettings = true, showAddProject = true, projectOptions, onChange, style } = props

  const { projectStore, userStore } = useStores()

  const history = useHistory()
  const location = useLocation()

  const createProjectRef = useRef<DrawerRef>()

  const [open, toggleOpen] = useState(false)

  const { isLoading } = useQuery(projectStore.indexProjects)

  const currentValue = projectStore.selectedProject ? projectStore.selectedProject?.id : allProjectsOption.id

  // See if we need to restore the initial project
  useEffect(() => {
    async function loadProject() {
      const initialProjectId =
        new URLSearchParams(location.search).get('project_id') || sessionStorage['selectedProjectId']

      const projectId = await projectStore.showProject(initialProjectId)

      if (projectId) {
        const params = new URLSearchParams(window.location.search)
        params.set('project_id', projectId)
        history.replace({ search: params.toString() })
      }
    }

    loadProject()
  }, [])

  // Clear project_id param when the user selects All Projects
  useEffect(() => {
    const params = new URLSearchParams(location.search)

    if (!projectStore.selectedProject && params.get('project_id')) {
      params.delete('project_id')
      history.replace({ search: params.toString() })
    }
  }, [location.search, projectStore.selectedProject])

  const options = projectOptions
    ? [...projectOptions, ...projectStore.projects]
    : [allProjectsOption, ...projectStore.projects]

  const defaultBoxWidth = { _: '100%', md: 250 }
  const width = style?.width || defaultBoxWidth

  // We should always have the all id option as the first one
  if (options[0].id !== 'all') return null

  return (
    <Box width={width}>
      <Select
        data-cy="project-select"
        aria-label="project-select"
        style={{ height: '100%', width: '100%', fontSize: 16 }}
        value={currentValue}
        showSearch
        onChange={(value: string) => {
          const selectedProjectId = value === allProjectsOption.id ? null : value
          projectStore.showProject(selectedProjectId).then(() => {
            const params = new URLSearchParams(window.location.search)
            if (selectedProjectId) {
              params.set('project_id', selectedProjectId)
            } else {
              params.delete('project_id')
            }
            history.replace({ search: params.toString() })
          })

          if (onChange) {
            onChange(projectStore.selectedProject)
          }
        }}
        filterOption={(input, option) => {
          const inputValue = input.toLowerCase()
          return option?.searchable?.toLowerCase().includes(inputValue)
        }}
        dropdownRender={(menu) => {
          if (showAddProject && userStore.canManageProjects) {
            return (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <div style={{ margin: 8 }}>
                  <Button
                    data-cy="create-project-button"
                    type="primary"
                    onClick={() => {
                      toggleOpen(false)
                      createProjectRef.current?.show()
                    }}
                    block
                  >
                    Create project
                  </Button>
                </div>
                <CreateProjectModal
                  data-cy="create-project-modal"
                  onCancel={() => createProjectRef.current?.close()}
                  ref={createProjectRef}
                />
              </>
            )
          }

          return menu
        }}
        dropdownStyle={{ minWidth: 100 }}
        loading={isLoading}
        open={open}
        optionLabelProp="label"
        onDropdownVisibleChange={toggleOpen}
      >
        {options.map((project) => (
          <Select.Option
            key={project.id}
            value={project.id}
            label={project.name}
            searchable={`${project.name}:${project.project_id ? project.project_id : ''}`}
          >
            <OptionDescriptor
              value={project.id}
              label={project.name}
              descriptor={project.project_id}
              showSettings={showSettings && userStore.canManageProjects && project.id !== 'all'}
            />
          </Select.Option>
        ))}
      </Select>
    </Box>
  )
})

export default ProjectDropdown
