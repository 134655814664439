import React from 'react'

import { Space, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'

import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'

export const Footer = () => {
  const {
    calculatedBudgetedTotal,
    calculatedOrdered,
    precision,
    calculateConcreteBudgeted,
    commitment,
    commitmentType,
  } = useCommitment()

  const isConcrete = commitmentType == 'ConcreteCommitment'
  const totalInvoiced = Number(commitment?.total_invoiced)

  const remaining = isConcrete
    ? calculateConcreteBudgeted - totalInvoiced
    : Number(calculatedBudgetedTotal) - calculatedOrdered

  const remainingColor = remaining < calculatedBudgetedTotal / 10 ? 'red' : 'green'
  const budget = isConcrete ? calculateConcreteBudgeted : calculatedBudgetedTotal
  const spent = isConcrete ? totalInvoiced : calculatedOrdered

  return (
    <Box
      bg="white"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={16}
      py={12}
      borderTop="1px solid"
      borderTopColor="gray-4"
      width="100%"
      position="fixed"
      bottom={0}
      left={0}
      style={{ zIndex: 900 }}
    >
      <Space size="large" style={{ justifyContent: 'flex-end', width: '100%' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography.Text type="secondary">Budgeted</Typography.Text>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {currencyFormatter(Number(budget || 0), precision)}
          </Typography.Title>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography.Text type="secondary">{isConcrete ? 'Spent' : 'Ordered'}</Typography.Text>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {currencyFormatter(Number(spent || 0), precision)}
          </Typography.Title>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography.Text type="secondary">Remaining</Typography.Text>
          <Typography.Title level={5} style={{ margin: 0, color: remainingColor }}>
            {currencyFormatter(Number(remaining || 0), precision)}
          </Typography.Title>
        </Box>
      </Space>
    </Box>
  )
}
