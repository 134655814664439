import React, { useState } from 'react'

import moment from 'moment'

import { Button, Form, Input, Switch, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form/hooks/useForm'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { SearchInput } from 'common/components/SearchInput'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { OrderDelivery } from 'common/server/deliveries'

import { IncompleteItems } from 'contractor/components/DeliveryDetail/IncompleteItems/incomplete_items'
import { DeliveryDetailsUploader } from 'contractor/components/LogDelivery/DeliveryDetails/delivery_details_uploader'
import { Header } from 'contractor/components/LogDelivery/DeliveryDetails/header'

type DeliveryDetailsProps = {
  onBack: () => void
  form: FormInstance
  filesKey: string
  loading: boolean
  orderDelivery: OrderDelivery
  orderName: string
  orderNumber: string
}

export const DeliveryDetails = observer((props: DeliveryDetailsProps) => {
  const { onBack, form, filesKey, loading, orderNumber, orderName, orderDelivery } = props
  const [filter, setFilter] = useState('')
  const isMobile = useMediaQuery('md')

  const dataSource = Form.useWatch('incompleteItems', form)?.filter((item) =>
    item?.company_material?.description?.toLowerCase()?.includes(filter?.toLowerCase() || ''),
  )
  const resolved = Form.useWatch('resolved', form)
  const showResolve = dataSource?.some((item) => item.has_open_issue || orderDelivery?.delivery_issues?.length > 0)

  const whoMarkedResolved = React.useMemo(() => {
    return orderDelivery?.delivery_issue_resolved_by?.first_name
      ? `${orderDelivery?.delivery_issue_resolved_by.first_name} ${orderDelivery?.delivery_issue_resolved_by?.last_name}`
      : 'you'
  }, [orderDelivery])

  return (
    <Box height="100vh" display="flex" maxWidth={isMobile ? '100%' : 600} flexDirection="column">
      <Box maxWidth="100vw" marginBottom={64} flexGrow={1} overflowY="auto">
        <Header onBack={onBack} orderName={orderName} orderNumber={orderNumber} />

        <DeliveryDetailsUploader filesKey={filesKey} />

        <SearchInput
          onChange={(search) => setFilter(search?.target?.value)}
          style={{ paddingLeft: 16, paddingRight: 16 }}
        />

        <Form.Item name="incompleteItems">
          <IncompleteItems orderMaterials={dataSource} />
        </Form.Item>

        {showResolve && (
          <Form.Item
            style={{ paddingLeft: 16, paddingRight: 16 }}
            name="deliveryIssues"
            data-cy="delivery-issues"
            label="Provide details on the problem for your vendor"
          >
            <Input.TextArea placeholder="Describe issues with delivery here" style={{ resize: 'none' }} />
          </Form.Item>
        )}

        {showResolve && (
          <Box ml="16" display="flex">
            <Form.Item name="resolved" valuePropName="checked" noStyle>
              <Switch />
            </Form.Item>
            <Typography.Text style={{ marginLeft: 8, marginRight: 8 }}>
              {resolved
                ? `Issue resolved on ${
                    orderDelivery?.delivery_issue_resolved_at
                      ? moment(orderDelivery?.delivery_issue_resolved_at).format('YYYY-MM-DD HH:mm')
                      : moment().format('YYYY-MM-DD HH:mm')
                  } by ${whoMarkedResolved}`
                : 'Mark as Resolved'}
            </Typography.Text>
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        style={{
          padding: '16px',
          gap: '16px',
          backgroundColor: 'white',
          borderTopStyle: 'solid',
          borderTopWidth: '1px',
          borderTopColor: '#f0f0f0',
          position: 'sticky',
          bottom: 0,
          zIndex: 1,
        }}
      >
        <Button disabled={loading} onClick={onBack} style={{ flexGrow: 1 }}>
          Back
        </Button>

        <Button loading={loading} data-cy="save_delivery" type="primary" htmlType="submit" style={{ flexGrow: 1 }}>
          Save
        </Button>
      </Box>
    </Box>
  )
})
