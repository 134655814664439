import React from 'react'

import { Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { makeCostCodeValue, Option, SelectCostCode } from 'common/components/SelectCostCode'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { Unit } from 'common/server/units'

import { MaterialsAutocomplete, MaterialsAutocompleteProps } from 'contractor/components/MaterialsAutocomplete'
import { CommitmentMaterial } from 'contractor/server/commitments'
import { CostCodeSettings } from 'contractor/server/company_settings/other_settings'

import { ActionsColumn, QuantityColumn, UnitColumn, UnitCostColumn } from '../Columns'
import { Wrapper } from './wrapper'

type ListProps = {
  disabled?: boolean
  hideCostCode?: boolean
  costCodeSettings: CostCodeSettings
  materialsAutocompleteProps: MaterialsAutocompleteProps
  dataSource: CommitmentMaterial[]
  costCodes: CostCode[]
  units: Unit[]
  projectId: string
  onChange: (value: CommitmentMaterial, changes: CommitmentMaterial) => void
  onRemove?: (material: CommitmentMaterial) => void
  precision?: number
}

export const List = observer<ListProps>((props) => {
  const {
    disabled,
    hideCostCode,
    costCodeSettings,
    materialsAutocompleteProps,
    dataSource,
    costCodes,
    units,
    onChange,
    onRemove,
    precision,
  } = props

  return (
    <>
      <Wrapper width="100%" alignItems="flex-start">
        {dataSource.map((data, index) => {
          const companyMaterial = data.company_material
          return (
            <FlexBoxY width="100%" key={`row-${index}`} p={8} borderRadius={4}>
              <FlexBoxX width="100%" alignItems="flex-start" mb={12}>
                <ActionsColumn onRemove={onRemove} commitmentMaterial={data} disabled={disabled} onChange={onChange} />
              </FlexBoxX>

              <FlexBoxX width="100%" mb={12}>
                <Box width="30%" mr={8} textAlign="end">
                  <Typography.Text>Item</Typography.Text>
                </Box>
                <Typography.Text>{companyMaterial?.description}</Typography.Text>
              </FlexBoxX>

              <FlexBoxX width="100%" mb={12}>
                <Box width="30%" mr={8} textAlign="end">
                  <Typography.Text>Quantity</Typography.Text>
                </Box>
                <FlexBoxX width="70%">
                  <QuantityColumn onChange={onChange} commitmentMaterial={data} disabled={disabled} index={index} />
                </FlexBoxX>
              </FlexBoxX>

              <FlexBoxX width="100%" mb={12}>
                <Box width="30%" mr={8} textAlign="end">
                  <Typography.Text>Unit</Typography.Text>
                </Box>
                <Box width="70%">
                  <UnitColumn units={units} onChange={onChange} commitmentMaterial={data} disabled={disabled} />
                </Box>
              </FlexBoxX>

              <FlexBoxX width="100%" mb={12} alignItems="baseline">
                <Box width="30%" mr={8} textAlign="end">
                  <Typography.Text>Unit Cost</Typography.Text>
                </Box>

                <Box width="70%">
                  <UnitCostColumn
                    onChange={onChange}
                    commitmentMaterial={data}
                    disabled={disabled}
                    roundingPrecision={precision}
                  />
                </Box>
              </FlexBoxX>

              {!hideCostCode && (
                <FlexBoxX width="100%" mb={12}>
                  <Box width="30%" mr={8} textAlign="end">
                    <Typography.Text>Cost Code</Typography.Text>
                    <SelectCostCode
                      costCodes={costCodes}
                      costCodeSettings={costCodeSettings}
                      value={makeCostCodeValue(data['cost_code'], costCodeSettings)}
                      disabled={disabled}
                      onChange={(option: Option) =>
                        onChange(data, {
                          ...data,
                          cost_code: option?.costCode,
                          cost_code_id: option?.costCode?.id,
                        })
                      }
                    />
                  </Box>
                </FlexBoxX>
              )}
            </FlexBoxY>
          )
        })}
        <MaterialsAutocomplete {...materialsAutocompleteProps} />
      </Wrapper>
    </>
  )
})
