import React from 'react'

import styled from '@emotion/styled'

import { CheckCircleOutlined, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Tabs as AntdTabs, Typography, Badge } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useTheme } from 'common/hooks/use-theme'

import { useStores } from 'contractor/hooks/use-stores'
import { PriceSheetMaterial } from 'contractor/server/price_sheet'

import { Search } from './search'

type TabsProps = {
  activeKey: string
  priceSheetMaterials: PriceSheetMaterial[]
  disabled?: boolean
}

const StyledTabs = styled(AntdTabs)`
  .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs-tab {
    padding: 8px 16px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
`

const CustomBadge = (props) => (
  <Badge showZero overflowCount={9999} style={{ backgroundColor: '#E9EBF8', color: '#121C4E' }} {...props} />
)

export const Tabs = observer<TabsProps>(({ activeKey, priceSheetMaterials, disabled }) => {
  const {
    priceSheetStore: { priceSheetMaterialListStore },
  } = useStores()

  const theme = useTheme()

  const persistentFilters = usePersistentFilters()

  return (
    <StyledTabs
      style={{ backgroundColor: 'white', borderRadius: 2 }}
      activeKey={activeKey}
      onChange={(key) => {
        priceSheetMaterialListStore.searchState.per_page = 30
        persistentFilters.handleChangeFilters({ state: key })
      }}
      items={[
        {
          label: (
            <Box display="flex" alignItems="center">
              <Typography.Text style={{ marginRight: 12 }}>All</Typography.Text>
              <CustomBadge count={priceSheetMaterialListStore.stateCounts['all'] || 0} />
            </Box>
          ),
          key: 'all',
          children: (
            <Search
              priceSheetMaterials={priceSheetMaterials}
              itemsCount={priceSheetMaterialListStore.stateCounts['all'] || 0}
            />
          ),
          disabled,
        },
        {
          label: (
            <Box display="flex" alignItems="center">
              <CheckCircleOutlined style={{ color: theme.colors['success'] }} />
              <Typography.Text style={{ marginRight: 12 }}>Matches</Typography.Text>
              <CustomBadge count={priceSheetMaterialListStore.stateCounts['matches'] || 0} />
            </Box>
          ),
          key: 'matches',
          children: (
            <Search
              priceSheetMaterials={priceSheetMaterials}
              itemsCount={priceSheetMaterialListStore.stateCounts['all'] || 0}
            />
          ),
          disabled,
        },
        {
          label: (
            <Box display="flex" alignItems="center">
              <WarningOutlined style={{ color: theme.colors['warning'] }} />
              <Typography.Text style={{ marginRight: 12 }}>Partial matches</Typography.Text>
              <CustomBadge count={priceSheetMaterialListStore.stateCounts['partial_matches'] || 0} />
            </Box>
          ),
          key: 'partial_matches',
          children: (
            <Search
              priceSheetMaterials={priceSheetMaterials}
              itemsCount={priceSheetMaterialListStore.stateCounts['all'] || 0}
            />
          ),
          disabled,
        },
        {
          label: (
            <Box display="flex" alignItems="center">
              <ExclamationCircleOutlined style={{ color: theme.colors['error'] }} />
              <Typography.Text style={{ marginRight: 12 }}>No matches</Typography.Text>
              <CustomBadge count={priceSheetMaterialListStore.stateCounts['no_matches'] || 0} />
            </Box>
          ),
          key: 'no_matches',
          children: (
            <Search
              priceSheetMaterials={priceSheetMaterials}
              itemsCount={priceSheetMaterialListStore.stateCounts['all'] || 0}
            />
          ),
          disabled,
        },
      ]}
    />
  )
})
