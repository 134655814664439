import React from 'react'

import { FilePdfOutlined } from '@ant-design/icons'
import FilterOutlined from '@ant-design/icons/FilterOutlined'
import PlusOutlined from '@ant-design/icons/PlusOutlined'
import { Button, Dropdown, Space } from 'antd'
import { MenuProps } from 'antd/lib/menu'

import { DateRangePicker } from 'common/components/DateRangePicker'
import { SearchInput } from 'common/components/SearchInput'
import { Visibility } from 'common/components/Visibility'

import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'
import { ColumnsView } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/LogView/columns_view'
import { ColumnsProps } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/LogView/columns_definition'
import { useConcreteCommitment } from 'contractor/pages/@v2/Commitments/Concrete/hooks/use-concrete-commitment'

type LookAheadExtraContentProps = {
  disabled?: boolean
  onPriceIncrease: () => void
  columns: ColumnsProps[]
  toggleColumnVisibility: (dataIndex: string | string[]) => void
}

export const LookAheadActions = (props: LookAheadExtraContentProps) => {
  const { disabled, onPriceIncrease, columns, toggleColumnVisibility } = props
  const materialCommitmentHandlers = useConcreteCommitment()
  const { setFilters, setSearch, search, filters, isSubmitting } = useCommitment()

  const items: MenuProps['items'] = [
    {
      label: 'Filters',
      key: 'filters',
      onClick: onPriceIncrease,
    },
    {
      label: 'Confirm all',
      key: 'confirm_all',
      onClick: materialCommitmentHandlers.confirmAllInspections,
    },
    {
      label: 'Generate PDF',
      key: 'generate_pdf',
      onClick: materialCommitmentHandlers.handleGenerateLookAheadPdf,
    },
  ]

  const menuProps: MenuProps = {
    items,
  }

  return (
    <>
      <Visibility.Hidden breakpoint={'md'}>
        <Space>
          <SearchInput value={search} onSearch={(value) => setSearch(value)} style={{ minWidth: 300 }} />

          <DateRangePicker
            placeholderStart="Start date"
            placeholderEnd="End date"
            attribute="date"
            value={{
              startDate: filters['date_start'],
              endDate: filters['date_end'],
            }}
            onChange={({ startDate, endDate }) => {
              setFilters({
                ...filters,
                date_start: startDate,
                date_end: endDate,
              })
            }}
          />

          <Dropdown
            overlay={<ColumnsView columnSettings={columns} toggleColumnVisibility={toggleColumnVisibility} />}
            trigger={['click', 'hover']}
          >
            <Button icon={<FilterOutlined />} />
          </Dropdown>

          <Button disabled={disabled} onClick={materialCommitmentHandlers.confirmAllInspections}>
            Confirm all
          </Button>

          <Button
            loading={isSubmitting}
            type="primary"
            disabled={disabled}
            onClick={materialCommitmentHandlers.handleGenerateLookAheadPdf}
          >
            <FilePdfOutlined />
          </Button>
        </Space>
      </Visibility.Hidden>
      <Visibility.Show breakpoint={'md'}>
        <Dropdown menu={menuProps}>
          <Button type="primary" style={{ marginLeft: 8, marginRight: 8 }}>
            <PlusOutlined />
          </Button>
        </Dropdown>
      </Visibility.Show>
    </>
  )
}
