import React from 'react'

import styled from '@emotion/styled'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Alert, Table as AntdTable, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { PsqlColumn } from 'common/components/PsqlTable/psql_table_provider'
import { OrderMaterial } from 'common/server/orders'

import { Cell } from 'contractor/pages/@v2/Commitments/common/components/ListCells'
import { Wrapper } from 'contractor/pages/@v2/Commitments/Material/components/Table/Table/wrapper'
import { OrderMaterialCells } from 'contractor/pages/@v2/OrderMaterials/components/cells'
import { CommitmentMaterial } from 'contractor/server/commitments'

const insertIf = (condition, ...elements) => (condition ? elements : [])

const StyledTable = styled(AntdTable)`
  .ant-table.cell {
    background-color: white !important;
  }

  .ant-table.table {
    background-color: white !important;
  }
`

type TableProps = {
  orderMaterials: OrderMaterial[]
  commitmentMaterials: CommitmentMaterial[]
  phaseCodeEnabled: boolean
  costCodeEnabled?: boolean
}

export const getRemainingQuantity = (commitmentMaterial: CommitmentMaterial | undefined, quantity: number) => {
  return commitmentMaterial
    ? Number(commitmentMaterial.quantity) - Number(commitmentMaterial.quantity_ordered) - quantity
    : 0
}

// Commented for now since we need to revisit the unique_id property
// export const getNonExistingMaterials = (orderMaterials: OrderMaterial[], commitmentMaterials: CommitmentMaterial[]) => {
//   return orderMaterials.filter((om) => !commitmentMaterials.some((cm) => cm.unique_id === om.unique_id))
// }

export const getNonExistingMaterials = (orderMaterials: OrderMaterial[], commitmentMaterials: CommitmentMaterial[]) => {
  return orderMaterials.filter(
    (om) => !commitmentMaterials.some((cm) => cm.company_material.id === om.company_material.id),
  )
}

export const getOverBudgetMaterials = (orderMaterials: OrderMaterial[], commitmentMaterials: CommitmentMaterial[]) => {
  return orderMaterials.filter((om) => {
    // const commitmentMaterial = commitmentMaterials.find((cm) => cm.unique_id === om.unique_id)
    const commitmentMaterial = commitmentMaterials.find((cm) => cm.company_material.id === om.company_material.id)
    const remainingQuantity = getRemainingQuantity(commitmentMaterial, Number(om.quantity))
    return remainingQuantity < 0 && !!commitmentMaterial
  })
}

export const Table = observer<TableProps>((props: TableProps) => {
  const { orderMaterials, commitmentMaterials, phaseCodeEnabled, costCodeEnabled } = props

  const nonExistingMaterials = React.useMemo(
    () => getNonExistingMaterials(orderMaterials, commitmentMaterials),
    [orderMaterials, commitmentMaterials],
  )

  const overBudgetMaterials = React.useMemo(
    () => getOverBudgetMaterials(orderMaterials, commitmentMaterials),
    [orderMaterials, commitmentMaterials],
  )

  return (
    <FlexBoxY bg="white" mt="16" width="100%">
      <Wrapper alignItems="flex-start" justifyContent="flex-start" width="100%" overflowX="auto">
        {overBudgetMaterials.length > 0 && (
          <Alert
            style={{ marginBottom: 8, width: '100%' }}
            message="The materials highlighted surpass the commitment quantity, adding this order will automatically reajust the commitment quantity."
            type="error"
            showIcon
          />
        )}
        {nonExistingMaterials.length > 0 && (
          <Alert
            style={{ marginBottom: 8, width: '100%' }}
            message="The materials highlighted do not exist in the commitment, adding this order will automatically add them as new materials."
            type="warning"
            showIcon
          />
        )}
        <StyledTable
          rowKey={(record: OrderMaterial) => record.id}
          pagination={false}
          size="small"
          style={{ width: '100%', backgroundColor: 'white' }}
          data-cy="material-table"
          aria-label="order-materials-table"
          dataSource={orderMaterials}
          rowClassName={(record: OrderMaterial) => {
            if (overBudgetMaterials.map((m) => m.id).includes(record.id)) return 'over_budget'
            if (nonExistingMaterials.map((m) => m.id).includes(record.id)) return 'non_existing_material'
            return ''
          }}
          columns={[
            {
              title: 'Description',
              width: '20%',
              render: (record: OrderMaterial) => (
                <Cell.FormattedText column={record as PsqlColumn} value={record.company_material.description} />
              ),
            },
            {
              title: 'Unit',
              width: '10%',
              render: (_, record: OrderMaterial) => {
                const unit = record.company_material?.unit?.name || record.company_material.unit_name
                return <Cell.FormattedText column={record as PsqlColumn} value={unit} />
              },
            },
            {
              title: 'Commitment remaining qty',
              width: '15%',
              render: (_, record: OrderMaterial) => {
                // Commented for now since we need to revisit the unique_id property
                // const commitmentMaterial = commitmentMaterials.find((cm) => cm.unique_id === record.unique_id)
                const commitmentMaterial = commitmentMaterials.find(
                  (cm) => cm.company_material.id === record.company_material_id,
                )
                const remainingQuantity = commitmentMaterial
                  ? Number(commitmentMaterial.quantity) - Number(commitmentMaterial.quantity_ordered)
                  : '-'
                return <Cell.FormattedText column={record as PsqlColumn} value={remainingQuantity} />
              },
            },
            {
              title: 'Order qty',
              width: '10%',
              render: (_, record: OrderMaterial) => (
                <Cell.FormattedText column={record as PsqlColumn} value={record.quantity} />
              ),
            },
            {
              title: 'Remaining qty',
              width: '10%',
              render: (_, record: OrderMaterial) => {
                // Commented for now since we need to revisit the unique_id property
                // const commitmentMaterial = commitmentMaterials.find((cm) => cm.unique_id === record.unique_id)
                const commitmentMaterial = commitmentMaterials.find(
                  (cm) => cm.company_material.id === record.company_material_id,
                )
                const remainingQuantity = getRemainingQuantity(commitmentMaterial, Number(record.quantity))

                return (
                  <>
                    <Cell.FormattedText column={record as PsqlColumn} value={remainingQuantity} />
                    {remainingQuantity < 0 && (
                      <Tooltip title="The remaining quantity is negative">
                        <ExclamationCircleOutlined style={{ color: 'red', marginLeft: 8 }} />
                      </Tooltip>
                    )}
                  </>
                )
              },
            },
            ...insertIf(costCodeEnabled, {
              title: 'Cost code',
              width: '10%',
              render: (_, record: OrderMaterial) => (
                <OrderMaterialCells.CostCode column={record} value={record.company_material.cost_code_id} />
              ),
            }),
            ...insertIf(phaseCodeEnabled, {
              title: 'Phase code',
              width: '10%',
              render: (_, record: OrderMaterial) => (
                <OrderMaterialCells.PhaseCode column={record} value={record.company_material.cost_code_id} />
              ),
            }),
          ]}
        />
      </Wrapper>
    </FlexBoxY>
  )
})
