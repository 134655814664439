import React, { useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import _ from 'lodash'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { PsqlTableProvider } from 'common/components/PsqlTable/psql_table_provider'
import { PsqlTable } from 'common/components/PsqlTable/table'
import { ResponsiveTable } from 'common/components/ResponsiveTable'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { SearchInput } from 'common/components/SearchInput'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useMaybeOpenIntoNewTab } from 'common/hooks/use-maybe-open-into-new-tab'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { StateResults } from 'contractor/pages/@v2/Invoices/state_results'
import InvoiceMobileCard from 'contractor/pages/@v2/Invoices/tabs/List/invoice_mobile_card'
import { getInvoiceColumns } from 'contractor/pages/@v2/Invoices/tabs/List/invoice_table_columns'
import { ShowProjectResponse } from 'contractor/server/projects'

import { InvoiceProjectSelector } from '../../../../Invoices/project_selector'
import { ListFilters } from '../../Filters/List'

interface InvoiceTabProps {
  EmptyState?: React.ElementType
  ribbonFilterExcludedStates?: string
  excludedColumns?: string[]
  finalState?: string
  tabKey?: string
}

export const InvoiceTab = observer((props: InvoiceTabProps) => {
  const { finalState, tabKey, EmptyState = StateResults, ribbonFilterExcludedStates, excludedColumns = [] } = props

  const { invoiceStore, projectStore, invoiceStateStore, integrationStore, invoiceSettingsStore, userStore } =
    useStores()
  const { listStore } = invoiceStore

  const persistentFilters = usePersistentFilters()
  const { maybeOpenIntoNewTab } = useMaybeOpenIntoNewTab()

  const history = useHistory()
  const location = useLocation()

  const emptyStates = ['null', null, 'undefined', undefined, '']

  const handleInvoiceSessionFilters = (states: string[]) => {
    // Filter and clean the states
    const validStates = states
      .map((state) => state.trim()) // Remove extra spaces
      .filter((state) => invoiceStateStore?.invoiceStates?.map((s) => s.label).includes(state)) // Validate against allowed states

    // Check if there are any valid states left after filtering
    if (_.isEmpty(validStates.filter((v) => !emptyStates.includes(v)))) {
      sessionStorage.removeItem('invoiceRibbonFilter') // Clear storage if no valid states
    } else {
      sessionStorage.setItem('invoiceRibbonFilter', validStates.join(',')) // Save cleaned states
    }
  }

  const setStateFilters = (states: string[], doNotRefetch: boolean) => {
    const allowedTabs = ['invoices', 'to_review']
    // Avoids loading filters for inbox and deleted tabs
    if (!allowedTabs.includes(tabKey)) return

    const validStates = states.filter((v) => !emptyStates.includes(v))
    let newStates: string[]
    if (tabKey === 'to_review' && finalState) {
      const notPosted = [`not:${finalState}`]
      const toReviewStates = validStates.filter((v: string) => v !== finalState)
      newStates = toReviewStates.length > 0 ? toReviewStates : ['']
      listStore.setPageFilters('state_name', notPosted.concat(toReviewStates))
      listStore.setFilter('state_name', newStates, true, doNotRefetch)
    } else {
      newStates = validStates.length > 0 ? validStates : ['']
      listStore.setFilter('state_name', newStates, true, doNotRefetch)
    }
    handleInvoiceSessionFilters(newStates)
  }

  // Update URL when RibbonFilter changes
  function handleRibbonFilterChange(value: string[]) {
    if (value.length > 1) {
      value = value.filter((v) => v !== '')
    }
    setStateFilters(value, false)
    const urlParams = new URLSearchParams(location.search)

    const filteredValues = value.filter((v) => !emptyStates.includes(v))
    if (filteredValues.length > 0) {
      urlParams.set('state_name', filteredValues.join(','))
    } else {
      urlParams.delete('state_name')
    }
    history.replace({ search: urlParams.toString() })
  }

  function handleChangeProject(project: ShowProjectResponse) {
    persistentFilters.handleChangeFilters({ project_id: project?.id })
  }

  const adjustMissingURLStates = (urlParams: URLSearchParams, stateNameFromURL: string) => {
    const urlStates = stateNameFromURL.split(',').filter((v) => !emptyStates.includes(v))
    const statesToRemove = tabKey === 'to_review' && finalState ? [finalState] : []
    const newStates = urlStates.filter((v) => !statesToRemove.includes(v))

    if (newStates.length === 0) {
      urlParams.delete('state_name')
    } else {
      urlParams.set('state_name', newStates.join(','))
    }
    history.replace({ search: urlParams.toString() })
  }

  const clearEmptyStatesFromUrl = (urlParams: URLSearchParams, states: string[]) => {
    const filteredStates = states.filter((v) => !emptyStates.includes(v))
    if (filteredStates.length > 0) {
      urlParams.set('state_name', filteredStates.join(','))
    } else {
      urlParams.delete('state_name')
    }
    history.replace({ search: urlParams.toString() })
  }

  const loadStateNamesForRibbonFilter = (urlParams: URLSearchParams) => {
    const stateNames = urlParams.get('state_name') || ''
    const states = stateNames.split(',')
    clearEmptyStatesFromUrl(urlParams, states)
    if (stateNames && stateNames !== '') {
      setStateFilters(states, true)
      adjustMissingURLStates(urlParams, stateNames)
    }
  }

  const loadProjectIdUrlParam = (urlParams: URLSearchParams) => {
    const initialProjectId = urlParams.get('project_id') || sessionStorage['selectedProjectId']

    if (initialProjectId && initialProjectId.length > 0) {
      listStore.setFilter('project_id', initialProjectId, true, true)
    }
  }

  useEffect(() => {
    loadStateNamesForRibbonFilter(new URLSearchParams(location.search))
  }, [tabKey])

  async function init() {
    const urlParams = new URLSearchParams(location.search)
    loadProjectIdUrlParam(urlParams)

    // Clear inbox state filters
    listStore.setFilter('state', [], true, true)
    return persistentFilters.init()
  }

  useQuery(listStore.fetchFacets)
  // Maybe index in case we are creating a new order from a fresh load
  const { isLoading } = useQuery(
    () =>
      Promise.all([
        projectStore.maybeIndexProjects(),
        invoiceStateStore.getAllInvoiceStates(),
        integrationStore.accountingInfo(),
        invoiceSettingsStore.indexSettings(),
        init(),
      ]),
    [],
  )

  function handleSearch(value: string) {
    persistentFilters.handleChangeFilters({ search: value }, false)
  }

  if (isLoading) return <Loading />

  return (
    <PsqlTableProvider
      tableName="invoices"
      options={{
        data: toJS(listStore.records),
        columns: getInvoiceColumns(excludedColumns),
        sort: {
          onChange: persistentFilters.handleChangeSort,
          field: listStore.searchState.sort,
          direction: listStore.searchState.sort_direction,
        },
      }}
    >
      <Page.Header>
        <Box width="100%" display="flex" alignItems="initial" flexDirection={{ _: 'column', md: 'row' }}>
          <Box mb={{ _: 8, md: 0 }} mr={8}>
            <InvoiceProjectSelector onChange={handleChangeProject} canViewAllInvoices={userStore.canViewAllInvoices} />
          </Box>

          <Box width="100%" display="flex" alignItems="center" flexGrow={1}>
            <Box mr={8} flexGrow={1}>
              <SearchInput value={listStore.searchState.search} onSearch={handleSearch} style={{ width: '100%' }} />
            </Box>

            <ListFilters />
          </Box>
        </Box>

        <Box mt={16} width="100%">
          <RibbonFilter
            showCounts
            boxProps={{
              mt: { _: 8, lg: 0 },
            }}
            value={listStore.searchState.filters['state_name'] || []}
            counts={listStore.stateCounts || {}}
            onChange={handleRibbonFilterChange}
            options={[
              { label: 'All', filter: '' },
              ...(invoiceStateStore.invoiceStates || [])
                .filter((state) => state.state !== ribbonFilterExcludedStates)
                .map((setting) => ({
                  label: setting?.label,
                  filter: setting?.label,
                })),
            ]}
          />
        </Box>
      </Page.Header>

      <Page.Content p={0} px={{ _: 0, md: 16 }} py={16} height="100%">
        {listStore.isFetching ? (
          <Loading />
        ) : listStore.records.length === 0 ? (
          <EmptyState />
        ) : (
          <ResponsiveTable
            Table={PsqlTable}
            data={toJS(listStore.records)}
            tableName="AllInvoices"
            hasMore={listStore.hasMore}
            loadMore={listStore.fetchNextPage}
            onLoadMore={listStore.fetchNextPage}
            onClickRow={({ row }) => maybeOpenIntoNewTab(`/invoice/${row.invoice_id}`)}
            renderMobileCard={InvoiceMobileCard}
          />
        )}
      </Page.Content>
    </PsqlTableProvider>
  )
})
