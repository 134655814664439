import React, { memo } from 'react'

import styled from '@emotion/styled'

import { ProjectOutlined, RightOutlined, ShopOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'
import { UnreconciledInvoiceResponse } from 'common/server/invoice'

import { InvoiceStatusTag } from 'contractor/components/@v2/Invoices/invoice_status_tag'

export const OrderTextContainer = styled(Typography.Text)`
  margin-left: 4px;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
`

interface InvoiceCardProps {
  invoice: UnreconciledInvoiceResponse
  onSelect: () => void
}

export const InvoiceCard: React.FC<InvoiceCardProps> = memo((props: InvoiceCardProps) => {
  const { invoice, onSelect } = props
  const getInvoiceName = () => {
    if (invoice.name && invoice.number) {
      return `${invoice.name}: ${invoice.number}`
    }
    return invoice.number
  }
  return (
    <Box
      style={{
        position: 'relative',
        cursor: 'pointer',
        padding: '8px 10px',
        width: '100%',
      }}
      onClick={onSelect}
    >
      <Box width="100%">
        <FlexBoxX width="100%" display="flex" alignItems="center" justifyContent="flex-start">
          <Typography.Text style={{ maxWidth: '70%' }} strong>
            {getInvoiceName()}
          </Typography.Text>
          <InvoiceStatusTag state={invoice.state} style={{ maxWidth: 120, marginLeft: 8 }} />
        </FlexBoxX>
        <Box display="flex">
          <Box style={{ marginTop: 6 }} display="flex" alignItems="center">
            <ProjectOutlined style={{ fontSize: 12, color: '#121C4E' }} />
            <OrderTextContainer>{invoice.project_name || 'Unknown'}</OrderTextContainer>
          </Box>
          <Box style={{ marginTop: 6, marginLeft: 8 }} display="flex" alignItems="center">
            <ShopOutlined style={{ fontSize: 12, color: '#121C4E' }} />
            <OrderTextContainer>{invoice.vendor_name || 'Unknown'}</OrderTextContainer>
          </Box>
        </Box>
      </Box>
      <RightOutlined style={{ position: 'absolute', right: 16, top: 16, fontSize: 12 }} />
    </Box>
  )
})
