import React, { useCallback, useRef } from 'react'

import { Form, Input } from 'antd'
import { TextAreaProps } from 'antd/lib/input'

import { useForm } from './row_context'

type EditableCellInputTextAreaProps = {
  inputName: string
  onSave: (value: string) => void
} & TextAreaProps

export const EditableCellInputTextArea = ({ inputName, onSave, ...props }: EditableCellInputTextAreaProps) => {
  const inputTextRef = useRef<HTMLTextAreaElement | null>(null)
  const form = useForm()

  const save = useCallback(async () => {
    try {
      const values = await form.validateFields()

      onSave(values[inputName])
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }, [form, onSave])

  const handleFocus = useCallback(() => {
    // Programmatically select all text in the TextArea
    const inputElement = inputTextRef.current
    if (inputElement) {
      inputElement.select()
    }
  }, [])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      inputTextRef.current?.focus()
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [inputTextRef.current])

  return (
    <Form.Item style={{ margin: 0 }} name={inputName}>
      <Input.TextArea
        ref={(ref) => {
          // Use the ref to set the textarea element
          if (ref && 'resizableTextArea' in ref) {
            inputTextRef.current = ref.resizableTextArea?.textArea as HTMLTextAreaElement
          }
        }}
        onFocus={handleFocus} // Select text when focused
        onPressEnter={save}
        onBlur={save}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            save()
          }
        }}
        style={{ width: '100%', resize: 'none' }}
        rows={2}
        {...props}
      />
    </Form.Item>
  )
}
