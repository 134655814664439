import React, { useMemo } from 'react'

import { Select } from 'antd'

import { observer } from 'mobx-react-lite'

import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../../context'

const INVOICE_EXTRA_FILE_KEY = 'extra_files'

const handleFileName = (filename) => {
  if (filename === '.pdf') return 'Original Extracted File.pdf'
  return filename
}

export const PdfSelect = observer(() => {
  const { invoice, selectedPdfInvoice, setSelectedPdfInvoice } = useInvoice()
  const { uploaderStore } = useStores()

  const fileList = uploaderStore.fileList(INVOICE_EXTRA_FILE_KEY)

  const options = useMemo(
    () => [
      ...invoice?.extracted_files.map((file) => ({ value: file.url, label: handleFileName(file.filename) })),
      ...fileList.map((file) => ({ value: file.url, label: handleFileName(file.name) })),
    ],
    [invoice?.extracted_files, fileList],
  )

  return (
    <Select
      filterOption={false}
      aria-autocomplete="none"
      options={options}
      placeholder="Select a PDF"
      size="small"
      value={selectedPdfInvoice || options[0]?.value}
      onSelect={setSelectedPdfInvoice}
      style={{ width: 300 }}
    />
  )
})
