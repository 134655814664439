import React from 'react'

import styled from '@emotion/styled'

import { Button, Drawer, Select } from 'antd'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { InvoiceMaterial } from 'common/server/invoice'
import theme from 'common/styles/theme'

import { useInvoice } from '../../context'
import { InputInvoiceMaterialPercentage } from '../InputInvoiceMaterialPercentage'
import { InvoiceItem } from '../InvoiceItem'
import { SelectCostCode } from '../SelectCostCode'
import { SelectPhaseCode } from '../SelectPhaseCode'

const RenderItemsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 0;
  min-height: calc(100% - 128px - 55px);

  & > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors['gray-4']};
  }
`

export const AccountingDetailsDrawerContent = ({ onClose }: { onClose: () => void }) => {
  const { accountingDetails, calcExtCost, invoiceMaterialsFiltered, handleEditInvoiceMaterial } = useInvoice()

  const [fields, setFields] = React.useState({
    cost_code_id: null,
    cost_code_phase_id: null,
    retainage_amount: 0,
    retainage_percentage: 0,
    retainage_type: 'AMOUNT',
  })

  const getInitialOptions = () => {
    const invoicesMaterialsChecked = invoiceMaterialsFiltered.materialsFilteredBySearch.filter(
      (material) => material.checked,
    )
    const invoiceMaterialGroupsChecked = invoiceMaterialsFiltered.groups.filter((group) => group.checked)

    const allInvoiceMaterialsChecked = [
      ...invoicesMaterialsChecked,
      ...invoiceMaterialGroupsChecked.reduce((acc, group) => [...acc, ...group.invoice_materials], []),
    ]
    if (allInvoiceMaterialsChecked.length === 0) return invoiceMaterialsFiltered.materialsFilteredBySearch
    return allInvoiceMaterialsChecked
  }

  const [invoiceMaterials, setInvoiceMaterials] = React.useState(getInitialOptions())

  const invoicesItems = React.useMemo(() => {
    return invoiceMaterialsFiltered.materials
      .filter((item) => !invoiceMaterials.find((invoiceMaterial) => invoiceMaterial.id === item.id))
      .map((invoiceMaterial) => ({
        label: invoiceMaterial.description,
        value: invoiceMaterial.id,
      }))
  }, [invoiceMaterials, invoiceMaterialsFiltered])

  const allInvoiceMaterialsIsChecked = React.useMemo(() => {
    return !!invoiceMaterials?.every((item) => item.checked === true)
  }, [invoiceMaterials])

  const handleCheckInvoiceMaterials = (invoiceMaterial: InvoiceMaterial) => {
    const newInvoiceMaterials = invoiceMaterials.map((item) => {
      if (item.id === invoiceMaterial.id) return { ...item, checked: !item.checked }
      return item
    })

    setInvoiceMaterials(newInvoiceMaterials)
  }

  const handleChangeInvoiceMaterial = (invoiceMaterialId: string, field: string, value) => {
    setInvoiceMaterials((oldInvoiceMaterials) =>
      oldInvoiceMaterials.map((item) => {
        if (item.id === invoiceMaterialId) return { ...item, [field]: value }
        return item
      }),
    )
  }

  const handleSaveInvoiceMaterials = React.useCallback(() => {
    invoiceMaterials.forEach((invoiceMaterial) => {
      if (invoiceMaterial.checked) {
        handleEditInvoiceMaterial(invoiceMaterial)
      }
    })
    onClose()
  }, [invoiceMaterials, handleEditInvoiceMaterial])

  const applyAllInvoiceMaterials = () => {
    setInvoiceMaterials(
      invoiceMaterials.map((item) => ({
        ...item,
        cost_code_id: fields.cost_code_id,
        cost_code_phase_id: fields.cost_code_phase_id,
        retainage_amount: fields.retainage_amount,
        retainage_percentage: fields.retainage_percentage,
        retainage_type: fields.retainage_type,
      })),
    )
  }

  const renderFields = React.useCallback(({ costCodeProps, phaseCodeProps, retainageProps }) => {
    return (
      <>
        <SelectCostCode {...costCodeProps} />
        <SelectPhaseCode {...phaseCodeProps} />
        <InputInvoiceMaterialPercentage {...retainageProps} style={{ width: '100%' }} />
      </>
    )
  }, [])

  const renderSearch = React.useMemo(() => {
    return (
      <FlexBoxY px={16} pt={16} style={{ gap: 8 }}>
        <Select
          loading={false}
          showSearch
          onSelect={(invoiceMaterialId) => {
            const selectedMaterial = invoiceMaterialsFiltered.materials.find((item) => item.id === invoiceMaterialId)
            if (selectedMaterial) setInvoiceMaterials([...invoiceMaterials, { ...selectedMaterial, checked: true }])
          }}
          value={null}
          notFoundContent="Not found"
          options={invoicesItems}
          placeholder="Extracted items..."
          style={{ width: '100%' }}
          filterOption={(input, option) => {
            const inputValue = input.toLowerCase()
            return option?.label?.toLowerCase().includes(inputValue)
          }}
        />

        <FlexBoxX width="100%" style={{ gap: 8 }}>
          {renderFields({
            costCodeProps: {
              ...accountingDetails,
              value: fields['cost_code'],
              onChange: (field: { value: string }) => setFields({ ...fields, cost_code_id: field.value }),
            },
            phaseCodeProps: {
              ...accountingDetails,
              value: fields['phase_code'],
              onChange: (field: { value: string }) => setFields({ ...fields, cost_code_phase_id: field.value }),
            },
            retainageProps: {
              retainageAmount: fields['retainage_amount'],
              retainagePercentage: fields['retainage_percentage'],
              retainageType: fields['retainage_type'],
              onChange: (amount: number, type: string, percentage: number) => {
                setFields({
                  ...fields,
                  retainage_amount: amount,
                  retainage_percentage: percentage,
                  retainage_type: type,
                })
              },
            },
          })}

          <Button type="ghost" onClick={applyAllInvoiceMaterials}>
            Apply all
          </Button>
        </FlexBoxX>

        <Button type="primary" style={{ width: '100%' }} disabled>
          Copy accouting details from order
        </Button>
      </FlexBoxY>
    )
  }, [applyAllInvoiceMaterials, allInvoiceMaterialsIsChecked, invoiceMaterialsFiltered, invoiceMaterials])

  const renderItems = React.useMemo(() => {
    return (
      <RenderItemsContainer>
        {invoiceMaterials.map((item, idx) => {
          return (
            <InvoiceItem
              key={idx}
              item={{
                id: item.id,
                description: item.description,
                quantity_shipped: item.quantity_shipped,
                uom: item.uom,
                unit_price: item.unit_price,
                calcExtCost,
                checked: item.checked,
              }}
              onCheck={() => handleCheckInvoiceMaterials(item)}
            >
              <FlexBoxX width="100%" py={16} style={{ gap: 8 }}>
                {renderFields({
                  costCodeProps: {
                    ...accountingDetails,
                    value: item.cost_code_id,
                    onChange: (field: { value: string }) =>
                      handleChangeInvoiceMaterial(item.id, 'cost_code_id', field.value),
                  },
                  phaseCodeProps: {
                    ...accountingDetails,
                    value: item.cost_code_phase_id,
                    onChange: (field: { value: string }) =>
                      handleChangeInvoiceMaterial(item.id, 'cost_code_phase_id', field.value),
                  },
                  retainageProps: {
                    onChange: (amount: number, type: string, percentage: number) => {
                      handleChangeInvoiceMaterial(item.id, 'retainage_amount', amount)
                      handleChangeInvoiceMaterial(item.id, 'retainage_type', type)
                      handleChangeInvoiceMaterial(item.id, 'retainage_percentage', percentage)
                    },
                    totalCost: item.extended_price,
                    retainageAmount: item.retainage_amount,
                    retainagePercentage: item.retainage_percentage,
                    retainageType: item.retainage_type,
                    roundingPrecision: 3,
                  },
                })}
              </FlexBoxX>
            </InvoiceItem>
          )
        })}
      </RenderItemsContainer>
    )
  }, [calcExtCost, handleChangeInvoiceMaterial, invoiceMaterials])

  const renderFooter = React.useMemo(() => {
    return (
      <FlexBoxX
        position="sticky"
        justifyContent="flex-end"
        paddingX={16}
        paddingY={10}
        bottom={0}
        style={{ gap: 16 }}
        backgroundColor={theme.colors.white}
        borderTop={`1px solid ${theme.colors['gray-4']}`}
      >
        <Button type="ghost" size="middle" style={{ width: 100 }} onClick={onClose}>
          Back
        </Button>
        <Button type="primary" size="middle" style={{ width: 100 }} onClick={handleSaveInvoiceMaterials}>
          Save
        </Button>
      </FlexBoxX>
    )
  }, [handleSaveInvoiceMaterials, onClose])

  return (
    <Box width="100%" height="100%" overflow="auto">
      {renderSearch}
      {renderItems}
      {renderFooter}
    </Box>
  )
}

export const AccountingDetailsDrawer = ({ isOpened, setIsOpened }) => {
  return (
    <Drawer
      onClose={() => setIsOpened(false)}
      open={isOpened}
      width={700}
      title="Accounting details"
      forceRender
      closable={false}
      bodyStyle={{ padding: 0 }}
    >
      {isOpened && <AccountingDetailsDrawerContent onClose={() => setIsOpened(false)} />}
    </Drawer>
  )
}

export const AccountingDetails = ({ disabled }: { disabled: boolean }) => {
  const [isOpened, setIsOpened] = React.useState(false)

  return (
    <>
      <Button type="ghost" disabled={disabled} onClick={() => setIsOpened(true)}>
        Accounting details
      </Button>

      <AccountingDetailsDrawer isOpened={isOpened} setIsOpened={setIsOpened} />
    </>
  )
}
