import React, { useState } from 'react'

import { useParams } from 'react-router-dom'

import { uniq } from 'lodash'

import { Typography, Modal, Button, Alert, message } from 'antd'

import { Box } from 'common/components/boxes'
import { NewComment } from 'common/components/comments/new_comment'
import { OrderMaterials } from 'common/components/OrderMaterialsV2'
import { resetCompanyMaterialId, makeOrderMaterial, makeCostCode } from 'common/helpers/order'
import { SUPPORT_EMAIL_ADDRESS } from 'common/helpers/other'
import { useQuery } from 'common/hooks/use-query'
import { OrderStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

import { useLeveling } from './context'

export const AddMaterials = () => {
  const { orderPackageStore, userStore, companySettingStore, costCodeStore, unitsStore, companyMaterialStore } =
    useStores()

  const params = useParams()

  const { handleMakeTableData, ordersSession } = useLeveling()

  const [isSubmitting, setSubmitting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [comment, setComment] = useState('')
  const [orderMaterials, setOrderMaterials] = useState([])

  const { isLoading } = useQuery(() => {
    return Promise.all([
      companyMaterialStore.loadSearchKey(),
      companySettingStore.indexOtherSettings(),
      costCodeStore.fetchAllActiveCostCodes(),
      costCodeStore.fetchAllActivePhaseCodes(),
      unitsStore.maybeUnits(),
    ])
  })

  const { company_attributes = [] } = companySettingStore.companyMaterialConfiguration
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings
  const requiredQuoteFields = companySettingStore.otherSettings?.required_quote_fields

  const ordersLocked = ordersSession.isBlocked

  const { orderPackage } = orderPackageStore

  const handleCancel = () => {
    setOrderMaterials([])
    setComment('')
    setIsModalOpen(false)
  }

  const handleSendRFQ = async () => {
    if (!orderMaterials.length) return

    setSubmitting(true)
    try {
      await orderPackageStore.updateOrderPackage({
        id: orderPackage.id,
        order_materials: orderMaterials.map(resetCompanyMaterialId).map(makeOrderMaterial).map(makeCostCode),
        comment: comment?.trim(),
      })
      orderPackageStore.getOrderPackage(params['id'], { with_discarded: true }).then(handleMakeTableData)
      handleCancel()
      message.success('Successfully added materials')
    } catch (error) {
      message.error(error?.response?.data?.error || 'Unable to add materials')
    } finally {
      setSubmitting(false)
    }
  }

  const hasMultiplesDeliveries = orderPackageStore.orderPackage?.orders?.some((order) => order.deliveries.length > 1)

  const projectIds = uniq(orderPackageStore.orderPackage?.orders?.map((order) => order.project_id))
  const hasDifferentProjects = projectIds.length > 1

  const project = orderPackageStore.orderPackage?.orders[0]?.project
  const projectId = project?.id

  const ordersStates = orderPackage.orders.map((order) => order.state)

  // Do not show add materials if the quotes were placed
  if (isLoading || ordersStates.every((status) => status !== OrderStates.QUOTED)) {
    return null
  }

  return (
    <>
      <Modal
        title="Add more materials"
        open={isModalOpen}
        onCancel={handleCancel}
        width="90vw"
        footer={false}
        bodyStyle={{ padding: 12 }}
      >
        <Box width="100%" height="100%" display="flex" flexDirection="column">
          <Box overflowY="auto" maxHeight={415}>
            <OrderMaterials
              orderType="RFQ"
              dataSource={orderMaterials}
              onChange={(newOrderMaterials) => {
                if (requiredQuoteFields?.quantity) {
                  setOrderMaterials(
                    newOrderMaterials?.map((newOrderMaterial) => ({
                      ...newOrderMaterial,
                      quantity: newOrderMaterial?.quantity || 1,
                    })),
                  )
                } else {
                  setOrderMaterials(newOrderMaterials)
                }
              }}
              hideUnitCost
              hideVendorResponse
              hideCostCode={!company_attributes.includes('cost_code_id')}
              projectId={projectId}
              unitCostInput={{ hideDetails: !userStore.canUseHistoricalCost }}
              costCodeInput={{
                costCodes: costCodeStore.costCodeListStore.records,
                costCodeSettings,
                projectId,
              }}
              unitInput={{ units: unitsStore.units }}
              units={unitsStore.units}
              phaseCodeInput={{
                projectSpecificPhaseCodesEnabled: costCodeSettings?.project_specific_phase_codes_enabled,
                initialCostCodePhases: costCodeStore.costCodePhaseListStore.records,
                projectId: projectId,
              }}
              requiredFields={{ quote: requiredQuoteFields }}
              costCodeSettings={costCodeSettings}
            />
          </Box>

          <NewComment
            currentView="CONTRACTOR"
            onChange={setComment}
            newComment={comment}
            placeholder="Add a message for all vendors"
          />

          <Box>
            <Alert
              type="info"
              message="Materials will be added to all RFQs and re-sent to vendors for pricing."
              style={{ margin: '16px 0' }}
            />
            {hasMultiplesDeliveries && (
              <Alert
                type="info"
                message="We noticed you have multiple deliveries, the new materials will be added in the first delivery."
                style={{ margin: '16px 0' }}
              />
            )}
            {hasDifferentProjects && (
              <Alert
                type="info"
                message={`We noticed you have different projects associated with the quotes. As this is an unexpected flow we will use the project "${project?.name}" as the current project. If you have any questions contact ${SUPPORT_EMAIL_ADDRESS}`}
                style={{ margin: '16px 0' }}
              />
            )}

            <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
              <Button onClick={handleCancel} loading={isSubmitting}>
                Cancel
              </Button>
              <Button type="primary" onClick={handleSendRFQ} loading={isSubmitting}>
                Send RFQ
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Typography.Link onClick={() => setIsModalOpen(true)} disabled={ordersLocked}>
        Add More Materials
      </Typography.Link>
    </>
  )
}
