import React from 'react'

import PlusOutlined from '@ant-design/icons/PlusOutlined'
import { Button, Dropdown, Space } from 'antd'
import { MenuProps } from 'antd/lib/menu'

import { Visibility } from 'common/components/Visibility'

type InvoiceExtraContentProps = {
  disabled?: boolean
  onAttachInvoice: () => void
}

export const InvoiceActions = (props: InvoiceExtraContentProps) => {
  const { disabled, onAttachInvoice } = props
  const items: MenuProps['items'] = [
    {
      label: 'Attach invoice',
      key: 'attach_invoice',
      onClick: onAttachInvoice,
    },
  ]

  const menuProps: MenuProps = {
    items,
  }

  return (
    <>
      <Visibility.Hidden breakpoint={'md'}>
        <Space>
          <Button disabled={disabled} onClick={onAttachInvoice}>
            Attach invoice
          </Button>
        </Space>
      </Visibility.Hidden>
      <Visibility.Show breakpoint={'md'}>
        <Dropdown menu={menuProps}>
          <Button type="primary" style={{ marginLeft: 8, marginRight: 8 }}>
            <PlusOutlined />
          </Button>
        </Dropdown>
      </Visibility.Show>
    </>
  )
}
