import React, { useEffect, useRef } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Space, Spin, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Attachments as AttachmentsComponent } from 'common/components/Attachments'
import { Box } from 'common/components/boxes'
import { Card } from 'common/components/Card'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import UploaderModal from 'common/components/uploaderModal'
import { CommitmentStatuses } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'

type AttachmentsProps = {
  disabled?: boolean
}

export const AttachmentsDrawer = observer((props: AttachmentsProps) => {
  const { uploaderStore } = useStores()
  const { commitment } = useCommitment()
  const { disabled } = props
  const attachmentsDrawerRef = useRef<DrawerRef>()
  const showResume =
    commitment?.status !== CommitmentStatuses.DRAFT && commitment.commitment_type == 'MaterialCommitment'
  const showLookAheadPdfs = commitment?.commitment_type == 'ConcreteCommitment'

  useEffect(() => {
    uploaderStore.resetAllUploads()

    uploaderStore.addExistingFiles('attachments', commitment?.attachments)
    uploaderStore.addExistingFiles('resume', commitment?.resume)
    uploaderStore.addExistingFiles('look_ahead_pdfs', commitment?.look_ahead_pdfs)

    return () => {
      uploaderStore.resetAllUploads()
    }
  }, [commitment?.attachments, commitment?.resume])

  return (
    <>
      <Box onClick={() => attachmentsDrawerRef?.current?.show()}>
        <Typography.Text>Attachments</Typography.Text>
      </Box>

      <Drawer title="Attachments" ref={attachmentsDrawerRef} forceRender width={600}>
        <Box width="100%" overflow="auto">
          <Card width="100%" title="Attachments">
            <AttachmentsComponent
              data-cy="uploader"
              disabled={disabled}
              multiple
              uploadKey="attachments"
              noResetUploads
              fileList={uploaderStore.fileList('attachments')}
              onRemoveUpload={uploaderStore.removeUpload}
              onAddNewUpload={uploaderStore.addNewUpload}
              onResetUploads={uploaderStore.resetUploads}
              onSetUploadError={uploaderStore.setUploadError}
              onUpdateUpload={uploaderStore.updateUpload}
            />
          </Card>

          {showResume && (
            <Card width="100%" title="Commitment Resume">
              <UploaderModal
                disabled
                multiple={false}
                uploadKey="commitment_resume"
                fileList={uploaderStore.fileList('resume')}
              />
              {!commitment.resume && (
                <Tooltip title="Your resume will be generated in a few seconds." placement="topLeft">
                  <Space style={{ width: 'max-content' }}>
                    <Spin indicator={<LoadingOutlined spin style={{ fontSize: 14 }} />} />
                    <Typography.Text type="secondary">Loading...</Typography.Text>
                  </Space>
                </Tooltip>
              )}
            </Card>
          )}

          {showLookAheadPdfs && (
            <Card width="100%" title="Look Ahead PDFs">
              <UploaderModal
                disabled
                multiple={false}
                uploadKey="look_ahead_pdfs"
                fileList={uploaderStore.fileList('look_ahead_pdfs')}
              />
            </Card>
          )}
        </Box>
      </Drawer>
    </>
  )
})
