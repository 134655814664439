import axios from 'axios'

import { StandardIndexRequest, StandardIndexResponse } from 'common/server/server_types'
import { Unit } from 'common/server/units'

export interface PriceSheet {
  id: string
  created_at: string
  updated_at: string
  name: string
  number: string
  start_at: string
  end_at: string
  workflow_state: string
  price_sheet_materials_count: number
  company_vendor_id: string
  company_vendor: {
    id: string
    vendor_name: string
    vendor?: {
      id: string
      name: string
    }
  }
  project_id?: string
  project?: {
    id: string
    name: string
    project_id?: string
    active: boolean
  }
}

export interface PriceSheetCreate {
  name: string
  company_vendor_id: string
  project_id?: string
  number?: string
  start_at?: string
  end_at?: string
}

export interface PriceSheetUpdate {
  id: string
  name: string
  company_vendor_id: string
  project_id?: string
  number?: string
  start_at?: string
  end_at?: string
  price_sheet_materials: {
    id: string
    upc?: string
    description: string
    unit_name?: string
    unit_id?: string
    size?: string
    price: string
    company_material?: {
      id?: string
      description?: string
      unit_name?: string
      unit_id?: string
      size?: string
    }
    _destroy: boolean
  }[]
}

export interface PriceSheetMaterial {
  id: string
  price_sheet_id: string
  match_confidence: 'no_match' | 'partial_match' | 'match'
  description: string
  price: string
  size?: string
  upc?: string
  unit_name?: string
  unit_id?: string
  unit?: Unit
  company_material?: {
    id: string
    description: string
    unit_name?: string
    unit_id?: string
    unit?: Unit
  }
  created_at: string
  updated_at: string
}

export interface PriceSheetFacets {
  company_vendos: { label: string; value: string }[]
}

export function index(params: StandardIndexRequest) {
  return axios.get<StandardIndexResponse<PriceSheet[]>>('/price_sheets', { params })
}

export function facets() {
  return axios.get<PriceSheetFacets>('/price_sheets/facets')
}

export function create(payload: PriceSheetCreate) {
  return axios.post<PriceSheet>('/price_sheets', { price_sheet: payload })
}

export function update(payload: PriceSheetUpdate, applyPreferredPrice?: boolean) {
  return axios.patch<PriceSheet>(`/price_sheets/${payload.id}`, {
    price_sheet: payload,
    apply_preferred_price: applyPreferredPrice,
  })
}

export function destroy(id: string) {
  return axios.delete(`/price_sheets/${id}`)
}

export function expire(id: string) {
  return axios.post(`/price_sheets/${id}/expire`)
}

export function show(id: string) {
  return axios.get<PriceSheet>(`/price_sheets/${id}`)
}

export function price_sheet_materials(params: StandardIndexRequest) {
  return axios.get<StandardIndexResponse<PriceSheetMaterial[]>>(
    `/price_sheets/${params?.filters['price_sheet_id']}/price_sheet_materials`,
    { params },
  )
}
