import React from 'react'

import { Col, Row, Typography } from 'antd'

import { FlexBoxY } from 'common/components/boxes'
import { Unit } from 'common/server/units'

import { MaterialsAutocompleteProps } from 'contractor/components/MaterialsAutocomplete'
import { ShowCompanyMaterialResponse } from 'contractor/server/company_materials'

import { CreateDetailedMaterial } from './create_detailed_material'
import { QuickCreateMaterial } from './quick_create_material'
import { RequestNewMaterial } from './request_new_material'

export type FooterProps = {
  materialDescription: string
  publicOrderFormUrlExtension?: string
  companyAttributes?: string[]
  isCommitment?: boolean
  units?: Unit[]
  setIsOpen?: (isOpen: boolean) => void
  userId?: string
  onSelect?: (material: ShowCompanyMaterialResponse | QuickCreateMaterial) => void
  disableQuickCreate?: boolean
} & Pick<MaterialsAutocompleteProps, 'canCreateNewMaterial' | 'orderState'>

export const Footer = (props: FooterProps) => {
  const { canCreateNewMaterial, isCommitment } = props

  if (isCommitment) {
    return null
  }

  if (!canCreateNewMaterial) {
    return <RequestNewMaterial {...props} />
  }

  return (
    <FlexBoxY px="8px" py="16px" backgroundColor="gray-2">
      <Typography.Text type="secondary" strong style={{ fontSize: 16, marginBottom: 4 }}>
        Don&apos;t see what you are looking for?
      </Typography.Text>

      <Row gutter={12} justify="center">
        <Col style={{ margin: '4px 0' }}>
          <QuickCreateMaterial {...props} />
        </Col>

        <Col style={{ margin: '4px 0' }}>
          <CreateDetailedMaterial {...props} />
        </Col>
      </Row>
    </FlexBoxY>
  )
}
