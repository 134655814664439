import React from 'react'

import moment from 'moment/moment'

import { ConcreteCommitmentMaterial } from 'contractor/server/commitments'

type InputType = 'text' | 'number' | 'date' | 'time'

export type ColumnsProps = {
  title: string
  dataIndex: string | string[]
  sorter?: (a: ConcreteCommitmentMaterial, b: ConcreteCommitmentMaterial) => number
  render?: (value) => React.ReactNode
  width: number
  editable: boolean
  visible?: boolean
  inputType?: InputType
}

export const columns: ColumnsProps[] = [
  {
    title: 'Date',
    dataIndex: 'schedule',
    sorter: (a, b) => new Date(a.schedule).getTime() - new Date(b.schedule).getTime(),
    render: (date) => moment(date).format('MM/DD/YYYY'),
    width: 180,
    editable: true,
    visible: true,
    inputType: 'date',
  },
  {
    title: 'Hour',
    dataIndex: 'time',
    sorter: (a, b) => new Date(a.time)?.getTime() - new Date(b.time)?.getTime(),
    render: (hour) => moment(hour).format('HH:mm'),
    width: 120,
    editable: true,
    visible: true,
    inputType: 'time',
  },
  {
    title: 'Phase',
    dataIndex: 'phase',
    sorter: (a, b) => a.phase?.localeCompare?.(b?.phase),
    width: 105,
    editable: true,
    visible: true,
    inputType: 'text',
  },
  {
    title: 'Location & Description',
    dataIndex: ['company_material', 'description'],
    sorter: (a, b) => a.company_material.description?.localeCompare?.(b.company_material.description),
    render: (description) => description,
    width: 340,
    editable: true,
    visible: true,
    inputType: 'text',
  },
  {
    title: 'Spacing',
    dataIndex: 'spacing',
    sorter: (a, b) => a.spacing - b.spacing,
    width: 115,
    editable: true,
    visible: true,
    inputType: 'text',
  },
  {
    title: 'Requested (CY)',
    dataIndex: 'estimated_cy',
    sorter: (a, b) => a.estimated_cy - b.estimated_cy,
    width: 150,
    editable: true,
    visible: true,
    inputType: 'number',
  },
  {
    title: 'Pour (CY)',
    dataIndex: 'actual_cy',
    sorter: (a, b) => a.actual_cy - b.actual_cy,
    width: 125,
    editable: true,
    visible: true,
    inputType: 'number',
  },
  {
    title: 'Total (CY)',
    dataIndex: 'total_cy',
    sorter: (a, b) => a.total_cy - b.total_cy,
    width: 125,
    editable: true,
    visible: true,
    inputType: 'number',
  },
  {
    title: 'Mix',
    dataIndex: ['company_material', 'mix_code'],
    sorter: (a, b) => a.company_material.mix_code?.localeCompare?.(b.company_material.mix_code),
    render: (mix_code) => mix_code,
    width: 200,
    editable: true,
    visible: true,
    inputType: 'text',
  },
  {
    title: 'Pump',
    dataIndex: 'pump',
    sorter: (a, b) => a.pump - b.pump,
    width: 125,
    editable: true,
    visible: true,
    inputType: 'number',
  },
  {
    title: 'Pump Hours',
    dataIndex: 'pump_hours',
    sorter: (a, b) => a.pump_hours - b.pump_hours,
    width: 125,
    editable: true,
    visible: true,
    inputType: 'text',
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    sorter: (a, b) => a.notes?.localeCompare?.(b.notes),
    width: 240,
    editable: true,
    visible: true,
    inputType: 'text',
  },
]
