import React, { useState, createContext, useContext, useEffect, useCallback } from 'react'

import { Form, FormInstance } from 'antd'

import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { Commitment } from 'contractor/server/commitments'
import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'

const PrivateOrderFormContext = createContext<PrivateOrderFormContextProps>({} as PrivateOrderFormContextProps)

export type FormValues = {
  projectId: string
  orderPackageName: string
  companyVendorContact: {
    label: string
    value: string
    contact: CompanyVendorContact
  }
  addressId: string
  addressToId: string
  requestedDeliveredAt: string
  watchers: Array<{ value: string }>
  commitmentId?: string
}

type PrivateOrderFormContextProps = {
  isSubmitting: boolean
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  projectId: string
  form: FormInstance
  commitment?: Commitment
  setCommitment?: React.Dispatch<React.SetStateAction<Commitment | null>>
}

type PrivateOrderFormProviderProps = {
  children: React.ReactNode
}

export const usePrivateOrderForm = () => useContext(PrivateOrderFormContext)

export const PrivateOrderFormProvider = observer(({ children }: PrivateOrderFormProviderProps) => {
  const {
    projectStore,
    orderStore,
    addressStore,
    commitmentStore,
    companySettingStore,
    costCodeStore,
    unitsStore,
    addressToStore,
  } = useStores()

  const [isSubmitting, setSubmitting] = useState(false)
  const [commitment, setCommitment] = useState<Commitment>()

  const [form] = Form.useForm<FormValues>()
  const projectId = Form.useWatch('projectId', form)
  const commitmentId = Form.useWatch('commitmentId', form)

  const costCodeSettings = companySettingStore?.otherSettings?.cost_code_settings

  const loadCommitment = useCallback(async (commitmentId: string) => {
    const commitment = await commitmentStore.loadCommitment(commitmentId)
    setCommitment(commitment)
    form.setFieldValue('commitmentId', commitment.id)
  }, [])

  const fetchPhaseCodes = () => {
    if (!costCodeSettings?.phase_code_enabled) return Promise.resolve()

    costCodeStore.costCodePhaseListStore.setFilter('active', true, true, true)

    if (costCodeSettings?.project_specific_phase_codes_enabled) {
      costCodeStore.costCodePhaseListStore.setFilter('project_id', projectId, true, true)
    }
    return costCodeStore.costCodePhaseListStore.fetchRecords()
  }

  useEffect(() => {
    fetchPhaseCodes()
  }, [projectId])

  const { isLoading } = useQuery(() => {
    return Promise.all([
      projectStore.indexProjects(),
      addressStore.indexAddresses(),
      companySettingStore.indexCompanyMaterialConfiguration(),
      companySettingStore.maybeIndexUsers(),
      costCodeStore.fetchAllActiveCostCodes(),
      commitmentStore.listStore.fetchRecords(),
      unitsStore.maybeUnits(),
      fetchPhaseCodes(),
      addressToStore.indexAddressesTo(),
    ])
  })

  useEffect(() => {
    orderStore.clearOrder(10)
  }, [])

  useEffect(() => {
    // If the user clears the commitment field, we should clear the selected commitment too

    if (commitmentId) {
      loadCommitment(commitmentId)
    } else {
      setCommitment(undefined)
    }
  }, [commitmentId, loadCommitment])

  if (isLoading) {
    return <Loading />
  }

  return (
    <PrivateOrderFormContext.Provider
      value={{ isSubmitting, setSubmitting, projectId, form, commitment, setCommitment }}
    >
      {children}
    </PrivateOrderFormContext.Provider>
  )
})

export const withPrivateOrderFormProvider = (Component) => (props) => {
  return (
    <PrivateOrderFormProvider>
      <Component {...props} />
    </PrivateOrderFormProvider>
  )
}
