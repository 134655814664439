import React from 'react'

import LockOutlined from '@ant-design/icons/LockOutlined'
import { Checkbox, Menu, Typography } from 'antd'

import { FlexBoxX } from 'common/components/boxes'

import { ColumnsProps } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/LogView/columns_definition'

type ColumnsSettingsProps = {
  columnSettings: ColumnsProps[]
  toggleColumnEditable: (dataIndex: string | string[]) => void
}

export const ColumnsSettings = React.memo((props: ColumnsSettingsProps) => {
  const { columnSettings, toggleColumnEditable } = props
  return (
    <Menu>
      {columnSettings.map((col) => {
        const keyStr = Array.isArray(col.dataIndex) ? col.dataIndex.join('.') : col.dataIndex
        return (
          <Menu.Item style={{ width: '100%' }} key={keyStr}>
            <FlexBoxX justifyContent="space-between" style={{ minWidth: 250 }}>
              <FlexBoxX justifyContent="start">
                <Typography.Text>{col.title}</Typography.Text>
                <LockOutlined style={{ color: col.editable ? 'blue' : 'black', marginLeft: 8 }} />
              </FlexBoxX>

              <Checkbox checked={!col.editable} onChange={() => toggleColumnEditable(col.dataIndex)} />
              <Typography.Text style={{ marginLeft: 8 }}>Lock</Typography.Text>
            </FlexBoxX>
          </Menu.Item>
        )
      })}
    </Menu>
  )
})
