import React from 'react'

import { Button, Comment, Input, Space } from 'antd'

import { Box } from 'common/components/boxes'

import { Avatar } from './avatar'
import { CommentsProps } from './comments'

type NewCommentProps = {
  onChange: (comment: string) => void
  newComment: string
  showSendButton?: boolean
} & Pick<CommentsProps, 'onSubmit' | 'currentView' | 'placeholder' | 'loading'>

export const NewComment = ({
  onChange,
  newComment,
  onSubmit,
  currentView,
  placeholder = 'Add a comment',
  loading = false,
  showSendButton = false,
}: NewCommentProps) => {
  const handlePressEnter = (event) => {
    if (!showSendButton || event.shiftKey || !newComment.trim().length) return

    onSubmit?.(newComment)
    setTimeout(() => onChange(''), 100)
  }

  return (
    <Comment
      data-cy={`new-comment-input`}
      style={{ width: '100%' }}
      avatar={<Avatar isStyled isContractor={currentView === 'CONTRACTOR'} />}
      content={
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Input.TextArea
            rows={4}
            data-cy="add-comment-input"
            placeholder={placeholder}
            onChange={(e) => onChange(e.target.value)}
            onPressEnter={handlePressEnter}
            value={newComment}
            style={{ resize: 'none', width: '100%' }}
          />

          {showSendButton && (
            <Space size="middle" style={{ marginTop: 12 }}>
              <Button
                onClick={() => onChange('')}
                loading={loading}
                disabled={!newComment}
                data-cy="cancel-comment-button"
              >
                Cancel
              </Button>
              <Button
                data-cy={`send-comment-button`}
                onClick={() => {
                  onSubmit(newComment)
                  onChange('')
                }}
                loading={loading}
                type="primary"
                disabled={!newComment}
              >
                Send
              </Button>
            </Space>
          )}
        </Box>
      }
    />
  )
}
