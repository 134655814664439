import React, { useState } from 'react'

import LockOutlined from '@ant-design/icons/LockOutlined'
import UserOutlined from '@ant-design/icons/UserOutlined'
import { Alert, Button, Checkbox, Form, Input, Typography, Modal } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { Box } from 'common/components/boxes'
import { SignInTypes, LandingMethods, LandingProps } from 'common/pages/Landing'

import { Logo } from '../logo'
import { AccessType } from './select_access_type'
import { SignInSSO } from './sign_in_sso'

export type SignInFormProps = {
  setSignInType: (string) => void
  confirmationToken?: string
  signIn: LandingMethods['signIn']
  accessType: AccessType
} & Pick<LandingProps, 'redirectPath'>

export const SignInForm = ({ setSignInType, confirmationToken, signIn, redirectPath, accessType }: SignInFormProps) => {
  const [error, setError] = useState('')
  const [form] = Form.useForm()

  const onValuesChange = (changedValues) => {
    if (changedValues?.email) form.setFieldsValue({ email: changedValues.email.toLowerCase().trim() })
  }

  const onFinish = async (values: { email: string; password: string; remember_me: boolean }): Promise<void> => {
    try {
      await signIn(values)
      location.pathname = redirectPath
      trackEvent(Events.CLICK_LOGIN, location.pathname, {
        access_type: accessType,
      })
    } catch (err) {
      const error = JSON.parse(err?.request?.response)?.['error']
      // TODO: Maybe redirect user to resend confirmation?
      // if (error === 'You have to confirm your email address before continuing.') {
      // }
      console.error('err', error)
      setError(error)
      trackEvent(Events.CLICK_LOGIN, location.pathname, {
        access_type: accessType,
        error,
      })
    }
  }

  const isContractor = accessType === 'CONTRACTOR'
  const title = isContractor ? 'Contractor' : 'Vendor'

  const oppositeTitle = isContractor ? 'Vendor' : 'Contractor'
  const oppositeLoginUrl = isContractor ? '/vendor?access-type=VENDOR' : '/?access-type=CONTRACTOR'

  const handleRegister = () => {
    trackEvent(Events.CLICK_REGISTER, location.pathname, {
      access_type: accessType,
    })

    Modal.info({
      title: 'Account registration',
      content: (
        <Typography.Text>
          Please reach out to <Typography.Link href="mailto: help@subbase.io">help@subbase.io</Typography.Link> to setup
          your account and our team will get back to you shortly.
        </Typography.Text>
      ),
    })
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent={{ _: 'space-between', xs: 'center' }}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start" width="inherit">
        <Logo />

        <Typography.Title level={4} style={{ margin: 0 }}>
          Log in as
        </Typography.Title>
        <Typography.Title level={5} style={{ marginTop: 0 }}>
          a {title}
        </Typography.Title>

        <Typography.Text type="secondary">Welcome back!</Typography.Text>

        <Form
          style={{ width: '100%', marginTop: 24 }}
          form={form}
          initialValues={{ remember_me: true }}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >
          {confirmationToken && (
            <Alert type="success" style={{ marginBottom: '20px' }} message="Confirmation successful" />
          )}

          <Form.Item
            name="email"
            validateTrigger="onBlur"
            rules={[
              { required: true, message: 'Please input your e-mail' },
              { type: 'email', message: 'The input is not a valid e-mail' },
            ]}
          >
            <Input data-cy="email" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
            <Input
              data-cy="password"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item>
            <FlexBoxX justifyContent="space-between">
              <Form.Item name="remember_me" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <FlexBoxY alignItems="flex-end">
                <Typography.Link data-cy="forgot-password" onClick={() => setSignInType(SignInTypes.FORGOT_PASSWORD)}>
                  Forgot password
                </Typography.Link>
              </FlexBoxY>
            </FlexBoxX>
          </Form.Item>

          {error && <Alert style={{ marginBottom: '20px' }} message={error} type="error" />}

          <Form.Item>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Button data-cy="submit" block type="primary" htmlType="submit" style={{ marginBottom: 8 }}>
                Log In
              </Button>

              <Typography.Text>
                Don&apos;t have an account? <Typography.Link onClick={handleRegister}>Register here</Typography.Link>
              </Typography.Text>
            </Box>
          </Form.Item>
        </Form>
      </Box>

      {isContractor && <SignInSSO />}

      <Box display="flex" justifyContent="center" width="100%" mt={24}>
        <Typography.Text>
          Not a {title}?{' '}
          <Typography.Link
            href={oppositeLoginUrl}
            onClick={() => {
              trackEvent(Events.CLICK_LOGIN_AS, location.pathname, {
                access_type: accessType,
              })
            }}
          >
            Login as {oppositeTitle}
          </Typography.Link>
        </Typography.Text>
      </Box>
    </Box>
  )
}
