import React, { useEffect } from 'react'

import moment from 'moment/moment'

import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'
import { InvoiceActions } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/Invoices/invioce_actions'
import { LogViewActions } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/LogView/log_view_actions'
import { LookAheadActions } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/LookAhead/look_ahead_actions'
import { MixListActions } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/MixList/mix_list_actions'
import { ColumnsProps } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/LogView/columns_definition'

type MixListExtraContentProps = {
  disabled?: boolean
  onAdditionCosts: () => void
  onPriceIncrease: () => void
  onAttachInvoice: () => void
  tabKey: string
  columns: ColumnsProps[]
  toggleColumnVisibility: (dataIndex: string | string[]) => void
  toggleColumnEditable: (dataIndex: string | string[]) => void
}

export const ExtraActions = (props: MixListExtraContentProps) => {
  const {
    disabled,
    onAdditionCosts,
    onPriceIncrease,
    tabKey,
    columns,
    toggleColumnVisibility,
    toggleColumnEditable,
    onAttachInvoice,
  } = props
  const { setFilters } = useCommitment()

  const handleFilterLastMonth = () => {
    const currentDate = moment()
    const currentMonthStart = currentDate.clone().startOf('month').format('YYYY-MM-DD')
    const currentMonthEnd = currentDate.clone().endOf('month').format('YYYY-MM-DD')
    setFilters({ date_start: currentMonthStart, date_end: currentMonthEnd })
  }

  useEffect(() => {
    handleFilterLastMonth()
  }, [])

  switch (tabKey) {
    case 'mix_list':
      return <MixListActions disabled={disabled} onAdditionCosts={onAdditionCosts} onPriceIncrease={onPriceIncrease} />
    case 'look_ahead':
      return (
        <LookAheadActions
          disabled={disabled}
          onPriceIncrease={onPriceIncrease}
          toggleColumnVisibility={toggleColumnVisibility}
          columns={columns}
        />
      )
    case 'log':
      return (
        <LogViewActions
          columns={columns}
          toggleColumnEditable={toggleColumnEditable}
          toggleColumnVisibility={toggleColumnVisibility}
        />
      )
    case 'invoices':
      return <InvoiceActions disabled={disabled} onAttachInvoice={onAttachInvoice} />
    default:
      return null
  }
}
