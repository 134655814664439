import React from 'react'

import { Empty, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'
import { LookAheadRow } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/LookAhead/look_ahead_row'
import { ConcreteCommitmentMaterial } from 'contractor/server/commitments'

export const LookAhead = observer(() => {
  const { commitmentStore } = useStores()
  const { dateFilteredMaterials } = useCommitment()

  if (!dateFilteredMaterials?.length) {
    return (
      <Box p={16}>
        <Empty />
      </Box>
    )
  }

  return (
    <Space direction="vertical" style={{ marginTop: 16, width: '100%' }}>
      <FlexBoxX width="100%"></FlexBoxX>
      <Box width="100%" pt={8} display="flex" flexDirection="column" gridGap={16}>
        {dateFilteredMaterials?.map((commitmentMaterial: ConcreteCommitmentMaterial) => (
          <LookAheadRow
            key={commitmentMaterial.id}
            dataSource={commitmentMaterial}
            onChange={commitmentStore?.updateMaterial}
          />
        ))}
      </Box>
    </Space>
  )
})
