import React, { useCallback } from 'react'

import { PlusOutlined, EditOutlined } from '@ant-design/icons'
import { Skeleton, Tooltip, Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DebounceSelect, DebounceSelectProps } from 'common/components/DebounceSelect'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

type SelectCostCodeClassProps = Partial<DebounceSelectProps> & {
  onEdit: () => void
  onAdd: () => void
}

const makeOption = (costCodeClass) => ({
  value: costCodeClass.id,
  label: costCodeClass.code,
})

export const SelectCostCodeClass = observer<SelectCostCodeClassProps>(({ onEdit, onAdd, ...props }) => {
  const { costCodeStore } = useStores()

  const { costCodeClassListStore } = costCodeStore

  const { isLoading, data = [] } = useQuery(costCodeClassListStore.fetchRecords)

  const fetchOptions = useCallback((search: string) => {
    return costCodeClassListStore.setSearch(search).then((data) => data.map(makeOption))
  }, [])

  if (isLoading) {
    return <Skeleton.Input block active />
  }

  return (
    <Box width="100%" display="inline-flex" style={{ gap: 10 }}>
      <DebounceSelect
        data-cy="select-cost-code-class"
        allowClear
        placeholder="Select Class"
        loading={isLoading}
        // @ts-ignore
        initialOptions={data?.map(makeOption)}
        fetchOptions={fetchOptions}
        {...props}
      />
      <Tooltip title="Edit Class">
        <Button icon={<EditOutlined />} onClick={onEdit} disabled={!props?.value} style={{ minWidth: 32 }} />
      </Tooltip>
      <Tooltip title="Add New Class">
        <Button
          data-cy="add-new-cost-code-class-button"
          icon={<PlusOutlined />}
          onClick={onAdd}
          style={{ minWidth: 32 }}
        />
      </Tooltip>
    </Box>
  )
})
