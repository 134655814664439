import React, { useCallback, useRef } from 'react'

import PlusOutlined from '@ant-design/icons/PlusOutlined'
import { Button, Tooltip } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { BoxProps, FlexBoxX } from 'common/components/boxes'
import { DebounceSelect, DebounceSelectProps } from 'common/components/DebounceSelect'
import { DrawerRef } from 'common/components/Drawer'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { CreateCompanyVendorContact } from 'contractor/pages/CompanyVendorContacts/MyVendors/Create'
import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'
import { IndexCompanyVendor } from 'contractor/server/company_vendors'

export type Option = {
  label: string
  value: string
  original: IndexCompanyVendor
  disabled?: boolean
}

export type SelectCompanyVendorProps = {
  isCreate?: boolean
  wrapperProps?: BoxProps
  onMakeOption?: (companyVendor: IndexCompanyVendor) => Option
  onChange?: (option: Nullable<Option>) => void
} & Partial<Omit<DebounceSelectProps, 'onChange'>>

export const makeCompanyVendorOption = (companyVendor: Nullable<IndexCompanyVendor>) => {
  if (!companyVendor) {
    return undefined
  }

  return {
    label: companyVendor?.vendor?.name || companyVendor?.vendor_name,
    value: companyVendor.id,
    original: toJS(companyVendor),
  }
}

export const SelectCompanyVendor = observer<SelectCompanyVendorProps>(
  ({ isCreate, wrapperProps, onMakeOption = makeCompanyVendorOption, onChange, ...props }) => {
    const { companyVendorStore, userStore } = useStores()

    const drawerCreateRef = useRef<DrawerRef>()

    const { companyVendorListSelectorStore } = companyVendorStore

    const { isLoading } = useQuery(
      () => companyVendorListSelectorStore.fetchRecords(),
      [],
      !companyVendorListSelectorStore.records.length,
    )

    const fetchOptions = useCallback((search: string) => {
      return companyVendorListSelectorStore.setSearch(search).then((data) => toJS(data).map(onMakeOption))
    }, [])

    const handleAfterCreate = (newContact: CompanyVendorContact) => {
      onChange?.(makeCompanyVendorOption(newContact.company_vendor as IndexCompanyVendor))
    }

    const initialOptions = companyVendorListSelectorStore?.records?.map(onMakeOption)

    return (
      <FlexBoxX width="100%" justifyContent="flex-start" {...wrapperProps}>
        <DebounceSelect
          allowClear
          labelInValue
          placeholder=""
          loading={isLoading}
          initialOptions={initialOptions}
          fetchOptions={fetchOptions}
          {...props}
          onSelect={(_, option: Option) => {
            onChange?.(option)
            companyVendorListSelectorStore.searchState.search = ''
          }}
          onClear={() => {
            onChange?.(null)
            companyVendorListSelectorStore.searchState.search = ''
          }}
          style={{ width: '100%', ...props?.style }}
        />

        {isCreate && !userStore.isMaterialRequester && (
          <>
            <Tooltip title="Add New Vendor Contact">
              <Button
                data-cy="add-new-vendor-contact"
                style={{ marginLeft: 10 }}
                onClick={() => drawerCreateRef.current.show()}
                icon={<PlusOutlined />}
                disabled={props?.disabled}
              />
            </Tooltip>

            <CreateCompanyVendorContact
              hideNotification
              hideShowSaveAndAddAnotherButton
              onCancel={() => drawerCreateRef.current.close()}
              onFinish={handleAfterCreate}
              ref={drawerCreateRef}
            />
          </>
        )}
      </FlexBoxX>
    )
  },
)
