import React from 'react'

import { PlusOutlined } from '@ant-design/icons'

import { observer } from 'mobx-react-lite'

import { UploaderTitle } from 'common/components/@v2/UploaderTitle'

import { useStores } from 'contractor/hooks/use-stores'

import { INVOICE_EXTRA_FILE_KEY } from '../../context'

export const PdfButton = observer(() => {
  const { uploaderStore } = useStores()

  return (
    <UploaderTitle
      multiple
      uploadKey={INVOICE_EXTRA_FILE_KEY}
      title=""
      btnIcon={<PlusOutlined />}
      btnType="primary"
      accept=".pdf,application/pdf"
      fileList={uploaderStore.fileList(INVOICE_EXTRA_FILE_KEY)}
      onRemoveUpload={uploaderStore.removeUpload}
      onAddNewUpload={uploaderStore.addNewUpload}
      onResetUploads={uploaderStore.resetUploads}
      onSetUploadError={uploaderStore.setUploadError}
      onUpdateUpload={uploaderStore.updateUpload}
    />
  )
})
