import React, { useState } from 'react'

import { Button, Divider, Input } from 'antd'

import { Box } from 'common/components/boxes'

import { ConcreteCommitmentMaterial } from 'contractor/server/commitments'

type FailureReasonsProps = {
  concreteMaterial: ConcreteCommitmentMaterial
  onChange: (concreteMaterial: ConcreteCommitmentMaterial, changes: {}) => void
}

export const FailureReason = (props: FailureReasonsProps) => {
  const { concreteMaterial, onChange } = props
  const [reason, setReason] = useState(concreteMaterial?.failure_reason)

  const setInspectionStatus = () => {
    onChange(concreteMaterial, {
      ...concreteMaterial,
      failure_reason: reason,
    })
  }

  return (
    <>
      <Divider style={{ margin: 0, marginBottom: 8, marginTop: 8 }} />
      <Box width="100%" display="flex">
        <Input
          value={reason}
          onChange={(event) => setReason(event.target.value)}
          placeholder="Reason"
          style={{ flexGrow: 1 }}
        />
        <Button type="primary" style={{ marginLeft: 8 }} onClick={setInspectionStatus}>
          Save
        </Button>
      </Box>
    </>
  )
}
