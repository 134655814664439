import React from 'react'

import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import UnloadWarning from 'common/components/unload_warning'
import { Visibility } from 'common/components/Visibility'

import { ModalConfirmSubmit } from 'contractor/pages/@v2/Commitments/common/components/ConfirmSubmitModal/modal_confirm_submit'
import { Footer } from 'contractor/pages/@v2/Commitments/common/components/Footer/footer'
import { CommitmentHeader } from 'contractor/pages/@v2/Commitments/common/components/Header/commitment_header'
import { useCommitment, withCommitmentProvider } from 'contractor/pages/@v2/Commitments/common/context/context'
import { MaterialsAndOrders } from 'contractor/pages/@v2/Commitments/Material/components/Materials/materials_and_orders'
import { useMaterialCommitment } from 'contractor/pages/@v2/Commitments/Material/hooks/use-material-commitment'

import { Actions } from '../../common/components/Actions/actions'

const Details = () => {
  const { isSubmitting, goBack, isDirty, setShowConfirmSubmitVisible, isDraft, isLoading } = useCommitment()
  const materialCommitmentHandlers = useMaterialCommitment()

  if (isLoading) return <Loading />

  return (
    <Page>
      <CommitmentHeader
        disabled={!isDraft}
        goBack={goBack}
        isSubmitting={isSubmitting}
        handleUpdateCommitment={materialCommitmentHandlers.handleUpdateCommitment}
        handleSaveDraftCommitment={materialCommitmentHandlers.handleSaveDraftCommitment}
        handleDeleteCommitment={materialCommitmentHandlers.handleDeleteCommitment}
      />

      <Page.Content p={0} px={{ _: 0, sm: 16 }} mb={64} py={16}>
        <MaterialsAndOrders />

        <Footer />

        <Visibility.Show breakpoint="lg">
          <Box
            style={{
              position: 'fixed',
              bottom: '80px',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#fff',
              zIndex: 1000,
            }}
          >
            <Actions
              key="actions"
              isLoading={isSubmitting}
              showConfirmSubmit={() => setShowConfirmSubmitVisible(true)}
              disabled={!isDirty}
              onSaveDraft={materialCommitmentHandlers.handleSaveDraftCommitment}
              onUpdate={materialCommitmentHandlers.handleUpdateCommitment}
            />
          </Box>
        </Visibility.Show>

        <UnloadWarning showWarning={() => true} isSubmitting={isSubmitting} />
      </Page.Content>

      <ModalConfirmSubmit
        handleCreateSubmittedCommitment={materialCommitmentHandlers.handleCreateSubmittedCommitment}
        handleSubmitCommitment={materialCommitmentHandlers.handleSubmitCommitment}
        isLoading={isSubmitting}
      />
    </Page>
  )
}

export const MaterialCommitmentDetails = withCommitmentProvider(Details)
