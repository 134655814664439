import React, { useMemo } from 'react'

import styled from '@emotion/styled'

import { DeleteOutlined, EyeOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox, Typography } from 'antd'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import theme from 'common/styles/theme'

type Option = {
  id?: string
  group_name: string
  group_total: number
  quantity_shipped: number
  uom?: string
  unit_price: number
  unit_qty_increment?: number
  extended_price?: number
  checked?: boolean
  calcExtCost?: (...rest) => number
}

type OnAction = (itemId: string) => void

type ColumnActions = {
  isExpanded?: boolean
  onExpand?: (itemId: string) => void
  onDelete?: (itemId: string) => void
}

type Column = {
  id: string
  label?: string | React.ReactNode
  render: (value, option: Option, actions: ColumnActions) => React.ReactNode
  width?: number | string
}

const BadgeGroupName = styled(Box)`
  background-color: ${({ theme }) => theme.colors['gray-4']};
  border-radius: ${({ theme }) => theme.radii.lg};
  padding: 0 4px;
`

type InvoiceGroupItemsProps = {
  children?: React.ReactNode
  group: Option
  onCheck: OnAction
  onEdit: OnAction
  onDelete?: OnAction
  isDisabled?: boolean
  childrenIsExpandable?: boolean
}

const InvoiceGroupItems = ({
  isDisabled,
  group,
  onEdit,
  onCheck,
  onDelete,
  children,
  childrenIsExpandable,
}: InvoiceGroupItemsProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  const handleExpand = React.useCallback(() => {
    if (childrenIsExpandable) {
      setIsExpanded((prev) => !prev)
    }
  }, [childrenIsExpandable, setIsExpanded])

  const COLUMNS: Column[] = useMemo(
    () => [
      {
        id: 'group_name',
        label: (
          <FlexBoxX style={{ gap: 4 }}>
            <Typography.Text>Group name</Typography.Text>
            <BadgeGroupName>{group.group_total} Grouped Items</BadgeGroupName>
            <Button type="text" icon={<EyeOutlined />} onClick={() => onEdit(group.id)} />
          </FlexBoxX>
        ),
        render: (value) => <Typography.Text>{value}</Typography.Text>,
        width: '100%',
      },
      {
        id: 'quantity_shipped',
        label: 'Qty',
        render: (value) => <Typography.Text>{value || '-'}</Typography.Text>,
        width: 100,
      },
      {
        id: 'uom',
        label: 'UOM',
        render: (value) => <Typography.Text>{value || '-'}</Typography.Text>,
        width: 150,
      },
      {
        id: 'unit_price',
        label: 'Unit cost',
        render: (value) => <Typography.Text>{value ? currencyFormatter(value) : '-'}</Typography.Text>,
        width: 150,
      },
      {
        id: 'extended_price',
        label: 'Ext. cost',
        render: (value) => <Typography.Text>{currencyFormatter(value)}</Typography.Text>,
        width: 200,
      },
      {
        id: 'actions',
        render: (_, option, actions) => (
          <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
            {actions?.onExpand && (
              <Button
                icon={actions?.isExpanded ? <MinusOutlined /> : <PlusOutlined />}
                size="small"
                style={{ width: 16, height: 16 }}
                onClick={() => actions.onExpand(option.id)}
              />
            )}

            {actions?.onDelete && (
              <Button
                icon={<DeleteOutlined />}
                type="text"
                size="small"
                style={{ width: 16, height: 16 }}
                onClick={() => actions.onDelete(option.id)}
              />
            )}
          </Box>
        ),
      },
    ],
    [group.group_total, onEdit],
  )

  const renderColumn = React.useCallback(
    (column: Column, option: Option, actions: ColumnActions) => (
      <FlexBoxY key={`${option.group_name}-${column.id}`} alignItems="baseline" width={column.width}>
        <Typography.Text color={theme.colors['gray-8']} style={{ fontSize: 10 }}>
          {column.label}
        </Typography.Text>
        <Typography.Text color={theme.colors['gray-12']}>
          {column.render(option[column.id], option, actions)}
        </Typography.Text>
      </FlexBoxY>
    ),
    [],
  )

  const customChildren = React.useMemo(() => {
    if (childrenIsExpandable) return isExpanded ? children : null
    return children
  }, [childrenIsExpandable, children, isExpanded])

  const expandProps = React.useMemo(() => {
    if (!childrenIsExpandable) return {}
    return { isExpanded, onExpand: childrenIsExpandable && !!children && handleExpand }
  }, [childrenIsExpandable, isExpanded, children, handleExpand])

  return (
    <FlexBoxY>
      <FlexBoxX alignItems="center" width="100%" maxWidth="100%" style={{ gap: 16 }}>
        <Checkbox onChange={() => onCheck(group.id)} checked={group.checked} disabled={isDisabled} />
        {COLUMNS.map((column) => renderColumn(column, group, { onDelete, ...expandProps }))}
      </FlexBoxX>

      {customChildren}
    </FlexBoxY>
  )
}

export { InvoiceGroupItems }
