import React, { useRef } from 'react'

import styled from '@emotion/styled'

import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import { Tooltip, Badge, Form, FormInstance } from 'antd'
import { FormItemProps } from 'antd/lib/form'

import { DebounceSelectProps } from 'common/components/DebounceSelect'
import { DrawerRef } from 'common/components/Drawer'

import { makeCostCodePhaseOption } from 'contractor/components/SelectCostCodePhase'
import { UpsertCostCodePhase } from 'contractor/pages/CompanyMaterials/CostCodes/Upsert/upsert_cost_code_phase'
import { SelectDefaultPhase } from 'contractor/pages/CompanyMaterials/Materials/Customs/select_default_phase'

const FormItemWithIcon = styled(Form.Item)`
  .ant-form-item-label {
    overflow: unset;
  }
`

type FieldCostCodePhaseProps = {
  inputProps?: DebounceSelectProps
  formItemProps?: FormItemProps
  form: FormInstance
}

export const FieldCostCodePhase = ({ inputProps, formItemProps, form }: FieldCostCodePhaseProps) => {
  const createCostCodePhaseRef = useRef<DrawerRef>()

  const onSubmit = (costCodePhase) => form.setFieldsValue({ cost_code_phase: makeCostCodePhaseOption(costCodePhase) })

  return (
    <>
      <UpsertCostCodePhase
        ref={createCostCodePhaseRef}
        onCancel={() => createCostCodePhaseRef.current?.close()}
        onSubmit={onSubmit}
        initialValues={{ active: true }}
      />
      <FormItemWithIcon
        name="cost_code_phase"
        label={
          <Badge
            count={
              <Tooltip
                placement="right"
                title="When this material is ordered we will automatically assign it to this phase code.
            If this material can be assigned to multiple cost codes then don't worry about specifying a default."
              >
                <QuestionCircleOutlined style={{ right: -5 }} />
              </Tooltip>
            }
          >
            <span>Default Phase Code</span>
          </Badge>
        }
        {...formItemProps}
      >
        <SelectDefaultPhase labelInValue {...inputProps} />
      </FormItemWithIcon>
    </>
  )
}
