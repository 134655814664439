import React from 'react'

import { useHistory } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'
import { CommitmentStatuses } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { PlaceOrder } from 'contractor/pages/@v2/Commitments/common/components/Actions/place_order'
import { SaveDraftButton } from 'contractor/pages/@v2/Commitments/common/components/Actions/save_draft_button'
import { SubmitCommitmentButton } from 'contractor/pages/@v2/Commitments/common/components/Actions/submit_commitment_button'
import { UpdateCommitmentButton } from 'contractor/pages/@v2/Commitments/common/components/Actions/update_button'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'

export type ActionsProps = {
  isLoading: boolean
  disabled?: boolean
  showConfirmSubmit: () => void
  onUpdate: () => void
  onSaveDraft: () => void
}

export const Actions = observer<ActionsProps>((props) => {
  const { showConfirmSubmit, isLoading, disabled, onUpdate, onSaveDraft } = props
  const { userStore } = useStores()
  const { project, commitment, selectedCompanyVendor } = useCommitment()

  const commitmentId = commitment?.id
  const history = useHistory()
  const isDeleted = !!commitment?.discarded_at
  const status = commitment?.status || CommitmentStatuses.DRAFT
  const isSubmitted = status !== CommitmentStatuses.DRAFT
  const isCreated = !!commitment?.created_at
  const hidePlaceOrder = commitment?.commitment_type === 'ConcreteCommitment'

  const handleOpenNewOrder = () => {
    history.push(`/orders/new_order?commitment_id=${commitmentId}&project_id=${project?.id}`)
  }

  if (isDeleted) return null

  if (isSubmitted) {
    return (
      <FlexBoxX width="100%" display="flex" flexWrap="wrap" style={{ gap: 8 }}>
        <PlaceOrder disabled={disabled} handleOpenNewOrder={handleOpenNewOrder} hidePlaceOrder={hidePlaceOrder} />
        <UpdateCommitmentButton
          disabled={disabled || !userStore.canEditAndDeleteCommitments}
          handleUpdate={onUpdate}
          isLoading={isLoading}
        />
      </FlexBoxX>
    )
  }

  return (
    <Box display="flex">
      <SaveDraftButton
        disabled={disabled}
        onSaveDraft={isCreated ? onUpdate : onSaveDraft}
        status={status}
        isLoading={isLoading}
      />
      <SubmitCommitmentButton
        handleSubmit={showConfirmSubmit}
        isLoading={isLoading}
        disabled={!selectedCompanyVendor || disabled}
      />
    </Box>
  )
})
