import React from 'react'

import styled from 'styled-components'

import { Tabs, Tag, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY, Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { useTheme } from 'common/hooks/use-theme'

import { useStores } from 'contractor/hooks/use-stores'
import { ShowCompanyMaterialResponse } from 'contractor/server/company_materials'

import { OrderMaterialHistoryTab, QuoteMaterialHistoryTab } from '../PriceHistory'
import { EditableData } from './editable_data'

export type UpdateMaterialProps = {
  hideNotification?: boolean
  editable?: boolean
  onCancel: () => void
  onSubmit?: (response: ShowCompanyMaterialResponse, originalMaterial: ShowCompanyMaterialResponse) => void
  onDuplicate?: (material: ShowCompanyMaterialResponse) => void
  scope?: 'MaterialPage' | 'OrderPage'
}

const StyledTabs = styled(Tabs)`
  .ant-tabs,
  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-tabpane {
    height: 100%;
  }
`

const Title = ({ title, isManagedBySubBase }) => {
  const theme = useTheme()
  return (
    <Box display="flex" alignItems="baseline" flexDirection={{ _: 'column', xs: 'row' }} style={{ columnGap: 20 }}>
      <Typography.Title level={5} style={{ margin: 0 }}>
        {title}
      </Typography.Title>
      {isManagedBySubBase && <Tag color={theme.colors.primary}>Managed By SubBase</Tag>}
    </Box>
  )
}

const insertIf = (condition, ...elements) => (condition ? elements : [])

// HACK: Shouldn't be setting editable to optional and assuming default is true, super weird way to build a component
export const UpdateMaterial = observer<UpdateMaterialProps, DrawerRef>(
  ({ editable, scope = 'MaterialPage', ...props }, ref) => {
    const { companyMaterialStore, userStore } = useStores()

    const companyMaterial = companyMaterialStore.selectedCompanyMaterial

    const showTabs = companyMaterial?.order_materials?.length > 0 && scope === 'MaterialPage'

    const isManagedBySubBase = !!companyMaterial?.manufacturer_material_id || companyMaterial?.company_id === 'CATALOG'

    const editableComponent = (
      <EditableData editable={editable} isManagedBySubBase={isManagedBySubBase} scope={scope} {...props} />
    )

    const showOrderAndQuoteHistory = userStore.canViewHistoricalPricing && scope === 'MaterialPage'

    const tabItems = [
      {
        label: 'Material',
        key: 'material',
        children: editableComponent,
      },
      ...insertIf(showOrderAndQuoteHistory, {
        label: 'Order History',
        key: 'orders',
        children: <OrderMaterialHistoryTab />,
      }),
      ...insertIf(showOrderAndQuoteHistory, {
        label: 'Quote History',
        key: 'quotes',
        children: <QuoteMaterialHistoryTab editable />,
      }),
    ]

    return (
      <Drawer
        data-cy="update-material-drawer"
        ref={ref}
        bgGray
        destroyOnClose
        title={
          <Title
            title={companyMaterial?.requested_by_id ? 'Approve Material' : 'Update Material'}
            isManagedBySubBase={isManagedBySubBase}
          />
        }
        closable={false}
        onClose={() => props?.onCancel()}
      >
        <FlexBoxY
          px={16}
          width="100%"
          minHeight="100%"
          alignItems="flex-start"
          justifyContent="flex-start"
          overflow="auto"
          pb={16}
          pt={showTabs ? 0 : 16}
        >
          {showTabs ? (
            <StyledTabs defaultActiveKey="material" style={{ flexGrow: 1, width: '100%' }} items={tabItems} />
          ) : (
            editableComponent
          )}
        </FlexBoxY>
      </Drawer>
    )
  },
  { forwardRef: true },
)
