import { OrderMaterial } from 'common/server/orders'

export const extractQtyAndUnitsOfOrderMaterials = (order_materials: OrderMaterial[] = []) => {
  return order_materials
    .reduce(
      ([quantities, units], orderMaterial) => [
        quantities.add(orderMaterial?.quantity_delivered ?? orderMaterial?.quantity ?? 0),
        units.add(
          orderMaterial?.company_material?.unit?.name?.trim() ||
            orderMaterial?.company_material?.unit_name?.trim() ||
            '',
        ),
      ],
      [new Set(), new Set()],
    )
    .map((set) => Array.from(set))
}
