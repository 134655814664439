import React from 'react'

import { useHistory } from 'react-router-dom'

import { Dropdown, Popconfirm, DropdownProps } from 'antd'

import { Tool, trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { OrderSubStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import { MergeOrders } from 'contractor/pages/NewQuote/Actions/merge_orders'

import { useNewQuote } from '../context'

type DropdownOptions = {
  orderPackageId: string
  orderType: OrderType
  commitmentId?: string
  onSwitchToOrder: () => void
  onSwitchToRfq: () => void
  onSplit: () => void
} & DropdownProps

export const DropdownOptions = ({
  orderPackageId,
  orderType,
  commitmentId,
  onSwitchToOrder,
  onSwitchToRfq,
  onSplit,
  ...props
}: DropdownOptions) => {
  const { userStore, orderPackageStore, orderStore } = useStores()
  const history = useHistory()
  const { isSpreadsheetMode, toggleSpreadsheetMode } = useNewQuote()
  const showRFQMerge = orderType === 'RFQ' && userStore?.canSendAndUpdateRfqs && !commitmentId
  const showOrderMerge = orderType === 'Order' && userStore?.canSendAndUpdateOrders && !commitmentId
  const isDeleted = [OrderSubStates.DELETED_DRAFT, OrderSubStates.DELETED_REQUEST].includes(
    orderStore?.selectedOrder?.sub_state,
  )
  const isRequest = orderStore?.selectedOrder?.requested_at

  const handleDelete = async () => {
    await orderPackageStore.deleteOrderPackage(orderPackageId)
    history.push('/orders')
  }

  const handleDuplicate = () => {
    orderStore.updateSelectedOrder('internal_comments', [])
    orderStore.updateSelectedOrder('order_number', null)
    orderStore.setDuplicateOrder()
    history.push(`/orders/new_order?duplicate`)
  }

  const getSwitchSpreadsheetModeOption = () => [
    {
      label: (
        <div data-cy="spreadsheet-mode" onClick={() => toggleSpreadsheetMode(!isSpreadsheetMode)}>
          {isSpreadsheetMode && 'Exit '}Spreadsheet Mode
        </div>
      ),
      key: 'spreadsheet-mode',
    },
  ]

  const switchToRfq = () => {
    trackEvent(Events.CLICK_SWITCH_TO_RFQ, window.location.pathname, {}, Tool.AMPLITUDE)
    onSwitchToRfq()
  }

  const switchToOrder = () => {
    trackEvent(Events.CLICK_SWITCH_TO_ORDER, window.location.pathname, {}, Tool.AMPLITUDE)
    onSwitchToOrder()
  }

  const getSwitchOptions = () => {
    if (!userStore.canUseSwitchOrderRFQ || isDeleted) {
      return []
    }

    if (orderType === 'RFQ') {
      return [
        {
          label: (
            <div data-cy="switch-to-order" onClick={switchToOrder}>
              Switch to Order
            </div>
          ),
          key: '1',
        },
      ]
    }

    if (orderType === 'Order') {
      return [
        {
          label: (
            <div data-cy="switch-to-rfq" onClick={switchToRfq}>
              Switch to RFQ
            </div>
          ),
          key: '2',
        },
      ]
    }

    return []
  }

  const getRestoreOrderOption = () => {
    if (!isDeleted) {
      return []
    }

    return [
      {
        label: (
          <Popconfirm
            data-cy="restore-order"
            placement="bottomLeft"
            title="You are restoring this order. Would you like to restore it as an Order or RFQ?"
            onConfirm={() => orderStore.undiscardOrder(true)}
            onCancel={() => orderStore.undiscardOrder(false)}
            okText="Restore as Order"
            cancelText="Restore as RFQ"
          >
            Restore Order
          </Popconfirm>
        ),
        key: 'undiscard_order',
      },
    ]
  }

  //  If no order package then it's not a duplicate and there is nothing to delete
  const getSplitOption = () => {
    if (orderPackageId && userStore.canManageOrders && !isDeleted) {
      return [
        {
          label: (
            <div data-cy={`split-${orderType.toLowerCase()}`} onClick={onSplit}>
              Split {orderType}
            </div>
          ),
          key: '3',
        },
      ]
    }
    return []
  }

  const getMergeRFQOption = () => {
    if (showRFQMerge && !isDeleted) {
      return [
        {
          label: <MergeOrders data-cy="merge-quote" isQuote />,
          key: 'merge-quote',
          disabled: orderStore.anyRequestedMaterial,
        },
      ]
    }
    return []
  }

  const getMergeOrderOption = () => {
    if (showOrderMerge && !isDeleted) {
      return [
        {
          label: <MergeOrders data-cy="merge-order" isQuote={false} />,
          key: 'merge-order',
          disabled: orderStore.anyRequestedMaterial,
        },
      ]
    }
    return []
  }

  const getDuplicateOption = () => {
    if (orderPackageId && !isDeleted) {
      return [
        {
          label: (
            <div data-cy={'duplicate-draft'} onClick={handleDuplicate}>
              {isRequest ? 'Duplicate Request' : 'Duplicate Draft'}
            </div>
          ),
          key: 'duplicate-draft',
        },
      ]
    }
    return []
  }

  const getDeleteOption = () => {
    if (orderPackageId && !isDeleted) {
      return [
        {
          label: (
            <Popconfirm
              data-cy={`delete-${isRequest ? 'request' : 'draft'}`}
              title={`Are you sure you want to delete the ${isRequest ? 'request' : 'draft'}?`}
              onConfirm={() => handleDelete()}
              okText="Yes"
              cancelText="No"
              placement="left"
            >
              <span data-cy="delete-draft">Delete {isRequest ? 'Request' : 'Draft'}</span>
            </Popconfirm>
          ),
          key: '4',
        },
      ]
    }
    return []
  }

  const switchSpreadsheetModeOption = getSwitchSpreadsheetModeOption()
  const switchOptions = getSwitchOptions()
  const restoreOrderOption = getRestoreOrderOption()
  const splitOption = getSplitOption()
  const mergeRFQOptions = getMergeRFQOption()
  const mergeOrderOptions = getMergeOrderOption()
  const duplicateOption = getDuplicateOption()
  const deleteOption = getDeleteOption()

  const items = [
    ...switchSpreadsheetModeOption,
    ...switchOptions,
    ...restoreOrderOption,
    ...splitOption,
    ...mergeRFQOptions,
    ...mergeOrderOptions,
    ...duplicateOption,
    ...deleteOption,
  ]

  if (!items.length) {
    return null
  }

  return (
    <Dropdown.Button
      menu={{ items }}
      trigger={['click', 'hover']}
      buttonsRender={([_, rightButton]) => [null, React.cloneElement(rightButton as React.ReactElement)]}
      data-cy="extra-options"
      {...props}
    />
  )
}
