import React, { useEffect, useState } from 'react'

import { Alert, Form, Typography, Divider, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { ErrorBoundary } from 'common/components/ErrorBoundary'
import { noticeError } from 'common/helpers/new_relic'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'
import { IndexCompanyVendor } from 'contractor/server/company_vendors'
import { VendorUser } from 'contractor/server/vendors'
import { Vendor } from 'contractor/server/vendors'

import { CompanyVendorContactAutoComplete } from './company_vendor_contact_autocomplete'
import { CompanyVendorContactForm } from './company_vendor_contact_form'
import { CompanyVendorForm } from './company_vendor_form'
import { makeCompanyVendorOption, makeVendorOption } from './company_vendor_selector'
import { Option as CompanyVendorSelectorOption } from './company_vendor_selector'
import { GlobalizedVendorCard } from './globalized_vendor_card'
import { makeCompanyVendorContactPayload, makeCompanyVendorPaylod } from './helpers'
import { SubmitActions } from './submit_actions'

export type CreateCompanyVendorContactProps = {
  onFinish?: (newVendor: CompanyVendorContact) => void
  onCancel: () => void
  hideShowSaveAndAddAnotherButton?: boolean
  selectedCompanyVendor?: IndexCompanyVendor
  selectedVendor?: Vendor
  hideNotification?: boolean
  hideTitle?: boolean
  withDrawer?: boolean
}

export const CreateCompanyVendorContact = observer<CreateCompanyVendorContactProps, DrawerRef>(
  (props, ref) => {
    const {
      onFinish,
      onCancel,
      hideShowSaveAndAddAnotherButton,
      hideNotification,
      selectedCompanyVendor,
      selectedVendor,
      hideTitle,
      withDrawer = true,
    } = props
    const { companyVendorStore, vendorStore } = useStores()

    // Get all vendors and company vendors to show on select
    useQuery(vendorStore.getAllVendors)
    // Get all vendors users to show on auto-complete
    useQuery(vendorStore.getAllVendorsUsers)

    const [alertMessage, setAlertMessage] = useState('')
    const [vendorUser, setVendorUser] = useState<VendorUser>(null)
    const [isExistingVendor, setExistingVendor] = useState(true)
    const [contactDomain, setContactDomain] = useState('')
    const [matchVendorBydomain, setMatchVendorByDomain] = useState<CompanyVendorSelectorOption>(null)
    const [isSubmitting, setSubmitting] = useState(false)

    const [form] = Form.useForm()

    const resetComponent = () => {
      setAlertMessage('')
      setSubmitting(false)
      setExistingVendor(true)
      setVendorUser(null)
      setMatchVendorByDomain(null)
      setContactDomain('')
      form.resetFields()
    }

    const handleCancel = () => {
      resetComponent()
      onCancel()
    }

    const handleSubmit = async (isAddAnother = false) => {
      setSubmitting(true)

      try {
        const { companyVendorOrVendor, vendorName, contact, externalVendorId } = await form.validateFields()
        const companyVendor = makeCompanyVendorPaylod({
          companyVendorOrVendor,
          vendorName,
          externalVendorId,
          vendorUser,
        })
        const companyVendorContact = makeCompanyVendorContactPayload({ contact, vendorUser })

        const newVendorContact = await companyVendorStore.createVendorContact(companyVendor, companyVendorContact)

        Promise.all([
          vendorStore.getAllVendors(),
          vendorStore.getAllVendorsUsers(),
          companyVendorStore.companyVendorListStore.fetchRecords(),
        ])

        if (isAddAnother) {
          resetComponent()
        } else {
          handleCancel()
        }

        if (!hideNotification) {
          message.success('Successfully created vendor contact')
        }

        onFinish?.(newVendorContact)
      } catch (error) {
        setAlertMessage(error?.response?.data?.error || 'Unable to create new vendor contact')
        noticeError(error, {
          entry: 'create-vendor-contact',
          data: form.getFieldsValue(),
        })
      } finally {
        setSubmitting(false)
      }
    }

    useEffect(() => {
      if (selectedCompanyVendor) {
        form.setFieldsValue({ companyVendorOrVendor: makeCompanyVendorOption(selectedCompanyVendor) })
      } else if (selectedVendor) {
        form.setFieldsValue({ companyVendorOrVendor: makeVendorOption(selectedVendor) })
      }
    }, [selectedCompanyVendor, selectedVendor])

    const defaultDomain =
      selectedCompanyVendor?.vendor?.domain || selectedCompanyVendor?.domain || selectedVendor?.domain

    const children = (
      <ErrorBoundary isFull={false}>
        <Form
          data-cy="create-company-vendor-contact-form"
          layout="vertical"
          form={form}
          autoComplete="off"
          onValuesChange={(formValues) => {
            if (Object.prototype.hasOwnProperty.call(formValues?.contact || {}, 'email')) {
              const email = formValues.contact.email.toLowerCase().trim()

              if (email && vendorUser && email !== vendorUser?.email) {
                vendorUser && setVendorUser(null)
                matchVendorBydomain && setMatchVendorByDomain(null)
                contactDomain && setContactDomain('')
                form.setFieldsValue({ companyVendorOrVendor: null })
              }

              if (email) {
                const domain = email.split('@').pop()

                if (domain && !selectedCompanyVendor) {
                  setContactDomain(domain)
                }
              }

              form.setFieldsValue({ contact: { email } })
            }

            if (formValues?.companyVendorOrVendor) {
              setMatchVendorByDomain(null)
              setContactDomain('')
            }
          }}
          style={{
            padding: 16,
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflowY: 'auto',
          }}
        >
          <Box width="100%" display="flex" flexDirection="column" alignItems="inherit" justifyContent="flex-start">
            {alertMessage && <Alert type="error" message={alertMessage} style={{ marginBottom: 16, width: '100%' }} />}

            <Form.Item
              name={['contact', 'email']}
              label="Email"
              validateTrigger="onBlur"
              rules={[{ type: 'email', message: 'The input is not valid E-mail!' }]}
              extra={
                vendorUser && (
                  <Alert
                    type="success"
                    message={`Awesome! ${vendorUser?.full_name} is already on SubBase!`}
                    style={{ margin: '8px 0' }}
                  />
                )
              }
            >
              <CompanyVendorContactAutoComplete
                data-cy="company-vendor-contact-autocomplete-email"
                onSelect={(_, option) => setVendorUser(option?.vendorUser)}
                domain={defaultDomain}
                autoFocus
              />
            </Form.Item>

            {vendorUser ? (
              <GlobalizedVendorCard
                vendorName={vendorUser?.vendor?.name}
                vendorDomain={vendorUser?.vendor?.domain}
                vendorDescription={vendorUser?.vendor?.description}
                vendorLogo={vendorUser?.vendor?.logo?.url}
                contactName={vendorUser.full_name}
                contactEmail={vendorUser?.email}
                contactHeadline={vendorUser?.headline}
              />
            ) : (
              <>
                <CompanyVendorContactForm />
                <Divider />
                <CompanyVendorForm
                  isExistingVendor={isExistingVendor}
                  matchVendorBydomain={matchVendorBydomain}
                  disabledRadio={!!selectedCompanyVendor || !!selectedVendor}
                  onRadioChange={() => {
                    form.setFieldsValue({ vendor: null })
                    setMatchVendorByDomain(null)
                    setExistingVendor((prev) => !prev)
                  }}
                  contactDomain={contactDomain}
                  onMatchDomain={setMatchVendorByDomain}
                  disabledCompanyVendorSelector={selectedCompanyVendor || selectedVendor}
                />
              </>
            )}
          </Box>

          <SubmitActions
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            hideShowSaveAndAddAnotherButton={hideShowSaveAndAddAnotherButton}
          />
        </Form>
      </ErrorBoundary>
    )

    if (withDrawer) {
      return (
        <Drawer
          ref={ref}
          bgGray
          onClose={handleCancel}
          title={
            !hideTitle && (
              <Typography.Title level={5} style={{ margin: 0 }}>
                New Vendor Contact
              </Typography.Title>
            )
          }
        >
          {children}
        </Drawer>
      )
    }

    return children
  },
  { forwardRef: true },
)
