import React, { useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import UserOutlined from '@ant-design/icons/UserOutlined'
import { Alert, Button, Form, Input, Divider } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'

import { sign_in } from 'contractor/server/sso'

export const SignInSSO = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [error, setError] = useState(searchParams.get('sso_error') || '')

  const [form] = Form.useForm()

  useEffect(() => {
    if (error) {
      trackEvent(Events.SSO_LOGIN_ERROR, location.pathname, { error })
    }
  }, [error])

  const onValuesChange = (changedValues) => {
    if (changedValues?.email) form.setFieldsValue({ email: changedValues.email.toLowerCase().trim() })
  }

  const onFinish = async (values: { email: string }): Promise<void> => {
    try {
      trackEvent(Events.CLICK_SSO_LOGIN, location.pathname)
      setError('')
      const response = await sign_in(values)
      window.location.href = response.data.redirect_to
    } catch (err) {
      setError(err.response?.data?.error)
    }
  }

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Form layout="vertical" form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
        <Divider>or</Divider>

        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please input your email' },
            { type: 'email', message: 'The input is not a valid e-mail' },
          ]}
        >
          <Input
            data-cy="email"
            prefix={<UserOutlined className="site-form-item-icon" />}
            type="text"
            placeholder="email@subbase.io"
          />
        </Form.Item>

        {error && <Alert style={{ marginBottom: '20px' }} message={error} type="error" />}

        <Form.Item>
          <Button data-cy="submit" block type="default" htmlType="submit" style={{ marginBottom: 8 }}>
            Log with SSO
          </Button>
        </Form.Item>
      </Form>
    </Box>
  )
}
