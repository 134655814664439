import React, { useCallback, useEffect, useState } from 'react'

import { v4 as uuidV4 } from 'uuid'

import { Skeleton } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { reaction, toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { DebounceSelect, DebounceSelectProps } from 'common/components/DebounceSelect'
import { CostCodePhase } from 'common/server/cost_codes/cost_code_phases'

import { useStores } from 'contractor/hooks/use-stores'

import { ApplyToAllEmptyPhaseCodes } from './apply_to_all_empty_phase_codes'
import { makeCostCodePhaseOptionAdvanced } from './helpers'
import { useQueryCostCodePhases } from './hooks'

interface OnChangeApplyToAllProps {
  path: string
  value: string
  index?: number
}

type Option = { value: string; label: React.ReactNode; originalObject?: CostCodePhase }

export type SelectCostCodePhaseAdvancedProps = Partial<Omit<DebounceSelectProps, 'onChange' | 'onBlur'>> & {
  projectId?: string
  projectSpecificPhaseCodesEnabled?: boolean
  onChange?: (option: Nullable<Option>, shouldPropagateValues?: boolean) => void
  onBlur?: (event: React.FocusEvent, applyToAllChecked: boolean) => void
  onChangeApplyToAll?: (props: OnChangeApplyToAllProps) => void
  defaultApplyToAllChecked?: boolean
  initialCostCodePhases?: CostCodePhase[]
}

/*
 Use this component in a list of materials with cost code phase, like: order materials, invoice materials, commitment materials, etc.
*/
export const SelectCostCodePhaseAdvanced = observer<SelectCostCodePhaseAdvancedProps>((props) => {
  const {
    projectId,
    projectSpecificPhaseCodesEnabled,
    defaultApplyToAllChecked,
    onChangeApplyToAll,
    onBlur,
    onChange,
    initialCostCodePhases = [],
    ...rest
  } = props

  const { costCodeStore } = useStores()
  const { isLoading, data = [] } = useQueryCostCodePhases({ projectId, enabled: !initialCostCodePhases.length })

  const [applyToAllChecked, setApplyToAllChecked] = useState(defaultApplyToAllChecked)
  const [options, setOptions] = useState<CostCodePhase[]>([])
  const [searching, setSearching] = useState(false)
  const [key, setKey] = useState<string>(uuidV4())

  const { costCodePhaseListStore } = costCodeStore

  const fetchOptions = useCallback(
    async (search: string) => {
      setSearching(true)
      // We need to search it direct to the API so the dispose action from the store is not called
      // If we don't the re-render of the component will happen and the search is overwritten
      const result = await costCodeStore.searchActivePhaseCodes(projectId, search)
      return toJS(result.data.records).map(makeCostCodePhaseOptionAdvanced)
    },
    [projectId],
  )

  // Add an explicit reaction to track changes in initialCostCodePhases
  useEffect(() => {
    if (initialCostCodePhases.length === 0) return

    if (searching) {
      setSearching(false)
      return
    }

    // Dispose function to clean up the reaction
    const disposeReaction = reaction(
      () => toJS(initialCostCodePhases), // Track the observable
      (newPhases) => {
        setOptions(newPhases)
        // Force component re-render
        setKey(uuidV4())
      },
      {
        fireImmediately: true, // Trigger immediately on setup
        equals: (prev, next) => JSON.stringify(prev) === JSON.stringify(next), // Custom equality check
      },
    )

    // Return cleanup function
    return () => disposeReaction()
  }, [initialCostCodePhases])

  useEffect(() => {
    if (data && data.length > 0) {
      setOptions(data)
    }
  }, [data])

  const handleBlur = useCallback(
    (event: React.FocusEvent) => onBlur?.(event, applyToAllChecked),
    [onBlur, applyToAllChecked],
  )

  const handleChangeApplyForAllEmptyPhaseCodes = (e: CheckboxChangeEvent) => {
    const shouldApplyForAllEmptyCostCodes = e.target.checked

    // it should apply only when is in the first step and the value is not empty
    // otherwhise, it'll trigger a re-render
    // when we don't apply here, it'll be applied when the customer finishes the selection
    if (!!rest?.value && shouldApplyForAllEmptyCostCodes) {
      onChangeApplyToAll({
        path: 'cost_code_phase',
        value: rest?.value?.originalObject,
      })
    }

    setApplyToAllChecked(shouldApplyForAllEmptyCostCodes)
  }

  useEffect(() => {
    if (applyToAllChecked !== defaultApplyToAllChecked) {
      setApplyToAllChecked(defaultApplyToAllChecked)
    }

    return () => setApplyToAllChecked(false)
  }, [defaultApplyToAllChecked])

  if (isLoading) {
    return <Skeleton.Input block active />
  }

  const projectRequired = projectSpecificPhaseCodesEnabled && !projectId

  return (
    <DebounceSelect
      data-cy="select-cost-code-phase-advanced"
      key={key}
      allowClear
      labelInValue
      placeholder="Select Phase"
      loading={isLoading}
      initialOptions={options.map(makeCostCodePhaseOptionAdvanced)}
      fetchOptions={fetchOptions}
      dropdownMatchSelectWidth={250}
      {...rest}
      onBlur={handleBlur}
      onSelect={(_, option: Option) => {
        onChange(option, applyToAllChecked)
        costCodePhaseListStore.searchState.search = ''
      }}
      onClear={() => {
        onChange(null, applyToAllChecked)
        costCodePhaseListStore.searchState.search = ''
      }}
      style={{ width: '100%', ...rest?.style }}
      disabled={projectRequired || rest?.disabled}
      dropdownRender={(menu) => (
        <>
          {menu}
          {!!onChangeApplyToAll && (
            <ApplyToAllEmptyPhaseCodes
              onChangeApplyForAllEmptyPhaseCodes={handleChangeApplyForAllEmptyPhaseCodes}
              disabled={rest?.disabled}
              applyToAllChecked={applyToAllChecked}
            />
          )}
        </>
      )}
    />
  )
})
