import React, { useCallback } from 'react'

import { PlusOutlined, EditOutlined } from '@ant-design/icons'
import { Skeleton, Tooltip, Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DebounceSelect, DebounceSelectProps } from 'common/components/DebounceSelect'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

export type SelectCostCodePhaseProps = Partial<DebounceSelectProps> & {
  onEdit?: () => void
  onAdd?: () => void
}

export const makeCostCodePhaseOption = (costCodePhase) => {
  if (!costCodePhase) {
    return undefined
  }

  return {
    value: costCodePhase.id,
    label: costCodePhase.code,
  }
}

export const SelectCostCodePhase = observer<SelectCostCodePhaseProps>(({ onEdit, onAdd, ...props }) => {
  const { costCodeStore } = useStores()

  const { costCodePhaseListStore } = costCodeStore

  const { isLoading, data = [] } = useQuery(costCodePhaseListStore.fetchRecords)

  const fetchOptions = useCallback((search: string) => {
    return costCodePhaseListStore.setSearch(search).then((data) => data.map(makeCostCodePhaseOption))
  }, [])

  if (isLoading) {
    return <Skeleton.Input block active />
  }

  return (
    <Box width="100%" display="inline-flex" style={{ gap: 10 }}>
      <DebounceSelect
        data-cy="select-cost-code-phase"
        allowClear
        placeholder="Select Phase"
        loading={isLoading}
        // @ts-ignore
        initialOptions={data?.map(makeCostCodePhaseOption)}
        fetchOptions={fetchOptions}
        {...props}
      />
      {onEdit && (
        <Tooltip title="Edit Phase">
          <Button
            data-cy="edit-cost-code-phase-button"
            icon={<EditOutlined />}
            onClick={onEdit}
            disabled={!props?.value}
            style={{ minWidth: 32 }}
          />
        </Tooltip>
      )}
      {onAdd && (
        <Tooltip title="Add New Phase">
          <Button
            data-cy="add-new-cost-code-phase-button"
            icon={<PlusOutlined />}
            onClick={onAdd}
            style={{ minWidth: 32 }}
          />
        </Tooltip>
      )}
    </Box>
  )
})
