import React, { createContext, useContext, useEffect, useState } from 'react'

import { useHistory, useLocation, useParams } from 'react-router-dom'

import { Form, FormInstance } from 'antd'

import { observer } from 'mobx-react-lite'

import { getUnit } from 'common/components/OrderMaterialsV2'
import { calcExtCost } from 'common/helpers/order'
import { useQuery } from 'common/hooks/use-query'
import { CommitmentStatuses } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'
import {
  Commitment,
  CommitmentMaterial,
  CommitmentMaterials,
  ConcreteCommitmentMaterial,
} from 'contractor/server/commitments'
import { IndexCompanyVendor } from 'contractor/server/company_vendors'
import { IndexProject } from 'contractor/server/projects'

const CommitmentContext = createContext<CommitmentContextProps>({} as CommitmentContextProps)

export const useCommitment = () => useContext(CommitmentContext)

type CommitmentContextProps = {
  commitment: Commitment
  selectedCompanyVendor: IndexCompanyVendor
  setSelectedCompanyVendor: React.Dispatch<React.SetStateAction<IndexCompanyVendor>>
  isSubmitting: boolean
  goBack: () => void
  form: FormInstance
  isDraft: boolean
  isDirty: boolean
  setDirty: React.Dispatch<React.SetStateAction<boolean>>
  setShowConfirmSubmitVisible: React.Dispatch<React.SetStateAction<boolean>>
  confirmSubmitVisible: boolean
  calculatedBudgetedTotal: number
  calculatedOrdered: number
  ordersToImport: string[]
  setOrdersToImport: React.Dispatch<React.SetStateAction<string[]>>
  precision: number
  commitmentPayload: Commitment
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  filters: Record<string, unknown>
  setFilters: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
  searchFilteredMaterials: CommitmentMaterials[]
  dateFilteredMaterials: CommitmentMaterials[]
  isLoading: boolean
  project: IndexProject
  setProject: React.Dispatch<React.SetStateAction<IndexProject>>
  calculateConcreteBudgeted: number
  commitmentType: string
  isLoadingCommitment: boolean
}

export const CommitmentProvider = observer(({ children }) => {
  const {
    projectStore,
    companySettingStore,
    unitsStore,
    costCodeStore,
    vendorStore,
    uploaderStore,
    commitmentStore,
    userStore,
  } = useStores()

  const history = useHistory()
  const [form] = Form.useForm()

  const costCodeSettings = companySettingStore.otherSettings.cost_code_settings

  const [selectedCompanyVendor, setSelectedCompanyVendor] = useState<IndexCompanyVendor>(null)
  const [isSubmitting, setSubmitting] = React.useState(false)
  const [isDirty, setDirty] = React.useState(false)
  const [ordersToImport, setOrdersToImport] = React.useState<string[]>([])
  const [commitmentType, setCommitmentType] = React.useState('')
  const [confirmSubmitVisible, setShowConfirmSubmitVisible] = React.useState(false)
  const [search, setSearch] = useState(undefined)
  const [filters, setFilters] = useState({})
  const [searchFilteredMaterials, setSearchFilteredMaterials] = useState<CommitmentMaterials[]>([])
  const [dateFilteredMaterials, setDateFilteredMaterials] = useState<CommitmentMaterials[]>([])
  const [isLoadingCommitment, setIsLoadingCommitment] = React.useState(false)
  const commitment = commitmentStore?.selectedCommitment
  const isDraft = commitment?.status === CommitmentStatuses.DRAFT
  const [project, setProject] = useState()

  const params = new URLSearchParams(useLocation().search)
  // We can use the same precision for both order and commitment since
  // they are very similar in terms of calculations
  const precision = companySettingStore.otherSettings?.rounding_precision_settings?.order_precision || 3

  const { id } = useParams()

  const goBack = () => {
    commitmentStore.listStore?.fetchAllRecords()
    history.push('/commitments')
  }

  useEffect(() => {
    const projectIdParam = params.get('project_id')

    if (projectIdParam && !project) {
      form.setFieldsValue({ projectId: projectIdParam })
    }
  }, [params])

  const calculatedBudgetedTotal = React.useMemo(() => {
    if (commitmentType == 'ConcreteCommitment') return 0

    return commitment?.commitment_materials?.reduce((acc, material: CommitmentMaterial) => {
      const unit = material?.unit
      const option = unit ? unit : getUnit(material.company_material)
      const extendedCost = calcExtCost({
        unitCost: Number(material?.unit_price),
        quantity: Number(material?.quantity),
        multiplier: Number(option?.original?.multiplier),
        qtyIncrement: Number(option?.original?.qty_increment),
        precision,
      })
      return acc + extendedCost
    }, 0)
  }, [commitment?.commitment_materials])

  const calculatedOrdered = React.useMemo(() => {
    if (commitmentType == 'ConcreteCommitment') return 0

    return commitment?.commitment_materials?.reduce((acc, material: CommitmentMaterial) => {
      const unit = material?.unit
      const option = unit ? unit : getUnit(material.company_material)
      const extendedCost = calcExtCost({
        unitCost: Number(material?.unit_price),
        quantity: Number(material?.quantity_ordered),
        multiplier: Number(option?.original?.multiplier),
        qtyIncrement: Number(option?.original?.qty_increment),
        precision,
      })
      return acc + extendedCost
    }, 0)
  }, [commitment?.commitment_materials])

  const calculateConcreteBudgeted = React.useMemo(() => {
    if (commitmentType != 'ConcreteCommitment') return 0

    return commitment?.commitment_materials?.reduce((acc, material: ConcreteCommitmentMaterial) => {
      const extendedCost = Number(material?.unit_price) * Number(material?.estimated_cy)
      const additionalCost = Number(commitment?.fuel_charge || 0) + Number(commitment?.environmental_charge || 0)
      const totalCost = Number(extendedCost) + Number(additionalCost)

      return acc + totalCost
    }, 0)
  }, [
    commitment?.commitment_materials,
    commitment?.fuel_charge,
    commitment?.environmental_charge,
    commitment?.commitment_materials?.length,
  ])

  const loadCommitment = async () => {
    commitmentStore.selectedCommitment = null
    await commitmentStore.loadCommitment(id)
    commitmentStore?.commitmentSubscribe(id, userStore?.userId)

    setCommitmentType(commitmentStore.selectedCommitment?.commitment_type)
    form.setFieldsValue({
      commitmentName: commitmentStore.selectedCommitment?.commitment_name,
      projectId: commitmentStore.selectedCommitment?.project_id,
      commitmentNumber: commitmentStore.selectedCommitment?.commitment_number,
      expirationDate: commitmentStore.selectedCommitment?.expiration_date,
      tags: commitmentStore.selectedCommitment?.tags ? commitmentStore.selectedCommitment?.tags : [],
      watchers: commitmentStore.selectedCommitment?.watchers?.map((watcher) => ({
        value: watcher.id,
        label: watcher.full_name,
      })),
    })

    setSelectedCompanyVendor(commitmentStore.selectedCommitment?.company_vendor as IndexCompanyVendor)
    if (id) {
      setIsLoadingCommitment(true)
      commitmentStore.selectedCommitment = null
      commitmentStore
        .loadCommitment(id)
        .then(() => {
          setCommitmentType(commitmentStore.selectedCommitment?.commitment_type)
          form.setFieldsValue({
            commitmentName: commitmentStore.selectedCommitment?.commitment_name,
            projectId: commitmentStore.selectedCommitment?.project_id,
            commitmentNumber: commitmentStore.selectedCommitment?.commitment_number,
            expirationDate: commitmentStore.selectedCommitment?.expiration_date,
            watchers: commitmentStore.selectedCommitment?.watchers?.map((watcher) => ({
              value: watcher.id,
              label: watcher.full_name,
            })),
          })
          if (commitmentStore.selectedCommitment?.tags) {
            form.setFieldsValue({ tags: commitmentStore.selectedCommitment.tags })
          }

          setSelectedCompanyVendor(commitmentStore.selectedCommitment?.company_vendor as IndexCompanyVendor)
        })
        .finally(() => {
          setIsLoadingCommitment(false)
        })
    }
  }

  const maybeResetCompanyMaterialId = (commitmentMaterial: CommitmentMaterial) => {
    let currentCommitmentMaterial = commitmentMaterial

    if (
      !!commitmentMaterial?.unit &&
      commitmentMaterial?.unit?.value === commitmentMaterial?.unit?.label &&
      commitmentMaterial?.unit?.label !== commitmentMaterial?.company_material?.unit_name
    ) {
      currentCommitmentMaterial = {
        ...currentCommitmentMaterial,
        company_material: {
          ...currentCommitmentMaterial.company_material,
          unit_name: commitmentMaterial?.unit?.label,
          unit_id: null,
          id: null,
        },
      }
    }

    if (
      !!commitmentMaterial?.unit &&
      commitmentMaterial?.unit?.value !== commitmentMaterial?.unit?.label &&
      commitmentMaterial?.unit?.value !== commitmentMaterial?.company_material?.unit_id
    ) {
      currentCommitmentMaterial = {
        ...currentCommitmentMaterial,
        company_material: {
          ...currentCommitmentMaterial.company_material,
          unit_name: null,
          unit_id: currentCommitmentMaterial?.unit?.value,
          id: null,
        },
      }
    }

    return currentCommitmentMaterial
  }

  const commitmentPayload = React.useMemo(() => {
    const formValues = form.getFieldsValue()
    return {
      id: id == 'new' ? null : id,
      commitment_name: formValues['commitmentName'],
      project_id: formValues['projectId'],
      commitment_number: formValues['commitmentNumber'],
      company_vendor_id: selectedCompanyVendor?.id,
      expiration_date: formValues['expirationDate'],
      commitment_materials: commitmentStore?.selectedCommitment?.commitment_materials?.map(maybeResetCompanyMaterialId),
      tags: formValues['tags'],
      internal_comments: commitmentStore?.selectedCommitment?.internal_comments,
      watcher_ids: commitmentStore?.selectedCommitment?.watcher_ids,
      attachments_files_signed_ids: uploaderStore?.signedIds('attachments'),
      attachments_files_delete_ids: uploaderStore?.deleteAttachmentIds['attachments'],
      orders_to_import: ordersToImport,
      commitment_type: commitmentType,
    } as Commitment
  }, [
    form.getFieldsValue(),
    selectedCompanyVendor,
    commitmentStore?.selectedCommitment,
    uploaderStore?.signedIds('attachments'),
    uploaderStore?.deleteAttachmentIds['attachments'],
  ])

  const createEmptyCommitment = () => {
    commitmentStore.userSubscribe(userStore?.userId)
    setSearch('')
    setFilters({})
    setDateFilteredMaterials([])
    setCommitmentType(window.location.pathname.includes('concrete') ? 'ConcreteCommitment' : 'MaterialCommitment')
    commitmentStore.selectedCommitment = {
      commitment_materials: [],
      commitment_name: '',
      commitment_number: '',
      internal_comments: [],
      tags: [],
      status: CommitmentStatuses.DRAFT,
    } as Commitment
  }

  const searchableMaterial = (material: ConcreteCommitmentMaterial) => {
    return `${material.company_material.description} ${material.spacing} ${material.pump} ${material.pump_hours} ${material.notes} ${material.company_material.mix_design} ${material.company_material.mix_code} ${material.phase}`
  }

  useEffect(() => {
    const filteredBySearch = commitmentStore.selectedCommitment?.commitment_materials.filter(
      (material: ConcreteCommitmentMaterial) =>
        !(search === undefined) ? searchableMaterial(material).toLowerCase().includes(search.toLowerCase()) : true,
    )

    const filteredByDate = filteredBySearch?.filter((material: ConcreteCommitmentMaterial) => {
      const matchesStartDate = filters['date_start']
        ? new Date(material.schedule) >= new Date(filters['date_start'])
        : true

      const matchesEndDate = filters['date_end'] ? new Date(material.schedule) <= new Date(filters['date_end']) : true

      return matchesStartDate && matchesEndDate
    })

    setSearchFilteredMaterials(filteredBySearch)
    setDateFilteredMaterials(filteredByDate)
  }, [search, filters, commitmentStore.selectedCommitment?.commitment_materials])

  const handleFirstLoad = async () => {
    if (id && id !== 'new') {
      await loadCommitment()
    } else {
      createEmptyCommitment()
    }
  }

  useEffect(() => {
    return () => {
      createEmptyCommitment()
    }
  }, [])

  const fetchPhaseCodes = () => {
    costCodeStore.costCodePhaseListStore.setFilter('active', true, true, true)

    if (costCodeSettings?.project_specific_phase_codes_enabled) {
      costCodeStore.costCodePhaseListStore.setFilter('project_id', project?.id, true, true)
    }
    return costCodeStore.costCodePhaseListStore.fetchRecords()
  }

  useEffect(() => {
    fetchPhaseCodes()
  }, [project?.id])

  const { isLoading } = useQuery(() => {
    return Promise.all<unknown>([
      handleFirstLoad(),
      projectStore.maybeIndexProjects(),
      costCodeStore.costCodePhaseListStore.fetchRecords(),
      companySettingStore.indexCompanyMaterialConfiguration(),
      companySettingStore.indexOtherSettings(),
      costCodeStore.fetchAllActiveCostCodes(),
      fetchPhaseCodes(),
      unitsStore.maybeUnits(),
      vendorStore.getAllVendors(),
      projectStore.maybeIndexProjects(),
    ])
  })

  return (
    <CommitmentContext.Provider
      value={{
        setSelectedCompanyVendor,
        selectedCompanyVendor,
        isSubmitting,
        goBack,
        form,
        isDraft,
        isDirty,
        setDirty,
        setShowConfirmSubmitVisible,
        confirmSubmitVisible,
        commitment,
        calculatedBudgetedTotal,
        calculatedOrdered,
        ordersToImport,
        setOrdersToImport,
        precision,
        commitmentPayload,
        setSubmitting,
        search,
        setSearch,
        filters,
        setFilters,
        dateFilteredMaterials,
        searchFilteredMaterials,
        isLoading,
        project,
        setProject,
        calculateConcreteBudgeted,
        commitmentType,
        isLoadingCommitment,
      }}
    >
      {children}
    </CommitmentContext.Provider>
  )
})

export const withCommitmentProvider = (Component) => (props) => {
  return (
    <CommitmentProvider>
      <Component {...props} />
    </CommitmentProvider>
  )
}
