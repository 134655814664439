import React, { useMemo } from 'react'

import { Empty } from 'antd'

import { Box } from 'common/components/boxes'
import { TableStyled } from 'common/components/EditableTable'

import { useStores } from 'contractor/hooks/use-stores'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'
import { ColumnsProps } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/LogView/columns_definition'
import { ConcreteCommitmentMaterial } from 'contractor/server/commitments'

import { EditableRow } from './editable_row'
import { EditableCell } from './EditableCell/editable_cell'

type LogViewProps = {
  columns: ColumnsProps[]
}

export const LogView = (props: LogViewProps) => {
  const { columns } = props
  const { searchFilteredMaterials } = useCommitment()
  const { commitmentStore } = useStores()

  const filteredColumns = useMemo(() => {
    return columns.filter((column) => column.visible)
  }, [columns])

  const dataSource = useMemo(() => {
    return searchFilteredMaterials
      ?.filter((material: ConcreteCommitmentMaterial) => !!material.release_date)
      .map((material) => ({ ...material, key: material.id }))
  }, [searchFilteredMaterials])

  const handleSave = (row: ConcreteCommitmentMaterial) => {
    const { id, ...rest } = row
    commitmentStore.updateMaterialById(id, rest)
  }

  const totalWidth = useMemo(() => {
    return filteredColumns.reduce((sum, column) => sum + Number(column.width), 0)
  }, [filteredColumns])

  const mergedColumns = filteredColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: ConcreteCommitmentMaterial) => ({
        record,
        visible: col.visible,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        inputType: col.inputType || 'text',
      }),
    }
  })

  if (!dataSource?.length) {
    return (
      <Box p={16}>
        <Empty />
      </Box>
    )
  }

  return (
    <Box width={'100%'}>
      <TableStyled
        rowClassName="editable-row"
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={dataSource}
        scroll={{ x: totalWidth }}
      />
    </Box>
  )
}
