import React, { useRef } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import styled from '@emotion/styled'

import { Badge, Tabs } from 'antd'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'

import { CommitmentsInvoices } from 'contractor/pages/@v2/Commitments/common/components/Invoices/commitment_invoices'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'
import { ExtraActions } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/extra_actions'
import { AttachInvoiceDrawer } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/Invoices/attach_invoice_drawer'
import { AdditionalCostsDrawer } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/MixList/additional_costs_drawer'
import { PriceIncreaseDrawer } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/MixList/price_increase_drawer'
import {
  columns as baseColumns,
  ColumnsProps,
} from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/LogView/columns_definition'
import { LogView } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/LogView/log_view'
import { LookAhead } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/LookAhead/look_ahead'
import { MixList } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/MixList/mix_list'

const insertIf = (condition, ...elements) => (condition ? elements : [])

type AttachmentsProps = {
  disabled?: boolean
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0 !important;
  }
`

export const ConcreteCommitmentMaterials = (props: AttachmentsProps) => {
  const { disabled } = props
  const history = useHistory()
  const location = useLocation()
  const { commitment, form } = useCommitment()
  const [isAdditionalCostDrawerVisible, setIsAdditionalCostDrawerVisible] = React.useState(false)
  const [isPriceIncreaseDrawerVisible, setIsPriceIncreaseDrawerVisible] = React.useState(false)
  const [columns, setColumns] = React.useState<ColumnsProps[]>(baseColumns)
  const attachInvoicesDrawerRef = useRef<DrawerRef>()
  const isCreated = commitment?.created_at
  const source = window.location.pathname

  const handleAdditionCosts = () => {
    setIsAdditionalCostDrawerVisible(!isAdditionalCostDrawerVisible)
  }

  const handlePriceIncrease = () => {
    setIsPriceIncreaseDrawerVisible(!isPriceIncreaseDrawerVisible)
  }

  const handleAttachInvoice = () => {
    attachInvoicesDrawerRef.current?.show()
  }

  const toggleColumnVisibility = React.useCallback((dataIndex: string | string[]) => {
    setColumns((prev) => prev.map((col) => (col.dataIndex === dataIndex ? { ...col, visible: !col.visible } : col)))
  }, [])

  const toggleColumnEditable = React.useCallback((dataIndex: string | string[]) => {
    setColumns((prev) => prev.map((col) => (col.dataIndex === dataIndex ? { ...col, editable: !col.editable } : col)))
  }, [])

  const activeKey = new URLSearchParams(location.search).get('commitment_tab') || 'mix_list'
  const tabItems = [
    {
      label: (
        <Badge style={{ zIndex: 900 }} count={commitment?.commitment_materials?.length} offset={[4, 0]} color="blue">
          <Box onClick={() => trackEvent(Events.CLICK_COMMITMENTS_TAB, source)} paddingX="8">
            Mix List
          </Box>
        </Badge>
      ),
      key: 'mix_list',
      children: <MixList disabled={disabled} />,
    },
    ...insertIf(isCreated, {
      label: 'Look ahead',
      key: 'look_ahead',
      children: <LookAhead />,
    }),
    ...insertIf(isCreated, {
      label: 'Log',

      key: 'log',
      children: <LogView columns={columns} />,
    }),
    ...insertIf(isCreated, {
      label: (
        <Badge style={{ zIndex: 900 }} count={commitment?.invoice_count} offset={[4, 0]} color="blue">
          Invoices
        </Badge>
      ),
      key: 'invoices',
      children: <CommitmentsInvoices />,
    }),
  ]

  return (
    <>
      <StyledTabs
        destroyInactiveTabPane={false}
        tabBarExtraContent={
          <ExtraActions
            columns={columns}
            toggleColumnVisibility={toggleColumnVisibility}
            toggleColumnEditable={toggleColumnEditable}
            tabKey={activeKey}
            disabled={disabled}
            onAdditionCosts={handleAdditionCosts}
            onPriceIncrease={handlePriceIncrease}
            onAttachInvoice={handleAttachInvoice}
          />
        }
        activeKey={activeKey}
        onChange={(key) => {
          const searchParams = new URLSearchParams(history.location.search)
          searchParams.set('commitment_tab', key)
          history.push({ search: searchParams.toString() })
        }}
        items={tabItems}
      />
      <AdditionalCostsDrawer
        visible={isAdditionalCostDrawerVisible}
        onClose={() => setIsAdditionalCostDrawerVisible(false)}
        title="Additional costs"
        commitment={commitment}
      />
      <PriceIncreaseDrawer
        visible={isPriceIncreaseDrawerVisible}
        onClose={() => setIsPriceIncreaseDrawerVisible(false)}
        title="Price increase"
        form={form}
      />
      <AttachInvoiceDrawer ref={attachInvoicesDrawerRef} onClose={() => attachInvoicesDrawerRef.current?.close()} />
    </>
  )
}
