import React from 'react'

import PlusOutlined from '@ant-design/icons/PlusOutlined'
import { Button, Dropdown, Space } from 'antd'
import { MenuProps } from 'antd/lib/menu'

import { Visibility } from 'common/components/Visibility'

type MixListExtraContentProps = {
  disabled?: boolean
  onAdditionCosts: () => void
  onPriceIncrease: () => void
}

export const MixListActions = (props: MixListExtraContentProps) => {
  const { disabled, onAdditionCosts, onPriceIncrease } = props
  const items: MenuProps['items'] = [
    {
      label: 'Additional costs',
      key: 'additional_costs',
      onClick: onAdditionCosts,
    },
    {
      label: 'Price increase',
      key: 'price_increase',
      onClick: onPriceIncrease,
    },
  ]

  const menuProps: MenuProps = {
    items,
  }

  return (
    <>
      <Visibility.Hidden breakpoint={'md'}>
        <Space>
          <Button disabled={disabled} onClick={onAdditionCosts}>
            Additional costs
          </Button>
          <Button disabled={disabled} onClick={onPriceIncrease}>
            Price increase
          </Button>
        </Space>
      </Visibility.Hidden>
      <Visibility.Show breakpoint={'md'}>
        <Dropdown menu={menuProps}>
          <Button type="primary" style={{ marginLeft: 8, marginRight: 8 }}>
            <PlusOutlined />
          </Button>
        </Dropdown>
      </Visibility.Show>
    </>
  )
}
