import React, { useEffect, useRef, useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { message, Select, Button, Divider } from 'antd'
import { SelectProps } from 'antd/lib/select'

import { observer } from 'mobx-react-lite'

import {
  useManageGroupDrawer,
  ManageGroupDrawerRef,
  ManageGroupDrawerForm,
  ManageGroupDrawer,
} from 'contractor/components/ManageGroupDrawer'
import { useStores } from 'contractor/hooks/use-stores'

import { SelectProjectGroupOption } from './select_project_group_option'

type SelectProjectGroupProps = SelectProps<Array<string> | string>

export const SelectProjectGroup = observer<SelectProjectGroupProps>((props) => {
  const { value, onClick, onBlur, onChange, mode } = props

  const [isOpen, setIsOpen] = useState(false)
  const { projectGroupsStore, userStore } = useStores()

  const drawerCreateGroupRef = useRef<ManageGroupDrawerRef>()
  const drawerUpdateGroupRef = useRef<ManageGroupDrawerRef>()
  const manageGroupDrawer = useManageGroupDrawer()

  async function handleOpenCreateDrawer() {
    setIsOpen(false)

    drawerCreateGroupRef.current?.show()
  }

  async function handleOpenUpdateModal(id: string) {
    try {
      const group = await manageGroupDrawer.defaultValuesFactory(id)
      drawerUpdateGroupRef.current?.show(group)
      setIsOpen(false)
    } catch (e) {
      message.error('Sorry, the project could not be identified. Please try selecting it again.')
    }
  }

  function handleCloseModal() {
    drawerCreateGroupRef.current?.close()
    drawerUpdateGroupRef.current?.close()
    projectGroupsStore.indexProjectGroups()
  }

  async function handleCreateGroup(values: ManageGroupDrawerForm) {
    try {
      const result = await projectGroupsStore.createProjectGroups({
        name: values.name,
        project_ids: values.selectedProjectsIds,
        company_user_ids: values.selectedCompanyUsersIds,
      })

      const group = result.data

      message.success('Successfully added new group')

      if (values.selectedCompanyUsersIds.includes(userStore.companyUserId)) {
        userStore.showUser()
      }

      handleCloseModal()

      const parsedValue = mode === 'multiple' ? [...(value || []), group.id] : group.id
      onChange(parsedValue, { value: values.id, label: values.name })
    } catch (error) {
      console.error('Project group creation error', error)
      message.error(`Unable to create project group`)
    }
  }

  async function handleUpdateGroup(values: ManageGroupDrawerForm) {
    try {
      await projectGroupsStore.updateProjectGroups(values.id, {
        name: values.name,
        project_ids: values.selectedProjectsIds,
        company_user_ids: values.selectedCompanyUsersIds,
      })

      message.success('Successfully updated group')

      if (values.selectedCompanyUsersIds.includes(userStore.companyUserId)) {
        userStore.showUser()
      }

      handleCloseModal()

      const shouldChange = mode === 'multiple' ? !value?.includes(values.id) : value !== values.id

      if (shouldChange) {
        const parsedValue = mode === 'multiple' ? [...(value || []), values.id] : values.id
        onChange(parsedValue, { value: values.id, label: values.name })
      }
    } catch (error) {
      message.error(`Unable to update project group`)
    }
  }

  function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setIsOpen(true)
    if (props.onClick) onClick(e)
  }

  function handleBlur(e: React.FocusEvent<HTMLElement, Element>) {
    // HACK: once antd select ref does not work properly
    // we need to check if the click was on the add new item button
    // and open the drawer
    if (e.target.id === 'add-new-item') {
      handleOpenCreateDrawer()
    }

    setIsOpen(false)
    if (props.onBlur) onBlur(e)
  }

  useEffect(() => {
    Promise.all([
      manageGroupDrawer.dataLoaders.projects(),
      manageGroupDrawer.dataLoaders.users(),
      projectGroupsStore.indexProjectGroups(),
    ])
  }, [])

  return (
    <>
      <Select
        aria-label="select-project-group"
        placeholder="Select a group"
        data-cy="project-group"
        aria-autocomplete="none"
        open={isOpen}
        onClick={handleClick}
        onBlur={handleBlur}
        {...props}
        onChange={props.onChange}
        showArrow
        dropdownRender={(menu) => (
          <>
            {menu}

            <Divider style={{ margin: 0 }} />
            <Button
              data-cy="add-new-project-group-button"
              id="add-new-item"
              style={{ width: '100%' }}
              type="text"
              icon={<PlusOutlined />}
            >
              Add item
            </Button>
          </>
        )}
      >
        {projectGroupsStore.projectGroups.map((group) => {
          return (
            <Select.Option key={group.id} value={group.id}>
              <SelectProjectGroupOption handleEdit={() => handleOpenUpdateModal(group.id)}>
                {group.name}
              </SelectProjectGroupOption>
            </Select.Option>
          )
        })}
      </Select>

      <ManageGroupDrawer
        ref={drawerCreateGroupRef}
        onClose={handleCloseModal}
        projects={manageGroupDrawer.projects}
        users={manageGroupDrawer.users}
        onSubmit={handleCreateGroup}
      />

      <ManageGroupDrawer
        ref={drawerUpdateGroupRef}
        onClose={handleCloseModal}
        projects={manageGroupDrawer.projects}
        users={manageGroupDrawer.users}
        onSubmit={handleUpdateGroup}
      />
    </>
  )
})
