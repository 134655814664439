import React, { useEffect, useState } from 'react'

import { DirectUpload } from 'activestorage'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { CameraOutlined, InboxOutlined } from '@ant-design/icons'
import { Button, message, Modal, Upload, UploadFile } from 'antd'
import ImgCrop from 'antd-img-crop'
import { RcFile, UploadProps } from 'antd/lib/upload'
import { isImageUrl } from 'antd/lib/upload/utils'

import { Box } from 'common/components/boxes'
import { noticeError } from 'common/helpers/new_relic'

// Tried using the prebuilt library  but was running into too many issues
//https://github.com/cbothner/react-activestorage-provider

export interface FileControl {
  onUpdateUpload?: (uploadKey: string, uid: string, precent: number, signed_id: string) => void
  onResetUploads?: (uploadKey: string) => void
  onAddNewUpload?: (uploadKey: string, directUpload: DirectUpload, file: File) => void
  onSetUploadError?: (uploadKey: string, uid: string) => void
  onRemoveUpload?: (uploadKey: string, uid: string) => void
  fileList: UploadProps['fileList']
  uploadKey: string
}

// https://cameronbothner.com/activestorage-beyond-rails-views/
// https://edgeguides.rubyonrails.org/active_storage_overview.html#direct-upload-javascript-events
// TODO: We are adding blobs then not necessarily attaching them if the Order doesn't go through. Will need to periodically run
// ActiveStorage::Blob.unattached.each(&:purge)
type UploaderProps = FileControl &
  Pick<UploadProps, 'onPreview'> & {
    disabled?: boolean
    multiple?: boolean
    onChange?: () => void
    noResetUploads?: boolean
    listType?: 'text' | 'picture' | 'picture-card'
    accept?: string
    hasCamera?: boolean
    component?: 'Upload' | 'Dragger'
    showDownload?: boolean
    showRemove?: boolean
    hasFileNameTimeStamp?: boolean
    showPreviewModal?: boolean
    modalTitle?: string
    withCrop?: boolean
    hideUploadButton?: boolean
    itemRender?: (
      originNode: React.ReactNode,
      file: UploadFile,
      fileList: { object: [] },
      actions: {},
    ) => React.ReactNode
    textContent?: React.ReactNode
  }

const componentSelector = {
  Upload: Upload,
  Dragger: Upload.Dragger,
}

const Wrapper = styled.div`
  width: 100%;
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    position: relative;
  }

  .ant-upload-select.ant-upload-select-text {
    width: 100%;
    border: 1px dashed ${({ theme }) => theme.colors['gray-35']};
    border-radius: 2px;
    cursor: pointer;
    text-align: center;
  }
`

const Uploader: React.FC<UploaderProps> = ({
  accept,
  disabled,
  multiple,
  onChange,
  uploadKey,
  noResetUploads,
  hasCamera,
  component = 'Dragger',
  showDownload,
  showRemove = true,
  hasFileNameTimeStamp,
  onUpdateUpload,
  onResetUploads,
  onAddNewUpload,
  onSetUploadError,
  onRemoveUpload,
  fileList,
  showPreviewModal,
  onPreview,
  modalTitle,
  withCrop,
  hideUploadButton,
  textContent,
  itemRender,
  ...props
}) => {
  const handleProgress = (event, uid) => {
    const percent = (event.loaded / event.total) * 100
    onUpdateUpload?.(uploadKey, uid, percent, '')
  }

  const [fileToDeleteId, setFileToDeleteId] = useState(null)
  const theme = useTheme()

  useEffect(() => {
    !noResetUploads && onResetUploads?.(uploadKey)
  }, [noResetUploads, uploadKey, onResetUploads])

  const handlePreview = async (file) => {
    onPreview?.(file)

    window.open(file.url, '_blank')
  }

  const handleUpload = (request) => {
    const uid = request.file['uid']

    let file = request.file

    if (hasFileNameTimeStamp) {
      /*
        Create a new File with a time stamp name:
        exmaple: 2022-02-25T00:41:42.032Z.png
      */
      const format = request.file?.name?.split('.').pop()
      const fileName = new Date().toISOString()
      const fileWithTimestampName = new File([request.file], `${fileName}.${format}`, {
        type: request.file.type,
        lastModified: request.file.lastModified,
      })
      fileWithTimestampName['uid'] = uid

      file = fileWithTimestampName
    }

    const directUpload = new DirectUpload(file, '/rails/active_storage/direct_uploads', {
      directUploadWillStoreFileWithXHR: (request) => {
        request?.upload.addEventListener('progress', (event) => handleProgress(event, uid))
      },
    })

    // If we aren't allowing multiple uploads we must remove the existing upload (no need to handle)
    // deletes, we will do the purging server side
    if (!multiple) {
      onResetUploads?.(uploadKey)
    }

    onAddNewUpload?.(uploadKey, directUpload, file)

    directUpload.create((error, blob) => {
      if (error) {
        onSetUploadError?.(uploadKey, uid)
        noticeError(error, { entry: 'direct-upload' })
      } else {
        onUpdateUpload?.(uploadKey, uid, 100, blob.signed_id)
      }
    })
  }

  const handleRemove = () => {
    onRemoveUpload?.(uploadKey, fileToDeleteId)
    setFileToDeleteId(null)
  }
  const beforeUpload = (file: RcFile) => {
    if (!accept) {
      return true
    }

    const acceptableTypesWithoutSpace = accept.replace(/\s+/g, '')

    const acceptableTypesArray = acceptableTypesWithoutSpace.split(',')

    if (!acceptableTypesArray.includes(file.type)) {
      const acceptableTypesList = acceptableTypesWithoutSpace
        .replace(/image\//g, '')
        .split(',')
        .join('/')
        .toUpperCase()
      message.error(`You can only upload ${acceptableTypesList} file!`)
      return false
    }

    return true
  }

  const commonProps = {
    multiple,
    fileList,
    ...(showPreviewModal && { onPreview: handlePreview }),
    ...props,
  }

  const Comp = componentSelector[component]

  const content = (
    <>
      <Comp
        disabled={disabled}
        itemRender={itemRender}
        onRemove={(file) => {
          setFileToDeleteId(file.uid)
        }}
        onChange={onChange}
        accept={accept}
        customRequest={handleUpload}
        showUploadList={{
          showDownloadIcon: showDownload,
          showRemoveIcon: showRemove,
        }}
        beforeUpload={beforeUpload}
        isImageUrl={(file) => {
          return isImageUrl({ ...file, type: file?.originFileObj?.type })
        }}
        {...commonProps}
      >
        {!hideUploadButton && (
          <>
            <Box height="50px">
              {component === 'Dragger' ? (
                <InboxOutlined style={{ fontSize: 24, color: theme.colors.highlightBlue }} />
              ) : (
                <InboxOutlined />
              )}

              {textContent ? (
                <p className="ant-upload-text">{textContent}</p>
              ) : (
                <p className="ant-upload-text">{component === 'Dragger' ? 'Drag to upload' : 'Upload'}</p>
              )}
            </Box>

            {hasCamera && (
              <Box position="absolute" top="-16px" right="-16px">
                <Button type="primary" shape="circle" icon={<CameraOutlined />} />
              </Box>
            )}
          </>
        )}
        {props.children}
      </Comp>
      <Modal
        title="Are you sure you want to delete this file?"
        visible={!!fileToDeleteId}
        onOk={handleRemove}
        okText={'Delete'}
        okType={'danger'}
        onCancel={() => {
          setFileToDeleteId(null)
        }}
      >
        This action cannot be undone.
      </Modal>
    </>
  )

  if (withCrop) {
    return (
      <Wrapper>
        <ImgCrop rotate modalTitle={modalTitle} beforeCrop={beforeUpload}>
          {content}
        </ImgCrop>
      </Wrapper>
    )
  }

  return <Wrapper>{content}</Wrapper>
}

export default Uploader
