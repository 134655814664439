import React from 'react'

import { Button, DatePicker, Form, InputNumber, Select, Switch } from 'antd'

import { Box } from 'common/components/boxes'
import { Visibility } from 'common/components/Visibility'
import { DeliveryIssuesType, OrderMaterial } from 'common/server/orders'

type IssueSelectFormProps = {
  orderMaterial: OrderMaterial
  handleFinish: (
    values: { quantity_delivered: number; back_ordered_date?: string; isNewIssue?: boolean },
    id: string,
  ) => void
  isLocked?: boolean
  isNewIssue?: boolean
  showResolve?: boolean
  setLocked?: (value: boolean) => void
  handleSwitchIssue?: (id: string, hasIssue: boolean) => void
  deliveryIssues?: DeliveryIssuesType[]
  onCancelEdit?: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any
}

export const IssueSelectForm = (props: IssueSelectFormProps) => {
  const { orderMaterial, handleFinish, isLocked, showResolve, onCancelEdit, deliveryIssues, form } = props

  return (
    <Box>
      <Form.Item label="Delivery Issues" name={`delivery_issues_${orderMaterial.id}`} style={{ marginBottom: 16 }}>
        <Select
          style={{ width: '100%', marginLeft: 8 }}
          mode="multiple"
          showArrow
          disabled={isLocked}
          allowClear
          showSearch={false}
          placeholder="Select the problem"
        >
          <Select.Option value={DeliveryIssuesType.MISSING}>Missing</Select.Option>
          <Select.Option value={DeliveryIssuesType.INCORRECT}>Incorrect</Select.Option>
          <Select.Option value={DeliveryIssuesType.BROKEN}>Broken</Select.Option>
          <Select.Option value={DeliveryIssuesType.BACKORDERED}>Backordered</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item hidden name={`isNewIssue_${orderMaterial.id}`} />

      {deliveryIssues?.length > 0 && (
        <>
          <Visibility.Hidden breakpoint="md">
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Form.Item
                label="Delivered Quantity"
                name={`quantity_delivered_${orderMaterial.id}`}
                rules={[{ required: true, type: 'number', message: 'Quantity is required' }]}
                style={{ flex: 1, marginRight: 8 }}
              >
                <InputNumber
                  disabled={isLocked}
                  max={orderMaterial?.quantity}
                  placeholder="0"
                  style={{ width: '100%' }}
                />
              </Form.Item>

              {deliveryIssues?.includes(DeliveryIssuesType.BACKORDERED) && (
                <Form.Item
                  name={`back_ordered_date_${orderMaterial.id}`}
                  label="Backordered Date"
                  style={{ flex: 1, marginRight: 8 }}
                >
                  <DatePicker disabled={isLocked} placeholder="" style={{ width: '100%' }} />
                </Form.Item>
              )}

              {showResolve && (
                <Form.Item
                  name={`resolved_${orderMaterial.id}`}
                  valuePropName="checked"
                  style={{ flex: 1, marginRight: 8 }}
                >
                  <Switch size="small" />
                </Form.Item>
              )}

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onCancelEdit} disabled={isLocked} type="ghost" style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button
                  onClick={() => handleFinish(form.getFieldsValue(), orderMaterial.id)}
                  disabled={isLocked}
                  type="primary"
                >
                  Confirm
                </Button>
              </div>
            </div>
          </Visibility.Hidden>

          <Visibility.Show breakpoint="md">
            <Form.Item
              label="Delivered Quantity"
              name={`quantity_delivered_${orderMaterial.id}`}
              rules={[{ type: 'number', message: 'Quantity is required' }]}
              style={{ marginBottom: 16 }}
            >
              <InputNumber max={orderMaterial?.quantity} placeholder="0" style={{ width: '100%', marginLeft: 8 }} />
            </Form.Item>

            {deliveryIssues?.includes(DeliveryIssuesType.BACKORDERED) && (
              <Form.Item
                name={`back_ordered_date_${orderMaterial.id}`}
                label="Backordered Date"
                style={{ marginBottom: 16 }}
              >
                <DatePicker placement="topLeft" style={{ width: '100%', marginLeft: 8 }} />
              </Form.Item>
            )}

            {showResolve && (
              <Form.Item
                valuePropName="checked"
                name={`resolved_${orderMaterial.id}`}
                label="Resolved"
                style={{ marginBottom: 16 }}
              >
                <Switch size="small" style={{ marginLeft: 9 }} />
              </Form.Item>
            )}
          </Visibility.Show>
        </>
      )}
    </Box>
  )
}
