import React from 'react'

import styled from '@emotion/styled'

import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { Box } from 'common/components/boxes'

import { ConcreteCommitmentMaterial } from 'contractor/server/commitments'

const SwitchButtonWrapper = styled(Button)<{ hasIssues?: boolean; isActive?: boolean }>`
  padding: 4px 12px;
  border-color: ${({ isActive, hasIssues }) => (isActive ? (hasIssues ? 'red' : 'green') : 'default')};

  &:hover,
  &:focus,
  &:active {
    border-color: ${({ hasIssues }) => (hasIssues ? 'red' : 'green')};
  }
`

type InspectionStatusProps = {
  concreteMaterial: ConcreteCommitmentMaterial
  onChange: (concreteMaterial: ConcreteCommitmentMaterial, changes: {}) => void
}

export const InspectionStatus = (props: InspectionStatusProps) => {
  const { concreteMaterial, onChange } = props

  const setInspectionStatus = (status: boolean) => {
    onChange(concreteMaterial, {
      ...concreteMaterial,
      inspection_status: status,
    })
  }

  return (
    <Box display="flex">
      <SwitchButtonWrapper
        hasIssues={false}
        disabled={!!concreteMaterial.release_date}
        isActive={concreteMaterial.inspection_status}
        onClick={() => setInspectionStatus(true)}
        type="default"
      >
        <CheckOutlined style={{ fontSize: 12, color: 'green' }} />
      </SwitchButtonWrapper>

      <SwitchButtonWrapper
        hasIssues={true}
        disabled={!!concreteMaterial.release_date}
        isActive={!concreteMaterial.inspection_status}
        onClick={() => setInspectionStatus(false)}
        type="default"
      >
        <CloseOutlined style={{ fontSize: 12, color: 'red' }} />
      </SwitchButtonWrapper>
    </Box>
  )
}
