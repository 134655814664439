import React, { forwardRef } from 'react'

import { v4 as uuid_v4 } from 'uuid'

import { Select } from 'antd'
import { RefSelectProps, SelectProps } from 'antd/lib/select'

import { CostCode } from 'common/server/cost_codes/cost_codes'

export type Option = {
  value: string
  label: React.ReactNode
  costCode?: CostCode
}

export type SelectPhaseCodeProps = {
  costCodes: CostCode[]
  projectId?: string
  costCodeSettings: {
    required: boolean
    project_filtering_enabled: boolean
  }
  isLoading?: boolean
  isCommitment?: boolean
  onChange?: (value: Option) => void
  onBlur?: (event: React.FocusEvent) => void
} & Omit<SelectProps<Option>, 'onChange' | 'onBlur'>

const filterByProject = (costCode: CostCode, projectId: string) => {
  return !costCode.project_ids.length || (!!projectId && costCode.project_ids.includes(projectId))
}

const getSearchable = (costCode: CostCode) => {
  return `${costCode.phase_code}:${costCode?.phase_code_description || ''}`
}

export const makePhaseCodeValue = (costCode) => {
  if (!costCode) {
    return undefined
  }

  return {
    costCode,
    value: costCode.id,
    label: costCode.phase_code_description || costCode.phase_code,
  }
}

export const SelectPhaseCode = forwardRef<RefSelectProps, SelectPhaseCodeProps>(
  (
    {
      costCodes: costCodesProp = [],
      projectId,
      value: valueProp,
      onChange,
      costCodeSettings,
      isLoading,
      style,
      disabled,
      autoFocus,
      placeholder,
      onBlur,
      onClick,
      isCommitment,
    },
    ref,
  ) => {
    const selectId = React.useRef(uuid_v4())
    const [value, setValue] = React.useState<Option>(valueProp)
    const [phaseCodes, setPhaseCodes] = React.useState([])

    React.useEffect(() => {
      setValue(valueProp)
    }, [valueProp])

    React.useEffect(() => {
      const filteredCostCodes = costCodeSettings?.project_filtering_enabled
        ? costCodesProp.filter((costCode) => filterByProject(costCode, projectId))
        : costCodesProp

      const uniquePhaseCodes = filteredCostCodes.reduce((acc, current) => {
        const exists = acc.find((item) => item.phase_code === current.phase_code)
        if (!exists && current.phase_code) {
          acc.push(current)
        }
        return acc
      }, [])

      setPhaseCodes(uniquePhaseCodes)
    }, [costCodesProp.length, costCodeSettings, projectId])

    const projectRequired = costCodeSettings?.project_filtering_enabled && !projectId

    const handleClear = () => {
      setValue(null)
      onChange(null)
    }

    const options = phaseCodes.map((costCode) => {
      const phaseCodeValue = makePhaseCodeValue(costCode)
      return {
        ...phaseCodeValue,
        searchable: getSearchable(costCode),
      }
    })

    return (
      <Select<Option>
        onBlur={onBlur}
        onClick={onClick}
        ref={ref}
        id={selectId.current}
        loading={isLoading}
        style={{ width: '100%', ...style }}
        showSearch
        filterOption={(input, option) => {
          const inputValue = input.toLowerCase()
          return option?.searchable?.toLowerCase().includes(inputValue)
        }}
        autoFocus={autoFocus}
        disabled={disabled || projectRequired || isCommitment}
        placeholder={placeholder ?? 'Select Phase Code'}
        allowClear
        dropdownMatchSelectWidth={250}
        aria-autocomplete="none"
        showAction={['click', 'focus']}
        value={value}
        onClear={handleClear}
        onSelect={(_, option: Option) => {
          setValue(option)
          onChange(option)
        }}
        options={options}
      />
    )
  },
)
