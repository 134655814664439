import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { omit } from 'lodash'

import { Alert, Button, Col, Divider, Form, Input, Row } from 'antd'
import { FormProps } from 'antd/lib/form'

import { observer } from 'mobx-react-lite'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'

import { useNotifyCompanyMaterial } from 'contractor/hooks/use-notify-company-material'
import { useStores } from 'contractor/hooks/use-stores'
import { FieldCostCode } from 'contractor/pages/CompanyMaterials/Materials/Fields/field_cost_code'
import { FieldCostCodePhase } from 'contractor/pages/CompanyMaterials/Materials/Fields/field_cost_code_phase'
import { getErrorMessages } from 'contractor/pages/CompanyMaterials/Materials/utils'
import { ShowCompanyMaterialResponse } from 'contractor/server/company_materials'

type CreateMaterialProps = {
  title?: string
  onCancel: () => void
  onSubmit?: (response: ShowCompanyMaterialResponse) => void
  initialValues?: FormProps['initialValues']
  hideNotification?: boolean
}

export const CreateMaterial = observer<CreateMaterialProps, DrawerRef>(
  (props, ref) => {
    const { title = 'Add New Concrete Material', onSubmit, onCancel, hideNotification, initialValues } = props

    const location = useLocation()
    const source = location.pathname.substr(1)
    const { companyMaterialStore, companySettingStore, userStore } = useStores()
    const [alertMessage, setAlertMessage] = useState('')
    const [isSubmitting, setSubmitting] = useState(false)
    const costCodeEnabled = companySettingStore?.companyAttributes?.some((attribute) => attribute == 'cost_code_id')
    const { notifyCompanyVendor } = useNotifyCompanyMaterial()
    const [form] = Form.useForm()

    useEffect(() => {
      form.setFieldsValue({
        ...initialValues,
      })
    }, [initialValues])

    const handleFinish = async (formValues) => {
      const companyMaterial = omit(formValues, ['cost_code'])
      companyMaterial.created_from = 'COMPANY_MATERIAL_ADD_MODAL'
      setSubmitting(true)

      try {
        const response = await companyMaterialStore.create({
          ...companyMaterial,
          description: `${companyMaterial?.mix_code} - ${companyMaterial?.mix_design}`,
          cost_code_id: formValues?.cost_code?.value,
          cost_code_phase_id: formValues?.cost_code_phase?.value,
          mix_code: formValues?.mix_code,
          mix_design: formValues?.mix_design,
          material_type: 'ConcreteMaterial',
        })
        form.resetFields()
        setAlertMessage('')
        onSubmit?.(response)
        if (!hideNotification) {
          notifyCompanyVendor({ companyMaterialId: response?.id })
        }
      } catch (error) {
        const errorMessage = getErrorMessages(error)
        setAlertMessage(errorMessage)
      } finally {
        setSubmitting(false)
        trackEvent(Events.ADD_MATERIAL, source)
      }
    }

    const handleCancel = () => {
      setAlertMessage('')
      form.resetFields()
      onCancel()
    }

    return (
      <Drawer aria-label="create-material-drawer" ref={ref} bgGray title={title} onClose={handleCancel}>
        <Box width="100%" p={16} overflow="auto">
          {alertMessage && <Alert type="error" message={alertMessage} style={{ marginBottom: '20px' }} />}

          <Form layout="vertical" form={form} style={{ width: '100%' }} onFinish={handleFinish}>
            <Row gutter={20}>
              <Col xs={24} sm={12}>
                <Form.Item name="mix_design" label="Mix Design">
                  <Input style={{ width: '100%' }} disabled={!userStore.canCreateNewMaterial} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item name="mix_code" label="Mix Code">
                  <Input style={{ width: '100%' }} disabled={!userStore.canCreateNewMaterial} />
                </Form.Item>
              </Col>

              {costCodeEnabled && (
                <React.Fragment>
                  <Col xs={24} sm={12}>
                    <FieldCostCode
                      data-cy="material-cost-code-input"
                      inputProps={{
                        isCreate: true,
                        disabled: !userStore.canCreateNewMaterial,
                      }}
                    />
                  </Col>
                  {companySettingStore.otherSettings?.cost_code_settings?.independent_phase_codes_enabled && (
                    <Col xs={24} sm={12}>
                      <FieldCostCodePhase
                        form={form}
                        inputProps={{
                          disabled: !userStore.canCreateNewMaterial,
                        }}
                      />
                    </Col>
                  )}
                </React.Fragment>
              )}
            </Row>

            <Divider />

            <Box display="flex" justifyContent="space-between">
              <Button onClick={handleCancel} loading={isSubmitting} style={{ width: 100 }}>
                Cancel
              </Button>

              <Button
                data-cy="material-save-button"
                htmlType="submit"
                type="primary"
                loading={isSubmitting}
                style={{ width: 100 }}
              >
                Create
              </Button>
            </Box>
          </Form>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
