import { OrderMaterial } from 'common/server/orders'

import { extractQtyAndUnitsOfOrderMaterials } from '..'

describe('[helpers: extractQtyAndUnitsOfOrderMaterials]', () => {
  it('should return empty arrays when no materials provided', () => {
    const [quantities, units] = extractQtyAndUnitsOfOrderMaterials()
    expect(quantities).toEqual([])
    expect(units).toEqual([])
  })

  it('should extract unique quantities and units from order materials', () => {
    const materials = [
      {
        quantity_delivered: 10,
        quantity: 15,
        company_material: {
          unit: { name: ' EA ' },
        },
      },
      {
        quantity_delivered: 10,
        quantity: 20,
        company_material: {
          unit: { name: ' EA ' },
        },
      },
    ] as OrderMaterial[]

    const [quantities, units] = extractQtyAndUnitsOfOrderMaterials(materials)
    expect(quantities).toEqual([10])
    expect(units).toEqual(['EA'])
  })

  it('should fallback to quantity when quantity_delivered is undefined', () => {
    const materials = [
      {
        quantity: 20,
        company_material: {
          unit: { name: 'EA' },
        },
      },
    ] as OrderMaterial[]

    const [quantities] = extractQtyAndUnitsOfOrderMaterials(materials)
    expect(quantities).toEqual([20])
  })

  it('should fallback to unit_name when unit is undefined', () => {
    const materials = [
      {
        quantity: 20,
        company_material: {
          unit_name: ' EA ',
        },
      },
    ] as OrderMaterial[]

    const [, units] = extractQtyAndUnitsOfOrderMaterials(materials)
    expect(units).toEqual(['EA'])
  })

  it('should handle null or undefined values', () => {
    const materials = [
      {
        quantity: null,
        company_material: {},
      },
    ] as OrderMaterial[]

    const [quantities, units] = extractQtyAndUnitsOfOrderMaterials(materials)
    expect(quantities).toEqual([0])
    expect(units).toEqual([''])
  })
})
