import React, { useState } from 'react'

import { flatten } from 'lodash'

import styled from '@emotion/styled'

import { Empty, Table, TableProps } from 'antd'

import { observer } from 'mobx-react-lite'

import { EditableTable } from 'common/components/EditableTable'
import { InvoiceMaterial } from 'common/server/invoice'

import { InvoiceOrderMaterial } from 'contractor/components/InvoiceOrderMaterial'
import { useStores } from 'contractor/hooks/use-stores'
import { ShowOrderResponse } from 'contractor/server/orders'

import { useInvoice } from '../context'
import { ExpandButton } from './expand_button'
import { ExtraCosts } from './extra_costs'

export enum DraggableType {
  ORDER_MATERIAL,
  INVOICE_MATERIAL,
}

const Wrapper = styled.div`
  height: 100%;
  .ant-table-summary .ant-table-cell {
    background-color: ${({ theme }) => theme.colors['gray-2']};
  }

  .ant-table-summary tr:last-child td {
    border-bottom: 0;
  }

  .ant-table-thead {
    position: sticky;
    top: 0 !important;
    z-index: 1;
  }
`

type InnerWrapperProps = {
  fixedLayout?: boolean
  children: React.ReactNode
}

export const InnerWrapper: React.FC<InnerWrapperProps> = ({ fixedLayout, children }) => {
  if (fixedLayout) {
    return <Wrapper>{children}</Wrapper>
  }
  return <>{children}</>
}

type InvoiceMaterialsProps = {
  // TODO: Apply correct type when the feature is ready
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expandableColumns?: any
  onFilterTable?: (dataSource: InvoiceMaterial) => void
  showExpandable?: boolean
  showFooter?: boolean
  empty?: React.ReactNode
  rowSelection?: TableProps<InvoiceMaterial>['rowSelection']
  components?: TableProps<InvoiceMaterial>['components']
  fixedLayout?: boolean
}

export const InvoiceMaterials = observer<InvoiceMaterialsProps>(
  ({
    columns,
    onFilterTable,
    expandableColumns,
    showExpandable,
    showFooter,
    fixedLayout,
    empty: emptyComponent,
    ...tableProps
  }) => {
    const { invoiceStore } = useStores()

    const {
      setSelectedInvoiceDirty,
      invoiceOrderMaterialDrawerRef,
      selectedOrderIdOnDrawer,
      taxAmountField,
      shippingCostField,
      discountAmountField,
      otherCostsField,
      calcTaxSplitBulk,
      taxLineItemsEnabled,
    } = useInvoice()

    const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([])

    const invoice = invoiceStore.invoice

    const dataSource = calcTaxSplitBulk({
      invoiceMaterials: invoice?.invoice_materials,
      taxAmount: taxAmountField,
      taxLineItemsEnabled,
    })

    const handleToggleExpandRowKeys = () => {
      if (expandedRowKeys.length) {
        setExpandedRowKeys([])
      } else {
        const columnsKeys = dataSource.map((invoiceMaterial) => invoiceMaterial.id)
        if (showFooter) {
          setExpandedRowKeys([...columnsKeys, 'Tax', 'Shipping', 'Discount', 'Other'])
        } else {
          setExpandedRowKeys(columnsKeys)
        }
      }
    }

    const order = invoiceStore.selectedOrders?.find(({ order }) => order.id === selectedOrderIdOnDrawer)?.order

    // Map the unsaved changes to the drawer to show changes without the user needing to save the invoice
    const mapDataToInvoiceOrderMaterialDrawer = () => {
      if (!order) return

      const allInvoiceOrderMaterials = flatten(
        invoice?.invoice_materials?.map((invoiceMaterial) =>
          invoiceMaterial.order_materials?.map((orderMaterial) => ({
            ...orderMaterial,
            invoice_material: { ...invoiceMaterial, invoice_id: invoice?.id, invoice_number: invoice?.number },
          })),
        ),
      )

      const orderMaterials = allInvoiceOrderMaterials.filter((orderMaterial) => orderMaterial?.order_id === order?.id)

      return {
        ...order,
        order_materials: order?.order_materials?.map((orderMaterial) => {
          return {
            ...orderMaterial,
            invoice_materials: orderMaterials.map((orderMaterial) => orderMaterial.invoice_material),
          }
        }),
        invoices: order?.invoices?.map((currentInvoice) => {
          if (currentInvoice?.id === invoice.id) {
            return {
              ...currentInvoice,
              invoice_materials: invoice?.invoice_materials?.map((invoiceMaterial) => ({
                ...invoiceMaterial,
                order_materials_count: invoiceMaterial?.order_materials?.length || 0,
              })),
              tax_amount: taxAmountField,
              shipping_cost: shippingCostField,
              discount_amount: discountAmountField,
              other_costs: otherCostsField,
              total_amount: invoice?.total_amount?.toString(),
            }
          }

          return currentInvoice
        }) as ShowOrderResponse['invoices'],
      }
    }

    return (
      <InnerWrapper fixedLayout={fixedLayout}>
        <InvoiceOrderMaterial order={mapDataToInvoiceOrderMaterialDrawer()} ref={invoiceOrderMaterialDrawerRef} />

        <EditableTable<InvoiceMaterial>
          data-cy="invoice-materials-table"
          rowKey="id"
          locale={{
            emptyText: emptyComponent || (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No extracted line items." />
            ),
          }}
          onMove={(orderMaterial, invoiceMaterial) => {
            const invoiceMaterials = dataSource.map((currentInvoiceMaterial) => {
              if (currentInvoiceMaterial.id === invoiceMaterial?.id) {
                return {
                  ...currentInvoiceMaterial,
                  cost_code: currentInvoiceMaterial?.cost_code
                    ? currentInvoiceMaterial?.cost_code
                    : orderMaterial?.cost_code,
                  cost_code_phase: currentInvoiceMaterial?.cost_code_phase
                    ? currentInvoiceMaterial?.cost_code_phase
                    : orderMaterial?.cost_code_phase,
                  order_materials: [...(currentInvoiceMaterial?.order_materials || []), orderMaterial],
                }
              }

              return currentInvoiceMaterial
            })

            invoiceStore.updateSelectedInvoice('invoice_materials', invoiceMaterials)
            setSelectedInvoiceDirty(true)
          }}
          columns={showExpandable ? [...columns, Table.EXPAND_COLUMN] : columns}
          dataSource={onFilterTable ? dataSource.filter(onFilterTable) : dataSource}
          pagination={false}
          size="small"
          expandable={
            showExpandable
              ? {
                  columnTitle: () => {
                    if (dataSource.some((invoiceMaterial) => !!invoiceMaterial?.order_materials?.length)) {
                      return (
                        <ExpandButton
                          onToggleExpandRowKeys={handleToggleExpandRowKeys}
                          hasExpandedRowKeys={!!expandedRowKeys.length}
                        />
                      )
                    }
                  },
                  onExpandedRowsChange: (expandedRows) => setExpandedRowKeys(expandedRows as React.Key[]),
                  expandedRowKeys,
                  expandRowByClick: true,
                  expandedRowRender: (invoiceMaterial) => (
                    <Table
                      rowKey="id"
                      showHeader={false}
                      pagination={false}
                      size="small"
                      dataSource={invoiceMaterial?.order_materials}
                      columns={expandableColumns}
                    />
                  ),
                  rowExpandable: (invoiceMaterial) => !!invoiceMaterial?.order_materials?.length,
                }
              : undefined
          }
          {...tableProps}
        />
        {showFooter && <ExtraCosts />}
      </InnerWrapper>
    )
  },
)
