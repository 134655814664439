import React, { memo } from 'react'

import { Checkbox } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'
import { UnreconciledInvoiceResponse } from 'common/server/invoice'

import { InvoiceCard } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/Invoices/invoice_card'

type InvoiceOptionsProps = {
  dataSource: UnreconciledInvoiceResponse[]
  onSelect: (invoice: UnreconciledInvoiceResponse) => void
  setSelectedInvoices: React.Dispatch<React.SetStateAction<UnreconciledInvoiceResponse[]>>
}

export const InvoiceOptions = memo((props: InvoiceOptionsProps) => {
  const { dataSource, onSelect, setSelectedInvoices } = props

  if (!dataSource || dataSource.length === 0) {
    return null
  }

  const handleCheckboxChange = (isChecked: boolean, invoice: UnreconciledInvoiceResponse) => {
    if (isChecked) {
      setSelectedInvoices((prev) => [...prev, invoice])
    } else {
      setSelectedInvoices((prev) => prev.filter((inv) => inv.id !== invoice.id))
    }
  }

  return (
    <Box overflow="auto" width="100%" height="100%">
      {dataSource.map((invoice) => (
        <React.Fragment key={invoice.id}>
          <FlexBoxX width="100%">
            <Checkbox
              onChange={(e) => handleCheckboxChange(e.target.checked, invoice)}
              style={{ marginLeft: 8, marginRight: 4 }}
            />
            <InvoiceCard key={invoice.id} invoice={invoice} onSelect={() => onSelect(invoice)} />
          </FlexBoxX>
        </React.Fragment>
      ))}
    </Box>
  )
})
