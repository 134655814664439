import React from 'react'

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import theme from 'common/styles/theme'

type Option = {
  id: string
  description?: string
  quantity_shipped: number
  uom?: string
  unit_price: number
  unit_multiplier?: number
  unit_qty_increment?: number
  calcExtCost: (...rest) => number
}

type ColumnActions = {
  onDelete?: (itemId: string) => void
}

type Column = {
  id: string
  label?: string
  render: (value, option: Option, actions: ColumnActions) => React.ReactNode
  width?: number | string
}

const COLUMNS: Column[] = [
  {
    id: 'description',
    label: 'Invoice Description',
    render: (value) => value,
    width: '100%',
  },
  {
    id: 'quantity_shipped',
    label: 'Qty',
    render: (value) => value || '-',
    width: 100,
  },
  {
    id: 'uom',
    label: 'UOM',
    render: (value) => value || '-',
    width: 150,
  },
  {
    id: 'unit_price',
    label: 'Unit cost',
    render: (value) => currencyFormatter(value),
    width: 150,
  },
  {
    id: 'ext_price',
    label: 'Ext. cost',
    render: (_, option) =>
      currencyFormatter(
        option.calcExtCost({
          unitCost: option?.unit_price,
          quantity: option?.quantity_shipped,
          multiplier: option?.unit_multiplier,
          qtyIncrement: option?.unit_qty_increment,
        }),
      ),
    width: 200,
  },
  {
    id: 'actions',
    render: (_, option, actions) => (
      <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
        {actions?.onDelete && (
          <Button
            icon={<DeleteOutlined />}
            type="text"
            size="small"
            style={{ width: 16, height: 16 }}
            onClick={() => actions.onDelete(option.id)}
          />
        )}
      </Box>
    ),
  },
]

type InvoiceItemOrderProps = {
  onDelete: (itemId: string) => void
  items: Option[]
}

export const InvoiceItemOrder = ({ onDelete, items = [] }: InvoiceItemOrderProps) => {
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      borderTop={`1px solid ${theme.colors['gray-5']}`}
      mt={16}
      style={{ gap: 16 }}
    >
      <Box display="flex" flex={1} justifyContent="space-between" alignItems="center" pt={16}>
        <Typography.Text color={theme.colors['gray-8']} style={{ fontSize: 10 }}>
          Order Items
        </Typography.Text>

        <Button size="small" type="link" icon={<PlusOutlined />} style={{ fontSize: 12, height: 20 }}>
          Add from order
        </Button>
      </Box>

      {items.map((orderItem) => (
        <Box
          display="flex"
          key={orderItem.id}
          style={{ gap: 8 }}
          alignItems="center"
          backgroundColor={theme.colors['gray-2']}
          p={8}
          pl={32}
        >
          {COLUMNS.map((column) => (
            <Box key={column.id} style={{ width: column.width }}>
              {column.render(orderItem[column.id], orderItem, { onDelete })}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  )
}
