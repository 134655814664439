import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'

export const ErrorState = ({ error = null }) => {
  const errorMessage = error?.response?.data?.error || error?.message

  return (
    <Box justifyContent="center" alignContent="center" height="100%" textAlign="center">
      <Typography.Title level={4}>Oops! Something went wrong.</Typography.Title>

      {errorMessage ? (
        <Typography.Text>{errorMessage}</Typography.Text>
      ) : (
        <Typography.Text>
          If you are seeing this screen repeatedly, please contact:
          <Typography.Link href="mailto: help@subbase.io">help@subbase.io</Typography.Link>
        </Typography.Text>
      )}
    </Box>
  )
}
