import React, { useState } from 'react'

import { CloseOutlined } from '@ant-design/icons'

import { observer } from 'mobx-react-lite'

import { Drawer, DrawerRef } from 'common/components/Drawer'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { UnreconciledInvoiceResponse } from 'common/server/invoice'

import { Stepper } from 'contractor/components/Stepper/stepper'
import { useStores } from 'contractor/hooks/use-stores'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'
import { InvoiceDetails } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/Invoices/invoice_details'
import { SelectInvoiceStep } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/Invoices/select_invoice_step'
import { useConcreteCommitment } from 'contractor/pages/@v2/Commitments/Concrete/hooks/use-concrete-commitment'

type AttachInvoiceDrawerProps = {
  onClose: () => void
}
export const AttachInvoiceDrawer = observer<AttachInvoiceDrawerProps, DrawerRef>(
  (props, ref) => {
    const { onClose } = props
    const { invoiceStore, commitmentStore } = useStores()
    const { commitment } = useCommitment()
    const commitmentHandlers = useConcreteCommitment()

    const [unreconciledInvoiceList, setUnreconciledInvoiceList] = useState<UnreconciledInvoiceResponse[]>([])
    const [isLoadingUnreconciledInvoiceList, setIsLoadingUnreconciledInvoiceList] = useState(false)
    const [currentInvoice, setCurrentInvoice] = useState<UnreconciledInvoiceResponse | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const currentStep = currentInvoice ? 2 : 1

    const isMobile = useMediaQuery('md')

    const fetchUnreconciledInvoices = async () => {
      try {
        setIsLoadingUnreconciledInvoiceList(true)
        const data = await invoiceStore.getUnreconciledInvoices({})
        setUnreconciledInvoiceList(data)
        setIsLoadingUnreconciledInvoiceList(false)
      } catch (error) {
        commitmentHandlers.handleLogError(error)
      }
    }

    const resetCurrentInvoice = () => {
      setCurrentInvoice(null)
    }

    const attachInvoices = async (invoiceIds: string[]) => {
      try {
        setIsLoading(true)
        await invoiceStore?.attachInvoicesToCommitment(invoiceIds, commitment.id)
        await fetchUnreconciledInvoices()
        await commitmentStore.loadCommitment(commitment.id)
      } catch (error) {
        commitmentHandlers.handleLogError(error)
      } finally {
        resetCurrentInvoice()
        setIsLoading(false)
      }
    }

    const handleCloseDrawer = () => {
      onClose()
      setIsLoading(false)
      resetCurrentInvoice()
    }

    return (
      <Drawer
        ref={ref}
        title="Attach invoice"
        push={false}
        closable={false}
        onClose={handleCloseDrawer}
        afterOpenChange={(open) => {
          if (open) {
            fetchUnreconciledInvoices()
          }
        }}
        extra={<CloseOutlined onClick={handleCloseDrawer} />}
        width={isMobile ? '100%' : 800}
        destroyOnClose
      >
        <Stepper currentStep={currentStep} style={{ display: 'flex', width: '200%' }}>
          <SelectInvoiceStep
            isLoading={isLoading}
            onBack={resetCurrentInvoice}
            handleSelectInvoice={setCurrentInvoice}
            onAttachInvoices={attachInvoices}
            unreconciledInvoiceList={unreconciledInvoiceList}
            isLoadingUnreconciledInvoiceList={isLoadingUnreconciledInvoiceList}
          />

          <InvoiceDetails
            selectedInvoice={currentInvoice}
            onAttachInvoices={attachInvoices}
            loading={isLoading}
            onBack={resetCurrentInvoice}
          />
        </Stepper>
      </Drawer>
    )
  },
  { forwardRef: true },
)
