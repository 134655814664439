import React from 'react'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { CloudUploadOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { UploadFile } from 'antd/lib/upload'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import Uploader from 'common/components/uploader'
import { CustomUploadItem } from 'common/components/uploader/custom_upload_item'

import { useStores } from 'contractor/hooks/use-stores'

type DeliveryDetailsUploaderProps = {
  filesKey: string
}

const UploaderWrapper = styled(Uploader)`
  .ant-upload.ant-upload-select {
    width: 100%;
    margin-bottom: 0;
  }
`

export const DeliveryDetailsUploader = observer((props: DeliveryDetailsUploaderProps) => {
  const { filesKey } = props
  const theme = useTheme()
  const { uploaderStore } = useStores()

  return (
    <Form.Item style={{ padding: 16, width: '100%', marginBottom: 0 }} name="files">
      <UploaderWrapper
        component="Upload"
        showPreviewModal
        hideUploadButton
        multiple
        itemRender={(
          _originNode,
          file,
          _fileList,
          actions: { remove: (file: UploadFile) => void; download: (file: UploadFile) => void },
        ) => (
          <CustomUploadItem
            file={file}
            onRemove={() => actions.remove(file)}
            onDownload={() => actions.download(file)}
          />
        )}
        uploadKey={filesKey}
        listType="picture"
        showDownload
        noResetUploads
        hasFileNameTimeStamp
        fileList={uploaderStore.fileList(filesKey)}
        onRemoveUpload={uploaderStore.removeUpload}
        onAddNewUpload={uploaderStore.addNewUpload}
        onResetUploads={uploaderStore.resetUploads}
        onSetUploadError={uploaderStore.setUploadError}
        onUpdateUpload={uploaderStore.updateUpload}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{
            padding: '8px 16px',
            borderRadius: '4px',
            borderColor: theme.colors['gray-4'],
            cursor: 'pointer',
            width: '100%',
            borderWidth: '1px',
            borderStyle: 'solid',
          }}
        >
          Upload file
          <CloudUploadOutlined />
        </Box>
      </UploaderWrapper>
    </Form.Item>
  )
})
