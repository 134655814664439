import moment from 'moment/moment'

import { message } from 'antd'

import { useNotifyCommitment } from 'common/hooks/use-notify-commitment'

import { useStores } from 'contractor/hooks/use-stores'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'
import { ConcreteCommitmentMaterial } from 'contractor/server/commitments'

export const useConcreteCommitment = () => {
  const { commitmentStore } = useStores()
  const { notifyCommitment } = useNotifyCommitment()
  const { commitmentPayload, goBack, setSubmitting, dateFilteredMaterials, filters } = useCommitment()

  const handleLogError = (error, defaultMessage = '') => {
    if (error?.response?.data?.error) {
      message.error({
        content: error.response.data.error,
        duration: 8,
      })
    } else {
      message.error(defaultMessage || `Unable to save the commitment`)
    }
  }

  const handleUpdateCommitment = async () => {
    try {
      setSubmitting(true)
      await commitmentStore.updateCommitment(commitmentPayload)

      notifyCommitment({
        message: 'Commitment Updated',
        commitmentId: commitmentPayload.id,
        commitmentType: commitmentPayload.commitment_type,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleSaveDraftCommitment = async () => {
    try {
      setSubmitting(true)
      let commitmentId = ''
      const commitment = await commitmentStore.createCommitment(commitmentPayload)
      commitmentId = commitment.id

      notifyCommitment({
        message: 'Commitment saved as draft',
        commitmentId: commitmentId,
        commitmentType: commitmentPayload.commitment_type,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleCreateSubmittedCommitment = async () => {
    try {
      setSubmitting(true)
      let commitmentId = 'new'
      commitmentPayload['submitted_at'] = moment().toISOString()
      await commitmentStore.createCommitment(commitmentPayload).then((commitment) => {
        commitmentId = commitment.id
      })

      notifyCommitment({
        message: 'Commitment submitted.',
        commitmentId: commitmentId,
        commitmentType: commitmentPayload.commitment_type,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleSubmitCommitment = async () => {
    try {
      setSubmitting(true)
      commitmentPayload['submitted_at'] = moment().toISOString()
      await commitmentStore.updateCommitment(commitmentPayload)

      notifyCommitment({
        message: 'Commitment submitted',
        commitmentId: commitmentPayload.id,
        commitmentType: commitmentPayload.commitment_type,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const confirmAllInspections = () => {
    const ids = dateFilteredMaterials.map((material) => material.id)
    const newCommitmentMaterials = commitmentStore?.selectedCommitment?.commitment_materials?.map(
      (material: ConcreteCommitmentMaterial) => {
        if (ids.some((id) => id === material.id && material.inspection_status)) {
          return {
            ...material,
            release_date: moment().toISOString(),
          }
        } else {
          return material
        }
      },
    )
    commitmentStore.updateSelectedCommitment('commitment_materials', newCommitmentMaterials)
  }

  const handleGenerateLookAheadPdf = async () => {
    try {
      setSubmitting(true)
      const ids = dateFilteredMaterials.map((material: ConcreteCommitmentMaterial) => material.id)
      const commitmentPayload = await commitmentStore.generateNewLookAheadPdf(ids, { ...filters })

      window.open(commitmentPayload.url, '_blank')
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleGenerateLogPdf = async () => {
    try {
      setSubmitting(true)
      const ids = dateFilteredMaterials
        ?.filter((material: ConcreteCommitmentMaterial) => !!material.release_date)
        ?.map((material) => material.id)
      const commitmentPayload = await commitmentStore.generateNewLookAheadPdf(ids, { ...filters })

      window.open(commitmentPayload.url, '_blank')
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleDeleteCommitment = async () => {
    try {
      await commitmentStore.deleteSelectedCommitment()
      notifyCommitment({
        message: 'Commitment Deleted',
        commitmentId: commitmentPayload.id,
        commitmentType: commitmentPayload.commitment_type,
      })
      goBack()
    } catch (error) {
      handleLogError(error)
    }
  }

  return {
    handleUpdateCommitment,
    handleSubmitCommitment,
    handleCreateSubmittedCommitment,
    handleSaveDraftCommitment,
    confirmAllInspections,
    handleGenerateLookAheadPdf,
    handleGenerateLogPdf,
    handleDeleteCommitment,
    handleLogError,
  }
}
