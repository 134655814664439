import React, { useRef, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { uniqBy } from 'lodash'

import styled from '@emotion/styled'

import { PlusOutlined, ThunderboltFilled } from '@ant-design/icons'
import { Button, Checkbox, Input, message, Tabs, Tooltip, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { observer } from 'mobx-react-lite'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { Card } from 'common/components/Card'
import { Drawer, DrawerRef } from 'common/components/Drawer'
import { noticeError } from 'common/helpers/new_relic'
import { useTheme } from 'common/hooks/use-theme'

import { makeContactOption, SelectCompanyVendorContact } from 'contractor/components/SelectCompanyVendorContact'
import { useStores } from 'contractor/hooks/use-stores'
import { CreateCompanyVendorContact } from 'contractor/pages/CompanyVendorContacts/MyVendors/Create'
import { CompanyVendorContact } from 'contractor/server/company_vendor_contacts'

import { useNewQuote } from '../context'
import { SelectedVendor } from './selected_vendor'
import { SubBaseNetwork } from './subbase_network'

type VendorFormProps = {
  orderType: OrderType
  form: FormInstance
  disabled?: boolean
}

const TabsStyled = styled(Tabs)`
  width: 100%;
  .ant-tabs-tabpane-active,
  .ant-tabs-content {
    height: 100%;
  }
  .ant-tabs-nav {
    padding: 10px 24px 0 24px;
  }
`

export const addCompanyVendor = (vendorContact: CompanyVendorContact, comment: string, existingCompanyVendors = []) => {
  const { company_vendor, contact } = vendorContact

  if (existingCompanyVendors.some((existingCompanyVendor) => existingCompanyVendor.id === company_vendor.id)) {
    return existingCompanyVendors.map((existingCompanyVendor) => {
      if (existingCompanyVendor.id === company_vendor.id) {
        return {
          ...existingCompanyVendor,
          contacts: [
            ...existingCompanyVendor.contacts,
            {
              id: company_vendor.id,
              vendor_name: company_vendor.vendor_name,
              vendor: company_vendor.vendor,
              contacts: [contact],
              comment: comment || existingCompanyVendor.comment,
            },
          ],
        }
      }

      return existingCompanyVendor
    })
  } else {
    return [
      ...existingCompanyVendors,
      {
        id: company_vendor.id,
        vendor_name: company_vendor.vendor_name,
        vendor: company_vendor.vendor,
        contacts: [contact],
        comment,
      },
    ]
  }
}

export const VendorForm = observer<VendorFormProps>(({ orderType, disabled }) => {
  const { companyVendorStore, userStore, orderStore } = useStores()
  const location = useLocation()
  const orderPackageIdParam = new URLSearchParams(location.search).get('order_package_id')

  const theme = useTheme()

  const {
    isSameDefaultVendorsMaterialsAndOrderVendor,
    showDefaultVendor,
    toggleShowDefaultVendor,
    companyVendors,
    setCompanyVendors,
    commitment,
  } = useNewQuote()

  const drawerCreateRef = useRef<DrawerRef>()

  // HACK: If this was a draft then assume we had different messages, otherwise assume the same message
  const [sameMessageAllVendors, setSameMessageAllVendors] = React.useState(!orderPackageIdParam)
  const [activeTab, setActiveTab] = useState('subbase-network')

  const isRFQ = orderType === 'RFQ'

  const placeholders = React.useMemo(() => {
    if (isRFQ) {
      return {
        text: 'Hey, Think you could get me a quote by next Wednesday? Thanks!',
        vendor: 'Select vendors',
      }
    }

    return {
      text: 'Hey, please confirm you received the order. Thanks!',
      vendor: 'Select vendor',
    }
  }, [isRFQ])

  function onAddNewVendor(newContact: CompanyVendorContact) {
    if (!newContact) return

    try {
      // Maybe initialize the message to what the others have
      const comment = sameMessageAllVendors ? companyVendors[0]?.comment : ''
      // If this an order instead of a quote then we just set the vendor directly
      if (isRFQ) {
        const newCompanyVendors = addCompanyVendor(newContact, comment, companyVendors)
        setCompanyVendors(newCompanyVendors)
      } else {
        const { company_vendor } = newContact
        const existingVendors = companyVendors.some(
          (existingCompanyVendor) => existingCompanyVendor.id === company_vendor.id,
        )
          ? companyVendors
          : []
        const newCompanyVendors = addCompanyVendor(newContact, comment, existingVendors)
        setCompanyVendors(newCompanyVendors)
      }
    } catch (error) {
      message.error('Unable to select the vendor')
      noticeError(error, { entry: 'order-select-vendor', content: newContact })
    }
  }

  function handleRemove(companyVendorId) {
    const newCompanyVendors = companyVendors.filter((companyVendors) => companyVendors.id !== companyVendorId)
    setCompanyVendors(newCompanyVendors)
  }

  function handleChangeMessage(companyVendorId, comment) {
    const newCompanyVendors = companyVendors.map((companyVendor) => {
      if (companyVendor.id === companyVendorId) {
        return {
          ...companyVendor,
          comment,
        }
      }
      return companyVendor
    })

    setCompanyVendors(newCompanyVendors)
  }

  function handleChangeAllMessages(comment: string) {
    const newCompanyVendors = companyVendors.map((companyVendor) => {
      return {
        ...companyVendor,
        comment,
      }
    })

    setCompanyVendors(newCompanyVendors)
  }

  function handleChange(options = []) {
    if (!options) return

    const newCompanyVendors = new Map()
    options?.forEach((option) => {
      const { companyVendor, companyVendorContact } = option

      if (newCompanyVendors.has(companyVendor?.id)) {
        const currentCompanyVendor = newCompanyVendors.get(companyVendor?.id)
        newCompanyVendors.set(currentCompanyVendor?.id, {
          ...currentCompanyVendor,
          contacts: uniqBy([...currentCompanyVendor.contacts, companyVendorContact], 'id'),
        })
      } else {
        if (!isRFQ) {
          newCompanyVendors.clear()
        }

        newCompanyVendors.set(companyVendor?.id, {
          ...companyVendor,
          contacts: [companyVendorContact],
        })
      }
    })

    setCompanyVendors(Array.from(newCompanyVendors, ([_, value]) => value))
  }

  function getValue() {
    const companyVendorContacts = []
    companyVendors.forEach((companyVendor) => {
      companyVendor.contacts.forEach((contact) => {
        companyVendorContacts.push({ contact, company_vendor: companyVendor })
      })
    })

    return companyVendorContacts.map(makeContactOption)
  }

  return (
    <Card key={commitment?.id} title="Vendors">
      <FlexBoxY alignItems="flex-start" justifyContent="flex-start">
        <FlexBoxX width="100%" mb={16} justifyContent="flex-start" alignItems="flex-start" position="relative">
          {/* Just using this as a dropdown to select the right option, never has a value set */}
          <SelectCompanyVendorContact
            data-cy={`select-vendor-${orderType}`}
            placeholder={placeholders.vendor}
            defaultVendorId={commitment?.company_vendor_id}
            disabled={orderStore.isSplitting || disabled}
            mode="multiple"
            onClickDiscover={() => {
              drawerCreateRef.current?.show()
              setActiveTab('subbase-network')
            }}
            onChange={handleChange}
            value={getValue()}
            hideDiscoverVendors={!userStore.canManageVendors || userStore.isMaterialRequester || !!commitment?.id}
          />
          {userStore.canManageVendors && !userStore.isMaterialRequester && !commitment?.id && (
            <>
              <Tooltip title="Add New Vendor">
                <Button
                  data-cy="add-new-vendor-button"
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    drawerCreateRef.current?.show()
                    setActiveTab('new-vendor-contact')
                  }}
                  icon={<PlusOutlined />}
                  disabled={orderStore.isSplitting || disabled}
                />
              </Tooltip>
              <Drawer
                ref={drawerCreateRef}
                closable={false}
                bgGray
                destroyOnClose
                onClose={companyVendorStore.companyVendorContactListStore.fetchRecords}
              >
                <TabsStyled
                  activeKey={activeTab}
                  onChange={setActiveTab}
                  destroyInactiveTabPane
                  items={[
                    {
                      label: (
                        <FlexBoxX alignItems="center">
                          <ThunderboltFilled
                            style={{ color: theme.colors.highlightBlue, fontSize: 16, marginRight: 4 }}
                          />
                          <Typography.Text>SubBase Network</Typography.Text>
                        </FlexBoxX>
                      ),
                      key: 'subbase-network',
                      children: (
                        <SubBaseNetwork
                          data-cy={`subbase-network-${orderType}`}
                          onClickToCreate={() => setActiveTab('new-vendor-contact')}
                          onSubmitAndAdd={(newCompanyVendorContact) => {
                            drawerCreateRef.current?.close()
                            onAddNewVendor(newCompanyVendorContact)
                          }}
                          orderType={orderType}
                        />
                      ),
                    },
                    {
                      label: 'New Vendor Contact',
                      key: 'new-vendor-contact',
                      children: (
                        <CreateCompanyVendorContact
                          data-cy={`create-vendor-contact-${orderType}`}
                          hideNotification
                          hideTitle
                          hideShowSaveAndAddAnotherButton
                          onFinish={onAddNewVendor}
                          onCancel={() => drawerCreateRef.current?.close()}
                          withDrawer={false}
                        />
                      ),
                    },
                  ]}
                />
              </Drawer>
            </>
          )}
        </FlexBoxX>

        {/* Do not show alert for RFQ */}
        {!isSameDefaultVendorsMaterialsAndOrderVendor && orderType === 'Order' && (
          <FlexBoxY width="100%" mb={16} className="ant-alert ant-alert-warning ant-alert-no-icon">
            <Typography.Text className="ant-alert-message">
              Some materials in your order have a different default vendor specified. <br />
              You might want to consider splitting up your order.
            </Typography.Text>
            <Button
              data-cy="show-default-vendors-button"
              style={{ marginTop: 8 }}
              onClick={() => toggleShowDefaultVendor((prev) => !prev)}
            >
              {showDefaultVendor ? 'Hide' : 'Show'} Default Vendors
            </Button>
          </FlexBoxY>
        )}

        {companyVendors.map((companyVendor, index) => (
          <SelectedVendor
            data-cy="select-vendor"
            key={companyVendor.id}
            companyVendor={companyVendor}
            onRemove={handleRemove}
            onChangeMessage={handleChangeMessage}
            sameMessageAllVendors={sameMessageAllVendors}
            placeholder={placeholders.text}
            index={index}
            disabled={disabled}
          />
        ))}

        {sameMessageAllVendors && (
          <Input.TextArea
            data-cy="vendor-message"
            rows={3}
            style={{ width: '100%', resize: 'none' }}
            placeholder={placeholders.text}
            value={companyVendors[0]?.comment}
            disabled={companyVendors.length === 0 || orderStore.isSplitting || disabled}
            onChange={(e) => handleChangeAllMessages(e.target.value)}
          />
        )}

        {companyVendors.length > 1 && orderType === 'RFQ' && (
          <Checkbox
            data-cy="checkbox-same-vendor-message"
            checked={sameMessageAllVendors}
            onChange={(e) => setSameMessageAllVendors(e.target.checked)}
            style={{ padding: '10px 0', fontSize: 13 }}
            disabled={orderStore.isSplitting || disabled}
          >
            Use the same message for all vendors
          </Checkbox>
        )}
      </FlexBoxY>
    </Card>
  )
})
