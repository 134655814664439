import React, { useMemo } from 'react'

import styled from '@emotion/styled'

import { CheckCircleOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Tabs, Typography } from 'antd'

import { Box } from 'common/components/boxes'

import { InvoiceReconcileStatus, useInvoice } from '../../context'
import { extractQtyAndUnitsOfOrderMaterials } from '../../helpers'

const TabStyled = styled(Tabs)`
  background-color: ${({ theme }) => theme.colors.white};

  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-tab {
    margin-left: 0;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
`

const data: Array<{ label: string; icon?: React.ReactNode; key: InvoiceReconcileStatus; color: string }> = [
  {
    label: 'All',
    key: 'all',
    color: 'success',
  },
  {
    label: 'Matches',
    key: 'matches',
    icon: <CheckCircleOutlined />,
    color: 'success',
  },
  {
    label: 'Partial Matches',
    key: 'partial-matches',
    icon: <WarningOutlined />,
    color: 'warning',
  },
  {
    label: 'No Matches',
    key: 'no-matches',
    icon: <ExclamationCircleOutlined />,
    color: 'error',
  },
]

interface ReconcileStatusCounts {
  all: number
  matches: number
  'partial-matches': number
  'no-matches': number
}

export const TabReconcile = () => {
  const {
    calcTaxSplitBulk,
    currentReconcileStatus,
    invoiceMaterialsFiltered,
    setCurrentReconcileStatus,
    taxAmountField,
    taxLineItemsEnabled,
  } = useInvoice()

  const reconcileStatusCounts = useMemo<ReconcileStatusCounts>(() => {
    const dataSource = calcTaxSplitBulk({
      invoiceMaterials: invoiceMaterialsFiltered.materialsFilteredBySearch.concat(
        invoiceMaterialsFiltered.groups.flatMap((group) => group.invoice_materials),
      ),
      taxAmount: taxAmountField,
      taxLineItemsEnabled,
    })

    return dataSource.reduce<ReconcileStatusCounts>(
      (acc, invoiceMaterial) => {
        const extractedQuantity = Number(invoiceMaterial?.quantity_shipped || 0)
        const invoiceUnit = invoiceMaterial?.uom?.trim() || ''
        const hasOrderMaterials = !!invoiceMaterial?.order_materials?.length

        const [orderMaterialsQuantities, orderMaterialsUnits] = extractQtyAndUnitsOfOrderMaterials(
          invoiceMaterial?.order_materials,
        )

        if (!hasOrderMaterials) {
          acc['no-matches'] = (acc['no-matches'] || 0) + 1
        } else if (
          orderMaterialsQuantities?.length <= 1 &&
          orderMaterialsQuantities[0] === extractedQuantity &&
          orderMaterialsUnits?.length <= 1 &&
          invoiceUnit === orderMaterialsUnits[0]
        ) {
          acc.matches = (acc.matches || 0) + 1
        } else {
          acc['partial-matches'] = (acc['partial-matches'] || 0) + 1
        }

        acc.all = (acc.all || 0) + 1
        return acc
      },
      { all: 0, matches: 0, 'partial-matches': 0, 'no-matches': 0 },
    )
  }, [calcTaxSplitBulk, invoiceMaterialsFiltered, taxAmountField, taxLineItemsEnabled])

  return (
    <TabStyled
      defaultActiveKey={currentReconcileStatus}
      size="small"
      items={data.map((item) => ({
        label: (
          <Box display="flex" alignItems="center" color={item.color}>
            {item?.icon}
            <Typography.Text>{item.label}</Typography.Text>
            <Box borderRadius="12px" color="#121C4E" backgroundColor="#E9EBF8" ml={5} px={8}>
              {reconcileStatusCounts[item.key]}
            </Box>
          </Box>
        ),
        key: item.key,
      }))}
      onChange={(tab: InvoiceReconcileStatus) => setCurrentReconcileStatus(tab)}
    />
  )
}
