import React, { useCallback, useEffect, useState } from 'react'

import moment from 'moment'

import { CloseOutlined } from '@ant-design/icons'
import { Form, message, Modal } from 'antd'

import { observer } from 'mobx-react-lite'

import { Drawer, DrawerRef } from 'common/components/Drawer'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { LogDeliveryResponse, OrderDelivery } from 'common/server/deliveries'

import { DeliveryDetails } from 'contractor/components/LogDelivery/DeliveryDetails/delivery_details'
import { SelectOrderStep } from 'contractor/components/LogDelivery/SelectOrderStep/select_order_step'
import { Stepper } from 'contractor/components/Stepper/stepper'
import { useStores } from 'contractor/hooks/use-stores'
import { ConsolidatedOrders } from 'contractor/server/orders'

type LogDeliveryProps = {
  onClose: () => void
}

const LOG_DELIVERY_FILES_KEY = 'log_delivery_files'

export const LogDelivery = observer<LogDeliveryProps, DrawerRef>(
  (props, ref) => {
    const { onClose } = props
    const { deliveryStore, uploaderStore, userStore, orderStore } = useStores()
    const [recentDeliveries, setRecentDeliveries] = useState<LogDeliveryResponse[]>([])
    const [loadingRecentDeliveries, setLoadingRecentDeliveries] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState<ConsolidatedOrders.Order | null>(null)
    const isMobile = useMediaQuery('md')
    const [form] = Form.useForm()

    const handleShow = useCallback(() => {
      setLoadingRecentDeliveries(true)
      deliveryStore
        .fetchDeliveries()
        .then((data) => {
          setRecentDeliveries(data)
        })
        .finally(() => {
          setLoadingRecentDeliveries(false)
        })
    }, [deliveryStore])

    useEffect(() => {
      uploaderStore.resetUploads(LOG_DELIVERY_FILES_KEY)
    }, [uploaderStore])

    useEffect(() => {
      if (deliveryStore.selectedDelivery) {
        uploaderStore.addExistingFiles(LOG_DELIVERY_FILES_KEY, deliveryStore.selectedDelivery?.delivery.delivery_files)
      }
    }, [deliveryStore.selectedDelivery, uploaderStore])

    useEffect(() => {
      if (deliveryStore.selectedDelivery) {
        form.setFieldsValue({
          deliveryIssueType: deliveryStore.selectedDelivery?.delivery.delivery_issue_type,
          deliveryIssues: deliveryStore.selectedDelivery?.delivery.delivery_issues,
          resolved: !!deliveryStore.selectedDelivery?.delivery.delivery_issue_resolved_at,
          incompleteItems: deliveryStore.selectedDelivery?.order?.order_materials?.filter(
            (item) => item.delivery_id === deliveryStore.selectedDelivery?.delivery.id,
          ),
          orderDelivery: deliveryStore.selectedDelivery,
          orderNumber: deliveryStore.selectedDelivery?.order?.order_number,
          orderName: deliveryStore.selectedDelivery?.order?.order_package_name,
          deliveryIssueResolvedAt: deliveryStore.selectedDelivery.delivery?.delivery_issue_resolved_at,
          actualDeliveredAt: deliveryStore.selectedDelivery.delivery?.actual_delivered_at
            ? moment.utc(deliveryStore.selectedDelivery.delivery?.actual_delivered_at).local()
            : null,
        })
      }
    }, [deliveryStore.selectedDelivery])

    const handleClose = () => {
      deliveryStore.selectDelivery(null)
      uploaderStore.resetUploads(LOG_DELIVERY_FILES_KEY)
      form.resetFields()
      onClose()
      setLoading(false)
      orderStore.listStore.fetchRecords() // re-fetch order logs
    }

    const handleFinish = async (formValues) => {
      if (!uploaderStore.checkIfAllUploadsCompleted()) {
        Modal.error({ title: 'Uploads have not completed yet, please try again' })
        return
      }

      try {
        setLoading(true)
        const selectedDelivery = deliveryStore.selectedDelivery
        if (selectedDelivery) {
          await deliveryStore.updateDelivery({
            id: selectedDelivery.delivery.id,
            delivery_files_signed_ids: uploaderStore.signedIds(LOG_DELIVERY_FILES_KEY),
            delivery_files_delete_ids: uploaderStore.deleteAttachmentIds[LOG_DELIVERY_FILES_KEY],
            delivery_issues: formValues.deliveryIssues || null,
            delivery_issue_type: formValues.deliveryIssueType || null,
            delivery_issue_resolved_at: formValues.resolved ? moment().toISOString() : null,
            delivery_issue_resolved_by: formValues.resolved ? { id: userStore.companyUserId } : null,
            incomplete_items: formValues.incompleteItems || null,
            actual_delivered_at: formValues.actualDeliveredAt,
            marked_delivered_at: moment().toISOString(),
            marked_delivered_by: { id: userStore.companyUserId },
          } as OrderDelivery)

          handleClose()
          message.success('Delivery updated')
        }
      } catch (error) {
        console.error(error)
        message.error(error?.response?.data?.error || 'Unable to update delivery')
      } finally {
        setLoading(false)
        orderStore.listStore.fetchRecords() // re-fetch order logs
      }
    }

    const deliveryStep = React.useMemo(() => {
      return deliveryStore?.selectedDelivery?.delivery ? 2 : 1
    }, [deliveryStore.selectedDelivery])

    const clear = () => {
      deliveryStore.selectDelivery(null)
      uploaderStore.resetUploads(LOG_DELIVERY_FILES_KEY)
      form.resetFields()
      setSelectedOrder(null)
    }

    useEffect(() => {
      return clear
    }, [])

    return (
      <Drawer
        ref={ref}
        title="Log Delivery"
        push={false}
        closable={false}
        onClose={handleClose}
        afterOpenChange={(open) => {
          if (open) {
            handleShow()
          }
        }}
        extra={<CloseOutlined onClick={handleClose} />}
        width={isMobile ? '100%' : 600}
        destroyOnClose
      >
        <Stepper currentStep={deliveryStep} style={{ display: 'flex', width: '200%' }}>
          <SelectOrderStep
            recentDeliveries={recentDeliveries}
            form={form}
            loadingRecentDeliveries={loadingRecentDeliveries}
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
          />

          <Form
            form={form}
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              maxWidth: '100vw',
            }}
            layout="vertical"
            onFinish={handleFinish}
          >
            <DeliveryDetails
              loading={loading}
              filesKey={LOG_DELIVERY_FILES_KEY}
              form={form}
              orderDelivery={deliveryStore.selectedDelivery?.delivery}
              orderName={deliveryStore.selectedDelivery?.order?.order_package_name}
              orderNumber={deliveryStore.selectedDelivery?.order?.order_number}
              onBack={clear}
            />
          </Form>
        </Stepper>
      </Drawer>
    )
  },
  { forwardRef: true },
)
