import React, { useState } from 'react'

import { useRouteMatch, useLocation } from 'react-router-dom'

import moment from 'moment'

import { CheckOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import ArrowLeftIcon from '@ant-design/icons/ArrowLeftOutlined'
import { Alert, Button, Col, message, Modal, PageHeader, Popconfirm, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { OrderBlockedHeader } from 'common/components/OrderBlockedHeader'
import { OrderBlockedModals } from 'common/components/OrderBlockedModals'
import { Page } from 'common/components/Page'
import OrderState from 'common/components/statuses/order_state'
import UnloadWarning from 'common/components/unload_warning'
import { Visibility } from 'common/components/Visibility'
import { formatDateString } from 'common/helpers/formatters'
import { getOrderTitleType } from 'common/helpers/get_order_type_title'
import { OrderStates } from 'common/server/server_types'

import { InternalComments } from 'contractor/components/InternalComments'
import { TCMSyncStatusDetailed } from 'contractor/components/ManualSyncStatusDetailed'
import { FoundationSyncStatusDetailed } from 'contractor/components/ManualSyncStatusDetailed/foundation_sync_status_detailed'
import { useStores } from 'contractor/hooks/use-stores'
import { IntegrationSync } from 'contractor/pages/OrderDetail/integration_sync'
import { LockPo } from 'contractor/pages/OrderDetail/lock_po'
import { QuoteAlert } from 'contractor/pages/OrderDetail/QuoteReconciliation/quote_alert'
import { QuoteReconciliationDrawer } from 'contractor/pages/OrderDetail/QuoteReconciliation/quote_reconciliation_drawer'
import { WithdrawCancelation } from 'contractor/pages/OrderDetail/withdraw_cancelation'

import { OrderActions } from './components/Actions'
import { History } from './components/History'
import { Invoices } from './components/Invoices'
import { Leveling } from './components/Leveling'
import { MaterialsAndDeliveries } from './components/MaterialAndDeliveries'
import { OrderInfo } from './components/OrderInfo'
import { Tabs, TabAttachments, TabVendorChat } from './components/Tabs'
import { Total } from './components/Total'
import { useOrderDetail, withOrderDetailProvider } from './context'

const { confirm, destroyAll } = Modal

const QuoteValidUntil = () => {
  const { orderStore } = useStores()

  const { state, quote_scheduled_to_expire_at } = orderStore.selectedOrder

  if (state === OrderStates.QUOTED && !!quote_scheduled_to_expire_at) {
    return (
      <Typography.Text type="secondary" style={{ fontSize: 14, marginRight: 8 }}>{`Valid Until: ${formatDateString(
        quote_scheduled_to_expire_at,
      )}`}</Typography.Text>
    )
  }

  return null
}

const QuickPOAlert = (props) => (
  <Alert message="This PO was Quick Created during Invoice Approval" type="info" {...props} />
)

export const OrderDetailPage = withOrderDetailProvider(
  observer(() => {
    const { orderStore } = useStores()
    const { data, actions, permissions, session, states } = useOrderDetail()

    const location = useLocation()
    const { params } = useRouteMatch()

    const editablePackageName = permissions.canEditPackageName && {
      onChange: actions.handleUpdateOrderPackageName,
    }

    const [isConfirming, setIsConfirming] = useState(false)

    const confirmAndSaveOrder = async () => {
      setIsConfirming(true)

      try {
        await actions.handleSave({
          isBack: false,
          silentUpdate: true,
          stateChanges: { confirmed_at: moment().toISOString() },
        })
      } catch (error) {
        message.error('Unable to confirm the order.')
      } finally {
        setIsConfirming(false)
      }
    }

    if (data.isLoading) {
      return <Loading />
    }

    if (!data.selectedOrder) {
      return null
    }

    const { state, sub_state, order_package_name, deliveries = [], manual_exported_at, id } = data.selectedOrder

    const orderTypeTitle = getOrderTitleType(state)

    orderStore.orderChangedModal = () => {
      confirm({
        title: 'This Order has changed',
        content: (
          <>
            <p>
              Another user did some changes to this Order.
              <br />
              Do you want to refresh the Order data?
            </p>
            <p>
              <b>NOTE: </b>
              <i>Refreshing will discard your changes!</i>
            </p>
          </>
        ),
        icon: <ExclamationCircleFilled />,
        onOk: async () => {
          await actions.handleSelectOrder(params['id'])
        },
        onCancel: () => {
          destroyAll()
        },
        okText: 'Yes',
        cancelText: 'No',
      })
    }

    const orderActionProps = {
      onFinish: actions.handleSave,
      actionClicked: states.countActionClicked,
      onPlaceOrder: actions.handlePlaceOrder,
      onCancelOrder: actions.handleCancelOrder,
      purchaseOrderModalVisible: states.purchaseOrderModalVisible,
      togglePurchaseOrderModalVisible: states.setPurchaseOrderModalVisible,
      cancelOtherPackageOrders: states.cancelOtherPackageOrders,
      toggleCancelOtherPackageOrders: states.setCancelOtherPackageOrders,
      syncOrderWithIntegration: states.syncOrderWithIntegration,
      toggleSyncOrderWithIntegration: states.setSyncOrderWithIntegration,
      onRequestCancellation: actions.handleRequestCancellation,
      isPoLocked: states.isPoLocked,
      isSpreadsheetMode: states.isSpreadsheetMode,
      toggleSpreadsheetMode: () => states.setSpreadsheetMode(!states.isSpreadsheetMode),
    }

    return (
      <>
        <Page overflow="overlay">
          <Page.Header showShadow={!states.isMobileScreen}>
            <PageHeader
              backIcon={
                data?.selectedOrderDirty ? (
                  <Popconfirm
                    title="You may have unsaved changes, are you sure you want to exit?"
                    onConfirm={() => actions.handleGoBack()}
                    okText="Yes"
                    cancelText="No"
                    placement="bottomLeft"
                  >
                    <ArrowLeftIcon />
                  </Popconfirm>
                ) : (
                  <ArrowLeftIcon onClick={() => actions.handleGoBack()} />
                )
              }
              onBack={() => null}
              title={
                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={{ _: 'space-between', md: 'flex-start' }}
                    flexWrap="wrap"
                  >
                    <Box display="flex" alignItems="center" mb={2} mt={{ _: 8, md: 0 }}>
                      <Typography.Text>{orderTypeTitle}</Typography.Text>
                    </Box>
                    <Box ml={{ _: 0, sm: 20 }} display="flex" alignItems="center" style={{ gap: 8 }}>
                      <Visibility.Show breakpoint="md">
                        <InternalComments
                          onSubmit={actions.handleSendInternalComment}
                          loading={states.isSubmitting}
                          comments={data.selectedOrder?.internal_comments}
                          orderState={data.selectedOrder?.state}
                          orderSubState={data.selectedOrder?.sub_state}
                        />
                      </Visibility.Show>

                      <Visibility.Show breakpoint="md">
                        <History />
                      </Visibility.Show>

                      {states.showLockIcon && (
                        <Box display="flex" alignItems="flex-end" flexDirection={{ _: 'column', xs: 'row' }}>
                          <LockPo
                            cannotUpdateOrders={permissions.cannotSendAndUpdateOrders}
                            role={data?.currentUser?.role?.name}
                            rule={data?.lockOrderRule}
                          />
                        </Box>
                      )}

                      <Box display="flex" alignItems="flex-end" flexDirection={{ _: 'column', xs: 'row' }}>
                        <OrderState
                          state={state}
                          sub_state={sub_state}
                          showIssue={deliveries?.some((d) => d.has_open_issue)}
                          deliveryIssues={deliveries?.map((d) => d.delivery_issue_type)}
                          size="medium"
                        />
                      </Box>
                      {permissions.canShowOrderConfirmOverride && !states.isPoLocked && (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                          marginLeft="8"
                          marginRight="16"
                        >
                          <Tooltip
                            title={
                              data.selectedOrderDirty
                                ? 'Mark the order as confirmed and save your changes. This will not send an update to your vendor.'
                                : 'Mark the order as confirmed. This will not send an update to your vendor.'
                            }
                            placement="bottom"
                          >
                            <Button
                              type="default"
                              size="small"
                              icon={<CheckOutlined style={{ fontSize: '10px' }} />}
                              onClick={confirmAndSaveOrder}
                              shape="circle"
                              disabled={states.isPoLocked || isConfirming}
                              loading={isConfirming}
                            />
                          </Tooltip>
                        </Box>
                      )}
                    </Box>

                    <Visibility.Hidden breakpoint="lg">
                      <QuoteValidUntil />
                    </Visibility.Hidden>
                    {/* TCM it's just a fake integration, the user can just download and CSV and upload it in their ERP, it's controlled by feature flag */}
                    {permissions.canShowTCMV2Download && !states.isPoLocked && (
                      <Visibility.Hidden breakpoint="lg">
                        <TCMSyncStatusDetailed
                          isSynced={!!manual_exported_at}
                          onClick={() => actions.handleManualExport([{ id }]).then(() => actions.handleSelectOrder(id))}
                          order={data.selectedOrder}
                          canSyncWithErp={permissions.canSyncWithErp}
                        />
                      </Visibility.Hidden>
                    )}

                    {permissions.canShowFoundationDownload && (
                      <Visibility.Hidden breakpoint="lg">
                        <FoundationSyncStatusDetailed
                          isSynced={!!manual_exported_at}
                          onClick={() => actions.handleManualExport([{ id }]).then(() => actions.handleSelectOrder(id))}
                        />
                      </Visibility.Hidden>
                    )}
                    {permissions.canShowIntegration && (
                      <Visibility.Hidden breakpoint="lg">
                        {data.selectedOrder && <IntegrationSync order={data.selectedOrder} />}
                      </Visibility.Hidden>
                    )}
                  </Box>

                  <Visibility.Show breakpoint="lg">
                    <QuoteValidUntil />
                  </Visibility.Show>

                  <Visibility.Show>
                    <Typography.Title type="secondary" style={{ margin: 0 }} level={5} editable={editablePackageName}>
                      {order_package_name}
                    </Typography.Title>
                  </Visibility.Show>

                  {permissions.canShowTCMV2Download && (
                    <Visibility.Show breakpoint="lg">
                      <TCMSyncStatusDetailed
                        isSynced={!!manual_exported_at}
                        onClick={() => actions.handleManualExport([{ id }]).then(() => actions.handleSelectOrder(id))}
                        order={data.selectedOrder}
                        canSyncWithErp={permissions.canSyncWithErp}
                      />
                    </Visibility.Show>
                  )}

                  {permissions.canShowFoundationDownload && (
                    <Visibility.Show breakpoint="lg">
                      <FoundationSyncStatusDetailed
                        isSynced={!!manual_exported_at}
                        onClick={() => actions.handleManualExport([{ id }]).then(() => actions.handleSelectOrder(id))}
                      />
                    </Visibility.Show>
                  )}

                  {permissions.canShowIntegration && (
                    <Visibility.Show breakpoint="lg">
                      {data.selectedOrder && <IntegrationSync order={data.selectedOrder} />}
                    </Visibility.Show>
                  )}
                </Box>
              }
              extra={[
                <Visibility.Hidden breakpoint="md" key="internal-comments">
                  <InternalComments
                    onSubmit={actions.handleSendInternalComment}
                    loading={states.isSubmitting}
                    comments={data.selectedOrder?.internal_comments}
                    orderState={data.selectedOrder?.state}
                    orderSubState={data.selectedOrder?.sub_state}
                  />
                </Visibility.Hidden>,
                <Visibility.Hidden breakpoint="md" key="history">
                  <History />
                </Visibility.Hidden>,
                <Visibility.Hidden breakpoint="lg" key="leveling">
                  <Leveling />
                </Visibility.Hidden>,
                <Visibility.Hidden breakpoint="md" key="extras">
                  <OrderActions {...orderActionProps} />
                </Visibility.Hidden>,
              ]}
            />

            <Visibility.Hidden breakpoint="md">
              <OrderInfo isPoLocked={states.isPoLocked} />
            </Visibility.Hidden>

            <OrderBlockedHeader {...session} isRFQ={states.isRFQ} />
          </Page.Header>

          <Visibility.Show breakpoint="md">
            <Page.Tabs
              tabBarGutter={24}
              onChange={(activeKey) => {
                trackEvent(Events.CHANGE_ORDER_DETAIL_TAB, location.pathname.slice(1), {
                  active_key: activeKey,
                })
              }}
              items={[
                {
                  label: 'Details',
                  key: 'details',
                  children: (
                    <Box pt={24} pb={112} width="100%">
                      {data.selectedOrder?.quick_created_at && (
                        <QuickPOAlert style={{ width: '100%', marginBottom: 12 }} />
                      )}
                      <WithdrawCancelation
                        cancellationRequestedAt={data.selectedOrder?.cancellation_requested_at}
                        cancellationAcceptedAt={data.selectedOrder?.cancellation_accepted_at}
                        cancellationRejectedAt={data.selectedOrder?.cancellation_rejected_at}
                        cancellationRequestedReason={data.selectedOrder?.cancellation_requested_reason}
                        onClick={actions.handleRollbackRequestCancellation}
                        isSubmitting={states.countActionClicked > 0}
                      />
                      <OrderInfo isPoLocked={states.isPoLocked} />
                    </Box>
                  ),
                },
                {
                  label: 'Materials',
                  key: 'materials',
                  children: (
                    <Box pt={24} pb={112} width="100%">
                      {data.selectedOrder?.quick_created_at && (
                        <QuickPOAlert style={{ width: '100%', marginBottom: 12 }} />
                      )}
                      <MaterialsAndDeliveries
                        isPoLocked={states.isPoLocked}
                        onDirty={actions.handleDirtyOrder}
                        isSpreadsheetMode={states.isSpreadsheetMode}
                      />
                    </Box>
                  ),
                },
                states.showInvoicesTab && {
                  label: 'Invoices',
                  key: 'invoices',
                  children: <Invoices />,
                },
                {
                  label: 'Vendor Chat',
                  key: 'chat',
                  children: <TabVendorChat />,
                },
                {
                  label: 'Attachments',
                  key: 'attachments',
                  children: (
                    <TabAttachments
                      isPoLocked={states.isPoLocked}
                      canInputVendorData={permissions.canInputVendorData}
                    />
                  ),
                },
              ]}
            />
          </Visibility.Show>

          <Visibility.Hidden breakpoint="md">
            <Page.Content
              display="flex"
              flexDirection="column"
              p={0}
              px={{ _: 0, sm: 16 }}
              py={16}
              mb={56}
              overflowY={{ _: 'unset', lg: 'auto' }}
            >
              {states.showQuoteAlert && (
                <QuoteAlert
                  quoteFullyMatched={states.quoteFullyMatched}
                  setShowQuoteDrawer={states.setShowQuoteDrawer}
                  state={data?.quote?.status}
                />
              )}

              {states.showQuoteAlert && (
                <QuoteReconciliationDrawer
                  state={data?.selectedOrder?.quote_extraction?.quote_state}
                  closeDrawer={() => states.setShowQuoteDrawer(false)}
                  showDrawer={states.showQuoteDrawer}
                  pdfUrl={data?.selectedOrder?.quote?.url}
                />
              )}

              <WithdrawCancelation
                cancellationRequestedAt={data.selectedOrder?.cancellation_requested_at}
                cancellationAcceptedAt={data.selectedOrder?.cancellation_accepted_at}
                cancellationRejectedAt={data.selectedOrder?.cancellation_rejected_at}
                cancellationRequestedReason={data.selectedOrder?.cancellation_requested_reason}
                onClick={actions.handleRollbackRequestCancellation}
                isSubmitting={states.countActionClicked > 0}
              />

              <Box mb={16} flex={1}>
                {data.selectedOrder?.quick_created_at && (
                  <Col span={24}>
                    <QuickPOAlert />
                  </Col>
                )}

                <MaterialsAndDeliveries
                  isPoLocked={states.isPoLocked}
                  onDirty={actions.handleDirtyOrder}
                  isSpreadsheetMode={states.isSpreadsheetMode}
                />
              </Box>

              <Tabs canInputVendorData={permissions.canInputVendorData} isPoLocked={states.isPoLocked} />
            </Page.Content>
          </Visibility.Hidden>

          <Visibility.Show breakpoint="md">
            <OrderActions {...orderActionProps} isFloat />
          </Visibility.Show>

          <UnloadWarning
            showWarning={() => data.selectedOrderDirty}
            onSaveAndLeave={() => actions.handleSave({ isBack: false, action: 2 })}
            isSubmitting={states.countActionClicked > 0}
          />
        </Page>

        <OrderBlockedModals
          {...session}
          onCancelModalOrderExpired={() => actions.handleGoBack()}
          onCancelModalOrderReleased={() => actions.handleGoBack()}
          isRFQ={states.isRFQ}
        />

        <Total value={data.totalCost} precision={data.precision} />
      </>
    )
  }),
)
