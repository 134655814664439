import React from 'react'

import { InvoiceMaterial } from 'javascript/common/server/invoice'

import { DeleteOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox, Typography } from 'antd'

import { Box, FlexBoxY } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import theme from 'common/styles/theme'

import { MappedStatus } from '../../InvoiceMaterials'

type Option = {
  id: string
  description?: string
  group_name?: string
  group_total?: number
  quantity_shipped: number
  uom?: string
  unit_price: number
  unit_multiplier?: number
  unit_qty_increment?: number
  checked?: boolean
  calcExtCost: (...rest) => number
  invoice_material?: InvoiceMaterial
}

type ColumnActions = {
  isExpanded?: boolean
  onExpand?: (itemId: string) => void
  onDelete?: (itemId: string) => void
}

type Column = {
  id: string
  label?: string
  render: (value, option: Option, actions: ColumnActions) => React.ReactNode
  width?: number | string
}

const COLUMNS: Column[] = [
  {
    id: 'description',
    label: 'Invoice Description',
    render: (value, option) => (
      <>
        {option?.invoice_material && <MappedStatus invoiceMaterial={option?.invoice_material} />}
        {value}
      </>
    ),
    width: '100%',
  },
  {
    id: 'quantity_shipped',
    label: 'Qty',
    render: (value) => value || '-',
    width: 100,
  },
  {
    id: 'uom',
    label: 'UOM',
    render: (value) => value || '-',
    width: 150,
  },
  {
    id: 'unit_price',
    label: 'Unit cost',
    render: (value) => currencyFormatter(value),
    width: 150,
  },
  {
    id: 'ext_price',
    label: 'Ext. cost',
    render: (_, option) =>
      currencyFormatter(
        option.calcExtCost({
          unitCost: option?.unit_price,
          quantity: option?.quantity_shipped,
          multiplier: option?.unit_multiplier,
          qtyIncrement: option?.unit_qty_increment,
        }),
      ),
    width: 200,
  },
  {
    id: 'actions',
    render: (_, option, actions) => (
      <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
        {actions?.onExpand && (
          <Button
            icon={actions?.isExpanded ? <MinusOutlined /> : <PlusOutlined />}
            size="small"
            style={{ width: 16, height: 16 }}
            onClick={() => actions.onExpand(option.id)}
          />
        )}

        {actions?.onDelete && (
          <Button
            icon={<DeleteOutlined />}
            type="text"
            size="small"
            style={{ width: 16, height: 16 }}
            onClick={() => actions.onDelete(option.id)}
          />
        )}
      </Box>
    ),
  },
]

type InvoiceItemProps = {
  children?: React.ReactNode
  item: Option
  onCheck: (itemId: string) => void
  onDelete?: (itemId: string) => void
  childrenIsExpandable?: boolean
}

const InvoiceItem = ({ children, item, onCheck, onDelete, childrenIsExpandable }: InvoiceItemProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false)

  const handleExpand = React.useCallback(() => {
    if (childrenIsExpandable) {
      setIsExpanded((prev) => !prev)
    }
  }, [childrenIsExpandable, setIsExpanded])

  const renderColumn = React.useCallback((column: Column, option: Option, actions: ColumnActions) => {
    return (
      <FlexBoxY key={`${option.description}-${column.id}`} alignItems="baseline" width={column.width}>
        <Typography.Text color={theme.colors['gray-8']} style={{ fontSize: 10 }}>
          {column.label}
        </Typography.Text>
        <Typography.Text style={{ display: 'flex', gap: 8 }} color={theme.colors['gray-12']}>
          {column.render(option[column.id], option, actions)}
        </Typography.Text>
      </FlexBoxY>
    )
  }, [])

  const customChildren = React.useMemo(() => {
    if (childrenIsExpandable) return isExpanded ? children : null
    return children
  }, [childrenIsExpandable, children, isExpanded])

  const expandProps = React.useMemo(() => {
    if (!childrenIsExpandable) return {}
    return { isExpanded, onExpand: childrenIsExpandable && !!children && handleExpand }
  }, [childrenIsExpandable, isExpanded, children, handleExpand])

  return (
    <FlexBoxY>
      <Box display="flex" alignItems="center" width="100%" maxWidth="100%" style={{ gap: 16 }}>
        <Checkbox onChange={() => onCheck(item.id)} checked={item.checked} className="mt-4" />
        {COLUMNS.map((column) => renderColumn(column, item, { onDelete, ...expandProps }))}
      </Box>

      {customChildren}
    </FlexBoxY>
  )
}

export { InvoiceItem }
