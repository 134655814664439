import React from 'react'

import styled from '@emotion/styled'

import { CloseOutlined, SettingOutlined } from '@ant-design/icons'
import { Typography, Table, TableProps, Button } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { Drawer, DrawerBody, DrawerFooter, DrawerRef } from 'common/components/Drawer'
import { InputCurrency } from 'common/components/InputCurrency'
import { InvoiceMaterial } from 'common/server/invoice'

import { useInvoice } from '../../context'
import { TaxInputProps } from './types'

const TableWithRef = React.forwardRef<HTMLDivElement, TableProps<InvoiceMaterial>>((props, ref) => (
  <Table {...props} ref={ref} />
))

const TableStyled = styled(TableWithRef)`
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: ${({ theme }) => theme.colors['blue-1']};
  }
`

export const TaxInputDrawer = (props: TaxInputProps) => {
  const { currencyFormatter, calcExtCost, invoice, handleUpdateInvoiceMaterials } = useInvoice()

  const drawerRef = React.useRef<DrawerRef>()

  const [selectedItems, setSelectedItems] = React.useState<string[]>([])

  const handleSelection = (selectedRowKeys: string[]) => {
    setSelectedItems(selectedRowKeys)
  }

  const handleDrawerOpen = React.useCallback(() => {
    const currentSelectedKeys =
      invoice?.invoice_materials?.filter((material) => material.accepts_tax_split)?.map((material) => material.id) || []
    setSelectedItems(currentSelectedKeys)
    drawerRef.current?.show()
  }, [invoice?.invoice_materials, setSelectedItems])

  const handleSave = React.useCallback(() => {
    const updatedMaterials = invoice?.invoice_materials.map((invoiceMaterial) => ({
      ...invoiceMaterial,
      accepts_tax_split: selectedItems.includes(invoiceMaterial.id),
    }))

    handleUpdateInvoiceMaterials(updatedMaterials)
    drawerRef.current?.close()
  }, [invoice?.invoice_materials, selectedItems])

  const handleCancel = React.useCallback(() => {
    const currentSelectedKeys =
      invoice?.invoice_materials?.filter((material) => material.accepts_tax_split)?.map((material) => material.id) || []
    setSelectedItems(currentSelectedKeys)
    drawerRef.current?.close()
  }, [invoice?.invoice_materials])

  const rowSelectionProps = React.useMemo(() => {
    if (props?.disabled) return undefined
    return {
      onChange: handleSelection,
      selectedRowKeys: selectedItems,
    }
  }, [props?.disabled, handleSelection, selectedItems])

  return (
    <>
      <InputCurrency addonAfter={<SettingOutlined onClick={handleDrawerOpen} />} {...props} />

      <Drawer
        ref={drawerRef}
        closable={false}
        width="50%"
        forceRender
        bodyStyle={{ flexDirection: 'column' }}
        title={
          <FlexBoxX alignItems="center" justifyContent="space-between">
            <Typography.Text>Tax Split</Typography.Text>
            <Button type="text" icon={<CloseOutlined />} onClick={handleCancel} />
          </FlexBoxX>
        }
      >
        <DrawerBody px={24}>
          <Typography.Paragraph>
            Select which items will receive the split tax. The unselected items ext. cost won&apos;t be considered for
            the split calculation.
          </Typography.Paragraph>
          <TableStyled
            size="small"
            rowKey="id"
            rowSelection={rowSelectionProps}
            pagination={false}
            scroll={{ y: 'max-content', x: 480 }}
            dataSource={invoice?.invoice_materials}
            columns={[
              { title: 'Invoice Description', dataIndex: 'description', ellipsis: true },
              { title: 'Qty', dataIndex: 'quantity_shipped', width: 50, align: 'right' },
              {
                title: 'UOM',
                dataIndex: 'uom',
                width: 100,
                ellipsis: true,
                render: (_, record) => record?.unit?.name || record?.uom,
              },
              {
                title: 'Unit Cost',
                dataIndex: 'unit_price',
                align: 'right',
                width: 100,
                render: (value = 0) => (
                  <Typography.Text style={{ whiteSpace: 'nowrap' }}>{currencyFormatter(value)}</Typography.Text>
                ),
              },
              {
                title: 'Ext. Cost',
                dataIndex: 'extended_price',
                align: 'right',
                width: 100,
                render: (_, record) => {
                  const extCost = calcExtCost({
                    unitCost: record?.unit_price,
                    quantity: record?.quantity_shipped,
                    multiplier: record?.unit?.multiplier,
                    qtyIncrement: record?.unit?.qty_increment,
                  })

                  return (
                    <Typography.Text style={{ whiteSpace: 'nowrap' }}>{currencyFormatter(extCost)}</Typography.Text>
                  )
                },
              },
            ]}
          />
        </DrawerBody>

        <DrawerFooter>
          <Button onClick={handleCancel} style={{ width: 100 }}>
            Cancel
          </Button>

          <Button type="primary" onClick={handleSave} style={{ width: 100 }}>
            Save
          </Button>
        </DrawerFooter>
      </Drawer>
    </>
  )
}
