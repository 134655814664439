import React from 'react'

import { Alert, Button, Divider, message, Modal, Timeline, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerProps, DrawerRef } from 'common/components/Drawer'
import DeliveryState from 'common/components/statuses/delivery_state'
import { useQuery } from 'common/hooks/use-query'
import { OrderDelivery } from 'common/server/deliveries'
import { OrderMaterial } from 'common/server/orders'
import { OrderStates } from 'common/server/server_types'

import { useStores } from 'contractor/hooks/use-stores'

import ReceivingDetails from './receiving'
import RequestDetails from './request'
import ShippingDetails from './shipping'

type DetailTitleProps = {
  state: string
  isPickUp: boolean
} & Pick<DeliveryDetailProps, 'orderInfo'>

const DetailTitle = ({ orderInfo, state, isPickUp }: DetailTitleProps) => {
  const type = isPickUp ? 'Pick Up' : 'Delivery'

  if (!orderInfo) {
    return <div>{type}</div>
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" width="100%">
        <Typography.Text style={{ marginRight: 12 }}>{type}</Typography.Text>
        <DeliveryState state={state} style={{ margin: 0 }} />
      </Box>

      <Typography.Link style={{ width: '100%' }} href={`/order/${orderInfo.id}`} target="_blank">
        {orderInfo.order_number}:{orderInfo.order_package_name} - {orderInfo.vendor_name}
      </Typography.Link>
    </Box>
  )
}

type DeliveryDetailProps = {
  orderDelivery: OrderDelivery
  onUpdate?: (delivery: OrderDelivery) => Promise<void>
  onConfirm?: (delivery: OrderDelivery) => void
  project_ids: string[]
  orderInfo?: {
    id: string
    vendor_name: string
    order_package_name: string
    state: OrderStates
    order_number: string
    order_materials: OrderMaterial[]
  }
  disableEditing?: boolean
} & Pick<DrawerProps, 'onClose'>

export const DeliveryDetail = observer<DeliveryDetailProps, DrawerRef>(
  ({ orderDelivery, onUpdate, onClose, onConfirm, project_ids, orderInfo, disableEditing }, ref) => {
    const { addressStore, uploaderStore, userStore } = useStores()
    useQuery(addressStore.maybeIndexAddresses)

    const disabled = disableEditing || !userStore.canEditDeliveryInformation

    // Create a local version of the deliver
    const [delivery, setDelivery] = React.useState({ ...orderDelivery })

    const [isSubmitting, setSubmitting] = React.useState(false)
    const [confirmDisabled, setConfirmDisabled] = React.useState(false)

    React.useEffect(() => setDelivery({ ...orderDelivery }), [orderDelivery])

    const onChange = (paramsToUpdate) => {
      setDelivery((prevDelivery) => ({ ...prevDelivery, ...paramsToUpdate }))
    }

    const handleUpdate = async () => {
      if (!uploaderStore.checkIfAllUploadsCompleted()) {
        Modal.error({ title: 'Uploads have not completed yet, please try again' })
        return
      }

      try {
        setSubmitting(true)
        await onUpdate({
          ...delivery,
          delivery_files_signed_ids: uploaderStore.signedIds('delivery_files'),
          delivery_files_delete_ids: uploaderStore.deleteAttachmentIds['delivery_files'],
        })

        message.success('Delivery updated')
      } catch (error) {
        message.error(error?.response?.data?.error || 'Unable to update delivery')
      } finally {
        setSubmitting(false)
      }
    }

    return (
      <Drawer
        data-cy="delivery-detail-drawer"
        bgGray
        closable={false}
        width={800}
        ref={ref}
        title={<DetailTitle orderInfo={orderInfo} state={delivery.state} isPickUp={delivery.is_pick_up} />}
        onClose={onClose}
      >
        <Box
          px={{ _: 8, xs: 20 }}
          py={20}
          width="100%"
          height="100%"
          overflowY="auto"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Timeline>
            <Timeline.Item>
              <RequestDetails
                delivery={delivery}
                onChange={onChange}
                project_ids={project_ids}
                disableEditing={disabled}
              />
            </Timeline.Item>

            {!delivery.is_pick_up && (
              <>
                <Timeline.Item>
                  <ShippingDetails
                    delivery={delivery}
                    onChange={onChange}
                    disableEditing={disabled}
                    data-cy="shipping-details"
                  />
                </Timeline.Item>
              </>
            )}

            <Timeline.Item>
              <ReceivingDetails
                delivery={delivery}
                onChange={onChange}
                disableEditing={disabled}
                orderState={orderInfo?.state}
                orderMaterials={orderInfo?.order_materials?.filter((item) => item.delivery_id === delivery.id)}
                updateConfirmDisabled={(confirmDisabled) => {
                  setConfirmDisabled(confirmDisabled)
                }}
              />
            </Timeline.Item>
          </Timeline>

          <Box>
            {onConfirm && (
              <Alert
                type="warning"
                message="Confirming delivery details will ensure this information is sent to your vendor, but only once the order is either placed or updated."
              />
            )}

            <Divider style={{ margin: '20px 0' }} />

            <Box display="flex" justifyContent="space-between" hidden={disabled}>
              <Button data-cy="cancel-delivery-button" onClick={onClose} style={{ width: 100 }} loading={isSubmitting}>
                Cancel
              </Button>

              {onUpdate && (
                <Button
                  data-cy="save-delivery-button"
                  disabled={disabled}
                  type="primary"
                  onClick={handleUpdate}
                  style={{ width: 100 }}
                  loading={isSubmitting}
                >
                  Save
                </Button>
              )}

              {onConfirm && (
                <Button
                  data-cy="confirm-delivery-button"
                  disabled={disabled || confirmDisabled}
                  type="primary"
                  onClick={() =>
                    onConfirm({
                      ...delivery,
                      delivery_files_signed_ids: uploaderStore.signedIds('delivery_files'),
                      delivery_files_delete_ids: uploaderStore.deleteAttachmentIds['delivery_files'],
                    })
                  }
                  style={{ width: 100 }}
                >
                  Confirm
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
    )
  },
  { forwardRef: true },
)
