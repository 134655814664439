import { BindAll } from 'lodash-decorators'

import { computed, observable } from 'mobx'

import { AmplitudeUser } from 'common/analytics/amplitude/initialize_amplitude'
import { setEventsUserInfo } from 'common/analytics/event_tracking'
import { HeapUser } from 'common/analytics/heap/initialize_heap'
import { PendoUser } from 'common/analytics/pendo/initialize_pendo'
import * as newrelic from 'common/helpers/new_relic'
import { OrderStates, OrderSubStates, UserRoles } from 'common/server/server_types'
import { Flags } from 'common/server/user'
import UserBaseStore from 'common/stores/UserBaseStore'

import {
  show,
  ShowUserResponse,
  update,
  UserPasswordEditOptions,
  UserPersonalInformationEditOptions,
} from 'contractor/server/user'

import { ShowOrderResponse } from '../server/orders'

@BindAll()
export default class UserStore extends UserBaseStore {
  @observable.struct currentUser: ShowUserResponse
  @observable feature_flags: Flags
  pendoInitialized = false

  @computed get userId(): string {
    return this.currentUser?.id
  }

  @computed get companyUserId(): string {
    return this.currentUser?.company_user_id
  }

  @computed get companyId(): string {
    return this.currentUser?.company_id
  }

  @computed get isMaterialRequester(): boolean {
    return this.currentUser?.role?.name === UserRoles.MATERIAL_REQUESTER
  }

  /*
    This is not all the invoice permissions.
    It is the permissions that allow the user to see the invoice page.
  */
  @computed get hasAnyInvoicePermission(): boolean {
    return (
      this.canEditInvoices || this.canViewAllInvoices || this.canViewAllMyProjectInvoices || this.canViewInvoiceInbox
    )
  }

  // Helpers for permissions
  @computed get canManageBilling(): boolean {
    return this.hasPermission('can_manage_billing')
  }

  @computed get canManageProjects(): boolean {
    return this.hasPermission('can_manage_projects')
  }

  @computed get canReadProjects(): boolean {
    return this.hasPermission('can_read_projects')
  }

  @computed get canManageOrders(): boolean {
    return this.hasPermission('can_manage_orders_and_rfqs')
  }

  @computed get canUseAllInvoices(): boolean {
    return this.hasPermission('can_use_all_invoices')
  }

  // Check if the user can cancel an order

  @computed get canReadOrders(): boolean {
    return this.hasPermission('can_read_orders_and_rfqs')
  }

  @computed get canManageCompanySettings(): boolean {
    return this.hasPermission('can_manage_company_settings')
  }

  @computed get canReadCompanySettings(): boolean {
    return this.hasPermission('can_read_company_settings')
  }

  @computed get canCreateNewMaterial(): boolean {
    return this.hasPermission('can_create_new_material')
  }

  @computed get canEditMaterialDatabase(): boolean {
    return this.hasPermission('can_edit_material_database')
  }

  @computed get canCreateDrafts(): boolean {
    return this.hasPermission('can_create_drafts')
  }

  @computed get canManageVendors(): boolean {
    return this.hasPermission('can_edit_vendor_database')
  }

  @computed get canReadVendors(): boolean {
    return this.hasPermission('can_read_vendors')
  }

  @computed get canManageUsers(): boolean {
    return this.hasPermission('can_manage_users')
  }

  @computed get canSendAndUpdateOrders(): boolean {
    return this.hasPermission('can_send_and_update_orders')
  }

  @computed get canSendAndUpdateRfqs(): boolean {
    return this.hasPermission('can_send_and_update_rfqs')
  }

  @computed get canInputVendorData(): boolean {
    return this.hasPermission('can_input_vendor_data')
  }

  @computed get canApproveOrderRequest(): boolean {
    return this.hasPermission('can_approve_order_request')
  }

  @computed get canPostInvoices(): boolean {
    return this.hasPermission('can_post_invoices')
  }

  @computed get canChangeStatusAndAssignee(): boolean {
    return this.hasPermission('can_change_status_and_assignee')
  }

  @computed get canViewHistoricalPricing(): boolean {
    return this.hasPermission('can_view_historical_pricing')
  }

  @computed get canSyncWithErp(): boolean {
    return this.hasPermission('can_sync_with_erp')
  }

  @computed get canEditDeliveryInformation(): boolean {
    return this.hasPermission('can_edit_delivery_information')
  }

  @computed get canDeleteInvoices(): boolean {
    return this.hasPermission('can_delete_invoices')
  }

  @computed get canEditInvoices(): boolean {
    return this.hasPermission('can_edit_invoices')
  }

  @computed get canCreateQuickPO(): boolean {
    return this.hasPermission('can_create_quick_po')
  }

  @computed get canViewInvoiceInbox(): boolean {
    return this.hasPermission('can_view_invoice_inbox')
  }

  @computed get canViewAllInvoices(): boolean {
    return this.hasPermission('can_view_all_invoices')
  }

  @computed get canViewAllMyProjectInvoices(): boolean {
    return this.hasPermission('can_view_all_my_project_invoices')
  }

  @computed get canSeeOrdersFromAllGroups(): boolean {
    return this.hasPermission('can_see_orders_from_all_groups')
  }

  @computed get cannotSendAndUpdateOrders(): boolean {
    return !this.canSendAndUpdateOrders && !this.canSendAndUpdateRfqs
  }

  @computed get canSendOrderFromQuoteLeveling(): boolean {
    return this.hasPermission('can_send_and_update_orders') && this.hasPermission('can_send_and_update_rfqs')
  }

  @computed get canEditCostCode(): boolean {
    return this.hasPermission('can_edit_cost_code')
  }

  @computed get cannotEditCostCode(): boolean {
    return !this.canEditCostCode
  }

  @computed get canUnlockOrder(): boolean {
    return this.hasPermission('can_unlock_order')
  }

  // Tier permissions
  @computed get canUseInvoices(): boolean {
    return this.hasPermission('can_use_invoices')
  }

  @computed get canUseHistoricalCost(): boolean {
    return this.hasPermission('can_use_historical_cost')
  }

  @computed get canUseRfqLeveling(): boolean {
    return this.hasPermission('can_use_rfq_leveling')
  }

  @computed get canUseAutoPoGeneration(): boolean {
    return this.hasPermission('can_use_auto_po_generation')
  }

  @computed get canUseCreateRFQ(): boolean {
    return this.hasPermission('can_use_rfq')
  }

  @computed get canUseMaterialsList(): boolean {
    return this.hasPermission('can_use_materials_list')
  }

  @computed get canUseSwitchOrderRFQ(): boolean {
    return this.hasPermission('can_use_switch_order_rfq')
  }

  @computed get canUseCostCode(): boolean {
    return this.hasPermission('can_use_cost_code')
  }

  @computed get canUseRequiredFieldsSettings(): boolean {
    return this.hasPermission('can_use_required_fields_settings')
  }

  @computed get canUseIntegrations(): boolean {
    return this.hasPermission('can_use_integrations')
  }

  @computed get canSeeAndCreateCommitments(): boolean {
    return this.hasPermission('can_see_and_create_commitments')
  }

  @computed get canEditAndDeleteCommitments(): boolean {
    return this.hasPermission('can_edit_and_delete_commitments')
  }

  @computed get canViewPriceSheets(): boolean {
    return this.hasPermission('can_view_price_sheets')
  }

  @computed get canCreatePriceSheets(): boolean {
    return this.hasPermission('can_create_price_sheets')
  }

  @computed get canEditPriceSheets(): boolean {
    return this.hasPermission('can_edit_price_sheets')
  }

  async showUser(): Promise<ShowUserResponse> {
    this.currentUser = (await show()).data
    this.feature_flags = this.currentUser.feature_flags

    const featureFlagInitializeAmplitude = this.feature_flags.amplitude_analysis_tool.enabled
    const featureFlagInitializeHeap = this.feature_flags.heap_analysis_tool.enabled
    const featureFlagInitializePendo = this.feature_flags.pendo_analysis_tool.enabled

    // Analytic tools initialization
    const analyticsUser = {
      id: this.currentUser.id,
      email: this.currentUser.email,
      role: this.currentUser.role?.name,
      type: 'CONTRACTOR',
      account: {
        id: this.currentUser.company_id,
        name: this.currentUser.company_name,
        type: this.currentUser.company_type,
      },
    } as PendoUser | HeapUser | AmplitudeUser
    this.maybeInitializeAmplitude(analyticsUser, featureFlagInitializeAmplitude)
    this.maybeInitializePendo(analyticsUser, featureFlagInitializePendo)
    this.maybeInitializeHeap(analyticsUser, featureFlagInitializeHeap)

    newrelic.setUserInfo({
      userId: this.currentUser.id,
      email: this.currentUser.email,
      companyId: this.currentUser.company_id,
      companyName: this.currentUser.company_name,
      type: 'CONTRACTOR',
    })

    // Cache user info for Pendo Event Tracking
    setEventsUserInfo(this.currentUser.id, this.currentUser.company_id, this.currentUser.company_name)

    return this.currentUser
  }

  async update() {
    await update({ terms_and_conditions_approved: true })
    this.showUser()
  }

  async updatePersonalInformation(params: UserPersonalInformationEditOptions): Promise<void> {
    await update(params)
    this.showUser()
  }

  updatePassword(params: UserPasswordEditOptions) {
    return update(params)
  }

  // Users should only be able to cancel BEFORE the vendor confirms the order
  canCancelOrder(order: ShowOrderResponse): boolean {
    return (
      this.canManageOrders &&
      [OrderStates.DRAFT, OrderStates.REQUESTED, OrderStates.QUOTED, OrderStates.ORDERED].includes(order.state) &&
      OrderSubStates.ORDERED_CONFIRMED != order.sub_state
    )
  }

  // Check if user has a specific permission
  private hasPermission(permission: string) {
    return computed(() => this.currentUser?.user_permissions?.includes(permission)).get()
  }
}
