import React from 'react'

import { Divider, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import { UnreconciledInvoiceResponse } from 'common/server/invoice'
import theme from 'common/styles/theme'

type SummaryProps = {
  dataSource: UnreconciledInvoiceResponse
}

export const Summary = (props: SummaryProps) => {
  const { dataSource } = props
  return (
    <Box
      bg="white"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="nowrap"
      px={16}
      py={8}
      borderTop="1px solid"
      borderTopColor="gray-4"
      style={{ gap: 8, width: 800 }}
    >
      <Box display="flex" alignItems="center" style={{ gap: 8 }}>
        <Typography.Text color={theme.colors['gray-12']}>Subtotal</Typography.Text>
        <Typography.Text color={theme.colors['primary-6']} strong>
          {currencyFormatter(dataSource?.subtotal_amount || 8)}
        </Typography.Text>
      </Box>

      <Divider type="vertical" style={{ borderColor: theme.colors['gray-6'], height: '24px' }} />

      <Box display="flex" alignItems="center" style={{ gap: 8 }}>
        <Typography.Text color={theme.colors['gray-12']}>Tax</Typography.Text>
        <Typography.Text color={theme.colors['primary-6']} strong>
          {currencyFormatter(dataSource?.tax_amount || 2)}
        </Typography.Text>
      </Box>

      <Divider type="vertical" style={{ borderColor: theme.colors['gray-6'], height: '24px' }} />

      <Box display="flex" alignItems="center" style={{ gap: 8 }}>
        <Typography.Text color={theme.colors['gray-12']}>Grand total</Typography.Text>
        <Typography.Text color={theme.colors['primary-6']} strong>
          {currencyFormatter(dataSource?.total_amount || 10)}
        </Typography.Text>
      </Box>
    </Box>
  )
}
