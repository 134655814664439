import React, { useState } from 'react'

import styled from '@emotion/styled'

import { CheckCircleOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Card } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { useTheme } from 'common/hooks/use-theme'
import { InvoiceResponse } from 'common/server/invoice'

import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from './context'
import { extractQtyAndUnitsOfOrderMaterials } from './helpers'
import { InvoiceMaterials } from './InvoiceMaterials'
import { OrderMaterial, OrderSearch } from './OrderDetailNew'
import { OrdersTags } from './OrdersTags'

type ReconcileStepProps = {
  attachedOrderIds: string[]
  onAttachOrder: (orderId: Nullable<string>) => void
  onUnAttachOrder: (orderId: Nullable<string>) => void
  isLoading?: boolean
  visible: boolean
  saveInvoice: (autoMatch?: boolean, orderIds?: string[]) => Promise<InvoiceResponse>
}

const Wrapper = styled(Box)`
  .ant-table .ant-table-thead {
    position: sticky;
    top: 33px;
    z-index: 1;
  }
`

export const ReconcileStep = observer<ReconcileStepProps>(
  ({ attachedOrderIds = [], onAttachOrder, onUnAttachOrder, isLoading, visible, saveInvoice }) => {
    const { invoiceStore } = useStores()

    const theme = useTheme()

    const { calculatedGrandTotal, lockInvoice } = useInvoice()

    const [filterByInvoiceMaterials, setFilterByInvoiceMaterials] = useState(() => {
      return invoiceStore.invoice?.invoice_materials?.some(
        (invoiceMaterial) => !invoiceMaterial?.order_materials?.length,
      )
        ? ['no-matches']
        : ['all']
    })
    const [filterByOrders, setFilterByOrders] = useState(['all'])

    const invoice = invoiceStore.invoice

    const handleFilterInvoiceMaterialsTable = (invoiceMaterial) => {
      if (filterByInvoiceMaterials.includes('all')) {
        return true
      }

      const extractedQuantity = Number(invoiceMaterial?.quantity_shipped || 0)
      const invoiceUnit = invoiceMaterial?.uom?.trim() || ''

      const [orderMaterialsQuantities, orderMaterialsUnits] = extractQtyAndUnitsOfOrderMaterials(
        invoiceMaterial?.order_materials,
      )

      const hasOrderMaterials = !!invoiceMaterial?.order_materials?.length

      if (filterByInvoiceMaterials.includes('matches')) {
        return (
          hasOrderMaterials &&
          orderMaterialsQuantities?.length <= 1 &&
          orderMaterialsQuantities[0] === extractedQuantity &&
          orderMaterialsUnits?.length <= 1 &&
          invoiceUnit === orderMaterialsUnits[0]
        )
      }

      if (filterByInvoiceMaterials.includes('partial-matches')) {
        return (
          hasOrderMaterials &&
          (orderMaterialsQuantities?.length > 1 ||
            orderMaterialsUnits?.length > 1 ||
            orderMaterialsQuantities[0] !== extractedQuantity ||
            invoiceUnit !== orderMaterialsUnits[0])
        )
      }

      return !invoiceMaterial?.order_materials?.length
    }

    const handleFilteOrderMaterialsTable = (orderMaterial) => {
      if (filterByOrders.includes('all')) {
        return true
      }

      return filterByOrders.includes(orderMaterial?.['order_id'])
    }

    return (
      <Wrapper
        display={visible ? 'grid' : 'none'}
        gridTemplateColumns="2fr 1fr"
        height="fit-content"
        minHeight="100%"
        width="100%"
        minWidth={1200}
        style={{ gap: 8 }}
      >
        <Box display="flex" flexDirection="column">
          <RibbonFilter
            boxProps={{
              position: 'sticky',
              top: -15,
              zIndex: 1,
              padding: '8px 0',
              backgroundColor: theme.colors['gray-2'],
            }}
            value={filterByInvoiceMaterials}
            onChange={setFilterByInvoiceMaterials}
            options={[
              { label: 'All', filter: 'all' },
              {
                label: 'Matches',
                filter: 'matches',
                rightIcon: <CheckCircleOutlined style={{ color: theme.colors.success }} />,
              },
              {
                label: 'Partial Matches',
                filter: 'partial-matches',
                rightIcon: <WarningOutlined style={{ color: theme.colors.warning }} />,
              },
              {
                label: 'No Matches',
                filter: 'no-matches',
                rightIcon: <ExclamationCircleOutlined style={{ color: theme.colors.error }} />,
              },
            ]}
          />

          <Card
            style={{ width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}
            bodyStyle={{ padding: 10, flexGrow: 1 }}
          >
            <InvoiceMaterials onFilterTable={handleFilterInvoiceMaterialsTable} />
          </Card>
        </Box>

        <Box display="flex" flexDirection="column">
          <OrdersTags
            disabled={!lockInvoice.canEditInvoice}
            saveInvoice={saveInvoice}
            attachedOrderIds={attachedOrderIds}
            onAttachOrder={onAttachOrder}
            onUnAttachOrder={onUnAttachOrder}
            value={filterByOrders}
            onChange={setFilterByOrders}
          />

          <Card
            style={{ width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}
            bodyStyle={{ padding: 10, flexGrow: 1 }}
          >
            {attachedOrderIds?.length ? (
              <OrderMaterial isLoading={isLoading} onFilterTable={handleFilteOrderMaterialsTable} />
            ) : (
              <OrderSearch
                saveInvoice={saveInvoice}
                onSelect={(order) => onAttachOrder(order?.id)}
                onCreated={(order) => onAttachOrder(order?.id)}
                initialValues={{
                  grandTotal: calculatedGrandTotal,
                  orderNumber: invoice?.extracted_order_number,
                  companyVendorId: invoice?.company_vendor_id,
                }}
              />
            )}
          </Card>
        </Box>
      </Wrapper>
    )
  },
)
