import React from 'react'

import { Form, Input } from 'antd'

import { BaseInputProps } from '../../hooks/editable-hooks'

export const TextInput: React.FC<BaseInputProps> = ({ name, title, rules, onSave, ref }) => {
  return (
    <Form.Item
      style={{ margin: 0 }}
      name={name}
      rules={rules || [{ required: false, message: `Please input ${title}!` }]}
    >
      <Input ref={ref} onPressEnter={onSave} onBlur={onSave} />
    </Form.Item>
  )
}
