import React from 'react'

import { Empty, Typography } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { PsqlTable } from 'common/components/PsqlTable'
import { ResponsiveTable } from 'common/components/ResponsiveTable'
import { SearchInput } from 'common/components/SearchInput'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { Actions } from 'contractor/pages/CompanyMaterials/Materials/Actions'
import { ConsolidatedCompanyMaterials } from 'contractor/server/company_materials'
import { CostCodeSettings } from 'contractor/server/company_settings/other_settings'

import { CompanyMaterialMobileItem } from './components/company_material_mobile_item'
import { CompanyMaterialsOptions } from './Options'

type ContentProps = {
  onAdd: () => void
  onClickRow: (params: { rowIndex: number; row: ConsolidatedCompanyMaterials.CompanyMaterial }) => void
}

const makeCostCodeLabel = (
  companyMaterial: ConsolidatedCompanyMaterials.CompanyMaterial,
  costCodeSettings: CostCodeSettings,
) => {
  const phaseEnabled = costCodeSettings?.phase_code_enabled && !costCodeSettings?.independent_phase_codes_enabled
  const classEnabled = costCodeSettings?.class_enabled

  if (!phaseEnabled && !classEnabled) {
    return companyMaterial?.cost_code?.code
  }

  if (phaseEnabled && classEnabled) {
    return `${companyMaterial?.cost_code?.phase_code}.${companyMaterial?.cost_code?.code}.${companyMaterial?.cost_code?.clazz}`
  }

  if (phaseEnabled && !classEnabled) {
    return `${companyMaterial?.cost_code?.phase_code}.${companyMaterial?.cost_code?.code}`
  }

  if (!phaseEnabled && classEnabled) {
    return `${companyMaterial?.cost_code?.code}.${companyMaterial?.cost_code?.clazz}`
  }

  return null
}

function Content({ onClickRow, onAdd }: ContentProps) {
  const {
    companyMaterialStore: { listStore },
    companySettingStore,
  } = useStores()

  const persistentFilters = usePersistentFilters()

  const { company_attributes } = companySettingStore.companyMaterialConfiguration
  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const { isLoading } = useQuery(() => {
    persistentFilters.handleChangeFilters({ active: true }, false)
    persistentFilters.handleChangeFilters({ material_type: 'CompanyMaterial' }, false)

    return persistentFilters.init()
  }, [])

  const renderMobileCard = React.useCallback(
    (item: ConsolidatedCompanyMaterials.CompanyMaterial) => {
      return (
        <CompanyMaterialMobileItem
          companyAttributes={company_attributes}
          description={item.description}
          costCode={makeCostCodeLabel(item, costCodeSettings)}
          productIdentifier={item.product_identifier}
          unit={item.description}
          material={item.material}
          active={item.active}
        />
      )
    },
    [company_attributes?.length, costCodeSettings],
  )

  const handleSearch = (search: string) => persistentFilters.handleChangeFilters({ search }, false)

  if (isLoading) return <Loading />

  return (
    <Page>
      <Page.Header display="grid" gridGap={16}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Typography.Title style={{ marginBottom: 0 }} level={3}>
            Material Database
          </Typography.Title>

          <Box flexGrow={0}>
            <Actions goBackWithRefreshTable={listStore.fetchRecords} onAdd={onAdd} />
          </Box>
        </Box>

        <Box display="flex" gridGap={8} alignItems="center" justifyContent="space-between">
          <SearchInput value={listStore.searchState.search} onSearch={handleSearch} />
          <CompanyMaterialsOptions />
        </Box>
      </Page.Header>

      <Page.Content p={0} px={{ _: 0, md: 12, lg: 16 }} pb={24} pt={{ _: 0, md: 16 }} height="100%">
        {listStore.isFetching && listStore.records.length === 0 && listStore.searchState.page === 1 ? (
          <Loading />
        ) : listStore.records.length === 0 ? (
          <Box
            p={{ _: 12, lg: 24 }}
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <Typography.Title level={5} style={{ textAlign: 'center' }}>
                  Sorry! No Items were found with these search criteria.
                </Typography.Title>
              }
            />
          </Box>
        ) : (
          <ResponsiveTable
            Table={PsqlTable}
            data={toJS(listStore.records) ?? []}
            tableName="AllOrders"
            hasMore={listStore.hasMore}
            loadMore={listStore.fetchNextPage}
            onLoadMore={listStore.fetchNextPage}
            renderMobileCard={renderMobileCard}
            onClickRow={onClickRow}
          />
        )}
      </Page.Content>
    </Page>
  )
}

export const CompanyMaterialsContent = observer(Content)
