import React, { useEffect, useState } from 'react'

import { Button, Drawer, Space, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxX } from 'common/components/boxes'
import { InputCurrency } from 'common/components/InputCurrency'

import { useStores } from 'contractor/hooks/use-stores'
import { Commitment } from 'contractor/server/commitments'

type AdditionalCostsDrawerProps = {
  visible: boolean
  onClose: () => void
  title: string
  commitment: Commitment
}

export const AdditionalCostsDrawer = observer<AdditionalCostsDrawerProps>(
  ({ visible, onClose, title, commitment }: AdditionalCostsDrawerProps) => {
    const { commitmentStore } = useStores()

    const [fuelCharge, setFuelCharge] = useState<number>(commitment?.fuel_charge || 0)
    const [environmentalCharge, setEnvironmentalCharge] = useState<number>(commitment?.environmental_charge || 0)

    useEffect(() => {
      if (visible) {
        setFuelCharge(commitment?.fuel_charge || 0)
        setEnvironmentalCharge(commitment?.environmental_charge || 0)
      }
    }, [visible, commitment])

    const handleConfirm = () => {
      if (commitment) {
        commitmentStore.updateSelectedCommitment('fuel_charge', fuelCharge)
        commitmentStore.updateSelectedCommitment('environmental_charge', environmentalCharge)
      }
      onClose()
    }

    return (
      <Drawer
        style={{ padding: 0, paddingLeft: 16, paddingRight: 16 }}
        title={title}
        placement="bottom"
        closable={true}
        onClose={onClose}
        open={visible}
        height={150}
      >
        <FlexBoxX justifyContent="space-between" width="100%">
          <Space>
            <Space style={{ marginRight: 16 }}>
              <InputCurrency value={fuelCharge} onChange={(value) => setFuelCharge(value)} />
              <Typography.Text type="danger">$</Typography.Text>
              <Typography.Text style={{ whiteSpace: 'nowrap' }}>Fuel charge</Typography.Text>
            </Space>

            <Space>
              <InputCurrency value={environmentalCharge} onChange={(value) => setEnvironmentalCharge(value)} />
              <Typography.Text type="danger">$</Typography.Text>
              <Typography.Text style={{ whiteSpace: 'nowrap' }}>Environmental charge</Typography.Text>
            </Space>
          </Space>

          <Space style={{ marginTop: 16 }}>
            <Button type="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </Space>
        </FlexBoxX>
      </Drawer>
    )
  },
)
