import React from 'react'

import { Form, InputNumber } from 'antd'

import { BaseInputProps } from '../../hooks/editable-hooks'

export const NumberInput: React.FC<BaseInputProps> = ({ name, title, rules, onSave, ref }) => {
  return (
    <Form.Item
      style={{ margin: 0 }}
      name={name}
      rules={rules || [{ required: false, message: `Please input ${title}!` }]}
    >
      <InputNumber ref={ref} onPressEnter={onSave} onBlur={onSave} style={{ width: '100%' }} />
    </Form.Item>
  )
}
