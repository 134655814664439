import moment from 'moment/moment'

import { message } from 'antd'

import { useNotifyCommitment } from 'common/hooks/use-notify-commitment'

import { useStores } from 'contractor/hooks/use-stores'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'

export const useMaterialCommitment = () => {
  const { commitmentPayload, goBack, setSubmitting } = useCommitment()
  const { commitmentStore } = useStores()
  const { notifyCommitment } = useNotifyCommitment()

  const handleLogError = (error, defaultMessage = '') => {
    if (error?.response?.data?.error) {
      message.error({
        content: error.response.data.error,
        duration: 8,
      })
    } else {
      message.error(defaultMessage || `Unable to save the commitment`)
    }
  }

  const handleUpdateCommitment = async () => {
    try {
      setSubmitting(true)
      await commitmentStore.updateCommitment(commitmentPayload)

      notifyCommitment({
        message: 'Commitment Updated',
        commitmentId: commitmentPayload.id,
        commitmentType: commitmentPayload.commitment_type,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleSaveDraftCommitment = async () => {
    try {
      setSubmitting(true)
      let commitmentId = ''
      const commitment = await commitmentStore.createCommitment(commitmentPayload)
      commitmentId = commitment.id

      notifyCommitment({
        message: 'Commitment saved as draft',
        commitmentId: commitmentId,
        commitmentType: commitmentPayload.commitment_type,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleCreateSubmittedCommitment = async () => {
    try {
      setSubmitting(true)
      let commitmentId = 'new'
      commitmentPayload['submitted_at'] = moment().toISOString()
      await commitmentStore.createCommitment(commitmentPayload).then((commitment) => {
        commitmentId = commitment.id
      })

      notifyCommitment({
        message: 'Commitment submitted.',
        commitmentId: commitmentId,
        commitmentType: commitmentPayload.commitment_type,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleSubmitCommitment = async () => {
    try {
      setSubmitting(true)
      commitmentPayload['submitted_at'] = moment().toISOString()
      await commitmentStore.updateCommitment(commitmentPayload)

      notifyCommitment({
        message: 'Commitment submitted',
        commitmentId: commitmentPayload.id,
        commitmentType: commitmentPayload.commitment_type,
      })

      goBack()
    } catch (error) {
      handleLogError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleDeleteCommitment = async () => {
    try {
      await commitmentStore.deleteSelectedCommitment()
      notifyCommitment({
        message: 'Commitment Deleted',
        commitmentId: commitmentPayload.id,
        commitmentType: commitmentPayload.commitment_type,
      })
      goBack()
    } catch (error) {
      handleLogError(error)
    }
  }

  return {
    handleUpdateCommitment,
    handleSubmitCommitment,
    handleCreateSubmittedCommitment,
    handleSaveDraftCommitment,
    handleDeleteCommitment,
  }
}
