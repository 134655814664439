import axios from 'axios'

export interface OrderPermissionsAttributes {
  can_see_orders_from_all_groups: boolean
  can_input_vendor_data: boolean
  can_view_historical_pricing: boolean
  can_edit_vendor_database: boolean
  can_create_new_material: boolean
  can_edit_material_database: boolean
  can_sync_with_erp: boolean
  can_send_and_update_orders: boolean
  can_edit_delivery_information: boolean
  can_approve_order_request: boolean
  can_send_and_update_rfqs: boolean
  can_create_drafts: boolean
  can_edit_cost_code: boolean
  can_unlock_order: boolean
}

export interface InvoicePermissionsAttributes {
  can_view_all_invoices: boolean
  can_view_invoice_inbox: boolean
  can_create_quick_po: boolean
  can_delete_invoices: boolean
  can_edit_invoices: boolean
  can_view_all_my_project_invoices: boolean
  can_post_invoices: boolean
  can_change_status_and_assignee: boolean
}

export interface CommitmentPermissionsAttributes {
  can_see_and_create_commitments: boolean
  can_edit_and_delete_commitments: boolean
}

export interface PriceSheetPermissionsAttributes {
  can_view_price_sheets: boolean
  can_create_price_sheets: boolean
  can_edit_price_sheets: boolean
}

export interface PriceSheetPermissions {
  ADMIN: PriceSheetPermissionsAttributes
  PURCHASER: PriceSheetPermissionsAttributes
  USER: PriceSheetPermissionsAttributes
  MATERIAL_REQUESTER: PriceSheetPermissionsAttributes
}

export interface CommitmentPermissions {
  ADMIN: CommitmentPermissionsAttributes
  PURCHASER: CommitmentPermissionsAttributes
  USER: CommitmentPermissionsAttributes
  MATERIAL_REQUESTER: CommitmentPermissionsAttributes
}

export interface OrderPermissions {
  ADMIN: OrderPermissionsAttributes
  PURCHASER: OrderPermissionsAttributes
  USER: OrderPermissionsAttributes
  MATERIAL_REQUESTER: OrderPermissionsAttributes
}

export interface InvoicePermissions {
  ADMIN: InvoicePermissionsAttributes
  PURCHASER: InvoicePermissionsAttributes
  USER: InvoicePermissionsAttributes
  MATERIAL_REQUESTER: InvoicePermissionsAttributes
}

export interface Permissions {
  invoices_permissions: InvoicePermissions
  order_permissions: OrderPermissions
}

export interface Permissions {
  order_permissions: OrderPermissions
  invoices_permissions: InvoicePermissions
  commitment_permissions: CommitmentPermissions
  price_sheet_permissions: PriceSheetPermissions
}

export function index() {
  return axios.get<Permissions>('/company_settings/permissions')
}

export function update(payload: Permissions) {
  return axios.patch<Permissions>('/company_settings/permissions', payload)
}
