import React from 'react'

import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { Tag } from 'antd'

import { RelationshipStockStatus } from 'common/server/server_types'

type AgaveSyncStatusProps = {
  status: RelationshipStockStatus
}

const defaultStyle = {
  width: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const stateMapping = {
  [RelationshipStockStatus.NOT_SYNCED]: {
    text: 'Not Synced',
    icon: <ClockCircleOutlined />,
    color: null,
  },
  [RelationshipStockStatus.SYNCING]: {
    color: 'blue',
    text: 'Syncing',
    icon: <SyncOutlined />,
  },
  [RelationshipStockStatus.FAILED]: {
    color: 'red',
    text: 'Failed',
    icon: <CloseCircleOutlined />,
  },
  [RelationshipStockStatus.SYNCED]: {
    color: 'green',
    text: 'Complete',
    icon: <CheckCircleOutlined />,
  },
}

export const AgaveSyncStatus = ({ status }: AgaveSyncStatusProps) => {
  const current = stateMapping[status]

  return (
    <Tag style={defaultStyle} icon={current?.icon} color={current?.color} data-cy="agave-sync-status">
      {current?.text}
    </Tag>
  )
}
