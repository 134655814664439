import React, { useState } from 'react'

import styled from '@emotion/styled'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Badge, Switch, Table, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { UserRoles } from 'common/server/server_types'

import { PriceSheetPermissions } from 'contractor/server/company_settings/permissions'

const TableStyled = styled(Table)`
  width: 100%;

  tr.disabled-table-row:hover > td,
  .locked-table-row {
    background-color: ${({ theme }) => theme.colors['gray-3']};
    cursor: not-allowed;
  }
`

type PriceSheetsTableProps = {
  setPriceSheetPermissions: (data: PriceSheetPermissions) => void
  priceSheetPermissions: PriceSheetPermissions
}

export const PriceSheetsTable = ({ setPriceSheetPermissions, priceSheetPermissions }: PriceSheetsTableProps) => {
  const [data, setData] = useState([
    {
      id: 1,
      permission: 'Can view price sheets',
      tooltip: "Can view the price sheet table and all price sheets' informations.",
      admin: priceSheetPermissions?.ADMIN?.can_view_price_sheets,
      purchaser: priceSheetPermissions?.PURCHASER?.can_view_price_sheets,
      user: priceSheetPermissions?.USER?.can_view_price_sheets,
      material_requester: priceSheetPermissions?.MATERIAL_REQUESTER?.can_view_price_sheets,
    },
    {
      id: 2,
      permission: 'Can create price sheets',
      tooltip: 'Can create new price sheets and edit them while in draft status.',
      admin: priceSheetPermissions?.ADMIN?.can_create_price_sheets,
      purchaser: priceSheetPermissions?.PURCHASER?.can_create_price_sheets,
      user: priceSheetPermissions?.USER?.can_create_price_sheets,
      material_requester: priceSheetPermissions?.MATERIAL_REQUESTER?.can_create_price_sheets,
    },
    {
      id: 3,
      permission: 'Can edit price sheets',
      tooltip: 'Can edit any price sheet regardless of status.',
      admin: priceSheetPermissions?.ADMIN?.can_edit_price_sheets,
      purchaser: priceSheetPermissions?.PURCHASER?.can_edit_price_sheets,
      user: priceSheetPermissions?.USER?.can_edit_price_sheets,
      material_requester: priceSheetPermissions?.MATERIAL_REQUESTER?.can_edit_price_sheets,
    },
  ])

  const handleSwitchChange = (permission: string, role: UserRoles) => {
    const newData = [...data]
    newData.map((item) => {
      if (item.permission === permission) {
        item[role.toLowerCase()] = !item[role.toLowerCase()]
      }
      return item
    })

    const updatedCommitmentPermissions = {
      ADMIN: {},
      PURCHASER: {},
      USER: {},
      MATERIAL_REQUESTER: {},
    } as PriceSheetPermissions

    for (const key in updatedCommitmentPermissions) {
      if (Object.prototype.hasOwnProperty.call(updatedCommitmentPermissions, key)) {
        const rolePermissions = updatedCommitmentPermissions[key]
        for (const dataItem of newData) {
          const permission = dataItem.permission.toLowerCase().replace(/\s+/g, '_')
          rolePermissions[permission] = dataItem[key.toLowerCase()] || false
        }
      }
    }
    setData(newData)
    setPriceSheetPermissions(updatedCommitmentPermissions)
  }

  const columns: ColumnsType<{
    disabled: boolean
    permission: string
    admin: boolean
    purchaser: boolean
    user: boolean
    material_requester: boolean
    tooltip?: string
  }> = [
    {
      title: 'Price Sheet Permissions',
      dataIndex: 'permission',
      width: 200,
      render: (permission, record) => {
        return (
          <>
            <Typography.Text>{permission}</Typography.Text>{' '}
            {record?.tooltip && (
              <Badge
                count={
                  <Tooltip placement="top" title={record?.tooltip}>
                    <QuestionCircleOutlined style={{ top: '-5px', right: '-5px', fontSize: 14 }} />
                  </Tooltip>
                }
              />
            )}
          </>
        )
      },
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      width: 150,
      render: (_, record) => (
        <Switch
          checked={record.admin}
          disabled={record.disabled}
          onChange={() => handleSwitchChange(record.permission, UserRoles.ADMIN)}
        />
      ),
    },
    {
      title: 'Purchaser',
      dataIndex: 'purchaser',
      width: 150,
      render: (_, record) => (
        <Switch
          checked={record.purchaser}
          disabled={record.disabled}
          onChange={() => handleSwitchChange(record.permission, UserRoles.PURCHASER)}
        />
      ),
    },
    {
      title: 'User',
      dataIndex: 'user',
      width: 150,
      render: (_, record) => (
        <Switch
          checked={record.user}
          disabled={record.disabled}
          onChange={() => handleSwitchChange(record.permission, UserRoles.USER)}
        />
      ),
    },
    {
      title: 'Material Requester',
      dataIndex: 'material_requester',
      width: 150,
      render: (_, record) => (
        <Switch
          checked={record.material_requester}
          disabled={record.disabled}
          onChange={() => handleSwitchChange(record.permission, UserRoles.MATERIAL_REQUESTER)}
        />
      ),
    },
  ]

  if (!priceSheetPermissions) {
    return null
  }

  return <TableStyled rowKey="id" dataSource={data} columns={columns} pagination={false} />
}
