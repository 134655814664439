import React, { useCallback } from 'react'

import { PlusOutlined, EditOutlined } from '@ant-design/icons'
import { Skeleton, Tooltip, Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DebounceSelect, DebounceSelectProps } from 'common/components/DebounceSelect'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

type SelectCostCodeNumberProps = Partial<DebounceSelectProps> & {
  onEdit: () => void
  onAdd: () => void
}

const makeOption = (costCodeNumber) => ({
  value: costCodeNumber.id,
  label: costCodeNumber.code,
})

export const SelectCostCodeNumber = observer<SelectCostCodeNumberProps>(({ onEdit, onAdd, ...props }) => {
  const { costCodeStore } = useStores()

  const { costCodeNumberListStore } = costCodeStore

  const { isLoading, data = [] } = useQuery(costCodeNumberListStore.fetchRecords)

  const fetchOptions = useCallback((search: string) => {
    return costCodeNumberListStore.setSearch(search).then((data) => data.map(makeOption))
  }, [])

  if (isLoading) {
    return <Skeleton.Input block active />
  }

  return (
    <Box width="100%" display="inline-flex" style={{ gap: 10 }}>
      <DebounceSelect
        data-cy="select-cost-code-number"
        allowClear
        placeholder="Select Code"
        loading={isLoading}
        // @ts-ignore
        initialOptions={data?.map(makeOption)}
        fetchOptions={fetchOptions}
        {...props}
      />
      <Tooltip title="Edit Code">
        <Button
          data-cy="edit-cost-number-button"
          icon={<EditOutlined />}
          onClick={onEdit}
          disabled={!props?.value}
          style={{ minWidth: 32 }}
        />
      </Tooltip>
      <Tooltip title="Add New Code">
        <Button data-cy="add-new-cost-number-button" icon={<PlusOutlined />} onClick={onAdd} style={{ minWidth: 32 }} />
      </Tooltip>
    </Box>
  )
})
