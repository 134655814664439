import React, { memo } from 'react'

import { LeftOutlined } from '@ant-design/icons'
import { Typography } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'

type HeaderProps = {
  invoiceName: string
  invoiceNumber: string
  onBack: () => void
}

export const Header = memo((props: HeaderProps) => {
  const { invoiceName, invoiceNumber, onBack } = props
  return (
    <Box>
      <Box
        style={{
          position: 'relative',
          cursor: 'pointer',
          padding: '16px 16px',
        }}
      >
        <FlexBoxX
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{ position: 'relative' }}
        >
          <LeftOutlined
            style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', fontSize: 12 }}
            onClick={onBack}
          />

          <Typography.Text ellipsis strong style={{ marginLeft: 32, maxWidth: '40vw' }}>
            {invoiceName}: {invoiceNumber}
          </Typography.Text>
        </FlexBoxX>
      </Box>
    </Box>
  )
})
