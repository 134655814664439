import axios from 'axios'

import { StandardIndexRequest, StandardIndexResponse } from 'common/server/server_types'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ConsolidatedCompanyMaterials {
  interface PreferredVendorPrice {
    id: string
    price: string
    company_vendor: { id: string; safe_globalized_vendor_name: string; external_vendor_id?: string }
  }

  interface CostCode {
    id: string
    code: string
    description?: string
    phase_code?: string
    phase_code_description?: string
    clazz?: string
    clazz_description?: string
    project_ids?: string[]
  }

  interface CostCodePhase {
    id: string
    code: string
    description?: string
    project_ids?: string[]
  }

  interface Project {
    id: string
    name: string
    project_id?: string
  }

  interface Unit {
    id?: string
    name: string
    multiplier?: string
    qty_increment?: string
    synonyms?: string[]
    override_label?: string
    increments_label?: string
    unit_name_label?: string
    unit_name_with_increment_label?: string
  }

  export interface CompanyMaterial {
    id: string
    company_id: string
    company_material_id: string
    description: string
    product_identifier?: string
    manufacturer?: string
    unit_id?: string
    unit_name?: string
    unit?: Unit
    group?: string
    sub_group?: string
    size?: string
    connection_type?: string
    material?: string
    cost_code?: CostCode
    cost_code_phase?: CostCodePhase
    cost_code_code?: string
    cost_code_phase_code?: string
    cost_code_clazz?: string
    preferred_vendor_prices?: PreferredVendorPrice[]
    projects?: Project[]
    cached_average_price?: string
    cached_lowest_price?: string
    cached_last_price?: string
    active?: boolean
    project_names?: string
    tags?: string[]
    last_ordered?: string
    manufacturer_material_id?: string
    image_url?: string
    remaining_quantity?: string
    unit_price?: string
    mix_code?: string
    mix_design?: string
  }

  export interface Facets {
    [key: string]: string | Array<string>
  }

  export type StateCounts = {
    [stateName: string]: number
  }

  export type Response = StandardIndexResponse<Array<CompanyMaterial>> & {
    state_counts: StateCounts
  }
}

export async function index(request: StandardIndexRequest) {
  const { filters, ...restRequest } = request
  const url = `/consolidated_company_materials`

  const params = {
    ...filters,
    ...restRequest,
  }

  if (Object.prototype.hasOwnProperty.call(filters, 'projects_with_empty') && !filters.projects_with_empty.length) {
    params['projects_with_empty'] = JSON.stringify([])
  }

  return axios.get<ConsolidatedCompanyMaterials.Response>(url, { params })
}

export function facets() {
  const url = `/consolidated_company_materials/facets`

  return axios.get<ConsolidatedCompanyMaterials.Facets>(url)
}

export function exportRecords(params: { filters: { [key: string]: string } }) {
  const url = `/consolidated_company_materials/export`

  return axios.post(url, params)
}
