import React from 'react'

import { Form } from 'antd'
import type { FormInstance } from 'antd/es/form'

import { EditableContext } from './hooks/editable_context'

interface EditableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index?: number
}

export const EditableRow: React.FC<EditableRowProps> = React.memo(({ index, ...props }) => {
  const [form] = Form.useForm()
  return (
    <Form key={index} form={form} component={false}>
      <EditableContext.Provider value={form as FormInstance}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
})
