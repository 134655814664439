import React from 'react'

import { Divider, Form } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxY } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { Visibility } from 'common/components/Visibility'

import { ExpirationDate } from 'contractor/pages/@v2/Commitments/common/components/Header/expiration_date'
import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'

import { CommitmentNumber } from './commitment_number'
import { CommitmentProject } from './commitment_project'
import { CompanyVendorSelect } from './company_vendor_selector'
import { LeftActions } from './left_actions'
import { RightActions } from './right_actions'

export type CommitmentHeaderProps = {
  goBack: () => void
  disabled?: boolean
  isSubmitting: boolean
  handleUpdateCommitment: () => void
  handleSaveDraftCommitment: () => void
  handleDeleteCommitment: () => void
}
export const CommitmentHeader = observer((props: CommitmentHeaderProps) => {
  const { goBack, disabled, handleSaveDraftCommitment, handleUpdateCommitment, handleDeleteCommitment } = props
  const { form } = useCommitment()
  return (
    <Form style={{ width: '100%' }} form={form}>
      <Page.Header>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <LeftActions goBack={goBack} />
          <RightActions
            handleUpdateCommitment={handleUpdateCommitment}
            handleSaveDraftCommitment={handleSaveDraftCommitment}
            handleDeleteCommitment={handleDeleteCommitment}
          />
        </Box>

        <Visibility.Hidden breakpoint="md">
          <Box display="flex" alignItems="center" justifyContent="space-between" mt={8}>
            <Box fontSize={14} display="flex" alignItems="center" style={{ gap: 8 }}>
              <CompanyVendorSelect disabled={disabled} />
              <Divider type="vertical" />
              <CommitmentProject disabled={disabled} />
              <Divider type="vertical" />
              <CommitmentNumber />
              <Divider type="vertical" />
              <ExpirationDate />
            </Box>
          </Box>
        </Visibility.Hidden>

        <Visibility.Show breakpoint="md">
          <FlexBoxY alignItems="start" justifyContent="space-between" mt={8}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <CompanyVendorSelect disabled={disabled} />
              <Divider type="vertical" />
              <CommitmentProject disabled={disabled} />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" mt={8}>
              <CommitmentNumber />
              <Divider type="vertical" />
              <ExpirationDate />
            </Box>
          </FlexBoxY>
        </Visibility.Show>
      </Page.Header>
    </Form>
  )
})
