import React, { useRef, useCallback } from 'react'

import { Button } from 'antd'

import { DrawerRef } from 'common/components/Drawer'

import { useStores } from 'contractor/hooks/use-stores'
import { UpdateMaterial } from 'contractor/pages/CompanyMaterials/Materials/UpdateMaterial'

export const CustomApproveCell = ({ cell, row: rowIndex }) => {
  const { companyMaterialStore, userStore, orderStore } = useStores()

  const companyMaterial = cell?.materialRow?.company_material

  const drawerUpdateRef = useRef<DrawerRef>()

  const handleEditCompanyMaterial = () => {
    companyMaterialStore.selectMaterial(companyMaterial?.id).then(() => drawerUpdateRef.current?.show())
  }

  const handleAfterApproveCompanyMaterial = useCallback(
    (companyMaterial) => {
      cell?.onSelectMaterial(rowIndex, companyMaterial)
      drawerUpdateRef.current?.close()
    },
    [cell?.onSelectMaterial, drawerUpdateRef.current],
  )

  if (!companyMaterial?.requested_by_id || !userStore.canCreateNewMaterial) {
    return null
  }

  return (
    <>
      <Button
        data-cy={`approve-material-${companyMaterial?.id}`}
        size="small"
        onClick={handleEditCompanyMaterial}
        disabled={orderStore.isSplitting}
      >
        Approve
      </Button>
      <UpdateMaterial
        hideNotification
        editable
        onSubmit={handleAfterApproveCompanyMaterial}
        onCancel={() => drawerUpdateRef.current.close()}
        scope="OrderPage"
        ref={drawerUpdateRef}
      />
    </>
  )
}
