import React from 'react'

import { Box } from 'common/components/boxes'
import { usePsqlTable } from 'common/components/PsqlTable/psql_table_provider'

import { ExportButton } from 'contractor/components/ExportButton/export_button'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

export const InvoiceDownload = () => {
  const {
    invoiceStore: { listStore },
  } = useStores()
  const { state } = usePsqlTable()
  const sage300CsvDownloadEnabled = useFlag('sage_300_csv_download')
  const sage100CsvDownloadEnabled = useFlag('sage_100_csv_download')

  return (
    <Box width="100%">
      <ExportButton
        onExport={listStore?.exportRecords}
        extras={{ export_type: 'export_invoices_files' }}
        hiddenColumns={state?.hiddenColumns}
        title="Download Invoice Files"
      />

      {sage300CsvDownloadEnabled && (
        <ExportButton
          onExport={listStore?.exportRecords}
          extras={{ export_type: 'export_sage_300' }}
          hiddenColumns={state?.hiddenColumns}
          title="Download Sage 300"
        />
      )}

      {sage100CsvDownloadEnabled && (
        <ExportButton
          onExport={listStore?.exportRecords}
          extras={{ export_type: 'export_sage_100' }}
          hiddenColumns={state?.hiddenColumns}
          title="Download Sage 100"
        />
      )}

      <ExportButton onExport={listStore?.exportRecords} hiddenColumns={state?.hiddenColumns} title="Download CSV" />
    </Box>
  )
}
