import React, { useState } from 'react'

import { FormRule } from 'antd'

export interface BaseInputProps {
  name: string
  title: React.ReactNode
  rules?: FormRule[]
  onSave: () => void
  initialValue?: string | number
  ref?: React.RefObject<HTMLInputElement>
}

export function useEditableState(initialEditing = false) {
  const [editing, setEditing] = useState<boolean>(initialEditing)

  const toggleEdit = () => {
    setEditing(!editing)
  }

  return [editing, toggleEdit, setEditing]
}
