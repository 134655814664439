import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { Page } from 'common/components/Page'

export const InventoryPage = () => {
  const history = useHistory()
  const location = useLocation()

  const activeKey = new URLSearchParams(location.search).get('tab')

  const tabItems = [
    {
      label: 'Stock',
      key: 'stocks',
      children: 'Stock',
    },
    {
      label: 'Warehouses',
      key: 'warehouses',
      children: 'Warehouses',
    },
  ]

  return (
    <Page>
      <Page.Tabs
        activeKey={activeKey || 'stocks'}
        onChange={(key) => {
          history.push({
            search: new URLSearchParams({ tab: key }).toString(),
          })
        }}
        items={tabItems}
        destroyInactiveTabPane
      />
    </Page>
  )
}
