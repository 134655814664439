import _ from 'lodash'
import { BindAll } from 'lodash-decorators'
import moment from 'moment'

import { action, observable } from 'mobx'

import { subscribeCompanyChannel, TYPE_CONTRACTOR } from 'common/data-access/company_websocket'
import { InvoiceMaterialsResponse, InvoiceOrder, InvoiceResponse } from 'common/server/invoice'
import { InvoiceHistoryResponse } from 'common/server/invoice_history'
import { OrderMaterial } from 'common/server/orders'
import { WebSocketMessage } from 'common/server/server_types'
import AlgoliaBaseStore from 'common/stores/AlgoliaBaseStore'
import ListBaseStore from 'common/stores/ListBaseStore'

import {
  attachInvoicesToCommitment as attachInvoices,
  create,
  CreateInvoicePayload,
  discard,
  export_records,
  facets as fetchFacets,
  getInvoiceTags,
  history,
  index as indexInvoices,
  IndexInvoiceRecord,
  internal_comment,
  InvoiceHit,
  InvoicePayload,
  issue,
  mark_as_extracted,
  mark_as_reconciled,
  ReportInvoiceIssuePayload,
  search_key,
  show,
  showInvoiceMaterials,
  unreconciledInvoices,
  update,
} from 'contractor/server/invoices/invoice'
import { show as findOrder } from 'contractor/server/orders'

class InvoiceListStore extends ListBaseStore<IndexInvoiceRecord> {
  index = indexInvoices
  getFacets = fetchFacets
  export = export_records
}

@BindAll()
export default class InvoiceStore extends AlgoliaBaseStore<InvoiceHit> {
  listStore: ListBaseStore<IndexInvoiceRecord>
  getSearchKey = search_key
  @observable invoice: Nullable<InvoiceResponse> = null
  selectedOrders = observable.array<InvoiceOrder>([])
  selectedOrdersMaterials = observable.array<OrderMaterial>([])
  @observable invoicesLastUpdated: Nullable<InvoiceHit[]> = []
  @observable selectedRibbonFilter: string = null
  @observable isSorted = false
  @observable assignedInvoicesLastUpdated: Nullable<InvoiceHit[]> = []
  invoiceHistory = observable.array<InvoiceHistoryResponse>([])
  invoiceTags = observable.array<string>([])
  @observable subscriptions = []

  constructor() {
    super()
    this.listStore = new InvoiceListStore()
  }

  async markAsExtracted(invoiceId: string) {
    const { data } = await mark_as_extracted({ id: invoiceId })
    this.invoice = {
      ...this.invoice,
      marked_extracted_at: data?.marked_extracted_at,
    }
  }

  async markAsReconciled(invoiceId: string) {
    const { data } = await mark_as_reconciled({ id: invoiceId })
    this.invoice = {
      ...this.invoice,
      marked_reconciled_at: data?.marked_reconciled_at,
    }
  }

  @action
  async sendInternalComment(params: { text: string; companyUserIds: string[] }) {
    const { data } = await internal_comment({
      id: this.invoice?.id,
      comment: params.text,
      company_user_ids: params.companyUserIds,
    })

    this.invoice = {
      ...this.invoice,
      comments: data.comments,
      watcher_ids: _.uniq([...this.invoice.watcher_ids, ...data.watcher_ids]),
    }
  }

  @action
  async getInvoice(id: Nullable<string>): Promise<InvoiceResponse> {
    if (!id) {
      this.invoice = null
    } else {
      const { data } = await show(id)
      this.invoice = data
    }

    return this.invoice
  }

  @action
  async getInvoiceById(id: Nullable<string>): Promise<InvoiceResponse> {
    const { data } = await show(id)
    return data
  }

  @action
  async getInvoiceMaterialsById(id: string, params?: { search?: string }): Promise<InvoiceMaterialsResponse> {
    const { data } = await showInvoiceMaterials(id, { search: params?.search })
    return data
  }

  @action
  async resetStore() {
    this.invoice = null
    this.selectedOrders.clear()
    this.selectedOrdersMaterials.clear()
  }

  @action
  async removeOrder(id: string) {
    this.selectedOrders.replace(this.selectedOrders?.filter(({ order }) => order.id !== id))

    this.selectedOrdersMaterials.replace(
      this.selectedOrdersMaterials?.filter((orderMaterial) => orderMaterial['order_id'] !== id),
    )

    this.invoice = {
      ...this.invoice,
      invoice_materials: this.invoice?.invoice_materials?.map((invoiceMaterial) => {
        if (invoiceMaterial?.order_materials?.some((orderMaterial) => orderMaterial['order_id'] === id)) {
          return {
            ...invoiceMaterial,
            order_materials: invoiceMaterial?.order_materials?.filter(
              (orderMaterial) => orderMaterial['order_id'] !== id,
            ),
          }
        }

        return invoiceMaterial
      }),
    }
  }

  @action
  async selectOrder(id: string, filterInvoicedMaterials?: boolean) {
    if (this.selectedOrders?.some((o) => o.order.id === id)) {
      return
    }

    const order = (await findOrder(id)).data
    const materials = _.groupBy(order.order_materials, 'delivery_id')
    this.selectedOrders.push({ order, materials })
    this.selectedOrdersMaterials.push(
      ...order.order_materials?.map((orderMaterial) => ({
        ...orderMaterial,
        quantity: filterInvoicedMaterials ? orderMaterial.remaining_quantity_to_invoice : orderMaterial.quantity,
        order_id: order.id,
        order_number: order.order_number,
        state: order.state,
        sub_state: order.sub_state,
      })),
    )
  }

  @action
  async selectOrderIgnoringCache(id: string) {
    const order = (await findOrder(id)).data
    const materials = _.groupBy(order.order_materials, 'delivery_id')

    const newSelectedOrders = this.selectedOrders.map((currentOrder) => {
      if (currentOrder.order.id === order.id) {
        return { order, materials }
      }
      return currentOrder
    })
    this.selectedOrders.replace(newSelectedOrders)

    const newSelectedOrdersMaterials = this.selectedOrdersMaterials.map((currentOrderMaterial) => {
      const matchOrderMaterial = order.order_materials.find(
        (orderMaterial) => orderMaterial.id === currentOrderMaterial.id,
      )

      if (matchOrderMaterial) {
        return {
          ...matchOrderMaterial,
          order_id: order.id,
          order_number: order.order_number,
          state: order.state,
          sub_state: order.sub_state,
        }
      }

      return currentOrderMaterial
    })
    this.selectedOrdersMaterials.replace(newSelectedOrdersMaterials)
  }

  invoiceSubscribe(id, userId) {
    if (this.subscriptions.includes(id)) {
      // Do not subscribe twice to the same entity
      return
    }
    this.subscriptions.push(id)
    return subscribeCompanyChannel(this.handleWebSocketMessage, TYPE_CONTRACTOR, id, userId)
  }

  getInvoiceOrder(id: string) {
    return this.selectedOrders?.find((o) => o.order.id === id)
  }

  async save(payload: InvoicePayload, autoMatch = false) {
    const response = await update(this.invoice.id, payload, autoMatch)
    this.invoice = response?.data
    return this.invoice
  }

  @action
  async getInvoiceTags() {
    const { data } = await getInvoiceTags()
    this.invoiceTags.replace(data?.invoice_tags || [])
  }

  async create(payload: CreateInvoicePayload) {
    const { data } = await create(payload)
    return data
  }

  @action.bound
  updateSelectedInvoice<Value = string | number | string[]>(path: string, value: Value) {
    _.set(this.invoice, path, value)
  }

  @action
  updateSelectedOrdersMaterials(ordersMaterials: OrderMaterial[]) {
    this.selectedOrdersMaterials.replace(ordersMaterials)
  }

  @action
  async delete(id: string, discardReason: string, discardExplained?: string) {
    await discard(id, discardReason, discardExplained)
    this.invoice = null
    this.selectedOrders.clear()
    this.invoiceTags.clear()
  }

  async reportIssue(id: string, payload: ReportInvoiceIssuePayload) {
    await issue(id, payload)
  }

  @action
  async getInvoiceHistory(invoiceId: string) {
    const { data } = await history(invoiceId)

    const parsedHistory = data?.history.map((history) => ({
      ...history,
      title: history.title.replace('{{title_date}}', moment(new Date(history.title_date)).format('MMM D, h:mm A')),
    }))

    this.invoiceHistory.replace(parsedHistory)
  }

  @action
  async handleWebSocketMessage(message: WebSocketMessage) {
    if (message.entity_name === 'Invoice' && message.entity_id === this.invoice?.id) {
      if (message.type === 'refresh') {
        this.invoice = (await show(this.invoice.id)).data
      }
    }
  }

  @action
  async getUnreconciledInvoices(params: {}) {
    const { data } = await unreconciledInvoices(params)
    return data
  }

  attachInvoicesToCommitment(invoiceIds: string[], commitmentId: string) {
    attachInvoices(invoiceIds, commitmentId)
  }
}
