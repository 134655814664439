import './style.css'

import React from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { Button, notification } from 'antd'

import { usePushToCommitment } from 'common/hooks/use-push-to-commitment'
import theme from 'common/styles/theme'

type Index = {
  basePath?: string
}

type NotifyCommitmentParams = {
  message: string
  commitmentId?: string
  isNewTab?: boolean
  commitmentType: string
}

export const useNotifyCommitment = ({ basePath }: Index = {}) => {
  const { push } = usePushToCommitment()
  const { getCommitmentUrl } = usePushToCommitment()
  const key = `commitment-notify-${new Date().getTime()}`

  const handleOpenClick = (params) => {
    notification.close(key)
    const url = getCommitmentUrl(params.commitmentId, params.commitmentType)
    push({
      url,
      basePath,
    })
  }

  const notifyCommitment = ({ message, commitmentId = '', isNewTab, commitmentType }: NotifyCommitmentParams) => {
    const closeIconElement = <CloseOutlined style={{ color: theme.colors['gray-7'] }} />

    const actionButton = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          handleOpenClick({ commitmentId, isNewTab, commitmentType })
        }}
      >
        View Commitment
      </Button>
    )

    notification.success({
      message,
      duration: 10,
      placement: 'bottomLeft',
      key,
      className: 'use-notify-commitment',
      closeIcon: closeIconElement,
      btn: actionButton,
    })
  }

  return { notifyCommitment }
}
