import React, { useCallback } from 'react'

import { Card, Modal } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'
import { InvoiceMaterial, InvoiceMaterialGroup } from 'common/server/invoice'
import { OrderMaterial } from 'common/server/orders'

import { CreateGroupItemDrawer } from '../components/CreateGroupItem'
import { useDeleteInvoiceMaterials } from '../components/DeleteInvoiceMaterials/hooks/use-delete-invoice-materials'
import { InvoiceGroupItems } from '../components/InvoiceGroupItems'
import { InvoiceItem } from '../components/InvoiceItem'
import { InvoiceItemOrder } from '../components/InvoiceItem/invoice_item_order'
import { useInvoice } from '../context'

export enum DraggableType {
  ORDER_MATERIAL,
  INVOICE_MATERIAL,
}

type InvoiceMaterialsProps = {
  onFilterTable?: (dataSource: InvoiceMaterial) => void
}

export const InvoiceMaterials = observer<InvoiceMaterialsProps>(({ onFilterTable }) => {
  const {
    taxAmountField,
    calcTaxSplitBulk,
    taxLineItemsEnabled,
    invoiceMaterialsFiltered,
    calcExtCost,
    handleSelectInvoiceMaterials,
    handleUnmatch,
  } = useInvoice()

  const { handleDelete, handleConfirmDelete, handleCancel, opened } = useDeleteInvoiceMaterials()

  const [groupInEdit, setGroupInEdit] = React.useState<InvoiceMaterialGroup>()

  const dataSource = calcTaxSplitBulk({
    invoiceMaterials: invoiceMaterialsFiltered.materialsFilteredBySearch,
    taxAmount: taxAmountField,
    taxLineItemsEnabled,
  })

  const dataFiltered = React.useMemo(() => {
    if (!onFilterTable) return { materials: dataSource, groups: invoiceMaterialsFiltered.groups }

    return {
      materials: dataSource.filter(onFilterTable),
      groups: invoiceMaterialsFiltered.groups.filter((group) => group.invoice_materials.some(onFilterTable)),
    }
  }, [dataSource, invoiceMaterialsFiltered.groups, onFilterTable])

  const getConsolidatedGroupItems = useCallback(
    (group: InvoiceMaterialGroup) => {
      const grouped = group.invoice_materials.reduce((acc, invoiceMaterial) => {
        invoiceMaterial.order_materials.forEach((material) => {
          const key = material.id
          if (!acc[key]) {
            acc[key] = {
              ...material,
              quantity_shipped: 0,
            }
          }
          acc[key].quantity_shipped += material.quantity_delivered || material.quantity
        })
        return acc
      }, {})

      return Object.values(grouped).map((material: OrderMaterial) => ({
        calcExtCost,
        id: material.id,
        description: material.company_material.description,
        quantity_shipped: material.quantity_shipped,
        uom: material.company_material.unit?.name || material.company_material?.unit_name,
        unit_price: material.unit_cost as number,
        unit_multiplier: Number(material.company_material?.unit?.multiplier),
        unit_qty_increment: Number(material.company_material?.unit?.qty_increment),
      }))
    },
    [calcExtCost],
  )

  return (
    <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
      {dataFiltered.groups.map((group) => (
        <Card key={group.id} bodyStyle={{ padding: 12 }}>
          <InvoiceGroupItems
            group={{
              id: group.id,
              group_name: group.name,
              group_total: group.invoice_materials?.length,
              quantity_shipped: group.quantity_shipped,
              unit_price: group.unit_price,
              uom: group.unit_name,
              extended_price: group.extended_price,
              calcExtCost,
              checked: group?.checked,
            }}
            onCheck={() => handleSelectInvoiceMaterials(group.invoice_materials.map((material) => material.id))}
            onEdit={() => {
              setGroupInEdit({
                ...group,
                invoice_materials: group.invoice_materials.map((material) => ({
                  ...material,
                  checked: true,
                })),
              })
            }}
            childrenIsExpandable
          >
            <FlexBoxX width="100%">
              <InvoiceItemOrder
                items={getConsolidatedGroupItems(group).map((material) => ({
                  calcExtCost,
                  ...material,
                }))}
                onDelete={handleUnmatch}
              />
            </FlexBoxX>
          </InvoiceGroupItems>
        </Card>
      ))}

      {dataFiltered.materials.map((invoiceMaterial) => (
        <Card key={invoiceMaterial.id} bodyStyle={{ padding: 12 }}>
          <InvoiceItem
            item={{
              checked: invoiceMaterial?.checked,
              id: invoiceMaterial.id,
              description: invoiceMaterial.description,
              quantity_shipped: invoiceMaterial.quantity_shipped,
              uom: invoiceMaterial.uom,
              unit_price: invoiceMaterial.unit_price,
              calcExtCost,
              invoice_material: invoiceMaterial,
            }}
            onCheck={(itemId) => handleSelectInvoiceMaterials([itemId])}
            onDelete={(itemId) => handleDelete([itemId])}
            childrenIsExpandable
          >
            <FlexBoxX width="100%">
              {invoiceMaterial.order_materials.length > 0 && (
                <InvoiceItemOrder
                  items={invoiceMaterial.order_materials.map((material) => ({
                    calcExtCost,
                    id: material.id,
                    description: material?.company_material.description,
                    quantity_shipped: material.quantity_delivered || material.quantity,
                    uom: material?.company_material.unit?.name || material.company_material?.unit_name,
                    unit_price: material.unit_cost as number,
                    unit_multiplier: Number(material.company_material?.unit?.multiplier),
                    unit_qty_increment: Number(material.company_material?.unit?.qty_increment),
                  }))}
                  onDelete={handleUnmatch}
                />
              )}
            </FlexBoxX>
          </InvoiceItem>
        </Card>
      ))}

      <CreateGroupItemDrawer isOpened={!!groupInEdit} setIsOpened={() => setGroupInEdit(null)} group={groupInEdit} />

      <Modal title="Delete items" open={opened} onCancel={handleCancel} onOk={handleConfirmDelete}>
        Do you want to permanently delete the items?
      </Modal>
    </Box>
  )
})
