import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import _ from 'lodash'
import moment from 'moment'

import { Button, Col, Form, message, Modal, PageHeader, Row, Space } from 'antd'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { UnsavedPopup } from 'common/components/Page/unsaved_popup'
import { Visibility } from 'common/components/Visibility'
import { nextWeekday } from 'common/helpers/other'
import { OrderDelivery } from 'common/server/deliveries'
import { MaterialRequestTypes, OrderSubStates } from 'common/server/server_types'

import { makeCompanyUserWatcherOption } from 'contractor/components/WatchersSelect'
import { useStores } from 'contractor/hooks/use-stores'
import { DropdownOptions } from 'contractor/pages/PrivateOrderForm/dropdown_options'

import { Attachments } from './attachments'
import { FormValues, usePrivateOrderForm, withPrivateOrderFormProvider } from './context'
import { InternalComments } from './internal_comments'
import Materials from './materials'
import { MaterialsV2 } from './materials_v2'
import OrderOptions from './order_options'
import { Watchers } from './watchers'

const PrivateOrderForm = observer(() => {
  const { setSubmitting, isSubmitting, form } = usePrivateOrderForm()

  const { uploaderStore, userStore, orderStore, companySettingStore } = useStores()

  const [isSpreadsheetMode, toggleSpreadsheetMode] = useState(false)

  const history = useHistory()
  const lastLocation = useLastLocation()

  const [requiredVendor, setRequireVendor] = useState(false)
  const [saveAsDraft, setSaveAsDraft] = useState(false)
  const [isRequest, setIsRequest] = useState(false)

  const orderPackageIdParam = new URLSearchParams(location.search).get('order_package_id')
  const projectIdParam = new URLSearchParams(location.search).get('project_id')

  const requiredRequestFields = [
    ...(companySettingStore.otherSettings?.required_request_fields?.quantity
      ? [
          {
            key: 'quantity',
            label: 'Quantity',
          },
        ]
      : []),
    ...(companySettingStore.otherSettings?.required_request_fields?.unit
      ? [
          {
            key: 'unit',
            label: 'Unit',
          },
        ]
      : []),
    ...(companySettingStore.otherSettings?.required_request_fields?.cost_code
      ? [
          {
            key: 'cost_code_id',
            label: 'Cost Code',
          },
        ]
      : []),
  ]

  useEffect(() => {
    if (requiredVendor) {
      form.validateFields()
    }
  }, [requiredVendor])

  const goBack = () => {
    const search = new URLSearchParams(lastLocation?.search)
    search.append('refresh', 'true')

    history.push({
      ...lastLocation,
      pathname: lastLocation?.pathname || '/orders',
      search: `?${search.toString()}`,
    })
  }

  const validOrderMaterial = () => {
    const orderMaterials = orderStore.filteredOrderedMaterials()
    const countOrderMaterials = orderMaterials.length
    const countPurchaserFiles = uploaderStore.signedIds('purchaser_files').length

    if (!countPurchaserFiles && !countOrderMaterials) {
      throw new Error('Must specify materials or add an attachment')
    }

    if (!uploaderStore.checkIfAllUploadsCompleted()) {
      throw new Error('Uploads have not completed yet, please try again.')
    }

    const errors = orderStore.validateRequiredFields(orderMaterials, requiredRequestFields)
    if (errors.length) {
      throw new Error(`Please fill in the following required fields: ${errors.join(', ')}`)
    }
  }

  const injectDraft = async (orderPackageId) => {
    try {
      const orderPackage = await orderStore.loadDraft(orderPackageId)

      const orders = orderPackage.orders

      const newCompanyVendors = new Map()
      if (orderPackage.temp_company_vendors) {
        orderPackage.temp_company_vendors.forEach((companyVendor) => {
          newCompanyVendors.set(companyVendor.id, {
            ...companyVendor,
            order_id: orders[0].id,
            contacts: companyVendor.vendor_contacts,
            comment: companyVendor.comment,
          })
        })
      } else {
        orders.forEach((order) => {
          if (!order.company_vendor) {
            return
          }

          newCompanyVendors.set(order.company_vendor.id, {
            ...order.company_vendor,
            order_id: order.id,
            contacts: order.vendor_contacts,
            comment: _.get(order, 'comments[0].comment', ''),
          })
        })
      }

      form.setFieldsValue({
        addressId: orders[0]?.deliveries[0]?.address?.id,
        addressToId: orders[0]?.deliveries[0]?.address_to?.id,
        orderPackageId: orderPackage.id,
        orderPackageName: orders[0].order_package_name,
        commitmentId: orders[0].commitment_id,
        projectId: orders[0].project_id,
        orderNumber: orders[0].order_number,
        quoteNumber: orders[0].quote_number,
        watchers: companySettingStore.possibleUsers
          .filter((user) => orders[0].watcher_ids.includes(user.company_user_id))
          .map((user) => makeCompanyUserWatcherOption(user)),
        tags: orders[0].tags,
      })

      uploaderStore.resetAllUploads()
      uploaderStore.addExistingFiles('purchaser_files', orderStore.selectedOrder.purchaser_files)
    } catch {
      message.error(`Unable to load the draft`)
    }
  }

  useEffect(() => {
    if (orderPackageIdParam) {
      injectDraft(orderPackageIdParam)
    }
  }, [])

  const handleFinish = async (formValues: FormValues) => {
    try {
      validOrderMaterial()
      setSubmitting(true)

      orderStore.newDeliveries[0].requested_delivered_at = formValues?.requestedDeliveredAt
      orderStore.newDeliveries[0].address_to = { id: formValues?.addressToId } as OrderDelivery['address_to']
      orderStore.newDeliveries[0].address = {
        id: formValues?.addressId,
      } as OrderDelivery['address']

      const isPlaceOrder = requiredVendor && formValues?.companyVendorContact

      const defaultOrderTypeRFQ =
        companySettingStore.otherSettings?.material_requisitions_default_order_type === MaterialRequestTypes.RFQ

      const companyVendors = isPlaceOrder
        ? [
            {
              id: formValues?.companyVendorContact?.contact?.company_vendor?.id,
              company_vendor_contact_ids: [formValues?.companyVendorContact?.value],
            },
          ]
        : [{ id: null, company_vendor_contact_ids: [] }]

      const state_changes = {
        requested_at: moment().toISOString(),
      }

      let feedbackMessage: string
      if (isPlaceOrder) {
        state_changes['ordered_at'] = moment().toISOString()
        feedbackMessage = 'Placed Order'
      } else if (saveAsDraft) {
        state_changes['drafted_request_at'] = moment().toISOString()
        feedbackMessage = 'Request saved as a Draft'
      } else if (orderPackageIdParam || isRequest) {
        state_changes['requested_at'] = moment().toISOString()
        state_changes['sub_state'] = defaultOrderTypeRFQ
          ? OrderSubStates.REQUESTED_QUOTE
          : OrderSubStates.REQUESTED_ORDER
        feedbackMessage = 'Request Submitted'
      } else {
        state_changes['sub_state'] = defaultOrderTypeRFQ
          ? OrderSubStates.REQUESTED_QUOTE
          : OrderSubStates.REQUESTED_ORDER
        feedbackMessage = 'Saved Draft'
      }

      const formWatchers = formValues?.watchers?.map((watcher) => watcher.value) || []

      await orderStore.createOrder(
        {
          project_id: formValues?.projectId,
          order_package_name: formValues?.orderPackageName,
          order_package_id: orderPackageIdParam,
          purchaser_files_signed_ids: uploaderStore.signedIds('purchaser_files'),
          purchaser_files_delete_ids: uploaderStore.deleteAttachmentIds['purchaser_files'],
          commitment_id: formValues?.commitmentId,
          vendors: companyVendors,
          state_changes,
          watcher_ids: _.uniq([...formWatchers, userStore.companyUserId]),
        },
        userStore.canCreateNewMaterial,
      )

      message.open({
        content: feedbackMessage,
      })

      goBack()
    } catch (err) {
      if (err.response?.data?.error) {
        message.error(err.response.data.error)
      } else if (err?.message) {
        Modal.error({
          title: err.message,
        })
      } else {
        message.error('Unable to save')
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      initialValues={{
        requestedDeliveredAt: nextWeekday().toISOString(),
        projectId: projectIdParam,
      }}
      scrollToFirstError
      style={{ width: '100%', height: '100%' }}
    >
      <Page>
        <Page.Header>
          <PageHeader
            backIcon={<UnsavedPopup goBack={goBack} />}
            onBack={() => null}
            title={orderPackageIdParam ? 'Drafted Request' : 'New Request'}
            extra={[
              <Watchers key="watchers" />,
              userStore.canSendAndUpdateOrders && (
                <Button key="place" loading={isSubmitting} onClick={() => setRequireVendor(true)} htmlType="submit">
                  Place Order
                </Button>
              ),
              <Button
                data-cy="save-draft-request"
                key="save-draft-request"
                loading={isSubmitting}
                type="default"
                onClick={() => setSaveAsDraft(true)}
                htmlType="submit"
              >
                Save as draft
              </Button>,
              <Button
                data-cy="request-materials"
                key="request"
                loading={isSubmitting}
                type="primary"
                onClick={() => {
                  setIsRequest(true)
                  setRequireVendor(false)
                }}
                htmlType="submit"
              >
                Submit
              </Button>,
              ...(orderPackageIdParam ? [<DropdownOptions key="delete" orderPackageId={orderPackageIdParam} />] : []),
            ]}
            style={{ padding: '0', width: '100%' }}
          />
        </Page.Header>

        <Page.Content p={0} px={{ _: 0, sm: 16 }} py={16}>
          <OrderOptions requiredVendor={requiredVendor} />

          {isSpreadsheetMode ? (
            <Materials isSpreadsheetMode={isSpreadsheetMode} toggleSpreadsheetMode={toggleSpreadsheetMode} />
          ) : (
            <MaterialsV2 isSpreadsheetMode={isSpreadsheetMode} toggleSpreadsheetMode={toggleSpreadsheetMode} />
          )}

          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Attachments />
            </Col>
            <Col xs={24} md={12}>
              <InternalComments />
            </Col>
          </Row>

          <Visibility.Show>
            <FlexBoxY mt={16} width="100%" justifyContent="flex-end" alignItems={{ _: 'center', sm: 'flex-end' }}>
              <Space size="large">
                {userStore.canSendAndUpdateOrders && (
                  <Form.Item>
                    <Button loading={isSubmitting} onClick={() => setRequireVendor(true)} htmlType="submit">
                      Place Order
                    </Button>
                  </Form.Item>
                )}
                <Form.Item>
                  <Button
                    loading={isSubmitting}
                    type="primary"
                    onClick={() => setRequireVendor(false)}
                    htmlType="submit"
                  >
                    Request Materials
                  </Button>
                </Form.Item>
              </Space>
            </FlexBoxY>
          </Visibility.Show>
        </Page.Content>
      </Page>
    </Form>
  )
})

export default withPrivateOrderFormProvider(PrivateOrderForm)
