import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { Global, css } from '@emotion/core'

import { Popover, Table, Button, Typography, Skeleton } from 'antd'
import { PopoverProps } from 'antd/lib/popover'
import { ColumnsType } from 'antd/lib/table'

import { observer } from 'mobx-react-lite'

import { trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { FlexBoxY } from 'common/components/boxes'
import { usePushToOrder } from 'common/hooks/use-push-to-order'
import { OrderMaterial } from 'common/server/orders'

import { useStores } from 'contractor/hooks/use-stores'

type OrderDetailPopoverProps = {
  orderId: string
} & PopoverProps

const columns: ColumnsType<OrderMaterial> = [
  {
    title: 'Description',
    dataIndex: ['company_material', 'description'],
    render: (value) => (
      <Typography.Text ellipsis style={{ maxWidth: 300 }}>
        {value}
      </Typography.Text>
    ),
  },
  { title: 'Qty', dataIndex: 'quantity', align: 'right' },
]

type OrderDetailPopoverContentProps = {
  orderId: string
}

const OrderDetailPopoverContent = observer<OrderDetailPopoverContentProps>(({ orderId }) => {
  const location = useLocation()
  const source = location.pathname.substr(1)
  const { orderStore } = useStores()
  const { getUrl } = usePushToOrder()

  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState(false)

  const order = orderStore.selectedOrder

  const orderUrl = getUrl({
    orderId: order?.id,
    orderPackageId: order?.order_package_id,
    state: order?.state,
    subState: order?.sub_state,
  })

  const handleLoadOrder = async (orderId) => {
    try {
      setLoading(true)
      await orderStore.selectOrder(orderId)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
      trackEvent(Events.ORDER_DETAIL_HOVER, source)
    }
  }

  useEffect(() => {
    orderStore.selectOrder(null).then(() => handleLoadOrder(orderId))
  }, [orderId])

  // Show first 5 items
  const orderMaterials = orderStore.selectedOrder?.order_materials?.slice(0, 5) || []

  if (isError) {
    return (
      <FlexBoxY alignItems="flex-end" p="8px">
        <Typography.Text style={{ width: '100%', marginBottom: 8 }}>Unable to load order details</Typography.Text>
        <Button data-cy={`open-order-${orderId}`} type="primary" href={orderUrl} onClick={(e) => e.stopPropagation()}>
          Open
        </Button>
      </FlexBoxY>
    )
  }
  const materialsQuantity = orderStore.selectedOrder?.order_materials.length
  const hasMoreMaterials = materialsQuantity > orderMaterials.length

  return (
    <FlexBoxY alignItems="flex-end">
      <Table<OrderMaterial>
        loading={isLoading}
        size="small"
        columns={columns}
        pagination={false}
        dataSource={orderMaterials}
        style={{ width: '100%' }}
      />
      {hasMoreMaterials && (
        <Typography.Text style={{ margin: '12px 0' }}>{`And ${materialsQuantity} other materials`}</Typography.Text>
      )}
      <Button
        data-cy={`open-order-${orderId}`}
        type="primary"
        href={orderUrl}
        onClick={(e) => e.stopPropagation()}
        style={{ marginTop: 12 }}
      >
        Open
      </Button>
    </FlexBoxY>
  )
})

export const OrderDetailPopover = observer<OrderDetailPopoverProps>(({ orderId, children }) => {
  const { orderStore } = useStores()

  const order = orderStore.selectedOrder

  const title = order ? `${order?.order_number}: ${order?.order_package_name}` : <Skeleton.Input size="small" active />

  return (
    <>
      <Global
        styles={css`
          .order-detail .ant-popover-title {
            padding: 12px 16px;
          }
          .order-detail .ant-skeleton {
            width: 100%;
          }
        `}
      />
      <Popover
        destroyTooltipOnHide
        placement="rightBottom"
        content={<OrderDetailPopoverContent orderId={orderId} />}
        title={title}
        overlayClassName="order-detail"
        mouseEnterDelay={1}
        overlayStyle={{ minWidth: 300 }}
      >
        <span>{children}</span>
      </Popover>
    </>
  )
})
