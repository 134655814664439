import React from 'react'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { PdfViewer } from './components/PdfViewer'
import { ExtractedData } from './ExtractedData'

export const ExtractedStep = observer(() => {
  return (
    <Box flexGrow={1} display="flex" style={{ gap: 8 }} minWidth={1200}>
      <PdfViewer />

      <ExtractedData />
    </Box>
  )
})
