import React from 'react'

import { Divider, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import theme from 'common/styles/theme'

import { useInvoice } from '../context'

export const Footer = () => {
  const { calculatedSubtotal, calculatedGrandTotal, calculatedAdditionalCosts, currencyFormatter } = useInvoice()

  return (
    <Box
      bg="white"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      px={16}
      py={8}
      borderTop="1px solid"
      borderTopColor="gray-4"
      width="100%"
      style={{ gap: 8 }}
    >
      <Box display="flex" flexShrink={1} py={8} style={{ gap: 8 }}>
        <Typography.Text color={theme.colors['gray-12']}>Subtotal</Typography.Text>
        <Typography.Text color={theme.colors['primary-6']} strong>
          {currencyFormatter(calculatedSubtotal)}
        </Typography.Text>
      </Box>

      <Divider type="vertical" style={{ borderColor: theme.colors['gray-6'], height: '100%' }} />

      <Box display="flex" flexShrink={1} py={8} style={{ gap: 8 }}>
        <Typography.Text color={theme.colors['gray-12']}>Additional costs</Typography.Text>
        <Typography.Text color={theme.colors['primary-6']} strong>
          {currencyFormatter(calculatedAdditionalCosts)}
        </Typography.Text>
      </Box>

      <Divider type="vertical" style={{ borderColor: theme.colors['gray-6'], height: '100%' }} />

      <Box display="flex" flexShrink={1} py={8} style={{ gap: 8 }}>
        <Typography.Text color={theme.colors['gray-12']}>Grand total</Typography.Text>
        <Typography.Text color={theme.colors['primary-6']} strong>
          {currencyFormatter(calculatedGrandTotal)}
        </Typography.Text>
      </Box>
    </Box>
  )
}
