import React from 'react'

import { InvoiceMaterial, InvoiceMaterialGroup } from 'javascript/common/server/invoice'

import { Button, notification } from 'antd'

import { useInvoice } from '../../../context'

export const useDeleteInvoiceMaterials = () => {
  const [opened, setOpened] = React.useState(false)
  const {
    handleAddInvoiceMaterial,
    handleRemoveMaterialItems,
    invoiceMaterialsFiltered,
    handleSaveInvoiceMaterialsGroup,
    toggleAllInvoiceMaterialsChecked,
    handleSearch,
  } = useInvoice()

  const [invoiceMaterialsDeleted, setInvoiceMaterialsDeleted] = React.useState<InvoiceMaterial[]>([])
  const [invoiceMaterialsGroupsDeleted, setInvoiceMaterialsGroupsDeleted] = React.useState<InvoiceMaterialGroup[]>([])

  const disabled =
    !invoiceMaterialsFiltered.materials.some((material) => material?.checked) &&
    !invoiceMaterialsFiltered.groups.some((group) => group?.checked)

  const reset = () => {
    setInvoiceMaterialsDeleted([])
    setInvoiceMaterialsGroupsDeleted([])
  }

  const handleDelete = (invoiceMaterialIds?: string[]) => {
    setInvoiceMaterialsDeleted(
      invoiceMaterialsFiltered.materialsFilteredBySearch
        .map((material, index) => {
          if (!invoiceMaterialIds) return material?.checked ? { ...material, position: index } : null
          if (invoiceMaterialIds.length === 0 || invoiceMaterialIds.includes(material?.id)) {
            return { ...material, position: index }
          }
          return null
        })
        .filter(Boolean),
    )

    setInvoiceMaterialsGroupsDeleted(
      invoiceMaterialsFiltered.groups
        .map((group, index) => (group?.checked ? { ...group, position: index } : null))
        .filter(Boolean),
    )

    setOpened(true)
  }

  const handleCancel = () => {
    setOpened(false)
  }

  const handleUndoDelete = () => {
    handleAddInvoiceMaterial(invoiceMaterialsDeleted)
    invoiceMaterialsGroupsDeleted.map((group) => {
      handleSaveInvoiceMaterialsGroup(group.name, group.invoice_materials, group.id)
    })
    reset()
    notification.close('invoice_material_deleted')
  }

  const handleConfirmDelete = () => {
    notification.success({
      key: 'invoice_material_deleted',
      message: 'Deleted successfully',
      duration: 10,
      placement: 'bottomLeft',
      onClose: reset,
      description: (
        <Button type="primary" size="small" onClick={handleUndoDelete}>
          Undo
        </Button>
      ),
    })
    handleRemoveMaterialItems(invoiceMaterialsDeleted)
    invoiceMaterialsGroupsDeleted.map((group) => {
      handleRemoveMaterialItems(group.invoice_materials, group.id)
    })
    toggleAllInvoiceMaterialsChecked(false)
    handleSearch('')
    setOpened(false)
  }

  return { disabled, opened, handleDelete, handleCancel, handleConfirmDelete, handleUndoDelete, reset }
}
