import React, { useEffect, useState } from 'react'

import moment from 'moment/moment'

import { Form, Select, Skeleton, Space, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import DeliveryState from 'common/components/statuses/delivery_state'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { formatDateString } from 'common/helpers/formatters'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { useQuery } from 'common/hooks/use-query'
import { LogDeliveryResponse, OrderDelivery } from 'common/server/deliveries'
import { OrderStates } from 'common/server/server_types'

import { DeliveryTag } from 'contractor/components/LogDelivery/SelectOrderStep/delivery_tag'
import { ExpectedDeliveries } from 'contractor/components/LogDelivery/SelectOrderStep/expected_deliveries'
import { OrdersAutocomplete } from 'contractor/components/OrdersAutocomplete'
import ProjectDropdown from 'contractor/components/ProjectSelector'
import { useStores } from 'contractor/hooks/use-stores'
import { ConsolidatedOrders } from 'contractor/server/orders'
import { ShowProjectResponse } from 'contractor/server/projects'

type SelectOrderProps = {
  form: FormInstance
  recentDeliveries: LogDeliveryResponse[]
  loadingRecentDeliveries: boolean
  selectedOrder: ConsolidatedOrders.Order
  setSelectedOrder: React.Dispatch<React.SetStateAction<ConsolidatedOrders.Order | null>>
}

interface ScreenDeliveryOption {
  id: string
  requested_delivered_at?: string
  estimated_delivered_at?: string
  best_guess_delivered_at?: string
  state: string
}

const makeDeliveryOptions = (deliveries: OrderDelivery[]) => {
  return deliveries.map((delivery) => {
    return {
      id: delivery.id,
      requested_delivered_at: delivery.requested_delivered_at,
      estimated_delivered_at: delivery.estimated_delivered_at,
      best_guess_delivered_at: delivery.best_guess_delivered_at,
      state: delivery.state,
    }
  })
}

const filterByDate = (deliveries: LogDeliveryResponse[], period: 'today' | 'yesterday' | 'tomorrow') => {
  let targetDate: moment.Moment

  switch (period) {
    case 'today':
      targetDate = moment()
      break
    case 'yesterday':
      targetDate = moment().subtract(1, 'day')
      break
    case 'tomorrow':
      targetDate = moment().add(1, 'day')
      break
    default:
      return []
  }

  return deliveries.filter((delivery) => moment(delivery?.delivery?.requested_delivered_at).isSame(targetDate, 'day'))
}

const filterDeliveriesByProject = (deliveries: LogDeliveryResponse[], projectId?: string) => {
  if (!projectId) {
    return deliveries
  }
  return deliveries.filter((delivery) => delivery?.order?.project_id === projectId)
}

export const SelectOrderStep = observer((props: SelectOrderProps) => {
  const { form, recentDeliveries, loadingRecentDeliveries, selectedOrder, setSelectedOrder } = props
  const {
    deliveryStore,
    orderStore: { listStore },
  } = useStores()
  const isMobile = useMediaQuery('md')

  const [selectedProject, setSelectedProject] = useState<ShowProjectResponse | null>(null)
  const [deliveries, setDeliveries] = useState<ScreenDeliveryOption[]>([])

  const [todayDeliveries, setTodayDeliveries] = useState<LogDeliveryResponse[]>(filterByDate(recentDeliveries, 'today'))
  const [yesterdayDeliveries, setYesterdayDeliveries] = useState<LogDeliveryResponse[]>(
    filterByDate(recentDeliveries, 'yesterday'),
  )
  const [tomorrowDeliveries, setTomorrowDeliveries] = useState<LogDeliveryResponse[]>(
    filterByDate(recentDeliveries, 'tomorrow'),
  )
  const [orderSearch, setOrderSearch] = useState<ConsolidatedOrders.StandardIndexRequest>({
    filters: {},
    state: [OrderStates.DELIVERED, OrderStates.ORDERED, OrderStates.SHIPPED, OrderStates.PARTIALLY_SHIPPED],
    filter_orders_delivered: true,
  })

  const persistentFilters = usePersistentFilters()

  const handleSelectDelivery = (deliveryId: string) => {
    form.setFieldValue('deliveryId', deliveryId)
    deliveryStore.selectDelivery(deliveryId)
  }

  const handleDeliveries = (deliveries: OrderDelivery[]) => {
    if (deliveries) {
      if (deliveries.length === 1) {
        handleSelectDelivery(deliveries[0].id)
      }
      setDeliveries(makeDeliveryOptions(deliveries))
    }
  }

  const onSelectOrder = (order: ConsolidatedOrders.Order) => {
    setSelectedOrder(order)
    handleDeliveries(order.deliveries)
  }

  const init = async () => {
    const initialProjectId =
      new URLSearchParams(location.search).get('project_id') || sessionStorage['selectedProjectId']

    if (initialProjectId && initialProjectId.length > 0) {
      listStore.setFilter('project_id', initialProjectId, true, true)
      setSelectedProject({ id: initialProjectId } as ShowProjectResponse)
    }

    return persistentFilters.init()
  }

  const handleChangeProject = (project?: ShowProjectResponse) => {
    setSelectedProject(project)
    listStore?.setFilter('project_id', project?.id)
    persistentFilters?.handleChangeFilters({ project_id: project?.id })
  }

  useQuery(() => init(), [])

  const handleOrderSearch = () => {
    const search = { filters: {} }
    if (selectedProject && selectedProject.id !== 'all') {
      search.filters['project_id'] = selectedProject.id
    }
    setOrderSearch({ ...orderSearch, ...search })
  }

  const handleDeliveriesList = () => {
    const projectId = !selectedProject || selectedProject.id === 'all' ? null : selectedProject.id
    const today = filterByDate(recentDeliveries, 'today')
    setTodayDeliveries(filterDeliveriesByProject(today, projectId))

    const yesterday = filterByDate(recentDeliveries, 'yesterday')
    setYesterdayDeliveries(filterDeliveriesByProject(yesterday, projectId))

    const tomorrow = filterByDate(recentDeliveries, 'tomorrow')
    setTomorrowDeliveries(filterDeliveriesByProject(tomorrow, projectId))
  }

  const deliveriesTitle = React.useMemo(() => {
    if (selectedOrder) {
      return `Deliveries for Order ${selectedOrder.order_number}`
    }
    return 'Deliveries'
  }, [selectedOrder])

  useEffect(() => {
    handleDeliveriesList()
  }, [selectedProject, recentDeliveries])

  useEffect(() => {
    handleOrderSearch()
  }, [selectedProject])

  return (
    <Box display="flex" flexDirection="column" maxWidth={isMobile ? '100%' : 600} pt="8" height="100%">
      <Typography.Text style={{ paddingLeft: 16 }}>Projects</Typography.Text>
      <Box style={{ width: '100%', paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}>
        <ProjectDropdown onChange={handleChangeProject} style={{ width: '100%' }} />
      </Box>

      <Typography.Text style={{ paddingLeft: 16 }}>Orders</Typography.Text>
      <Box style={{ width: '100%', paddingLeft: 16, paddingRight: 16 }}>
        <OrdersAutocomplete
          aria-label="order-autocomplete-log-delivery"
          portal={false}
          detachedMediaQuery="none"
          size="small"
          onSelect={onSelectOrder}
          filters={orderSearch}
        />
      </Box>

      <Box style={{ width: '100%', paddingLeft: 16, paddingRight: 16, paddingTop: 16 }}>
        <Typography.Text>{deliveriesTitle}</Typography.Text>
        <Form.Item name="deliveryId" rules={[{ required: true, message: 'Delivery is required!' }]}>
          <Select
            style={{ width: '100%' }}
            disabled={!(deliveries.length > 1)}
            options={deliveries.map((delivery, idx) => ({
              value: delivery.id,
              label: (
                <Space>
                  <DeliveryTag deliveryTag={`Delivery ${idx + 1}`} />
                  <Typography.Text type="secondary">
                    {formatDateString(
                      delivery.requested_delivered_at ||
                        delivery.estimated_delivered_at ||
                        delivery.best_guess_delivered_at,
                    )}
                  </Typography.Text>
                  <DeliveryState state={delivery.state} style={{ margin: 0 }} />
                </Space>
              ),
            }))}
            onChange={(deliveryId) => {
              handleSelectDelivery(deliveryId)
            }}
          />
        </Form.Item>
      </Box>

      {loadingRecentDeliveries ? (
        <Skeleton />
      ) : (
        <Box flexGrow={1} overflow="auto" style={{ paddingLeft: 8, paddingRight: 8, paddingTop: 8 }}>
          <ExpectedDeliveries
            title="Expected Yesterday"
            recentDeliveries={todayDeliveries}
            handleSelectDelivery={handleSelectDelivery}
          />
          <ExpectedDeliveries
            title="Expected Today"
            recentDeliveries={yesterdayDeliveries}
            handleSelectDelivery={handleSelectDelivery}
          />
          <ExpectedDeliveries
            title="Expected Tomorrow"
            recentDeliveries={tomorrowDeliveries}
            handleSelectDelivery={handleSelectDelivery}
          />
        </Box>
      )}
    </Box>
  )
})
