import React, { useState } from 'react'

import { Button } from 'antd'

import { FlexBoxY } from 'common/components/boxes'
import { Comment as IComment } from 'common/server/comment'

import { Comment } from './comment'
import { NewComment } from './new_comment'

export type CommentsProps = {
  comments: IComment[]
  onChangeNewComment: (comment: string) => void
  newComment: string
  onSubmit?: (comment: string) => void
  currentView?: 'CONTRACTOR' | 'VENDOR' | 'SUBBASE'
  placeholder?: string
  loading?: boolean
  unableToUpdate?: boolean
}

// By default only show last 3 comments
export const Comments = ({
  comments = [],
  newComment,
  onChangeNewComment,
  onSubmit,
  currentView = 'CONTRACTOR',
  placeholder,
  loading,
  unableToUpdate,
}: CommentsProps) => {
  const [showMore, setShowMore] = useState(false)

  const commentsToShow = showMore ? comments : comments?.slice(-3)

  return (
    <FlexBoxY alignItems="flex-start" justifyContent="flex-start">
      {comments?.length > 3 && !showMore && (
        <Button type="link" onClick={() => setShowMore(true)} data-cy={`show-earlier-comments-button`}>
          Show earlier comments
        </Button>
      )}

      <FlexBoxY alignItems="flex-start" justifyContent="flex-start" width="100%" overflowY="auto" maxHeight={300}>
        {commentsToShow?.map((comment) => (
          <Comment
            data-cy={`comment-${comment.created_at}`}
            key={comment.created_at}
            comment={comment}
            currentView={currentView}
          />
        ))}
      </FlexBoxY>

      <NewComment
        data-cy="new-comment"
        newComment={newComment}
        onChange={onChangeNewComment}
        onSubmit={onSubmit}
        currentView={currentView}
        placeholder={placeholder}
        loading={loading}
        showSendButton={unableToUpdate}
      />
    </FlexBoxY>
  )
}
