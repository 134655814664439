import React, { useMemo } from 'react'

import styled from '@emotion/styled'

import { FilePdfOutlined, UnorderedListOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Button, Card, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'
import { PdfViewer as PdfViewerComp } from 'common/components/PdfViewer'

import { useInvoice } from '../../context'
import { OrderViewer } from './order_viewer'
import { PdfButton } from './pdf_button'
import { PdfSelect } from './pdf_select'

const pdfFileIndex = 0

const ButtonGroup = styled(Box)<{ isOpened: boolean }>`
  display: flex;
  flex-direction: ${({ isOpened }) => (isOpened ? 'row' : 'column')};

  & > button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > button:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

type ModeViewType = 'pdf' | 'order_items'

export const PdfViewer = observer(() => {
  const {
    invoice,
    selectedPdfInvoice,
    handleReconcile,
    selectedOrderMaterialIdsToReconcile,
    selectedInvoiceMaterials,
  } = useInvoice()

  const [modeView, setModeView] = React.useState<ModeViewType>('pdf')
  const [isOpened, setIsOpened] = React.useState(true)

  const getModeViewType = (mode: ModeViewType) => {
    return mode === modeView ? 'primary' : 'default'
  }
  const getIconExpand = () => (isOpened ? <ArrowLeftOutlined /> : <ArrowRightOutlined />)

  const handleModeView = (mode: ModeViewType) => {
    setModeView(mode)
    setIsOpened(true)
  }

  const reconcileBtnDisabled = useMemo(() => {
    return !selectedOrderMaterialIdsToReconcile.length
  }, [selectedOrderMaterialIdsToReconcile, selectedInvoiceMaterials])

  const style = useMemo(
    () => ({
      wrapper: {
        flex: isOpened ? 1 : 0,
        gap: 16,
      },
      card: {
        display: 'flex',
        'flex-direction': 'column',
      },
      btnGroup: {
        display: 'flex',
        'flex-direction': isOpened ? 'row' : 'column-reverse',
        gap: 12,
      },
      btnAddFile: {
        ...(isOpened ? {} : { display: 'none' }),
      },
    }),
    [isOpened],
  )

  const renderTitle = useMemo(() => {
    if (!isOpened) return null
    if (modeView === 'pdf') return <PdfSelect />
    return <Typography.Text strong>Order Items</Typography.Text>
  }, [modeView, isOpened])

  const renderBtnAction = () => {
    if (!isOpened) return null

    if (modeView === 'order_items') {
      return (
        <Button
          style={style.btnAddFile}
          type="primary"
          size="small"
          onClick={handleReconcile}
          disabled={reconcileBtnDisabled}
        >
          Reconcile
        </Button>
      )
    }

    return <PdfButton />
  }

  const renderContent = useMemo(() => {
    if (!isOpened) return null
    if (modeView === 'pdf')
      return (
        <PdfViewerComp
          url={selectedPdfInvoice || invoice.extracted_files[pdfFileIndex]?.url}
          key={`${invoice.id}-expand`}
          style={{ height: '100%', minHeight: '100%', borderRadius: 4 }}
          loading={!invoice?.id}
        />
      )
    return <OrderViewer />
  }, [modeView, invoice?.id, invoice?.extracted_files, isOpened, selectedPdfInvoice])

  return (
    <Box display="flex" flexDirection="column" style={style.wrapper}>
      <Card style={style.card} bodyStyle={{ padding: 0 }}>
        <FlexBoxX style={style.btnGroup} p={12} justifyContent="space-between">
          <Box display="flex" style={{ gap: 12 }}>
            <ButtonGroup isOpened={isOpened}>
              <Button
                icon={<FilePdfOutlined />}
                type={getModeViewType('pdf')}
                size="small"
                onClick={() => handleModeView('pdf')}
              />
              <Button
                icon={<UnorderedListOutlined />}
                type={getModeViewType('order_items')}
                size="small"
                onClick={() => handleModeView('order_items')}
              />
            </ButtonGroup>

            {renderTitle}
          </Box>

          <Box display="flex" style={{ gap: 8 }}>
            {renderBtnAction()}
            <Button icon={getIconExpand()} size="small" onClick={() => setIsOpened(!isOpened)} />
          </Box>
        </FlexBoxX>
      </Card>

      {renderContent}
    </Box>
  )
})
