import React from 'react'

import { DatePicker, Form } from 'antd'

import { BaseInputProps } from '../../hooks/editable-hooks'

export const DateInput: React.FC<BaseInputProps> = ({ name, title, rules, onSave, ref }) => {
  return (
    <Form.Item
      style={{ margin: 0 }}
      name={name}
      rules={rules || [{ required: false, message: `Please input ${title}!` }]}
    >
      <DatePicker ref={ref} onChange={onSave} onBlur={onSave} />
    </Form.Item>
  )
}
