import React from 'react'

import { Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { useMediaQuery } from 'common/hooks/use-media-query'
import { UnreconciledInvoiceResponse } from 'common/server/invoice'

import { Header } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/Invoices/Header'
import { InvoiceMaterials } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/Invoices/invoice_materials'

type DeliveryDetailsProps = {
  onBack: () => void
  selectedInvoice: UnreconciledInvoiceResponse
  onAttachInvoices: (invoiceIds: string[]) => void
  loading: boolean
}

export const InvoiceDetails = observer((props: DeliveryDetailsProps) => {
  const { onBack, loading, selectedInvoice, onAttachInvoices } = props
  const isMobile = useMediaQuery('md')

  return (
    <Box height="100vh" display="flex" maxWidth={isMobile ? '100%' : 800} flexDirection="column">
      <Header onBack={onBack} invoiceName={selectedInvoice?.name} invoiceNumber={selectedInvoice?.number} />

      <Box maxWidth="100vw" marginBottom={64} flexGrow={1} overflowY="auto">
        <InvoiceMaterials dataSource={selectedInvoice} />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        style={{
          padding: '16px',
          gap: '16px',
          backgroundColor: 'white',
          borderTopStyle: 'solid',
          borderTopWidth: '1px',
          borderTopColor: '#f0f0f0',
          position: 'sticky',
          bottom: 0,
          zIndex: 1,
        }}
      >
        <Button disabled={loading} onClick={onBack} style={{ flexGrow: 1 }}>
          Back
        </Button>

        <Button
          loading={loading}
          data-cy="attach_invoice"
          type="primary"
          onClick={() => onAttachInvoices([selectedInvoice.id])}
          style={{ flexGrow: 1 }}
        >
          Attach
        </Button>
      </Box>
    </Box>
  )
})
