import React from 'react'

import { Input, Row, Col, Form } from 'antd'

import { observer } from 'mobx-react-lite'

import SelectAddress from 'common/components/address/selector'
import { Box } from 'common/components/boxes'
import { Card } from 'common/components/Card'
import DateTimePicker from 'common/components/date_time_picker'

import { DeliveryInstructions } from 'contractor/components/DeliveryInstructions'
import { SelectAddressTo } from 'contractor/components/OrderDeliveries/address_to_selector'
import { SelectCompanyVendorContact } from 'contractor/components/SelectCompanyVendorContact'
import { SelectProject } from 'contractor/components/SelectProject'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { SelectCommitment } from 'contractor/pages/@v2/Commitments/common/components/SelectCommitment/select_commitment'

import { usePrivateOrderForm } from './context'

const mapProjectOption = ({ id, name }) => ({ value: id, label: name })

type OrderOptionsProps = {
  requiredVendor: boolean
}

const OrderOptions = observer<OrderOptionsProps>(({ requiredVendor }: OrderOptionsProps) => {
  const { addressStore, userStore, projectStore, companySettingStore, addressToStore, orderStore } = useStores()
  const canUseCommitment = useFlag('commitments')
  const addressToEnabled = companySettingStore?.otherSettings?.order_fields_settings?.address_to?.enabled

  const addressToOptions = addressToStore?.addressesTo

  const { projectId, commitment } = usePrivateOrderForm()

  const projectOptions = React.useMemo(() => {
    return projectStore.projects.map(mapProjectOption)
  }, [projectStore.projects])

  const addressOptions = React.useMemo(() => {
    return addressStore.filteredAddresses([projectId])
  }, [projectId])

  const delivery = orderStore.newDeliveries[0]

  const lgSize = React.useMemo(() => {
    return canUseCommitment ? 4 : 5
  }, [canUseCommitment])

  return (
    <Card>
      <Row gutter={16}>
        <Col xs={24} md={12} lg={lgSize}>
          <Form.Item label="Project" name="projectId" rules={[{ required: true, message: 'Project is required' }]}>
            <SelectProject options={projectOptions} autoFocus disabled={!!commitment?.id} />
          </Form.Item>
        </Col>

        {canUseCommitment && (
          <Col xs={24} sm={12} lg={lgSize}>
            <Form.Item label="Commitment" name="commitmentId" style={{ width: '100%' }}>
              <SelectCommitment
                projectId={projectId}
                disabled={
                  orderStore.isSplitting || !projectId || orderStore.orderMaterialsLengthByDeliveryId(delivery.id) > 0
                }
              />
            </Form.Item>
          </Col>
        )}

        <Col xs={24} md={12} lg={lgSize}>
          <Form.Item
            data-cy="order-name"
            label="Order Title"
            name="orderPackageName"
            rules={[{ required: true, message: 'Order Title is required' }]}
          >
            <Input style={{ width: '100%' }} placeholder="Title of order" />
          </Form.Item>
        </Col>

        {userStore.canSendAndUpdateOrders && (
          <Col xs={24} md={12} lg={lgSize}>
            <Form.Item
              label="Vendor"
              name="companyVendorContact"
              rules={[
                {
                  required: requiredVendor,
                  message: 'Vendor is required',
                },
              ]}
            >
              <SelectCompanyVendorContact placeholder="Select vendor" hideDiscoverVendors />
            </Form.Item>
          </Col>
        )}

        <Col xs={24} md={12} lg={lgSize}>
          <Form.Item label="Address" name="addressId">
            <SelectAddress
              placeholder={'Select address'}
              addressOptions={addressOptions}
              addNewAddressOptionVisible={false}
              wrapperProps={{
                width: '100%',
                maxWidth: '100%',
              }}
            />
          </Form.Item>
        </Col>
        {addressToEnabled && (
          <Col xs={24} md={12} lg={lgSize - 1}>
            <Form.Item label="Attention To" name="addressToId">
              <SelectAddressTo
                addressToOptions={addressToOptions}
                placeholder="Optional"
                wrapperProps={{
                  maxWidth: '100%',
                  minWidth: '100%',
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>
        )}
        <Col xs={24} md={12} lg={lgSize} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Form.Item label="Deliver On" name="requestedDeliveredAt" style={{ flexGrow: 1 }}>
            <DateTimePicker
              wrapperProps={{
                width: '100%',
              }}
            />
          </Form.Item>

          <Box mt={1}>
            <DeliveryInstructions
              value={delivery?.instructions}
              onChange={(value) => {
                if (delivery) {
                  delivery.instructions = value
                }
              }}
            />
          </Box>
        </Col>
      </Row>
    </Card>
  )
})

export default OrderOptions
