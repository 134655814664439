import React, { useEffect } from 'react'

import { Form, message, Button, Input } from 'antd'

import { observer } from 'mobx-react-lite'

import { Drawer, DrawerRef, DrawerBody, DrawerFooter } from 'common/components/Drawer'
import { AddressTo } from 'common/server/addressesTo'

type AddressToProps = {
  onClose: () => void
  onSubmit: (Address: AddressTo) => Promise<void>
  initialValues?: AddressTo
}

const AddressToDrawer = observer<AddressToProps, DrawerRef>(
  (props, ref) => {
    const { onClose, onSubmit, initialValues } = props

    const [form] = Form.useForm()

    const [isSubmitting, setSubmitting] = React.useState(false)

    useEffect(() => {
      form.setFieldsValue(initialValues)
    }, [initialValues])

    const handleFinish = async (formValues) => {
      setSubmitting(true)

      const operation = initialValues?.id ? 'updated' : 'created'

      try {
        await onSubmit({ id: initialValues?.id, ...formValues })
        form.resetFields()
        message.success(`Successfully ${operation} attention to`)
      } catch (error) {
        message.error(error?.response?.data?.error || `Unable to ${operation} attention to`)
      } finally {
        setSubmitting(false)
      }
    }

    const handleClose = () => {
      form.resetFields()
      onClose()
    }

    return (
      <Drawer
        width={375}
        bgGray
        title={initialValues ? 'Edit Attention to' : 'Create a new Attention to'}
        ref={ref}
        onClose={handleClose}
      >
        <Form
          data-cy="address-to-drawer-form"
          layout="vertical"
          onFinish={handleFinish}
          form={form}
          style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <DrawerBody>
            <Form.Item required requiredMark name="name" label="Name" data-cy="address-to-drawer-name">
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="phone_number" label="Phone Number" data-cy="address-to-drawer-phone-number">
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="email" rules={[{ type: 'email' }]} label="Email" data-cy="address-to-drawer-email">
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </DrawerBody>

          <DrawerFooter>
            <Button onClick={handleClose} disabled={isSubmitting} style={{ width: 100 }}>
              Cancel
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              style={{ width: 100 }}
              data-cy="address-to-drawer-submit"
            >
              Save
            </Button>
          </DrawerFooter>
        </Form>
      </Drawer>
    )
  },
  { forwardRef: true },
)

export default AddressToDrawer
