import React from 'react'

import moment from 'moment'

import { Button, Col, DatePicker, Input, InputNumber, Row, TimePicker, Typography } from 'antd'

import { Box } from 'common/components/boxes'

import { FailureReason } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/LookAhead/failure_reason'
import { InspectionStatus } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/LookAhead/inspection_status'
import { ConcreteCommitmentMaterial } from 'contractor/server/commitments'

const Label = (props) => <Typography.Text type="secondary" style={{ fontSize: 10 }} {...props} />

type ConcreteMaterialRowProps = {
  dataSource: ConcreteCommitmentMaterial
  onChange: (value: ConcreteCommitmentMaterial, changes: ConcreteCommitmentMaterial) => void
}

export const LookAheadRow = React.memo((props: ConcreteMaterialRowProps) => {
  const { dataSource, onChange } = props

  const setReleaseDate = () => {
    onChange(dataSource, {
      ...dataSource,
      release_date: new Date().toISOString(),
    })
  }

  return (
    <Box
      bg="white"
      borderRadius={4}
      py={12}
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Row gutter={[16, 16]} align="middle" style={{ width: '100%' }}>
        <Col xs={12} sm={6} md={5} lg={6} xl={3} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Label>Date</Label>
            <DatePicker
              value={dataSource.schedule ? moment(dataSource.schedule) : null}
              onChange={(value) =>
                onChange(dataSource, {
                  ...dataSource,
                  schedule: value?.toISOString(),
                })
              }
            />
          </Box>
        </Col>

        <Col xs={12} sm={6} md={5} lg={5} xl={3} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Label>Time</Label>
            <TimePicker
              value={dataSource.time ? moment(dataSource.time) : null}
              format="HH:mm"
              onChange={(value) => {
                onChange(dataSource, {
                  ...dataSource,
                  time: value.toISOString(),
                })
              }}
            />
          </Box>
        </Col>

        <Col xs={12} sm={12} md={6} lg={5} xl={2} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Label>Mix code</Label>
            <Input
              value={dataSource?.company_material?.mix_code}
              onChange={(value) =>
                onChange(dataSource, {
                  ...dataSource,
                  company_material: {
                    ...dataSource.company_material,
                    mix_code: value.target.value,
                  },
                  company_material_id: null,
                })
              }
            />
          </Box>
        </Col>

        <Col xs={12} sm={10} md={8} lg={8} xl={3} xxl={4}>
          <Box display="flex" flexDirection="column">
            <Label>Mix design</Label>
            <Input
              value={dataSource?.company_material?.mix_design}
              onChange={(value) =>
                onChange(dataSource, {
                  ...dataSource,
                  company_material: {
                    ...dataSource.company_material,
                    mix_design: value.target.value,
                  },
                  company_material_id: null,
                })
              }
            />
          </Box>
        </Col>

        <Col xs={24} sm={14} md={11} lg={13} xl={6} xxl={7}>
          <Box display="flex" flexDirection="column">
            <Label>Location/Description</Label>
            <Input
              value={dataSource?.location}
              onChange={(value) =>
                onChange(dataSource, {
                  ...dataSource,
                  company_material: {
                    ...dataSource.company_material,
                    description: value.target.value,
                  },
                  company_material_id: null,
                })
              }
            />
          </Box>
        </Col>

        <Col xs={12} sm={12} md={5} lg={3} xl={3} xxl={3}>
          <Box display="flex" flexDirection="column">
            <Label>Estimated CY</Label>
            <InputNumber
              style={{ width: '100%' }}
              value={dataSource.estimated_cy}
              onChange={(value) =>
                onChange(dataSource, {
                  ...dataSource,
                  estimated_cy: value,
                })
              }
            />
          </Box>
        </Col>

        <Col xs={6} sm={5} md={4} lg={4} xl={2} xxl={2}>
          <Box display="flex" flexDirection="column">
            <Label>Inspection Status</Label>
            <InspectionStatus concreteMaterial={dataSource} onChange={onChange} />
          </Box>
        </Col>

        <Col
          xs={6}
          sm={7}
          md={4}
          lg={4}
          xl={2}
          xxl={2}
          style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
        >
          <Box width="100%" display="flex" flexDirection="column">
            <Label>Release</Label>
            <Button
              disabled={!dataSource.inspection_status || !!dataSource.release_date}
              type="primary"
              onClick={setReleaseDate}
              style={{ flexGrow: 1 }}
            >
              Confirm
            </Button>
          </Box>
        </Col>

        {!dataSource.inspection_status && (
          <Col span={24}>
            <FailureReason concreteMaterial={dataSource} onChange={onChange} />
          </Col>
        )}
      </Row>
    </Box>
  )
})
