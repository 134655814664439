/** @jsx jsx */

import React, { useRef } from 'react'

import { jsx } from '@emotion/core'

import { observer } from 'mobx-react-lite'

import SelectAddress, { AddressSelectorProps as CommonAddressSelectorProps } from 'common/components/address/selector'
import { FlexBoxX } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import { Address } from 'common/server/addresses'

import { AddressDrawer } from 'contractor/components/Addresses'
import { useStores } from 'contractor/hooks/use-stores'
import { create as createAddress } from 'contractor/server/addresses'

type AddressSelectorProps = {
  project_ids: string[]
  setAddress: (address: Nullable<Address>) => void
  onChangeAddressTo?: (addressTo: string) => void
  selectedAddress: Address
  placeholder?: string
  isPickUp?: boolean
  companyVendorId?: string
} & Partial<CommonAddressSelectorProps>

// TODO: Need to decide on what's mandatory vs optional
const AddressSelector: React.FC<AddressSelectorProps> = observer(
  ({ selectedAddress, setAddress, project_ids, placeholder, isPickUp, companyVendorId, ...props }) => {
    const { addressStore, orderStore } = useStores()

    const addressOptions = addressStore.filteredAddresses(project_ids)
    const hasValidProjectIds = project_ids.some((element) => element !== undefined)

    const drawerCreateAddressRef = useRef<DrawerRef>()

    // Submit addresses directly and set the delivery value to that addresses
    // Then update our addresses to refresh options
    const onSubmitAddress = async (address: Address, newAddressOwnedId) => {
      const newAddress = (await createAddress({ ...newAddressOwnedId, address })).data
      setAddress(newAddress)
      addressStore.indexAddresses()
      drawerCreateAddressRef.current?.close()
    }

    const companyVendor = companyVendorId
      ? addressOptions.companyVendor.filter((address) => address.owned_by_id === companyVendorId)
      : addressOptions.companyVendor

    return (
      <React.Fragment>
        <FlexBoxX width="100%">
          <SelectAddress
            setAddress={setAddress}
            selectedAddress={selectedAddress}
            placeholder={placeholder}
            addressOptions={{
              company: addressOptions.company,
              project: addressOptions.project,
              companyVendor,
            }}
            isProjectIdPresent={hasValidProjectIds}
            hideCompanyAddress={isPickUp}
            hideProjectAddress={isPickUp}
            hideVendorAddress={!isPickUp}
            addNewAddressOptionVisible={true}
            onShowAddressCreate={() => drawerCreateAddressRef.current?.show()}
            disabled={orderStore.isSplitting}
            wrapperProps={{
              minWidth: '250px',
            }}
            {...props}
          />
          <AddressDrawer
            data-cy={'create-address-drawer'}
            ref={drawerCreateAddressRef}
            onClose={() => drawerCreateAddressRef.current?.close()}
            onSubmit={onSubmitAddress}
            project_ids={project_ids}
            hideVendorAddress={!isPickUp}
            hideProjectAddress={isPickUp}
            hideCompanyAddress={isPickUp}
            companyVendorId={companyVendorId}
          />
        </FlexBoxX>
      </React.Fragment>
    )
  },
)

export default AddressSelector
