import React from 'react'

import styled from '@emotion/styled'

import { Table } from 'antd'

import { currencyFormatter } from 'common/helpers/formatters'
import { UnreconciledInvoiceResponse } from 'common/server/invoice'

import { Summary } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/Invoices/summary'

type InvoiceMaterialsProps = {
  dataSource: UnreconciledInvoiceResponse
}

const StyledTable = styled(Table)`
  .ant-table-footer {
    padding: 0 !important;
    margin: 0 !important;
  }
`

export const InvoiceMaterials = (props: InvoiceMaterialsProps) => {
  const { dataSource } = props
  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Quantity Shipped',
      dataIndex: 'quantity_shipped',
      key: 'quantity_shipped',
    },
    {
      title: 'Unit Price',
      dataIndex: 'unit_price',
      key: 'unit_price',
      render: (price: number) => currencyFormatter(price),
    },
    {
      title: 'Extended Price',
      dataIndex: 'extended_price',
      key: 'extended_price',
      render: (price: number) => currencyFormatter(price),
    },
    {
      title: 'UOM',
      dataIndex: 'uom',
      key: 'uom',
    },
  ]

  if (!dataSource) {
    return null
  }

  return (
    <StyledTable
      pagination={false}
      dataSource={dataSource?.invoice_materials}
      columns={columns}
      footer={() => <Summary dataSource={dataSource} />}
      rowKey={(record) => record.id || record.key}
    />
  )
}
