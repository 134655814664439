import React from 'react'

import FilterOutlined from '@ant-design/icons/FilterOutlined'
import SettingOutlined from '@ant-design/icons/SettingOutlined'
import { Button, Dropdown, Space } from 'antd'

import { SearchInput } from 'common/components/SearchInput'
import { Visibility } from 'common/components/Visibility'

import { useCommitment } from 'contractor/pages/@v2/Commitments/common/context/context'
import { ColumnsSettings } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/LogView/columns_settings'
import { ColumnsView } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/ExtraActions/LogView/columns_view'
import { ColumnsProps } from 'contractor/pages/@v2/Commitments/Concrete/Components/ConcreteMaterials/LogView/columns_definition'

type LogViewActionsProps = {
  columns: ColumnsProps[]
  toggleColumnVisibility: (dataIndex: string | string[]) => void
  toggleColumnEditable: (dataIndex: string | string[]) => void
}

export const LogViewActions = (props: LogViewActionsProps) => {
  const { toggleColumnVisibility, toggleColumnEditable, columns } = props
  const { setSearch, search } = useCommitment()

  return (
    <>
      <Visibility.Hidden breakpoint={'md'}>
        <Space>
          <SearchInput value={search} onSearch={(value) => setSearch(value)} style={{ minWidth: 300 }} />

          <Dropdown
            overlay={<ColumnsView columnSettings={columns} toggleColumnVisibility={toggleColumnVisibility} />}
            trigger={['click', 'hover']}
          >
            <Button icon={<FilterOutlined />} />
          </Dropdown>

          <Dropdown
            overlay={<ColumnsSettings columnSettings={columns} toggleColumnEditable={toggleColumnEditable} />}
            trigger={['click', 'hover']}
          >
            <Button icon={<SettingOutlined />} />
          </Dropdown>
        </Space>
      </Visibility.Hidden>
    </>
  )
}
