import axios from 'axios'

import {
  BasicRelationship,
  ListParams,
  MappingSuggestion,
  Relationship,
  SearchParams,
  UpdateMapping,
} from './integration'

export type UpdateCostCodePhase = UpdateMapping

export interface CostCodePhase {
  id: string
  code: string
  description?: string
  external_relationships: BasicRelationship[]
  mapping_suggestions?: MappingSuggestion[]
  project_ids?: string[]
}

export function cost_code_phases_relationships(params?: ListParams) {
  return axios.get<Relationship[]>('/integrations/accounting/cost_code_phases/relationships', { params })
}
export function cost_code_phases_count(mappingDirection: string, params?: SearchParams) {
  const queryParams = params
    ? { ...params, mapping_direction: mappingDirection }
    : { mapping_direction: mappingDirection }
  return axios.get<{ [key: string]: number }>(`/integrations/accounting/cost_code_phases/count`, {
    params: queryParams,
  })
}
export function cost_code_phases(params: ListParams) {
  return axios.get<CostCodePhase[]>(`/integrations/accounting/cost_code_phases`, { params })
}
export function update_cost_code_phase(cost_code: UpdateCostCodePhase) {
  return axios.patch(`/integrations/accounting/cost_code_phases/${cost_code?.id}`, cost_code)
}
export function resync_cost_code_phases() {
  return axios.patch('/integrations/accounting/cost_code_phases/resync')
}
export function import_sub_jobs() {
  return axios.post('/integrations/accounting/cost_code_phases/import_sub_jobs')
}
