import React from 'react'

import styled from '@emotion/styled'

import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Form, Switch, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { OrderMaterial } from 'common/server/orders'

const SwitchButtonWrapper = styled(Button)<{ hasIssues?: boolean; isActive?: boolean }>`
  padding: 4px 12px;
  background-color: ${({ isActive, hasIssues }) => (isActive ? (hasIssues ? '#f74a53' : '#71cf43') : 'default')};

  color: ${({ isActive }) => (isActive ? 'white' : 'black')};

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ hasIssues }) => (hasIssues ? '#f74a53' : '#71cf43')};
    color: white;
  }

  svg {
    color: inherit;
  }
`

type SwitchControlsProps = {
  showResolve?: boolean
  isLocked?: boolean
  onEditClick?: () => void
  isMobile?: boolean
  onSetIssue: () => void
  onRemoveIssue: () => void
  hasOpenIssue: boolean
  orderMaterial: OrderMaterial
  isResolved?: boolean
}

export const SwitchControls = (props: SwitchControlsProps) => {
  const {
    isLocked,
    onEditClick,
    onRemoveIssue,
    onSetIssue,
    hasOpenIssue,
    orderMaterial,
    showResolve,
    isMobile,
    isResolved,
  } = props

  if (showResolve && !isMobile) {
    return (
      <>
        <Typography.Text style={{ marginLeft: 8, marginRight: 8 }}>Resolved</Typography.Text>
        <Form.Item name={`resolved_${orderMaterial.id}`} style={{ marginBottom: 16 }}>
          <Switch noStyle checked={isResolved} style={{ marginLeft: 8, marginBottom: 0 }} />
        </Form.Item>
      </>
    )
  }

  if (isLocked) {
    return (
      <Button onClick={onEditClick}>
        <EditOutlined />
      </Button>
    )
  }

  return (
    <Box style={{ display: 'flex', marginLeft: 16 }}>
      <SwitchButtonWrapper hasIssues={false} isActive={!hasOpenIssue} onClick={onRemoveIssue} type="default">
        <CheckOutlined style={{ fontSize: 14 }} />
      </SwitchButtonWrapper>

      <SwitchButtonWrapper hasIssues={true} isActive={hasOpenIssue} onClick={onSetIssue} type="default">
        <CloseOutlined style={{ fontSize: 14 }} />
      </SwitchButtonWrapper>
    </Box>
  )
}
