import React, { useEffect, useRef } from 'react'

import { FlexBoxY } from 'common/components/boxes'
import { Comment as IComment } from 'common/server/comment'

import { Comment } from './comment'
import { NewComment } from './new_comment'

export type CommentsProps = {
  comments: IComment[]
  onChangeNewComment: (comment: string) => void
  newComment: string
  onSubmit?: (comment: string) => void
  currentView?: 'CONTRACTOR' | 'VENDOR' | 'SUBBASE'
  placeholder?: string
  loading?: boolean
  unableToUpdate?: boolean
}

export const Comments = ({
  comments = [],
  newComment,
  onChangeNewComment,
  onSubmit,
  currentView = 'CONTRACTOR',
  placeholder,
  loading,
  unableToUpdate,
}: CommentsProps) => {
  const commentsDivRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (commentsDivRef?.current) {
      commentsDivRef.current.scrollTop = commentsDivRef.current.scrollHeight
    }
  }, [comments.length])

  return (
    <FlexBoxY alignItems="flex-start" justifyContent="flex-start" p={16} pb={0} maxHeight="100%">
      <FlexBoxY
        ref={commentsDivRef}
        alignItems="flex-start"
        justifyContent="flex-start"
        width="100%"
        overflowY="auto"
        maxHeight={{ _: 'calc(100% - 180px)', md: 148 }}
        style={{ gap: 8 }}
      >
        {comments?.map((comment) => (
          <Comment
            data-cy={`comment-${comment.created_at}`}
            key={comment.created_at}
            comment={comment}
            currentView={currentView}
          />
        ))}
      </FlexBoxY>

      <NewComment
        data-cy="new-comment"
        newComment={newComment}
        onChange={onChangeNewComment}
        onSubmit={onSubmit}
        currentView={currentView}
        placeholder={placeholder}
        loading={loading}
        disabled={unableToUpdate}
      />
    </FlexBoxY>
  )
}
