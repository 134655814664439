import React, { useState } from 'react'

import { Button, message } from 'antd'

import { FooterProps } from './footer'

type QuickCreateMaterialProps = FooterProps

export type QuickCreateMaterial = {
  description: string
}

export const QuickCreateMaterial = (props: QuickCreateMaterialProps) => {
  const { materialDescription = '', onSelect, setIsOpen, disableQuickCreate } = props

  const [isSubmitting, setSubmitting] = useState(false)

  const handleCreateMaterial = async () => {
    setSubmitting(true)
    setIsOpen?.(false)
    try {
      const companyMaterial = {
        description: materialDescription.trim(),
      }
      onSelect(companyMaterial)
    } catch (err) {
      message.error(err?.response?.data?.error || 'Unable to create material')
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Button
      data-cy="quick-create-material-button"
      onClick={handleCreateMaterial}
      loading={isSubmitting}
      type="primary"
      style={{ width: 200 }}
      disabled={!!disableQuickCreate}
    >
      Quick Create &quot;
      {materialDescription.length > 9 ? materialDescription.slice(0, 9).concat('...') : materialDescription}&quot;
    </Button>
  )
}
