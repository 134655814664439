import React from 'react'

import { Card } from 'antd'

import { Box } from 'common/components/boxes'

import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../../context'
import { OrderItem } from '../OrderItem'

export const OrderViewer = () => {
  const { invoiceStore } = useStores()
  const { calcExtCost, selectedOrderMaterialIdsToReconcile, handleSelectOrderMaterialIdToReconcile } = useInvoice()

  const noMatches = invoiceStore.selectedOrdersMaterials
    .filter((orderMaterial) => !orderMaterial.is_invoiced)
    .filter((orderMaterial) => {
      return !invoiceStore.invoice?.invoice_materials?.some((invoiceMaterial) =>
        invoiceMaterial?.order_materials?.some((om) => om.id === orderMaterial.id),
      )
    })

  return (
    <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
      {noMatches.map((data) => (
        <Card key={data.id} bodyStyle={{ padding: 12 }}>
          <OrderItem
            item={{
              calcExtCost,
              id: data.id,
              quantity_shipped: data?.quantity_delivered ?? data?.quantity,
              unit_price: Number(data?.unit_cost),
              checked: !!selectedOrderMaterialIdsToReconcile.find((item) => item === data.id),
              description: data?.company_material?.description,
              unit_multiplier: Number(data?.company_material?.unit?.multiplier),
              unit_qty_increment: Number(data?.company_material?.unit?.qty_increment),
              uom: data?.company_material?.unit?.name || data?.company_material?.unit_name,
            }}
            onCheck={handleSelectOrderMaterialIdToReconcile}
          />
        </Card>
      ))}
    </Box>
  )
}
