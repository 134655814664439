import React, { useRef } from 'react'

import PlusOutlined from '@ant-design/icons/PlusOutlined'
import { Button, Tooltip } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import {
  SelectCostCode as SelectCostCodeComponent,
  SelectCostCodeProps as SelectCostCodeComponentProps,
  makeCostCodeValue,
} from 'common/components/SelectCostCode'
import { useQuery } from 'common/hooks/use-query'
import { CostCode } from 'common/server/cost_codes/cost_codes'

import { useStores } from 'contractor/hooks/use-stores'
import { UpsertCostCode } from 'contractor/pages/CompanyMaterials/CostCodes/Upsert'

export type SelectCostCodeProps = {
  isCreate?: boolean
} & Partial<SelectCostCodeComponentProps>

export const SelectCostCode = observer<SelectCostCodeProps>(({ isCreate, ...props }) => {
  const { costCodeStore, companySettingStore } = useStores()

  const costCodeSettings = companySettingStore.otherSettings?.cost_code_settings

  const createCostCodeRef = useRef<DrawerRef>()

  const { isLoading } = useQuery(costCodeStore.fetchAllActiveCostCodes)

  return (
    <Box width="100%" display="inline-flex" style={{ gap: 10 }}>
      <SelectCostCodeComponent
        data-cy="select-cost-code"
        loading={isLoading}
        costCodes={costCodeStore.costCodeListStore.records}
        costCodeSettings={costCodeSettings}
        {...props}
      />

      {isCreate && (
        <>
          <Tooltip title="Add New Cost Code">
            <Button
              data-cy="add-new-cost-code-button"
              onClick={() => createCostCodeRef.current?.show()}
              icon={<PlusOutlined />}
              disabled={props?.disabled}
              style={{ minWidth: 32 }}
            />
          </Tooltip>

          <UpsertCostCode
            ref={createCostCodeRef}
            onCancel={() => createCostCodeRef.current?.close()}
            onSubmit={(costCode: CostCode) => {
              const value = makeCostCodeValue(costCode, costCodeSettings)
              props?.onChange(value, null)
            }}
          />
        </>
      )}
    </Box>
  )
})
