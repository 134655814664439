import React from 'react'

import { Form } from 'antd'

import { FloatActionsWrapper } from 'common/components/FloatActionsWrapper'
import { Page } from 'common/components/Page'
import { Visibility } from 'common/components/Visibility'

import { makeCompanyVendorOption } from 'contractor/components/SelectCompanyVendor'
import { IndexCompanyVendor } from 'contractor/server/company_vendors'

import { Actions } from './components'
import { withEditPriceSheetProvider, useEditPriceSheet } from './context'
import { Header } from './Header'
import { Materials } from './Materials'

const getFormUnit = (formValues) => {
  if (formValues?.unit?.value === formValues?.unit?.label) {
    return { unit_name: formValues?.unit?.label, unit_id: null }
  }

  if (formValues?.unit?.value) {
    return { unit_id: formValues?.unit?.value, unit_name: null }
  }

  return { unit_id: null, unit_name: null }
}

export const PriceSheetEdit = withEditPriceSheetProvider(() => {
  const { handleUpdatePriceSheetDraft, form, applyPreferredPrice, priceSheet, updatedMaterialsRef, disabledForm } =
    useEditPriceSheet()

  return (
    <Page>
      <Form
        key={`${priceSheet?.id}-${priceSheet?.updated_at}`}
        layout="vertical"
        initialValues={{
          name: priceSheet?.name,
          companyVendor: makeCompanyVendorOption(priceSheet?.company_vendor as IndexCompanyVendor),
          projectId: priceSheet?.project_id,
          number: priceSheet?.number,
          startAt: priceSheet?.start_at,
          endAt: priceSheet?.end_at,
        }}
        disabled={disabledForm()}
        form={form}
        onFinish={(formValues) => {
          handleUpdatePriceSheetDraft(
            {
              id: priceSheet?.id,
              name: formValues.name,
              company_vendor_id: formValues.companyVendor?.value,
              project_id: formValues.projectId,
              number: formValues.number,
              start_at: formValues.startAt,
              end_at: formValues.endAt,
              price_sheet_materials: updatedMaterialsRef.current?.map((material) => {
                return {
                  id: material.id,
                  upc: material.upc,
                  description: material.description,
                  ...getFormUnit(material),
                  size: material.size,
                  price: material.price,
                  company_material: {
                    id: material.company_material?.id,
                    description: material.company_material?.description,
                    size: material.company_material?.size,
                    ...getFormUnit(material.company_material),
                  },
                  _destroy: material._destroy,
                }
              }),
            },
            applyPreferredPrice,
          )
        }}
        style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <Page.Header display="flex" flexDirection="column">
          <Header priceSheet={priceSheet} />
        </Page.Header>

        <Page.Content p={0} overflowY="hidden" display="flex" flexDirection="column">
          <Materials />
        </Page.Content>

        <Visibility.Show breakpoint="md">
          <FloatActionsWrapper>
            <Actions priceSheetState={priceSheet?.workflow_state} />
          </FloatActionsWrapper>
        </Visibility.Show>
      </Form>
    </Page>
  )
})
